import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import TitleRow from '../../../molecules/performAssignment/TitleRow';
import TabsRow from '../../../molecules/performAssignment/TabsRow';
import TabsRowMobile from '../../../molecules/performAssignment/TabsRowMobile';
import TimelineRow from '../../../molecules/performAssignment/TimelineRow';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';

const TopSection: React.FC = () => {
  const { isFold, setFold } = useContext(PerformanceContext);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const onClickFold = (): void => {
    setFold(!isFold);
  };

  return (
    <>
      {isDesktop ? (
        <div style={{ padding: '10px 20px 0 20px' }}>
          {!isFold && (
            <>
              <TitleRow />
              <TimelineRow />
            </>
          )}
          <TabsRow isFold={isFold} onClickFold={onClickFold} />
        </div>
      ) : (
        <div>
          {!isFold && (
            <div style={{ padding: '1rem' }}>
              <TitleRow />
              <TimelineRow />
            </div>
          )}
          <TabsRowMobile isFold={isFold} onClickFold={onClickFold} />
        </div>
      )}
    </>
  );
};

export default TopSection;
