import styled from 'styled-components';

const TopTitle = styled.h3`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 32px;
  color: #173c51;
`;

export default TopTitle;
