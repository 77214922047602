import styled from 'styled-components';

const DivMid = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export default DivMid;
