import React, { useContext } from 'react';
import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import SwitchPerformanceContext from '../../../../pages/PerformAssignment/SwitchPage/context';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import { switchStage } from '../../../../pages/EditAssignment/context';

export const EditorContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: scroll;
`;
const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 10,
      backgroundColor: 'rgba(23,60,81,0.2)',
    },
    editor: {
      flex: 1,
      width: '75%',
      maxWidth: '60vw',
      backgroundColor: '#fff',
      margin: '0 auto',
      padding: '72px 90px 90px 72px',
      fontSize: '16px',
      lineHeight: '1.5',
    },
    mobileEditor: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      margin: '0 auto',
      padding: '15px 20px 90px 20px',
      fontSize: '15px',
      lineHeight: '1.5',
    },
  }),
);
const EditorSection: React.FC = () => {
  const classes = useStyles();
  const { currentEditState, setCurrentEditState, is_completed } = useContext(
    SwitchPerformanceContext,
  );
  const { current_stage, is_waiting_next_stage } = useContext(
    PerformanceContext,
  );
  const prefix = switchStage(current_stage.stage);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <EditorContainer>
      {isDesktop ? (
        <Editor
          editorState={currentEditState}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          readOnly={
            (prefix !== 'FE' && is_completed) ||
            prefix === 'CO' ||
            (is_waiting_next_stage && is_completed)
          }
          onEditorStateChange={(editorState): void => {
            setCurrentEditState(editorState);
          }}
          localization={{
            locale: 'ko',
          }}
        />
      ) : (
        <Editor
          editorState={currentEditState}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.mobileEditor}
          readOnly={
            (prefix !== 'FE' && is_completed) ||
            prefix === 'CO' ||
            (is_waiting_next_stage && is_completed)
          }
          onEditorStateChange={(editorState): void => {
            setCurrentEditState(editorState);
          }}
          localization={{
            locale: 'ko',
          }}
        />
      )}
    </EditorContainer>
  );
};

export default React.memo(EditorSection);
