import styled from 'styled-components';

const QuestionListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export default QuestionListItem;
