import styled from 'styled-components';

const CoverLine = styled.img`
  @media (min-width: 768px) {
    margin-left: -2px;
    width: 20%;
    margin-right: 26.5px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  border-style: none;
`;

export default CoverLine;
