import styled from 'styled-components';

const AddDiv = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  marginbottom: '24px';
`;

export default AddDiv;
