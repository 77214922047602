import React from 'react';
import { FetchReportFeedbackReviewMatchingType } from 'apis';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  Answer,
  Question,
  ScoreText,
  useReportStyles,
  NothingDiv,
} from '../../../atoms/reports/containers';

type FeedbackReviewCardProps = {
  feedbackreviewmatching: FetchReportFeedbackReviewMatchingType | null;
};

const FeedbackReviewCard: React.FC<FeedbackReviewCardProps> = ({
  feedbackreviewmatching,
}) => {
  const classes = useReportStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { t } = useTranslation();

  if (!feedbackreviewmatching || !feedbackreviewmatching.point) {
    return (
      <NothingDiv>
        {t('reportsFeedbackReviewCard.noFeedHundredPyeongIsNotWritten')}
      </NothingDiv>
    );
  }
  return (
    <>
      {isDesktop ? (
        <>
          {' '}
          <Question>
            <span>
              {t('reportsFeedbackReviewCard.howHelpfulDidYouEvaluate')}
            </span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              className={classes.inline}
            >
              <Rating
                name="read-only"
                max={feedbackreviewmatching.feedback.max_point}
                value={feedbackreviewmatching.point}
                readOnly
              />
              <ScoreText>
                {`${feedbackreviewmatching.point} / ${feedbackreviewmatching.feedback.max_point}`}
              </ScoreText>
            </Box>
          </Question>
          <Answer>{feedbackreviewmatching.comment}</Answer>
        </>
      ) : (
        <>
          <Question>
            <span>
              {t('reportsFeedbackReviewCard.howHelpfulDidYouEvaluate')}
            </span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              style={{ marginLeft: '0.2rem' }}
            >
              <Rating
                name="read-only"
                max={feedbackreviewmatching.feedback.max_point}
                value={feedbackreviewmatching.point}
                readOnly
              />
              <ScoreText>
                {`${feedbackreviewmatching.point} / ${feedbackreviewmatching.feedback.max_point}`}
              </ScoreText>
            </Box>
          </Question>
          <Answer>{feedbackreviewmatching.comment}</Answer>
        </>
      )}
    </>
  );
};

export default FeedbackReviewCard;
