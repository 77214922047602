import React, { useContext } from 'react';
import { BasicDivTop100, FontSize18Div } from 'components/atoms';
import PersonalScoreContext from 'pages/ScoreProgress/context';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContainerForm: React.FC = ({ children }) => {
  const { enrollId } = useParams();
  const { name } = useContext(PersonalScoreContext);
  const { t } = useTranslation();
  const text = enrollId
    ? t('scoreProgressContainerForm.viewYourPersonalScores', { name })
    : t('scoreProgressContainerForm.myTaskScoreTrend');
  return (
    <BasicDivTop100>
      <FontSize18Div>{text}</FontSize18Div>
      {children}
    </BasicDivTop100>
  );
};

export default ContainerForm;
