import React, { useContext } from 'react';
import { FormGroup } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputLabel,
  NAField,
  Section,
  SectionLabelNormal,
  StageCheckbox,
  StageLabel,
} from '../../../atoms/newAssignmnets';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

const STAGES = [
  { name: 'essay', disabled: true, label: '에세이작성' },
  { name: 'question', disabled: false, label: '질문작성' },
  { name: 'peer', disabled: true, label: '동료평가' },
  // { name: 'adjust', disabled: false, label: '조정' },
  { name: 'feedback', disabled: false, label: '피드백평가' },
  { name: 'revise', disabled: false, label: '퇴고' },
];

const StageSelection: React.FC = () => {
  const { register, getValues, watch } = useFormContext();
  const { currentStage } = useContext(IsEditableContext);
  const { t } = useTranslation();
  const disableStages = STAGES.map((stage) => {
    const prefix = stage.name.slice(0, 2).toUpperCase();
    const currentStageIndex = stageIndex.indexOf(currentStage);
    const index = stageIndex.indexOf(prefix);
    return {
      ...stage,
      disabled: stage.disabled || currentStageIndex >= index,
      transLabel: t(`app.${stage.label}`),
    };
  });
  const state = getValues();
  return (
    <Section>
      <SectionLabelNormal>
        {t('newAssignmentsStageSelection.selectTheTaskStage')}
      </SectionLabelNormal>
      <NAField>
        <InputLabel>{t('newAssignmentsStageSelection.taskStage')}</InputLabel>
        <FormGroup row>
          {disableStages
            .filter((stage) => {
              if (stage.name !== 'revise') {
                return true;
              }
              return state.feedback;
            })
            .map((stage) => {
              watch(stage.name);
              return (
                <StageLabel key={stage.name}>
                  <StageCheckbox
                    type="checkbox"
                    name={stage.name}
                    ref={register}
                    disabled={stage.disabled}
                  />
                  {stage.transLabel}
                </StageLabel>
              );
            })}
        </FormGroup>
      </NAField>
    </Section>
  );
};

export default StageSelection;
