import styled from 'styled-components';
import ButtomButton from '../../courses/BottomButton';

const ButtonSave = styled(ButtomButton)`
  color: #5646ff;
  border-color: #5646ff;
  margin-right: 10px;
  outline: 0;
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
`;

export default ButtonSave;
