import styled from 'styled-components';

const RadioLabel = styled.label`
  &:not(:last-child) {
    margin-right: 20px;
  }
  display: inline-flex;
  align-items: center;
  font-weight: 300;
`;

export default RadioLabel;
