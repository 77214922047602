import { createReducer } from 'typesafe-actions';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { CoursesState } from './types';
import { COURSES } from './actions';

const initialState: CoursesState = {
  courses: [],
  currentCourse: '',
};

const courses = createReducer<CoursesState>(initialState, {
  [COURSES.FETCH_COURSES_SUCCESS]: (state, action) => {
    return {
      ...state,
      courses: action.payload,
    };
  },
  [COURSES.FETCH_COURSES_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersCoursesReducer.youDoNotHavePermission'));
    errorNotify();
    return {
      ...state,
    };
  },
  [COURSES.CREATE_COURSE_SUCCESS]: (state) => {
    const successNotify = (): ReactText =>
      toast.success(t('reducersCoursesReducer.lecturesHaveBeenCreated'));
    successNotify();
    return {
      ...state,
    };
  },
  [COURSES.CREATE_COURSE_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersCoursesReducer.thisIsTheWrongRequest'));
    errorNotify();
    return {
      ...state,
    };
  },
  [COURSES.SET_CURRENT_COURSE]: (state, payload) => {
    return {
      ...state,
      currentCourse: payload.payload,
    };
  },
});

export default courses;
