import React, { ChangeEvent, useContext } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ManageMainContext from '../../../../pages/managers/ManagerMain/context';
import AppContext from '../../../../App/context';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    field: {
      margin: '1rem',
      flex: 1,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  };
});

export type CourseSearchFormState = {
  q: string;
  group: number | string;
};
type CourseSearchFormProps = {
  state: CourseSearchFormState;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const CourseSearchForm: React.FC<CourseSearchFormProps> = ({
  state,
  onChange,
}) => {
  const classes = useStyles();
  const groups = useContext(ManageMainContext);
  const { is_integrated_management } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <TextField
        label={t('courseSearchForm.search') ?? '검색'}
        variant="outlined"
        name="q"
        onChange={onChange}
        value={state.q}
        className={classes.field}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {is_integrated_management && (
        <TextField
          label={t('courseSearchForm.group') ?? '그룹'}
          name="group"
          value={state.group}
          onChange={onChange}
          variant="outlined"
          className={classes.field}
          select
        >
          <MenuItem value="">{t('courseSearchForm.all')}</MenuItem>
          {groups.map((group) => {
            return (
              <MenuItem value={group.id} key={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    </div>
  );
};

export default CourseSearchForm;
