import { createContext } from 'react';
import { FetchMaxScoreResponseType } from '../../apis/assignments';

type FetchMaxScoreContextType = {
  refetch: () => void;
};

const ScoreContext = createContext<
  FetchMaxScoreResponseType & FetchMaxScoreContextType
>({
  id: 0,
  name: '',
  scores: [],
  reports: [],
  current_stage: {
    id: 0,
    start_date: null,
    end_date: null,
    stage: '',
    stage_object: {
      id: 0,
    },
  },
  refetch: () => {},
});

export default ScoreContext;
