import { createReducer } from 'typesafe-actions';
import { SettingsState } from './types';
import { SETTING } from './actions';

const initialState: SettingsState = {
  isWideSidebar: true,
  sideRole: 'learner',
};

const settings = createReducer<SettingsState>(initialState, {
  [SETTING.TOGGLE_SIDEBAR]: (state) => {
    return {
      ...state,
      isWideSidebar: !state.isWideSidebar,
    };
  },
  [SETTING.CHANGE_SIDE_ROLE]: (state, payload) => {
    return {
      ...state,
      sideRole: payload.payload,
    };
  },
});

export default settings;
