import styled from 'styled-components';
import NASaveButton from '../NASaveButton';

const NACancelButton = styled(NASaveButton)`
  border-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.2);
  }
`;

export default NACancelButton;
