import styled from 'styled-components';

const Input = styled.input`
  @media (max-width: 767px) {
    font-size: 16px;
  }
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  color: #173c51;
  font-weight: 300;
  ::placeholder {
    color: #bebebf;
    font-weight: 300;
  }
`;

export default Input;
