import styled from 'styled-components';

const StageOptionInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background: rgba(6, 88, 169, 0.2);
  padding: 9px 14px;
  font-size: 15px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  margin-right: 7px;
`;

export default StageOptionInput;
