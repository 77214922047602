import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import useProfileActions from 'hooks/profiles/useProfileActions';
import useProfiles from 'hooks/profiles/useProfiles';
import randomColor from 'randomcolor';

const BarDemo: React.FC = () => {
  const { dispatchFetchStandardScores } = useProfileActions();
  const { standardScores } = useProfiles();
  const colors = randomColor({
    count: standardScores.length,
    hue: 'blue',
    luminosity: 'light',
  });
  const data = {
    labels: standardScores.map((score) => score.assignment),
    datasets: [
      {
        fill: false,
        lineTension: 0.5,
        backgroundColor: colors,
        borderWidth: 1,
        borderColor: colors,
        data: standardScores.map((score) => score.standard_score),
      },
    ],
  };
  const options = {
    credits: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  useEffect(() => {
    dispatchFetchStandardScores();
  }, [dispatchFetchStandardScores]);
  return <Bar data={data} options={options} height={100} />;
};

export default React.memo(BarDemo);
