import styled from 'styled-components';

const Form = styled.form`
  @media (min-width: 768px) {
    width: 100%;
    margin: auto;
  }
`;

export default Form;
