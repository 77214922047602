import React, { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  BtnFullWidthOutline,
  ButtonFold,
  BasicDivFlex,
  BasicDivFlexRight,
} from 'components/atoms';
import styled from 'styled-components';
import PeerReviewCard from 'components/molecules/peerReviewCard';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import PeerReviewMatchingContext from 'pages/PerformAssignment/PeerReviewMatching/context';
import { FormContext, useForm } from 'react-hook-form';
import {
  peerReviewMatchingUpdateAPI,
  PeerReviewMatchingUpdateAPIProps,
} from 'apis/peerReviews';
import { toast } from 'react-toastify';
import { StandardType } from 'apis';
import PerformanceContext from 'pages/PerformAssignment/PerformanceMain/context';
import useSettings from 'hooks/settings/useSettings';
import { useTranslation } from 'react-i18next';

const FixedDiv = styled.div`
  position: fixed;
  overflow: auto;
  width: calc(100% - 280px);
  bottom: 0;
  z-index: 1;
  padding: 5px;
`;

const FixedDivLg = styled(FixedDiv)`
  @media (min-width: 768px) {
    width: calc(100% - 57px);
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const CardBackgroundDiv = styled.div`
  padding: 10px;
  background-color: #fff;
  flex: 1;
  min-width: 33%;
`;
const BottomDiv = styled(BasicDivFlexRight)`
  color: #173c51;
  margin: 0 8px 8px 0;
`;
const ReviewBtn = styled(BtnFullWidthOutline)`
  width: auto;
  color: #fff;
  border-color: #71bad8;
  background: rgba(113, 186, 216, 1);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background: rgba(113, 186, 216, 0.5);
    border-color: rgba(113, 186, 216, 0.5);
  }
`;

const FormDiv = styled.div`
  padding: 0 8px 8px 8px;
  border: 1px solid rgba(23, 60, 81, 0.4);
  background-color: #fff;
`;

const ReviewSection: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);
  const { url } = useRouteMatch();
  const history = useHistory();
  const handleClick = (): void => {
    setShow(!show);
  };

  const { setFold } = useContext(PerformanceContext);
  useEffect(() => {
    setFold(true);
  }, [setFold]);

  const { isWideSidebar } = useSettings();
  const { id, responses } = useContext(PeerReviewMatchingContext);
  const { current_stage, is_waiting_next_stage, refetch } = useContext(
    PerformanceContext,
  );
  const isOnProgressStage = current_stage.stage === '동료평가';
  const isReadOnly =
    isOnProgressStage && !is_waiting_next_stage ? undefined : true;
  const methods = useForm<PeerReviewMatchingUpdateAPIProps>();
  const onSubmit = async (
    formData: PeerReviewMatchingUpdateAPIProps,
  ): Promise<void> => {
    try {
      const response = await peerReviewMatchingUpdateAPI(formData);
      toast.success(
        t('componentsOrganismsPeerReviewMatchingReviewSection.submitted'),
      );
      const notCompletedList = response.data.not_completed_list.sort(
        (matching) => {
          return matching.reviewer === matching.target ? 1 : -1;
        },
      );
      if (notCompletedList.length > 0) {
        // eslint-disable-next-line no-alert
        if (
          window.confirm(
            t('componentsOrganismsPeerReviewMatchingReviewSection.following'),
          )
        ) {
          const pathParts = url.split('/');
          const targetPath = pathParts.slice(0, pathParts.length - 1).join('/');

          // 본인 제외하고 오름차순 정렬
          notCompletedList.sort(function compare(a, b) {
            if (a.reviewer === a.target) {
              return 1;
            }
            if (b.reviewer === b.target) {
              return -1;
            }
            return a.id - b.id;
          });

          history.push(`${targetPath}/${notCompletedList[0].id}`);
        }
      } else {
        refetch();
      }
    } catch (e) {
      toast.warn(
        t('componentsOrganismsPeerReviewMatchingReviewSection.wrongRequest'),
      );
    }
  };
  return (
    <div style={{ position: 'relative' }}>
      {isWideSidebar ? (
        <FixedDiv>
          {show ? (
            <ButtonFold type="button" onClick={handleClick}>
              <UnfoldLessIcon style={{ verticalAlign: 'middle' }} />
              {t('componentsOrganismsPeerReviewMatchingReviewSection.hide')}
            </ButtonFold>
          ) : (
            <ButtonFold type="button" onClick={handleClick}>
              <UnfoldMoreIcon />
            </ButtonFold>
          )}
          <div style={{ height: 260, display: show ? 'inherit' : 'none' }}>
            <FormDiv>
              <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <BasicDivFlex>
                    <input
                      type="hidden"
                      name="id"
                      value={id}
                      ref={methods.register.bind(methods)}
                    />
                    {responses.map((response, order) => {
                      const standard = response.standard as StandardType;
                      return (
                        <CardBackgroundDiv key={response.id}>
                          <PeerReviewCard
                            id={response.id}
                            order={order}
                            standard={standard.question}
                            maxPoint={standard.max_point}
                            point={response.point}
                            comment={response.comment}
                            isReadOnly={isReadOnly}
                          />
                        </CardBackgroundDiv>
                      );
                    })}
                  </BasicDivFlex>
                  <BottomDiv>
                    <ReviewBtn type="submit" disabled={isReadOnly}>
                      {responses.every((response) => !!response.point)
                        ? t(
                            'componentsOrganismsPeerReviewMatchingReviewSection.modification',
                          )
                        : t(
                            'componentsOrganismsPeerReviewMatchingReviewSection.save',
                          )}
                    </ReviewBtn>
                  </BottomDiv>
                </form>
              </FormContext>
            </FormDiv>
          </div>
        </FixedDiv>
      ) : (
        <FixedDivLg>
          {show ? (
            <ButtonFold type="button" onClick={handleClick}>
              <UnfoldLessIcon style={{ verticalAlign: 'middle' }} />
              {t('componentsOrganismsPeerReviewMatchingReviewSection.hide')}
            </ButtonFold>
          ) : (
            <ButtonFold type="button" onClick={handleClick}>
              <UnfoldMoreIcon />
            </ButtonFold>
          )}
          {show ? (
            <div style={{ height: '260px' }}>
              <FormDiv>
                <FormContext {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <BasicDivFlex>
                      <input
                        type="hidden"
                        name="id"
                        value={id}
                        ref={methods.register.bind(methods)}
                      />
                      {responses.map((response, order) => {
                        const standard = response.standard as StandardType;
                        return (
                          <CardBackgroundDiv key={response.id}>
                            <PeerReviewCard
                              id={response.id}
                              order={order}
                              standard={standard.question}
                              maxPoint={standard.max_point}
                              point={response.point}
                              comment={response.comment}
                              isReadOnly={isReadOnly}
                            />
                          </CardBackgroundDiv>
                        );
                      })}
                    </BasicDivFlex>
                    <BottomDiv>
                      <ReviewBtn type="submit" disabled={isReadOnly}>
                        {responses.every((response) => !!response.point)
                          ? t(
                              'componentsOrganismsPeerReviewMatchingReviewSection.modification',
                            )
                          : t(
                              'componentsOrganismsPeerReviewMatchingReviewSection.save',
                            )}
                      </ReviewBtn>
                    </BottomDiv>
                  </form>
                </FormContext>
              </FormDiv>
            </div>
          ) : null}
        </FixedDivLg>
      )}
    </div>
  );
};

export default ReviewSection;
