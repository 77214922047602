import styled from 'styled-components';

const StageStatusText = styled.h6`
  margin-bottom: 18px;
  text-align: center;
  color: #4a6cbb;
  font-weight: 400;
  font-size: 15px;
`;

export default StageStatusText;
