import styled from 'styled-components';

const ButtonAddFilesText = styled.input`
  margin-left: 3px;
  font-size: 15px;
  letter-spacing: -0.8px;
  color: rgba(0, 0, 0, 0.6);
`;

export default ButtonAddFilesText;
