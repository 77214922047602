import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  border-radius: 40px;
  background: #5646ff;
  cursor: pointer;
  color: white;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  border: 1px solid #5646ff;
  line-height: 1.4;
  padding: 11px 24px 10px 24px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: -1px;
`;
export default Button;
