import React, { useContext, useEffect } from 'react';
import Report from 'templates/Report';
import Container from 'components/organisms/reports/Container';
import ScoreSection from 'components/organisms/reports/ScoreSection/';
import FeedbackSection from 'components/organisms/reports/FeedbackSection/';
import EssaySection from 'components/organisms/reports/EssaySection';
import OthersSection from 'components/organisms/reports/OthersSection';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { reportFetchAPI } from 'apis';
import PerformanceContext from 'pages/PerformAssignment/PerformanceMain/context';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import MobileScoreSection from 'components/organisms/reportsMobile/ScoreSection/';
import MobileEssaySection from 'components/organisms/reportsMobile/EssaySection/';
import MobileFeedbackSection from 'components/organisms/reportsMobile/FeedbackSection/';
import MobileCommentSection from 'components/organisms/reportsMobile/CommentSection/';
import MobileOthersSection from 'components/organisms/reportsMobile/OthersSection/';
import LoadingPage from '../LoadingPage';
import ReportContext from './context';
import CommentSection from '../../components/organisms/reports/CommentSection';

const ReportPage: React.FC = () => {
  const { courseId, assignmentId, reportId, role } = useParams();
  const { data, isLoading, isError, refetch } = useQuery(
    ['report', courseId, assignmentId, reportId, role],
    reportFetchAPI,
  );
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const { setFold, setPageTitle } = useContext(PerformanceContext);
  useEffect(() => {
    setFold(true);
  }, [setFold]);
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t('pagesReport.completion'));
  }, [setPageTitle, t]);

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <ReportContext.Provider value={{ ...data.data, refetch }}>
      {isDesktop ? (
        <Report
          Container={Container}
          ScoreSection={ScoreSection}
          EssaySection={EssaySection}
          FeedbackSection={FeedbackSection}
          CommentSection={CommentSection}
          OthersSection={OthersSection}
        />
      ) : (
        <>
          <Report
            Container={Container}
            ScoreSection={MobileScoreSection}
            EssaySection={MobileEssaySection}
            FeedbackSection={MobileFeedbackSection}
            CommentSection={MobileCommentSection}
            OthersSection={MobileOthersSection}
          />
        </>
      )}
    </ReportContext.Provider>
  );
};

export default ReportPage;
