import styled from 'styled-components';

const PostWritingContentTextarea = styled.textarea`
  border: none;
  width: 100%;
  font-size: 16px;
  overflow: auto;
  line-height: 1.15;
  ::placeholder {
    color: #bebebf;
    font-weight: 300;
  }
`;

export default PostWritingContentTextarea;
