import styled from 'styled-components';

const CancelButton = styled.button`
  font-weight: 300;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #5646ff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  padding: 8px 24px 7px 24px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  background: none;
  letter-spacing: -1px;
  outline: 0;
  opacity: 0.7;
  border-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
`;
export default CancelButton;
