import styled from 'styled-components';

const SaveButton = styled.button`
  box-sizing: border-box;
  font-weight: 300;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  display: inline-block;
  color: #5646ff;
  border: 1px solid #5646ff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  padding: 8px 24px 7px 24px;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  background: none;
  letter-spacing: -1px;
  -webkit-appearance: button;
  opacity: 0.7;
`;
export default SaveButton;
