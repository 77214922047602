import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IsEditableContext } from 'pages/EditAssignment/context';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  NACancelButton,
  NAFormAction,
  NAInnerAction,
  NASaveButton,
} from '../../../atoms/newAssignmnets';
import { deleteAssignment } from '../../../../apis/assignments';

const OButtonDelete = styled(NASaveButton)`
  color: rgba(255, 49, 75, 0.7);
  border-color: #ff314b;
  margin-right: auto;
  &:hover {
    color: rgba(255, 49, 75, 0.2);
    border-color: rgba(255, 49, 75, 0.7);
  }
`;

const FormAction: React.FC = () => {
  const history = useHistory();
  const { assignmentId, courseId, role } = useParams();
  const { isEdit } = useContext(IsEditableContext);
  const { t } = useTranslation();
  const onDelete = async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    const answer = window.confirm(
      t('newAssignmentsFormAction.theTaskIsDeletedWouldYouLikeToContinue'),
    );
    if (answer) {
      try {
        await deleteAssignment(courseId, assignmentId);
        toast.success(t('newAssignmentsFormAction.theTaskHasBeenDeleted'));
        history.push(`/courses/${courseId}/${role}`);
      } catch (e) {
        toast.warn(t('newAssignmentsFormAction.thisIsTheWrongRequest'));
      }
    }
  };
  return (
    <NAFormAction>
      {isEdit && (
        <OButtonDelete type="button" onClick={onDelete}>
          {t('newAssignmentsFormAction.delete')}
        </OButtonDelete>
      )}
      <NAInnerAction>
        <NACancelButton type="button" onClick={(): void => history.goBack()}>
          {t('newAssignmentsFormAction.cancellation')}
        </NACancelButton>
        <NASaveButton type="submit">
          {isEdit
            ? t('newAssignmentsFormAction.correction')
            : t('newAssignmentsFormAction.produce')}
        </NASaveButton>
      </NAInnerAction>
    </NAFormAction>
  );
};

export default FormAction;
