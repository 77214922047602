import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UserBox,
  UserBoxButton,
  UserBoxEmail,
  UserBoxInfo,
  UserBoxName,
} from '../../../atoms/mains';
import useProfiles from '../../../../hooks/profiles/useProfiles';

const MenuUser: React.FC = () => {
  const {
    user: { name, email },
  } = useProfiles();
  const { t } = useTranslation();
  return (
    <UserBox>
      <UserBoxInfo>
        <div>
          <UserBoxName>{name}</UserBoxName>
          <UserBoxEmail>{email}</UserBoxEmail>
          <Link to="/my-page">
            <UserBoxButton>{t('menuUser.goToMyPage')}</UserBoxButton>
          </Link>
        </div>
      </UserBoxInfo>
    </UserBox>
  );
};

export default MenuUser;
