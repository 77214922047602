import styled from 'styled-components';

const StageContainer = styled.div`
  &:not(last-child) {
    margin-bottom: 25px;
  }
  color: #173c51;
  font-weight: 300;
`;

export default StageContainer;
