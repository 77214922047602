import styled from 'styled-components';

const CommonDivFlex = styled.div`
  line-height: 1.5;
  margin-bottom: 18px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  font-size: 18px;
  flex-wrap: wrap;
`;

export default CommonDivFlex;
