import React from 'react';
import { Wrapper } from 'components/atoms';
import { useHistory, useParams } from 'react-router-dom';
import { FormContext, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ChoiceCreateType, coursePostCreateAPI } from 'apis';
import { serializeFormData } from 'utils';
import { useTranslation } from 'react-i18next';

export type FormDataType = {
  title: string;
  content: string;
  files?: FileList;
  choices?: ChoiceCreateType[];
};

const ContainerForm: React.FC = ({ children }) => {
  const methods = useForm<FormDataType>();
  const history = useHistory();
  const { courseId } = useParams();
  const { t } = useTranslation();

  const onSubmit = async (data: FormDataType): Promise<void> => {
    const formData = serializeFormData(data);
    try {
      await coursePostCreateAPI({ courseId, coursePost: formData });
      toast.success(t('componentsOrganismsPostWritingContainerForm.created'));
      history.goBack();
    } catch (e) {
      toast.warn(t('componentsOrganismsPostWritingContainerForm.wrong'));
    }
  };
  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Wrapper style={{ padding: '54px 40px' }}>{children}</Wrapper>
      </form>
    </FormContext>
  );
};

export default ContainerForm;
