import React, { useContext, useRef, useState } from 'react';
import {
  ActionWrapper,
  Button,
  Field,
  FieldWrapper,
  Form,
  FormTitle,
  Links,
  StyledLink,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { signUpAPI, SignUpAPIProps } from '../../../../apis';
import ErrorParagraph from '../../../atoms/authentications/ErrorParagraph';
import AppContext from '../../../../App/context';
import DaumPostDialog from '../../modals/DaumPostDialog';

type SignUpFormData = {
  passwordCheck: string;
} & SignUpAPIProps;

export const Select = styled(Field).attrs({ as: 'select' })`
  &:invalid {
    padding-left: 5px;
  }
`;

const SignUpForm: React.FC = () => {
  const { is_private_info } = useContext(AppContext);
  const {
    register,
    errors,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm<SignUpFormData>({});
  const history = useHistory();
  const password = useRef({});
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  password.current = watch('password', '');
  watch('attending_type');
  const onSubmit = async (values: SignUpAPIProps): Promise<void> => {
    try {
      await signUpAPI(values);
      toast.success(t('signUpForm.success'));
      reset();
      history.push('/sign-in');
    } catch (e) {
      toast.warn(
        t([
          `signUpForm.${e.response.status}`,
          'signUpForm.thisIsTheWrongRequest',
        ]),
      );
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>{t('signUpForm.starting')}</FormTitle>
      <FieldWrapper>
        <Field
          placeholder="Email"
          autoFocus
          required
          type="email"
          name="email"
          ref={register}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Field
          placeholder={t('signUpForm.name') ?? ''}
          required
          type="text"
          name="name"
          ref={register}
        />
      </FieldWrapper>
      {is_private_info && (
        <>
          <FieldWrapper>
            <Select
              required
              name="attending_type"
              ref={register}
              defaultValue=""
            >
              <option value="" disabled hidden>
                {t('signUpForm.schoolSelection')}
              </option>
              <option value="N">{t('signUpForm.notApplicable')}</option>
              <option value="E">{t('signUpForm.elementarySchool')}</option>
              <option value="M">{t('signUpForm.middleSchool')}</option>
              <option value="H">{t('signUpForm.highSchool')}</option>
              <option value="U">{t('signUpForm.university')}</option>
            </Select>
          </FieldWrapper>
          {['E', 'M', 'H', 'U'].includes(getValues('attending_type') || '') && (
            <FieldWrapper>
              <Field
                placeholder={t('signUpForm.grade') ?? ''}
                required
                max={6}
                min={1}
                type="number"
                name="grade"
                ref={register}
              />
            </FieldWrapper>
          )}
          <FieldWrapper>
            <Field
              placeholder={t('signUpForm.address') ?? ''}
              required
              onClick={(): void => {
                setOpen(true);
              }}
              type="text"
              name="address"
              ref={register}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              placeholder={t('signUpForm.dateOfBirth') ?? ''}
              required
              type="text"
              name="date_of_birth"
              onFocus={(e): void => {
                e.currentTarget.type = 'date';
              }}
              ref={register}
            />
          </FieldWrapper>
        </>
      )}
      <FieldWrapper>
        <Field
          placeholder={t('signUpForm.passwordAtLeast6Letters') ?? ''}
          required
          type="password"
          name="password"
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 6,
              message: t('signUpForm.thePasswordMustBeEnteredAtLeast6Digits'),
            },
          })}
        />
        {errors.password && (
          <ErrorParagraph>{errors.password.message}</ErrorParagraph>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <Field
          placeholder={t('signUpForm.password') ?? ''}
          required
          type="password"
          name="passwordCheck"
          ref={register({
            validate: (value) => {
              if (value === password.current) return true;
              const msg = t('signUpForm.itDoesNotMatchThePassword', '');
              return msg;
            },
          })}
        />
        {errors.passwordCheck && (
          <ErrorParagraph>{errors.passwordCheck.message}</ErrorParagraph>
        )}
      </FieldWrapper>
      <ActionWrapper>
        <Button type="submit">{t('signUpForm.signUp')}</Button>
      </ActionWrapper>
      <Links>
        <StyledLink to="/sign-in">{t('signUpForm.login')}</StyledLink>
      </Links>
      <DaumPostDialog open={open} setOpen={setOpen} setValue={setValue} />
    </Form>
  );
};

export default SignUpForm;
