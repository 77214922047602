import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { BtnGrayLgOutline } from 'components/atoms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import StudentNumberEditDialog from '../../../organisms/modals/StudentNumberEdit';
import { DivMyClassInfo } from '../../../atoms/courses';
import { learnerInfoFetchAPI } from '../../../../apis';

const useStyles = makeStyles(() => ({
  tdColor: {
    color: '#5646ff',
    fontSize: '15px',
  },
  paddingBoth: {
    padding: '0 10px',
    fontWeight: 400,
  },
  editText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    outline: 0,
  },
  divAs: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Btn = styled(BtnGrayLgOutline)`
  border: 1px solid rgba(23, 60, 81, 0.5);
  color: rgb(23, 60, 81);
  margin-left: 20px;
`;

const Div = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const MyClassInfo: React.FC = () => {
  const classes = useStyles();
  const { courseId } = useParams();
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery(
    ['learnerInfo', courseId],
    learnerInfoFetchAPI,
  );

  const [open, setOpen] = useState(false);
  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };

  const { t } = useTranslation();
  if (!data || isLoading) {
    return <div>loading...</div>;
  }

  return (
    <Div>
      <DivMyClassInfo>
        <span className={classes.tdColor}>
          {t('myClassInfo.myCourseInformation')}
        </span>
        <span className={classes.paddingBoth}>{data.data.learner_code}</span>
        <button
          type="submit"
          className={classes.editText}
          onClick={handleOpenToggle(true)}
        >
          {t('myClassInfo.correction')}
        </button>
        <StudentNumberEditDialog
          handleClose={handleOpenToggle(false)}
          open={open}
          refetch={refetch}
          learnerCode={data.data.learner_code}
        />
      </DivMyClassInfo>

      {!!data.data.is_assistant && (
        <div className={classes.divAs}>
          <Btn
            onClick={(): void => history.push(`/courses/${courseId}/assistant`)}
          >
            {t('myClassInfo.assistantMode')}
          </Btn>
        </div>
      )}
    </Div>
  );
};

export default MyClassInfo;
