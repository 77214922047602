import styled from 'styled-components';

const ColoredSquar = styled.div`
  width: 7px;
  height: 7px;
  background: #2f64de;
  display: inline-block;
  margin-right: 7px;
`;

export default ColoredSquar;
