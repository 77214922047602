import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchConfigures, isStaffAPI } from 'apis';
import { Layout, MainContainer } from '../../../components/atoms/mains';
import MainTopBar from '../../../components/molecules/mains/MainTopbar';
import ManagerMainSide from '../../../components/organisms/managers/ManagerMainSide';
import Users from '../Users';
import UserDetail from '../UserDetail';
import UserCreation from '../UserCreation';
import Courses from '../Courses';
import Assignments from '../Assignments';
import AssignmentDetail from '../AssignmentDetail';
import LoadingPage from '../../LoadingPage';
import ManageMainContext from './context';
import CourseDetail from '../CourseDetail';

const ManagerMain: React.FC = () => {
  const { path } = useRouteMatch();
  const { data, isError, isLoading } = useQuery({
    queryKey: 'manager-apis',
    queryFn: () => {
      return Promise.all([isStaffAPI(), fetchConfigures()]);
    },
  });
  if (isError) {
    return <Redirect to="/" />;
  }
  if (!data || isLoading) {
    return <LoadingPage />;
  }
  const [, { data: configurations }] = data;
  return (
    <ManageMainContext.Provider value={configurations}>
      <Layout>
        <MainTopBar />
        <ManagerMainSide />
        <MainContainer>
          <Switch>
            <Route path={`${path}/users`} component={Users} exact />
            <Route path={`${path}/users/new`} component={UserCreation} exact />
            <Route
              path={`${path}/users/:userId`}
              component={UserDetail}
              exact
            />
            <Route path={`${path}/courses`} component={Courses} exact />
            <Route
              path={`${path}/courses/:courseId`}
              component={CourseDetail}
              exact
            />
            <Route path={`${path}/assignments`} component={Assignments} exact />
            <Route
              path={`${path}/assignments/courses/:courseId/assignments/:assignmentId`}
              component={AssignmentDetail}
              exact
            />
            <Redirect path="*" to={`${path}/users`} />
          </Switch>
        </MainContainer>
      </Layout>
    </ManageMainContext.Provider>
  );
};

export default React.memo(ManagerMain);
