import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import NewAssignment from '../../pages/NewAssignment';
import CoursePage from '../../pages/Course';
import PostWritingPage from '../../pages/PostWriting';
import Post from '../../pages/Post';
import PostEditing from '../../pages/PostEditing';
import manageAssignmentPage from '../../pages/ManageAssignment';
import CourseEditing from '../../pages/CourseEditing';
import EditAssignment from '../../pages/EditAssignment';
import { roleFetchAPI } from '../../apis';
import PerformanceMain from '../../pages/PerformAssignment/PerformanceMain';
import useCoursesActions from '../../hooks/courses/useCoursesActions';
import Score from '../../pages/Score';
import Report from '../../pages/Report';
import CourseResults from '../../pages/CourseResults';
import ScoreProgressPage from '../../pages/ScoreProgress';
import LoadingPage from '../../pages/LoadingPage';
import useSettingActions from '../../hooks/settings/useSettingActions';
import { RoleContext } from './context';

export type RouteType = {
  path: string;
  component: React.FC;
  exact: boolean;
};
const COURSE_ROUTES: RouteType[] = [
  { path: '', component: CoursePage, exact: true },
  { path: '/posts/new', component: PostWritingPage, exact: true },
  { path: '/posts/:postId(\\d+)', component: Post, exact: true },
  { path: '/posts/:postId(\\d+)/edit', component: PostEditing, exact: true },
  { path: '/assignments/new', component: NewAssignment, exact: true },
  { path: '/edit', component: CourseEditing, exact: true },
  {
    path: '/assignments/:assignmentId(\\d+)/edit',
    component: EditAssignment,
    exact: true,
  },
  {
    path: '/assignments/:assignmentId(\\d+)/performances/:performanceId',
    component: PerformanceMain,
    exact: false,
  },
  {
    path: '/assignments/:assignmentId(\\d+)/score',
    component: Score,
    exact: true,
  },
  {
    path: '/assignments/:assignmentId(\\d+)/reports/:reportId(\\d+)',
    component: Report,
    exact: false,
  },
  {
    path: '/assignments/:assignmentId(\\d+)',
    component: manageAssignmentPage,
    exact: false,
  },
  {
    path: '/results',
    component: CourseResults,
    exact: true,
  },
  {
    path: '/results/enrolls/:enrollId(\\d+)/personal-score',
    component: ScoreProgressPage,
    exact: true,
  },
  {
    path: '/my-score',
    component: ScoreProgressPage,
    exact: true,
  },
];

const CourseRoute: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {COURSE_ROUTES.map((route) => {
        return (
          <Route
            key={route.path}
            exact={route.exact}
            path={`${path}${route.path}`}
            component={route.component}
          />
        );
      })}
      <Redirect path="*" to="/" />
    </Switch>
  );
};

export const MiddleCourseRouter: React.FC = () => {
  const {
    params: { role, courseId },
  } = useRouteMatch();
  const { data, isLoading, isError } = useQuery(
    ['role', courseId],
    roleFetchAPI,
  );
  const { dispatchChangeSideRole } = useSettingActions();
  const { dispatchSetCurrentCourse } = useCoursesActions();
  useEffect(() => {
    if (data) {
      dispatchSetCurrentCourse(data.data.course);
      dispatchChangeSideRole(role);
    }
  }, [data, dispatchSetCurrentCourse, dispatchChangeSideRole, role]);

  if (isError) {
    return <Redirect to="/" />;
  }
  if (!data || isLoading) {
    return <LoadingPage />;
  }

  const { is_assistant, is_instructor, is_learner } = data.data;
  switch (role) {
    case 'instructor':
      if (!is_instructor) {
        return <Redirect to="/" />;
      }
      break;
    case 'learner':
      if (!is_learner) {
        return <Redirect to="/" />;
      }
      break;
    case 'assistant':
      if (!is_assistant) {
        return <Redirect to="/" />;
      }
      break;
    default:
      return <Redirect to="/" />;
  }

  return (
    <RoleContext.Provider value={{ ...data.data }}>
      <CourseRoute />
    </RoleContext.Provider>
  );
};
