import React from 'react';

interface ScoreProps {
  Container: React.FC;
  Top: React.FC;
  Mid: React.FC;
  Bottom: React.FC;
}

const Score: React.FC<ScoreProps> = ({ Container, Top, Mid, Bottom }) => {
  return (
    <Container>
      <Top />
      <Mid />
      <Bottom />
    </Container>
  );
};

export default Score;
