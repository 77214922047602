import React, { useContext } from 'react';
import {
  AssignmentDescription,
  AssignmentName,
  CourseName,
} from 'components/atoms';
import ManageAssignmentContext from 'pages/ManageAssignment/context';
import useCourses from '../../../../hooks/courses/useCourses';

const TopSectionForm: React.FC = () => {
  const { name, description } = useContext(ManageAssignmentContext);
  const { currentCourse } = useCourses();
  return (
    <div>
      <CourseName>{currentCourse}</CourseName>
      <AssignmentName>{name}</AssignmentName>
      <AssignmentDescription>{description}</AssignmentDescription>
    </div>
  );
};

export default TopSectionForm;
