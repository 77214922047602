import styled from 'styled-components';
import BtnPurpleLg from '../BtnPurpleLg';

const BtnGrayLgOutline = styled(BtnPurpleLg)`
  background: #fff;
  color: #acacac;
  border: 1px solid #acacac;
  outline: 0;
`;

export default BtnGrayLgOutline;
