import styled from 'styled-components';

const RightBox = styled.div`
  @media (min-width: 768px) {
    margin-right: 20px;
    min-width: 266px;
    margin-bottom: auto;
  }

  @media (min-width: 768px) {
    padding: 24px;
    padding-top: 27px;
  }
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
  width: 80%;
`;

export default RightBox;
