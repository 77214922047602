import styled, { css, FlattenInterpolation } from 'styled-components';

type CoverDivType = {
  rgb: [number, number, number];
};

const CoverDiv = styled.div<CoverDivType>`
  ${({ rgb }): FlattenInterpolation<CoverDivType> => css`
    @media (min-width: 768px) {
      background: ${`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.9)`};
      position: relative;
      display: flex;
      padding-right: 20px;
      min-width: 500px;
      max-width: 50%;
    }
    @media (max-width: 767px) {
      background: ${`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`};
      flex: 0;
    }
    flex: 1;
    color: white;
  `}
`;

export default CoverDiv;
