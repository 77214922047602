import styled from 'styled-components';

const StageOptionCaption = styled.p`
  font-size: 13px;
  letter-spacing: -0.9px;
  color: rgba(0, 0, 0, 0.6);
  margin: 4px 0 12px;
  font-weight: 300;
`;

export default StageOptionCaption;
