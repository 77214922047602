import React, { useContext, useEffect, useState } from 'react';
import {
  BasicDivFlexBetween,
  BtnSkyblueLgOutline,
  EssayWritingBottomBarDiv,
  FileLabel,
  FontSize18Span,
} from 'components/atoms';
import { convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Clear';
import { useParams } from 'react-router-dom';
import SwitchPerformanceContext, {
  SwitchPerformanceContextType,
} from 'pages/PerformAssignment/SwitchPage/context';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import MainContext from 'pages/PerformAssignment/PerformanceMain/context';
import {
  EssayWritingType,
  updatePerformance,
  WritingEssayPerformanceType,
} from '../../../../apis';
import { serializeFormData } from '../../../../utils';
import { switchStage } from '../../../../pages/EditAssignment/context';
import { fileGenerateHelper, onFileCancelHelper } from './helpers';

export const OverrideFileLabel = styled(FileLabel)`
  text-decoration: none;
  margin-top: 0;
`;
export const OverrideField = styled(BtnSkyblueLgOutline).attrs({
  as: 'div',
})`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
export const FileField = styled.a`
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
`;
export const FileFieldContainer = styled.div`
  border-radius: 8px;
  border: 1px solid;
  padding: 10px;
  margin: 5px 20px 5px 0;

  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  overflow: auto;
  :hover {
    color: #0090ff;
  }
`;
export const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const OrangeSpan = styled.span`
  @media (min-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
  font-weight: 400;
  color: orange;
`;

export const OCancelItem = styled(CancelIcon)`
  color: rgba(200, 90, 90, 0.6);
  :hover {
    color: rgba(200, 90, 90, 0.9);
  }
  margin-left: 15px;
`;

const BottomBar: React.FC = () => {
  const { progresses, current_stage, is_waiting_next_stage } = useContext(
    MainContext,
  );
  const essay_info_array = progresses.find(
    (progress) => progress.stage === '에세이작성',
  ) as EssayWritingType;
  const { max_length, limit_type } = essay_info_array.stage_object;

  const {
    currentEditState,
    refetch,
    stage_object: { files, content },
  } = useContext(SwitchPerformanceContext) as WritingEssayPerformanceType &
    SwitchPerformanceContextType;

  const [change, setChange] = useState(true);
  const [fileChange, setFileChange] = useState(false);
  const { courseId, assignmentId, performanceId } = useParams();
  const { t } = useTranslation();
  const currentText = currentEditState.getCurrentContent().getPlainText();
  let currentLength = 0;
  if (currentText !== '') {
    if (limit_type === 'W') {
      currentLength = currentText.split(/\s+/).length;
    } else {
      currentLength = currentText.length;
    }
  }
  const style = max_length < currentLength ? { color: 'red' } : {};
  useEffect(() => {
    try {
      const originContent = JSON.parse(content);
      const currentContent = convertToRaw(currentEditState.getCurrentContent());

      if (
        JSON.stringify(originContent) === JSON.stringify(currentContent) &&
        !fileChange
      ) {
        setChange(false);
      } else {
        setChange(true);
      }
    } catch (e) {
      setChange(true);
    }
  }, [currentEditState, content, fileChange]);

  const onClick = async (): Promise<void> => {
    if (currentLength > max_length) {
      toast.warn(t('revisementBottomBar.itExceededTheMaximumWrittenVolume'));
    } else {
      const data = serializeFormData({
        stage_object: {
          content: convertToRaw(currentEditState.getCurrentContent()),
          currentLength,
        },
      });
      try {
        await updatePerformance({
          data,
          courseId,
          assignmentId,
          performanceId,
        });

        toast.success(t('revisementBottomBar.essayHasBeenSubmitted'));
        setFileChange(false);
        refetch();
      } catch (e) {
        toast.warn(t('revisementBottomBar.thisIsTheWrongRequest'));
      }
    }
  };
  const onFileCancel = (fileName: string, fileId: number) => async (): Promise<
    void
  > => {
    await onFileCancelHelper({
      courseId,
      assignmentId,
      performanceId,
      fileName,
      refetch,
      fileId,
    });
    setFileChange(true);
  };

  const isEditable =
    switchStage(current_stage.stage) === 'FE' && !is_waiting_next_stage;
  const renderFileBtn = (): null | React.ReactElement => {
    if (!isEditable) {
      return null;
    }
    return (
      <OverrideFileLabel>
        <input
          type="file"
          name="files"
          accept="application/pdf,.mp4"
          style={{ display: 'none' }}
          onChange={async (e): Promise<void> => {
            await fileGenerateHelper({
              courseId,
              assignmentId,
              performanceId,
              refetch,
            })(e);
            setFileChange(true);
          }}
        />
        <OverrideField>
          <AttachFileIcon />
          {t('revisementBottomBar.fileUpload')}
        </OverrideField>
      </OverrideFileLabel>
    );
  };
  return (
    <fieldset disabled={!isEditable}>
      <EssayWritingBottomBarDiv>
        <BasicDivFlexBetween>
          <div style={{ fontSize: '18px' }}>
            <FontSize18Span style={style}>{currentLength}</FontSize18Span>
            <span> / </span>
            <span>{max_length}</span>{' '}
            {limit_type === 'L' && t('revisementBottomBar.letter')}
            {limit_type === 'W' && t('revisementBottomBar.word')}
          </div>
          {!isEditable && (
            <div>
              <OrangeSpan>
                {t(
                  'revisementBottomBar.youCanOnlyModifyItDuringThePeriodOfRetirement',
                )}
              </OrangeSpan>
            </div>
          )}
          <ActionContainer>
            {files.length > 0 &&
              files.map((file) => {
                const fileArray = file.file.split('/');
                const name = decodeURI(fileArray[fileArray.length - 1]);
                return (
                  <FileFieldContainer key={file.file}>
                    <AttachFileIcon />
                    <FileField download href={file.file} target="_blank">
                      {name}
                    </FileField>
                    {isEditable && (
                      <OCancelItem
                        onClick={onFileCancel(name, file?.id || 0)}
                      />
                    )}
                  </FileFieldContainer>
                );
              })}
            {renderFileBtn()}
            <BtnSkyblueLgOutline
              style={{ padding: '10px 50px' }}
              onClick={onClick}
              disabled={!change}
              type="button"
            >
              {change
                ? t('revisementBottomBar.saveAndSubmission')
                : t('revisementBottomBar.stored')}
            </BtnSkyblueLgOutline>
          </ActionContainer>
        </BasicDivFlexBetween>
      </EssayWritingBottomBarDiv>
    </fieldset>
  );
};

export default BottomBar;
