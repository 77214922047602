import styled from 'styled-components';

const TopManageButton = styled.a`
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(6, 88, 169, 0.7);
  border: 1px solid #5646ff;
  border-radius: 8px;
  line-height: 1.4;
  padding: 8px 24px 7px 24px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: 0.3s;
  letter-spacing: -1;
  border-color: rgba(6, 88, 169, 0.7);
  margin-left: 10px;
`;

export default TopManageButton;
