import styled from 'styled-components';

const Field = styled.div`
  margin-bottom: 27px;
  :last-child {
    margin-bottom: 0;
  }
`;

export default Field;
