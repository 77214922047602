import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  BtnSkyblueLgOutline,
  CardBorderedOnelineDiv,
  MgFt16Div,
  QuestionCard,
  QuestionDiv,
} from 'components/atoms';
import styled from 'styled-components';
import { EditorType, assignmentLearnerInfo } from 'apis';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import EssayViewCompareModal from '../../modals/EssayViewCompare';
import EssayViewModal from '../../modals/EssayView';
import ReportContext from '../../../../pages/Report/context';
import { CurrentEssay } from '../../reports/EssaySection';

const CustomBtn = styled(BtnSkyblueLgOutline)`
  white-space: nowrap;
  margin-right: 5px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 0.2rem;
  }
`;
const PurpleBtn = styled(CustomBtn)`
  color: #5646ff;
  border: 1px solid #5646ff;
`;
const BlueBtn = styled(CustomBtn)`
  color: #0765e8;
  border: 1px solid #0765e8;
`;
const AnswerDiv = styled(QuestionDiv)`
  font-weight: 300;
  line-height: 1.4;
  font-size: 15px;
`;
const Div = styled.div`
  margin-bottom: 15px;
`;
const Heading = styled.div`
  font-size: 15px;
  color: rgba(23, 60, 81, 0.5);
`;

const EssaySection: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { name, is_my_report, questions, essay, revisement } = useContext(
    ReportContext,
  );

  // 퇴고단계가 존재하는지 확인하기 위함
  const {
    params: { courseId, assignmentId },
  } = useRouteMatch();
  const { data: assignmentData } = useQuery(
    ['assignmentInfo', courseId, assignmentId],
    assignmentLearnerInfo,
  );
  const isExsistRevisement = assignmentData?.data.progresses.some(
    (a) => a.stage === '퇴고',
  );
  //
  const isExistQuestion = questions.responses.some(
    (data) => data.response.length > 0,
  );
  const [currentEssay, setCurrentEssay] = useState<CurrentEssay>({
    essay: '',
    files: [],
    editor_type: EditorType.DRAFT,
  });
  const handleOpenToggle = (state: boolean, type: string) => (): void => {
    if (type === 'origin') {
      setCurrentEssay({
        essay: essay.content,
        files: essay.files,
        editor_type: essay.editor_type,
      });
    } else if (type === 'revise') {
      setCurrentEssay({
        essay: revisement.content,
        files: revisement.files,
        editor_type: revisement.editor_type,
      });
    }
    setOpen(state);
  };
  const handleOpenToggle2 = (state: boolean) => (): void => {
    setOpen2(state);
  };
  const disabled = !essay.content && essay.files.length === 0;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {!isExistQuestion ? (
          <Heading>
            {t('componentsOrganismsReportsMobileEssaySection.myEssay')}
          </Heading>
        ) : (
          <Heading>
            {t(
              'componentsOrganismsReportsMobileEssaySection.myEssaysAndQuestions',
            )}
          </Heading>
        )}
      </AccordionSummary>
      <AccordionDetails style={{ width: '100%', display: 'block' }}>
        <CardBorderedOnelineDiv>
          <MgFt16Div style={{ marginBottom: 0 }}>
            {is_my_report
              ? t('componentsOrganismsReportsMobileEssaySection.myEssay')
              : t('componentsOrganismsReportsMobileEssaySection.notMyEssay', {
                  name,
                })}
          </MgFt16Div>
          <div style={{ marginLeft: 'auto' }}>
            {isExsistRevisement ? (
              <PurpleBtn
                onClick={handleOpenToggle(true, 'origin')}
                disabled={disabled}
              >
                {t('componentsOrganismsReportsMobileEssaySection.before')}
                {open}
              </PurpleBtn>
            ) : (
              <PurpleBtn
                onClick={handleOpenToggle(true, 'origin')}
                disabled={disabled}
              >
                {t('componentsOrganismsReportsMobileEssaySection.essayText')}
                {open}
              </PurpleBtn>
            )}

            {isExsistRevisement && (
              <>
                <CustomBtn
                  onClick={handleOpenToggle(true, 'revise')}
                  disabled={
                    !revisement.content &&
                    (revisement.files?.length === 0 || !revisement.files)
                  }
                >
                  {t('componentsOrganismsReportsMobileEssaySection.after')}
                  {open}
                </CustomBtn>
                <BlueBtn
                  onClick={handleOpenToggle2(true)}
                  disabled={!essay.content && !revisement.content}
                >
                  {t('componentsOrganismsReportsMobileEssaySection.comparison')}
                  {open2}
                </BlueBtn>
              </>
            )}
          </div>
        </CardBorderedOnelineDiv>

        {isExistQuestion ? (
          <QuestionCard style={{ padding: '24px 24px 9px 24px' }}>
            <MgFt16Div style={{ marginBottom: '20px' }}>
              {is_my_report
                ? t('componentsOrganismsReportsMobileEssaySection.myQuestion')
                : t(
                    'componentsOrganismsReportsMobileEssaySection.notMyQuestion',
                    { name },
                  )}
            </MgFt16Div>
            {questions.responses.map((data) => {
              return (
                <Div key={data.id}>
                  <QuestionDiv>{data.quiz.quiz}</QuestionDiv>
                  <AnswerDiv>{data.response}</AnswerDiv>
                </Div>
              );
            })}
          </QuestionCard>
        ) : null}
        <EssayViewModal
          content={currentEssay.essay || ''}
          files={currentEssay.files || []}
          editor_type={currentEssay.editor_type}
          handleClose={handleOpenToggle(false, '')}
          open={open}
        />
        <EssayViewCompareModal
          content={
            essay.editor_type === EditorType.DRAFT
              ? essay.content || ''
              : essay.plain_text
          }
          content2={
            revisement.editor_type === EditorType.DRAFT
              ? revisement.content || ''
              : revisement.plain_text
          }
          content_editor_type={essay.editor_type}
          content2_editor_type={revisement.editor_type}
          handleClose={handleOpenToggle2(false)}
          open={open2}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EssaySection;
