import Dashboard from '@material-ui/icons/Dashboard';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardMenu, Menu, SideHr } from '../../../atoms/mains';
import MainSupport from '../../../molecules/mains/MainSupport';
import MainCourse from '../../../molecules/mains/MainCourse';
import useProfiles from '../../../../hooks/profiles/useProfiles';

const MainMenu: React.FC = () => {
  const {
    role,
    user: { courses, enrolls },
  } = useProfiles();
  const { pathname } = useLocation();
  const isInstructor = role === '교수자';
  const history = useHistory();
  const { t } = useTranslation();
  const iconStyle = { marginRight: 5 };
  const addIconStyle = { ...iconStyle, marginLeft: 5 };
  return (
    <Menu>
      <DashboardMenu
        isActive={pathname === '/'}
        onClick={(): void => {
          history.push('/');
        }}
      >
        <Dashboard style={iconStyle} />
        {t('mainMenu.dashboard')}
      </DashboardMenu>
      <SideHr />
      {isInstructor && (
        <MainCourse
          iconStyle={iconStyle}
          addIconStyle={addIconStyle}
          isInstructor
          courses={courses}
        />
      )}
      <MainCourse
        iconStyle={iconStyle}
        addIconStyle={addIconStyle}
        isInstructor={false}
        courses={enrolls.map((enroll) => enroll.course)}
      />
      <SideHr />
      <MainSupport iconStyle={iconStyle} />
    </Menu>
  );
};

export default MainMenu;
