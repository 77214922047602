import styled from 'styled-components';

const BlankTh = styled.th`
  width: 100%;
  font-size: 15px;
  min-width: 100%;
  padding: 13px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  color: rgba(23, 60, 81, 0.5);
  font-weight: 300;
`;

export default BlankTh;
