import styled from 'styled-components';

const DivHover = styled.div`
  color: #cccccc;
  margin-left: auto;

  :hover {
    color: #5646ff;
  }
`;
export default DivHover;
