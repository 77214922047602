import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { coursePostsFetchAPI } from 'apis';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import {
  BasicDivFlexBothCentered,
  BtnPurpleLgOutline,
  CommonDivFlex,
  CommonDivider,
  FontSize14Div,
  IconNotice,
  IconVote,
  OtherTitleText,
} from 'components/atoms';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../../../pages/LoadingPage';

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

const CustomDiv = styled(CommonDivFlex)`
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: flex-end;
  }
`;

const CustomTablePagination = styled(TablePagination)`
  .MuiIconButton-root {
    padding: 0 12px;
  }
  .MuiTablePagination-toolbar {
    min-height: 36px;
  }
  .MuiTablePagination-caption {
    font-size: 12px;
  }
  .MuiIconButton-root {
    border-radius: 0;
  }
`;

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(
  props: TablePaginationActionsProps,
): React.ReactElement {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles2 = makeStyles({
  table: {
    minWidth: 650,
  },
  addIcon: {
    fontSize: '22px',
    verticalAlign: 'middle',
  },
  title: {
    // fontSize: '16px',
    fontSize: '13px',
  },
  date: {
    // fontSize: '15px',
    fontSize: '12px',
  },
  row: {
    cursor: 'pointer',
  },
  tableFooter: {
    minHeight: '36px',
  },
});

const CustomPaginationActionsTable: React.FC = () => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(3);
  const {
    url,
    params: { courseId },
  } = useRouteMatch();
  const { data, isLoading } = useQuery(
    ['posts', courseId],
    coursePostsFetchAPI,
  );
  const postsData = data?.data || [];
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { t } = useTranslation();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, postsData.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  if (!data || isLoading) {
    return <LoadingPage isHeight100 />;
  }

  return (
    <>
      <CustomDiv>
        {isDesktop && (
          <OtherTitleText>
            {t('coursesMidSectionForm.noticeBoard')}
          </OtherTitleText>
        )}
        <BtnPurpleLgOutline
          onClick={(): void => {
            history.push(`${url}/posts/new`);
          }}
        >
          <AddSharpIcon className={classes.addIcon} />
          {t('coursesMidSectionForm.writeANewArticle')}
        </BtnPurpleLgOutline>
      </CustomDiv>
      <TableContainer
        component={Paper}
        style={{ border: '1px solid rgba(0,0,0,0.2)', boxShadow: 'none' }}
      >
        <Table size="small" aria-label="pagination table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: '13px' }}>
                {t('coursesMidSectionForm.title')}
              </TableCell>
              <TableCell style={{ fontSize: '13px' }} align="right">
                {t('coursesMidSectionForm.dateCreated')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {postsData.length !== 0 ? (
              <>
                {(rowsPerPage > 0
                  ? postsData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : postsData
                ).map((post) => (
                  <TableRow
                    className={classes.row}
                    key={post.id}
                    onClick={(): void => {
                      history.push(`${url}/posts/${post.id}`);
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.title}
                    >
                      {post.is_poll && !post.is_notice && (
                        <IconVote>{t('coursesMidSectionForm.vote')}</IconVote>
                      )}
                      {post.is_notice && (
                        <IconNotice>
                          {t('coursesMidSectionForm.notice')}
                        </IconNotice>
                      )}
                      {post.title}
                    </TableCell>
                    <TableCell align="right" className={classes.date}>
                      {moment(post.created_at).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <td colSpan={2}>
                  <BasicDivFlexBothCentered>
                    <FontSize14Div>
                      {t('coursesMidSectionForm.empty')}
                    </FontSize14Div>
                  </BasicDivFlexBothCentered>
                </td>
              </TableRow>
            )}
          </TableBody>
          <tfoot>
            <tr>
              <CustomTablePagination
                count={postsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                style={{ borderBottom: 'none' }}
                colSpan={3}
              />
            </tr>
          </tfoot>
        </Table>
      </TableContainer>
      <CommonDivider />
    </>
  );
};

export default React.memo(CustomPaginationActionsTable);
