import styled from 'styled-components';

const CommentInput = styled.input`
  font-size: 16px;
  margin-right: 40px;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  width: 100%;
  padding: 10px 8px;
  overflow: visible;
  flex: 1;
  line-height: 1.15;
`;

export default CommentInput;
