import styled from 'styled-components';

const AssignmentInfoContent = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
  white-space: break-spaces;
  line-height: 1.3;
`;

export default AssignmentInfoContent;
