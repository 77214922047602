import React from 'react';
import Authentication from 'templates/Authentication';
import Wrapper from '../../components/organisms/authentications/Wrapper';
import SignUpForm from '../../components/organisms/authentications/SignUpForm';

const SignUp: React.FC = () => {
  return <Authentication Wrapper={Wrapper} Form={SignUpForm} />;
};

export default SignUp;
