import styled from 'styled-components';

const QuestionBackgroundForm = styled.form`
  padding: 40px 24px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;
export default QuestionBackgroundForm;
