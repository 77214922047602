import styled from 'styled-components';

const ButtonAddFiles = styled.button`
  border: 0;
  outline: 0;
  background: none;
  display: flex;
  align-items: center;
`;

export default ButtonAddFiles;
