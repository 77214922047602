import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchProfile, fetchStandardScores } from 'reducers/profiles';

type UseProfileActionsType = {
  dispatchFetchProfile: () => void;
  dispatchFetchStandardScores: () => void;
};

const useProfileActions = (): UseProfileActionsType => {
  const dispatch = useDispatch();
  const dispatchFetchProfile = useCallback(() => dispatch(fetchProfile()), [
    dispatch,
  ]);
  const dispatchFetchStandardScores = useCallback(
    () => dispatch(fetchStandardScores()),
    [dispatch],
  );
  return { dispatchFetchProfile, dispatchFetchStandardScores };
};
export default useProfileActions;
