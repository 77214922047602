import React, { useContext } from 'react';
import {
  BasicDivFlexRight,
  BasicTextarea,
  BtnSkyblueLgOutline,
  FontSize18Div,
  FontSize18Span,
  QuestionBackgroundForm,
} from 'components/atoms';
import { QuestionWritingPerformanceType, updatePerformance } from 'apis';
import { useFieldArray, useForm } from 'react-hook-form';
import { serializeFormData } from 'utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import SwitchPerformanceContext, {
  SwitchPerformanceContextType,
} from '../../../../pages/PerformAssignment/SwitchPage/context';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import { switchStage } from '../../../../pages/EditAssignment/context';
import { STAGES } from '../../../../utils/constants';

type SubmitResponse = {
  response: string;
  id: number;
};
type FormDataType = {
  responses: SubmitResponse[];
};

const EditableMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: auto;
`;

const OrangeMobile = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: orange;
`;

const WhiteBackCardForm: React.FC = () => {
  const { t } = useTranslation();
  const { courseId, assignmentId, performanceId } = useParams();
  const {
    refetch,
    stage_object: { responses },
  } = useContext(SwitchPerformanceContext) as QuestionWritingPerformanceType &
    SwitchPerformanceContextType;

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const {
    current_stage: { stage },
    is_waiting_next_stage,
  } = useContext(PerformanceContext);

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      responses,
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'responses',
  });
  const onSubmit = async (formData: FormDataType): Promise<void> => {
    const data = serializeFormData({ stage_object: formData });
    try {
      await updatePerformance({ courseId, assignmentId, performanceId, data });
      toast.success(
        t('componentsOrganismsQuestionsWritingWhiteBackCardForm.sent'),
      );
      refetch();
    } catch (e) {
      toast.warn(
        t('componentsOrganismsQuestionsWritingWhiteBackCardForm.wrong'),
      );
    }
  };
  const isEditable =
    switchStage(stage) === STAGES.ESSAY && !is_waiting_next_stage;
  return (
    <fieldset disabled={!isEditable}>
      <QuestionBackgroundForm onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => {
          return (
            <div key={item.id}>
              <FontSize18Div>{item.quiz.quiz}</FontSize18Div>
              <input
                type="hidden"
                value={item.id}
                ref={register()}
                name={`responses[${index}].id`}
              />
              <BasicTextarea
                rows={3}
                ref={register()}
                name={`responses[${index}].response`}
                placeholder={
                  t(
                    'componentsOrganismsQuestionsWritingWhiteBackCardForm.writeAQuestion',
                  ) ?? ''
                }
                style={{ marginBottom: '30px' }}
              />
            </div>
          );
        })}
        <BasicDivFlexRight>
          {!isEditable && (
            <>
              {isDesktop ? (
                <EditableMsgContainer>
                  <FontSize18Span style={{ color: 'orange' }}>
                    {t(
                      'componentsOrganismsQuestionsWritingWhiteBackCardForm.only',
                    )}
                  </FontSize18Span>
                </EditableMsgContainer>
              ) : (
                <div style={{ paddingBottom: '10px' }}>
                  <OrangeMobile>
                    {t(
                      'componentsOrganismsQuestionsWritingWhiteBackCardForm.only',
                    )}
                  </OrangeMobile>
                </div>
              )}
            </>
          )}
          <BtnSkyblueLgOutline style={{ padding: '10px 50px' }}>
            {t('componentsOrganismsQuestionsWritingWhiteBackCardForm.save')}
          </BtnSkyblueLgOutline>
        </BasicDivFlexRight>
      </QuestionBackgroundForm>
    </fieldset>
  );
};

export default React.memo(WhiteBackCardForm);
