import styled from 'styled-components';

const Content = styled.div`
  @media (min-width: 768px) {
    background: rgba(255, 255, 255, 0.9);
    overflow: auto;
    padding: 75px;
    display: flex;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    position: relative;
    padding: 28px 22px 40px 22px;
  }
  flex: 1;
`;

export default Content;
