import styled from 'styled-components';

type TabButtonGrayProps = {
  isActive?: boolean;
};

const TabButtonGray = styled.button<TabButtonGrayProps>`
  font-size: 16px;
  padding-right: 60px;
  font-weight: 400;
  border: none;
  outline: none;
  background: none;
  letter-spacing: -0.5px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${({ isActive }): string =>
    isActive ? '#5646ff' : 'rgba(0, 0, 0, 0.35)'};
  cursor: pointer;

  :hover {
    color: rgba(86, 70, 255, 0.5);
  }
`;
export default TabButtonGray;
