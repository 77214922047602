import React from 'react';

interface DashboardProps {
  Container: React.FC;
}

const Dashboard: React.FC<DashboardProps> = ({ Container }) => {
  return <Container />;
};

export default Dashboard;
