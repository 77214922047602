import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Field,
  FieldWrapper,
  Form,
  SaveButton,
  TitleSmall,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { learnerCodeUpdateAPI } from 'apis';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export type StudentNumberEditProps = {
  handleClose: () => void;
  open: boolean;
  learnerCode: string;
  refetch: () => void;
};

export type LearnerCodeEditFormData = {
  code: string;
};

const StudentNumberEditDialog: React.FC<StudentNumberEditProps> = ({
  handleClose,
  open,
  learnerCode,
  refetch,
}) => {
  const { register, handleSubmit } = useForm<LearnerCodeEditFormData>();
  const { courseId } = useParams();
  const { t } = useTranslation();
  const onSubmit = async (data: LearnerCodeEditFormData): Promise<void> => {
    try {
      await learnerCodeUpdateAPI({
        code: data.code,
        courseId,
      });
      refetch();
      handleClose();
    } catch (e) {
      toast.warn(t('modalsStudentNumberEdit.thisIsTheWrongRequest'));
      throw e;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {t('modalsStudentNumberEdit.courseInformationCorrection')}
      </DialogTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <FieldWrapper>
            <TitleSmall>{t('modalsStudentNumberEdit.studentID')}</TitleSmall>
            <Field
              name="code"
              ref={register}
              required
              maxLength={50}
              type="text"
              defaultValue={learnerCode}
            />
          </FieldWrapper>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 16px 24px' }}>
          <SaveButton type="submit">
            {t('modalsStudentNumberEdit.save')}
          </SaveButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default StudentNumberEditDialog;
