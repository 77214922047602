import styled from 'styled-components';

const CourseEnrollTitle = styled.span`
  color: #173c51;
  font-size: 20px;
  margin-bottom: 36px;
  font-weight: 400;
`;

export default CourseEnrollTitle;
