import styled from 'styled-components';

const Layout = styled.div`
  @media (max-width: 767px) {
    flex-direction: column;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

export default Layout;
