import React from 'react';

interface SupportsProps {
  Container: React.FC;
  CardSection: React.FC;
}

const Supports: React.FC<SupportsProps> = ({ Container, CardSection }) => {
  return (
    <Container>
      <CardSection />
    </Container>
  );
};

export default Supports;
