import React from 'react';

interface PeerReviewProps {
  Container: React.FC;
  WhiteBackCard: React.FC;
}

const PeerReview: React.FC<PeerReviewProps> = ({
  Container,
  WhiteBackCard,
}) => {
  return (
    <Container>
      <WhiteBackCard />
    </Container>
  );
};

export default PeerReview;
