import styled from 'styled-components';

const QuestionCard = styled.div`
  font-size: 16px;
  margin-top: 15px;
  flex: 1;
  padding: 24px;
  font-size: 15px;
  color: #173c51;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;

export default QuestionCard;
