import React, { useContext, useEffect } from 'react';
import RevisementTemplate from 'templates/Revisement';
import { useTranslation } from 'react-i18next';
import BottomBar from '../../../components/organisms/revisement/BottomBar';
import EditorSection from '../../../components/organisms/revisement/EditorSection';
import PerformanceContext from '../PerformanceMain/context';
import SwitchPerformanceContext from '../SwitchPage/context';
import {
  EditorType,
  WritingEssayStageObjectType,
} from '../../../apis/performances';
import QuillEditorEssayWriting from '../QuillEditorEssayWriting';

const Revisement: React.FC = () => {
  const { setPageTitle } = useContext(PerformanceContext);
  const { stage_object } = useContext(SwitchPerformanceContext);
  const { t } = useTranslation();
  const assertedStageObject = stage_object as WritingEssayStageObjectType;
  useEffect(() => {
    setPageTitle(t('app.퇴고'));
  }, [setPageTitle, t]);
  const isQuill =
    assertedStageObject.editor_type === EditorType.QUILL ||
    !assertedStageObject.content;
  return isQuill ? (
    <QuillEditorEssayWriting />
  ) : (
    <RevisementTemplate BottomBar={BottomBar} EditorSection={EditorSection} />
  );
};

export default React.memo(Revisement);
