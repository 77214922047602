import styled from 'styled-components';

const ManageTdAssignment = styled.td`
  color: #828b9f;
  text-align: center;
  min-width: 100px;
  padding: 13px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export default ManageTdAssignment;
