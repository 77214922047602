import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { BasicDivFlexRight, CardBorderedDiv } from 'components/atoms';
import SwitchPerformanceContext from 'pages/PerformAssignment/SwitchPage/context';
import { FeedbackReviewStageObjectType } from 'apis';
import { useTranslation } from 'react-i18next';
import Objection from '../../modals/Objection';
import ObjectionEdit from '../../modals/ObjectionEdit';
import FeedbackReviewForm from '../../../molecules/feedbackReviewForm';

const PaddingTopDiv = styled.div`
  padding: 0 20px 10px 20px;
`;

const LightGrayCard = styled(CardBorderedDiv)`
  border-color: rgba(6, 88, 169, 0.2);
  margin-bottom: 10px;
`;

const NameSection = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  color: #173c51;
`;

const Content = styled.p`
  color: rgba(23, 60, 81, 1);
  margin-top: 22px;
  margin-bottom: 0;
  white-space: pre-wrap;
`;

const BtnGray = styled.button`
  overflow: visible;
  font-size: 12px;
  cursor: pointer;
  color: rgb(168, 183, 190);
  border: none;
  outline: 0;
  background: none;
`;

const ReviewCardForm: React.FC = () => {
  const { stage_object, refetch } = useContext(SwitchPerformanceContext);
  const {
    targets,
    max_point: maxPoint,
  } = stage_object as FeedbackReviewStageObjectType;
  const [state, setState] = useState({
    open: false,
    matching: 0,
    objectionId: 0,
  });
  const { t } = useTranslation();
  const changeOpen = (
    open: boolean,
    matching: number,
    objectionId: number,
  ) => (): void => {
    setState({
      open,
      matching,
      objectionId,
    });
  };
  return (
    <PaddingTopDiv>
      {targets.map((target) => {
        const name = target.matching.reviewer_name;
        return (
          <CardBorderedDiv key={target.id}>
            <NameSection>
              {t('feedbackReviewReviewCardForm.nameSEvaluation', { name })}
            </NameSection>
            {target.matching.responses.map((response) => {
              return (
                <LightGrayCard key={response.id}>
                  <Title>{response.standard.question}</Title>
                  <Content>{response.comment}</Content>
                </LightGrayCard>
              );
            })}
            <FeedbackReviewForm data={{ target, maxPoint }} />
            <BasicDivFlexRight>
              <BtnGray
                onClick={changeOpen(
                  true,
                  target.matching.id,
                  target.matching.objection?.id || 0,
                )}
              >
                {target.matching.objection
                  ? t(
                      'feedbackReviewReviewCardForm.modificationAndWithdrawalOfObjection',
                    )
                  : t('feedbackReviewReviewCardForm.chargeOfObjections')}
              </BtnGray>
            </BasicDivFlexRight>
          </CardBorderedDiv>
        );
      })}
      {state.objectionId && state.open ? (
        <ObjectionEdit
          objectionId={state.objectionId}
          open={state.open}
          refetch={refetch}
          handleClose={changeOpen(false, 0, 0)}
        />
      ) : (
        <Objection
          matchingId={state.matching}
          open={state.open}
          refetch={refetch}
          handleClose={changeOpen(false, 0, 0)}
        />
      )}
    </PaddingTopDiv>
  );
};

export default React.memo(ReviewCardForm);
