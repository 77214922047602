import styled from 'styled-components';

const FormTitle = styled.h2`
  @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (max-width: 767px) {
    font-size: 28px;
  }
  color: #5646ff;
  font-weight: 400;
`;

export default FormTitle;
