import { createContext } from 'react';
import { AssignmentFileType } from '../../apis';
import { STAGES } from '../../utils/constants';

type ContextType = {
  isEditable: boolean;
  isEdit: boolean;
  currentStage: string;
  files: AssignmentFileType[];
};

export const IsEditableContext = createContext<ContextType>({
  isEditable: true,
  isEdit: false,
  currentStage: STAGES.PREPARATION,
  files: [],
});

export const stageIndex: string[] = [
  'PR',
  'QU',
  'ES',
  'PE',
  'AD',
  'RE',
  'FE',
  'CO',
];
export const switchStage = (stage: string): string => {
  switch (stage) {
    case '진행대기중':
      return STAGES.PREPARATION;
    case '에세이작성':
      return STAGES.ESSAY;
    case '질문작성':
      return STAGES.QUESTION;
    case '동료평가':
      return STAGES.PEER_REVIEW;
    case '조정':
      return STAGES.ADJUSTMENT;
    case '피드백평가':
      return STAGES.FEEDBACK_REVIEW;
    case '퇴고':
      return STAGES.REVISEMENT;
    case '완료':
      return STAGES.COMPLETE;
    default:
      return '';
  }
};
