import styled from 'styled-components';

const CardContainer = styled.div`
  @media (min-width: 768px) {
    margin-right: 15px;
    margin-bottom: 16px;
    width: 400px;
    padding: 25px 17px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    padding: 10px;
  }

  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;

export default CardContainer;
