import styled from 'styled-components';

const InputLabel = styled.h6`
  font-weight: 400;
  color: #173c51;
  margin-bottom: 7px;
  font-size: 15px;
`;

export default InputLabel;
