import styled from 'styled-components';

const MidButton = styled.button`
  font-size: 16px;
  justify-content: center;
  align-items: center;
  color: #5646ff;
  border: 1px solid #5646ff;
  padding: 10px 30px;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  background-color: #fff;
  outline: 0;
`;

export default MidButton;
