import React, { ReactElement, useContext, useState } from 'react';
import {
  ColoredSquar,
  CommonDiv,
  FontSize18Div,
  Section,
} from 'components/atoms';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Tooltip } from '@material-ui/core';
import ScoreEditDialog from 'components/organisms/modals/ScoreEdit';
import { withStyles } from '@material-ui/core/styles';
import ScoreContext from '../../../../pages/Score/context';
import { get_display_map } from '../TopForm';
import { FetchMaxScoreReportTarget } from '../../../../apis/assignments';

const Lines = styled.div`
  line-height: 1.7;
  font-size: 15px;
  margin-bottom: 20px;
`;

const Th1 = styled.th`
  background-color: #2f64de;
  color: #fff;
  width: 76px;
  height: 38px;
  border: 1px solid #fff;
  font-size: 14px;
  vertical-align: middle;
`;
const Th2 = styled(Th1)`
  background-color: #0d1b4c;
  color: #fff;
`;

const Td = styled.td`
  width: 76px;
  height: 38px;
  border: 1px solid #ececec;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
`;

type ReviewScoreType = {
  point: number | null;
  isEdited: boolean;
};

type GenerateReviewers = {
  id: number;
  name: string;
  scores: ReviewScoreType[];
};

type HandleModalType = (open: boolean, id: number, name: string) => () => void;

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

const renderCell = (
  name: string,
  handleModal: HandleModalType,
  isCompleted: boolean,
) => (item: GenerateReviewers): ReactElement => {
  const isEdited =
    item.scores.filter((itemScore) => itemScore.isEdited).length > 0;
  const isPerformed = item.scores.every((score) => score.point !== null);
  return (
    <Td
      key={item.id}
      style={{ cursor: isCompleted ? 'pointer' : 'inherit' }}
      onClick={isCompleted ? handleModal(true, item.id, name) : undefined}
    >
      <CustomTooltip placement="top" title={item.name}>
        <span style={{ color: isEdited ? 'blue' : 'inherit' }}>
          {isPerformed
            ? `(${item.scores.map((itemScore) => itemScore.point).join(', ')})`
            : i18next.t('scoresBottomForm.critics')}
        </span>
      </CustomTooltip>
    </Td>
  );
};

const BottomForm: React.FC = () => {
  const {
    reports,
    scores,
    current_stage: { stage },
  } = useContext(ScoreContext);
  const history = useHistory();
  const { role, courseId, assignmentId } = useParams();
  const isCompleted = stage === '완료';
  const [state, setModal] = useState({
    open: false,
    matchingId: 0,
    name: '',
  });
  const { t } = useTranslation();
  const handleModal = (
    open: boolean,
    matchingId: number,
    name: string,
  ) => (): void => {
    setModal({ open, matchingId, name });
  };
  const standards = scores.map((score) => {
    const { type } = score;
    const prefix = type.slice(0, 2).toUpperCase();
    return get_display_map()[prefix];
  });
  const includeStandardPrefixes = scores.map((score) => {
    const { type } = score;
    return type.slice(0, 2).toUpperCase();
  });
  const peerScore = scores.find((score) => score.reviewer_count);
  const reviewerCount = peerScore?.reviewer_count || 0;
  const reviewersColumn = Array.from(
    Array(reviewerCount),
    (_, i) => `${t('scoresBottomForm.rater')} ${i + 1}`,
  );
  const records = reports.map((report) => {
    const {
      id,
      name,
      peer_review_score,
      metacognition_score,
      feedback_review_score,
      essay_writing_score,
      revisement_score,
      pr_performance: { reviewers },
    } = report;
    const total =
      peer_review_score +
      metacognition_score +
      feedback_review_score +
      essay_writing_score +
      revisement_score;
    const recordScores = [
      total,
      essay_writing_score,
      peer_review_score,
      metacognition_score,
    ];
    if (includeStandardPrefixes.includes('FE')) {
      recordScores.push(feedback_review_score);
    }
    if (includeStandardPrefixes.includes('RE')) {
      recordScores.push(revisement_score);
    }
    const generateReviewers = (
      reviewer: FetchMaxScoreReportTarget,
    ): GenerateReviewers => {
      return {
        id: reviewer.id,
        name: reviewer.reviewer,
        scores: reviewer.responses.map((response) => ({
          point: response.edited_point ? response.edited_point : response.point,
          isEdited: !!response.edited_point,
        })),
      };
    };
    return {
      id,
      name,
      scores: recordScores,
      reviewer: reviewers
        .filter((reviewer) => !reviewer.is_self_review)
        .map(generateReviewers),
      selfReviewer: reviewers
        .filter((reviewer) => reviewer.is_self_review)
        .map(generateReviewers),
    };
  });
  return (
    <CommonDiv>
      <Section>
        <FontSize18Div>{t('scoresBottomForm.scoreTable')}</FontSize18Div>
        <Lines>
          <div>
            <ColoredSquar />
            {t('scoresBottomForm.ifThereAreTwoOrMore')}
          </div>
          <div>
            <ColoredSquar />
            {t('scoresBottomForm.theSideIsIndicatedInAnUnspecifiedPrice')}
          </div>

          {/* 아래 항목은 완료여부에 따라 달라짐 */}
          {isCompleted ? (
            <div>
              <ColoredSquar />
              {t('scoresBottomForm.clickingOnTheEvaluationScore')}
            </div>
          ) : (
            <div>
              <ColoredSquar />
              {t('scoresBottomForm.notCompleted')}
            </div>
          )}

          <div>
            <ColoredSquar />
            {t('scoresBottomForm.clickOnTheNameOfTheStudent')}
          </div>
          <div>
            <ColoredSquar />
            {t('scoresBottomForm.studentsWhoHaveNotCompletedTheEssay')}
          </div>
        </Lines>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <Th1>{t('scoresBottomForm.academicLife')}</Th1>
              {[t('scoresBottomForm.totalScore'), ...standards].map((data) => {
                return <Th1 key={data}>{data}</Th1>;
              })}
              {reviewersColumn.map((data) => {
                return <Th2 key={data}>{data}</Th2>;
              })}
              <Th2>{t('scoresBottomForm.selfAssessment')}</Th2>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => {
              return (
                <tr key={record.id}>
                  <Td
                    onClick={(): void => {
                      history.push(
                        `/courses/${courseId}/${role}/assignments/${assignmentId}/reports/${record.id}`,
                      );
                    }}
                  >
                    {record.name}
                  </Td>
                  {record.scores.map((item, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Td key={index} style={{ cursor: 'inherit' }}>
                        {item.toFixed(1)}
                      </Td>
                    );
                  })}
                  {record.reviewer.map(
                    renderCell(record.name, handleModal, isCompleted),
                  )}
                  {Array.from(
                    Array(reviewersColumn.length - record.reviewer.length),
                    (_, i) => (
                      <Td key={i} style={{ cursor: 'inherit' }}>
                        <CustomTooltip
                          placement="top"
                          title={t('scoresBottomForm.noEvaluator') ?? ''}
                        >
                          <span>-</span>
                        </CustomTooltip>
                      </Td>
                    ),
                  )}
                  {Array.from(Array(1 - record.selfReviewer.length), (_, i) => (
                    <Td key={i} style={{ cursor: 'inherit' }}>
                      <CustomTooltip
                        placement="top"
                        title={t('scoresBottomForm.noEvaluator') ?? ''}
                      >
                        <span>-</span>
                      </CustomTooltip>
                    </Td>
                  ))}
                  {record.selfReviewer.map(
                    renderCell(record.name, handleModal, isCompleted),
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Section>
      {state.open && (
        <ScoreEditDialog
          handleClose={handleModal(false, 0, '')}
          open={state.open}
          matchingId={state.matchingId}
          name={state.name}
        />
      )}
    </CommonDiv>
  );
};

export default BottomForm;
