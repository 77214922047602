import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  BtnPurpleLgOutline,
  QuestionCard,
  TextareaRounded,
} from 'components/atoms';
import { reportUpdateAPI } from 'apis';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';

const RightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 10px 0;
`;
const Div = styled.div`
  padding: 30px 20px 16px 0px;
`;
const Ft20 = styled.div`
  font-size: 20px;
  color: #173c51;
  font-weight: 400;
`;

export type ReportCommentFormType = { content: string };
const CommentSection: React.FC = () => {
  const { t } = useTranslation();
  const { courseId, assignmentId, reportId, role } = useParams();
  const { register, handleSubmit } = useForm<ReportCommentFormType>();
  const { comment: content } = useContext(ReportContext);

  const onSubmit = async (data: ReportCommentFormType): Promise<void> => {
    const comment = {
      content: data.content,
    };
    try {
      await reportUpdateAPI({
        courseId,
        assignmentId,
        reportId,
        comment,
      });

      toast.success(t('componentsOrganismsReportsCommentSection.success'));
    } catch (e) {
      toast.warn(t('componentsOrganismsReportsCommentSection.wrong'));
    }
  };

  return (
    <QuestionCard>
      <Ft20>
        {t('componentsOrganismsReportsCommentSection.instructorComment')}
      </Ft20>
      {role === 'instructor' || role === 'assistant' ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextareaRounded
            rows={5}
            name="content"
            placeholder={
              t('componentsOrganismsReportsCommentSection.forStudent') ?? ''
            }
            ref={register}
            defaultValue={content?.content}
          />
          <RightDiv>
            <BtnPurpleLgOutline type="submit">
              {t('componentsOrganismsReportsCommentSection.save')}
            </BtnPurpleLgOutline>
          </RightDiv>
        </form>
      ) : (
        <>
          <Div>
            {content ? (
              <>{content?.content}</>
            ) : (
              <>
                <ErrorOutlineIcon style={{ verticalAlign: 'middle' }} />
                <span style={{ fontSize: '14px' }}>
                  {t('componentsOrganismsReportsCommentSection.nothing')}
                </span>
              </>
            )}
          </Div>
        </>
      )}
    </QuestionCard>
  );
};

export default CommentSection;
