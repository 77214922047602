import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import { Document, Page } from 'react-pdf';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';
import PeerReviewMatchingContext from '../../../../pages/PerformAssignment/PeerReviewMatching/context';
import { parseEditorState } from '../../../../utils/parsers';
import { renderFilesWithFileFieldContainer } from '../../../../utils/renderFiles';
import { EssayWritingBottomBarDiv } from '../../../atoms/performAssignment';
import { EditorType } from '../../../../apis/performances';
import { QuillEditor } from '../../quillEditorEssayWritings/QuillEditorSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  position: relative;
`;

export const FileContainer = styled.div`
  display: flex;
  min-height: 50px;
  padding-left: 10px;
`;

const PeerReviewPDFDownloadSection = styled(FileContainer)`
  width: 75%;
  margin: 0 auto;
  padding-left: 0;
  flex-direction: column;
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flex: 1,
      width: '75%',
      margin: '0 auto',
      padding: '10px',
    },
    editor: {
      flex: 1,
      fontSize: '16px',
      backgroundColor: '#fff',
      margin: '0 auto',
      border: '1px solid lightgray',
      padding: '72px 90px',
      lineHeight: '1.5',
      overflow: 'initial',
    },
    mobileWrapper: {
      display: 'flex',
      flex: 1,
      width: '100%',
      margin: '0 auto',
    },
    mobileEditor: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      margin: '0 auto',
      padding: '15px 20px 90px 20px',
      fontSize: '15px',
      lineHeight: '1.5',
    },
  }),
);
const EssaySection: React.FC = ({ children }) => {
  const {
    target_essay: { content, files, editor_type },
  } = useContext(PeerReviewMatchingContext);
  const classes = useStyles();
  const editorState = parseEditorState({ content });
  const [pages, setPages] = useState(0);
  const [filePercentWidth, setFilePercentWidth] = useState(75);
  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setPages(numPages);
  };
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  return (
    <Container>
      <div style={{ paddingBottom: '400px' }}>
        {editor_type === EditorType.DRAFT ? (
          <Editor
            editorState={editorState}
            wrapperClassName={
              isDesktop ? classes.wrapper : classes.mobileWrapper
            }
            editorClassName={isDesktop ? classes.editor : classes.mobileEditor}
            localization={{
              locale: 'ko',
            }}
            readOnly
            toolbarHidden
          />
        ) : (
          <QuillEditor
            isMobile={!isDesktop}
            value={content}
            readOnly
            modules={{
              toolbar: null,
            }}
            theme="snow"
            editorRef={null}
            setPercentWidth={setFilePercentWidth}
          />
        )}
        {children}
        {files.length > 0 && (
          <PeerReviewPDFDownloadSection
            style={{ width: isDesktop ? `${filePercentWidth}%` : '100%' }}
          >
            {renderFilesWithFileFieldContainer({
              files,
              isPeerReview: true,
            })}
            <div>
              {files.map((file) => {
                const ext = file.file.split('.').pop();
                return (
                  <EssayWritingBottomBarDiv
                    key={file.id}
                    style={{ width: '100%' }}
                  >
                    {ext === 'pdf' ? (
                      <Document
                        file={file.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(pages), (el, index) => (
                          <Page pageNumber={index + 1} key={`page-${index}`} />
                        ))}
                      </Document>
                    ) : (
                      <ReactPlayer url={file.file} controls width="100%" />
                    )}
                  </EssayWritingBottomBarDiv>
                );
              })}
            </div>
          </PeerReviewPDFDownloadSection>
        )}
      </div>
    </Container>
  );
};

export default EssaySection;
