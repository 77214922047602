import styled from 'styled-components';

const BtnRoundSmOutlinePurple = styled.button`
  color: #5646ff;
  border: 1px solid #5646ff;
  padding: 0 8px;
  font-size: 12px;
  letter-spacing: -0.9px;
  line-height: 18px;
  border-radius: 12px;
  outline: 0;
  background-color: #fff;
`;

export default BtnRoundSmOutlinePurple;
