import React, { useContext, useEffect } from 'react';
import AssignmentInfoTemplate from 'templates/AssignmentInfo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Description from '../../../components/organisms/assignmentInfo/DescriptionForm';
import FileAttatchment from '../../../components/organisms/assignmentInfo/FileAttatchmentForm';
import Timeline from '../../../components/organisms/assignmentInfo/TimelineForm';
import { switchStage } from '../../EditAssignment/context';
import { STAGES } from '../../../utils/constants';
import PerformanceContext from '../PerformanceMain/context';
import { patchAttendance } from '../../../apis/assignments';

const AssignmentInfo: React.FC = () => {
  const { progresses, refetch, setFold, setPageTitle } = useContext(
    PerformanceContext,
  );
  const { courseId, assignmentId } = useParams();
  const { t } = useTranslation();
  const preparation = progresses.find(
    (progress) => switchStage(progress.stage || '') === STAGES.PREPARATION,
  );

  useEffect(() => {
    setPageTitle(t('pagesPerformAssignmentAssignmentInfo.taskInformation'));
  }, [setPageTitle, t]);

  useEffect(() => {
    setFold(true);
    if (!preparation?.performance?.is_completed) {
      patchAttendance(courseId, assignmentId).then(() => {
        refetch();
      });
    }
  }, [preparation, courseId, assignmentId, refetch, setFold]);

  return (
    <AssignmentInfoTemplate
      Description={Description}
      FileAttatchment={FileAttatchment}
      Timeline={Timeline}
    />
  );
};

export default React.memo(AssignmentInfo);
