import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  BasicDivFlexRight,
  BtnPurpleLgOutline,
  CoverBox,
  TopContainer,
  TopTitleText,
} from 'components/atoms';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import MaterialGrid from 'components/organisms/commons/MaterialGrid';
import UserSearchForm, {
  UserSearchFormState,
} from 'components/molecules/managers/UserSearchForm';
import { useQuery } from 'react-query';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Pagination } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { makeExcel } from 'utils/parsers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getUserColumns } from './columns';
import useDebounce from '../../../hooks/utils/useDebounce';
import { fetchUserList, fetchUserPageList } from '../../../apis/users';
import AppContext from '../../../App/context';

type StateType = {
  q: string;
  last_login: string;
  role: string;
  group: string;
  page?: number;
};

const generateQueryString = (
  state: UserSearchFormState,
  page: number,
  dQ: string,
): string => {
  const { last_login, role, group } = state;
  let query: Record<string, string | boolean | number | Date> = {
    page,
    page_size: 10,
    is_active: true,
  };
  if (last_login) {
    query = {
      ...query,
      last_login__gt: last_login,
    };
  }
  if (role) {
    if (['L', 'I'].includes(role)) {
      query = { ...query, profile__role: role };
    } else {
      query = { ...query, is_active: false };
    }
  }
  if (dQ) {
    query = { ...query, q: dQ };
  }
  if (group) {
    query = { ...query, group };
  }
  return queryString.stringify(query);
};

const Users: React.FC = () => {
  const { url } = useRouteMatch();
  const location = useLocation<StateType>();
  const [page, setPage] = useState(location?.state?.page || 1);
  const { is_integrated_management } = useContext(AppContext);
  const history = useHistory();
  const initialState = location.state || {
    q: '',
    last_login: '',
    role: '',
    group: '',
  };
  const [state, setState] = useState(initialState);
  const debouncedQ = useDebounce(state.q, 500);
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const query = generateQueryString(state, page, debouncedQ);
  const { data, isLoading, isError } = useQuery([query], fetchUserPageList);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (isError) {
      setPage(1);
    }
  }, [isError, setPage]);
  return (
    <CoverBox>
      <TopContainer>
        <TopTitleText>{t('pagesManagersUsers.userManagement')}</TopTitleText>
        <BasicDivFlexRight>
          <BtnPurpleLgOutline
            onClick={async (): Promise<void> => {
              try {
                const response = await fetchUserList();
                const header = [
                  t('pagesManagersUsers.group'),
                  t('pagesManagersUsers.category'),
                  t('pagesManagersUsers.name'),
                  t('pagesManagersUsers.email'),
                  t('pagesManagersUsers.recentConnectionDate'),
                  t('pagesManagersUsers.openingLectures'),
                  t('pagesManagersUsers.courseCourses'),
                  t('pagesManagersUsers.subscriptionDate'),
                  t('pagesManagersUsers.openingCourseName'),
                  t('pagesManagersUsers.class'),
                  t('pagesManagersUsers.professorsAccountFeeDate'),
                ];
                const rows = response.data.map((row) => [
                  row.group?.name,
                  row.role.display,
                  row.name,
                  row.email,
                  row.last_login
                    ? moment(row.last_login).format('YYYY-MM-DD')
                    : t('pagesManagersUsers.noRecord'),
                  row.courses_count,
                  row.enrolls_count,
                  moment(row.date_joined).format('YYYY-MM-DD'),
                  row.courses_joined_name,
                  row.enroll_courses_joined_name,
                  row.instructor_expire_date
                    ? moment(row.instructor_expire_date).format('YYYY-MM-DD')
                    : t('pagesManagersUsers.noRecord'),
                ]);
                makeExcel(
                  [header, ...rows],
                  t('pagesManagersUsers.administrator_UserManagement'),
                );
              } catch (e) {
                console.log(e);
                toast.warn(t('pagesManagersUsers.thisIsTheWrongRequest'));
              }
            }}
          >
            <GetAppIcon style={{ verticalAlign: 'middle' }} />
            {t('pagesManagersUsers.exportList')}
          </BtnPurpleLgOutline>
          <BtnPurpleLgOutline
            onClick={(): void => {
              history.push(`${url}/new`);
            }}
          >
            <AddSharpIcon style={{ verticalAlign: 'middle' }} />
            {t('pagesManagersUsers.addUser')}
          </BtnPurpleLgOutline>
        </BasicDivFlexRight>
      </TopContainer>
      <UserSearchForm state={state} onChange={onChange} />
      {React.useMemo(
        () =>
          language && (
            <MaterialGrid
              columns={
                is_integrated_management
                  ? getUserColumns()
                  : [
                      ...getUserColumns().slice(0, 3),
                      ...getUserColumns().slice(4, getUserColumns().length),
                    ]
              }
              onRowClick={(e, rowData): void => {
                history.push(`${url}/${rowData?.id}`, { ...state, page });
              }}
              isLoading={isLoading}
              components={{
                Pagination: (paginationProps): ReactElement => {
                  const styles = {
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                  };
                  return (
                    <td style={styles}>
                      <Pagination
                        color="primary"
                        count={Math.ceil(paginationProps.count / 10)}
                        onChange={(e, newPage): void => {
                          setPage(newPage);
                        }}
                        page={page}
                      />
                    </td>
                  );
                },
              }}
              page={page}
              data={data?.data.results || []}
              totalCount={data?.data.count}
            />
          ),
        [
          page,
          history,
          url,
          isLoading,
          data,
          is_integrated_management,
          state,
          language,
        ],
      )}
    </CoverBox>
  );
};
export default Users;
