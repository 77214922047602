import styled from 'styled-components';

const VoteSpan = styled.span`
  letter-spacing: -1px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.35);
  white-space: nowrap;
  margin: auto 14px auto 0;
  font-weight: 400;
`;

export default VoteSpan;
