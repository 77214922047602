import { createContext } from 'react';
import { AssignmentResponseType } from '../../apis';

type ManageAssignmentContextType = {
  is_waiting_next_stage: boolean;
  refetch: () => void;
};

const ManageAssignmentContext = createContext<
  AssignmentResponseType & ManageAssignmentContextType
>({
  name: '',
  description: '',
  is_auto_schedule: false,
  is_directly_starting: false,
  is_continue_from_previous_step: false,
  id: 0,
  progresses: [],
  files: [],
  current_stage: {
    start_date: '',
    end_date: '',
    stage: 'preparations',
  },
  status: 0,
  is_waiting_next_stage: false,
  is_public: true,
  now: '',
  refetch: () => {},
});

export default ManageAssignmentContext;
