import styled from 'styled-components';

const IconNotice = styled.span`
  margin-right: 10px;
  display: inline-block;
  line-height: 18px;
  border-radius: 12px;
  font-size: 12px;
  padding: 0 8px;
  color: #5646ff;
  border: 1px solid #5646ff;
`;

export default IconNotice;
