import Person from '@material-ui/icons/Person';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DashboardMenu,
  Menu,
  MenuItem,
  SupportWrapper,
} from 'components/atoms/mains';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useTranslation } from 'react-i18next';

const ManagerMainMenu: React.FC = () => {
  const history = useHistory();
  const baseUrl = '/manager';
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const iconStyle = { marginRight: 5 };
  return (
    <Menu>
      <DashboardMenu
        isActive={pathname.includes(`${baseUrl}/users`)}
        onClick={(): void => {
          history.push(`${baseUrl}/users`);
        }}
      >
        <Person style={iconStyle} />
        {t('managerMainMenu.userManagement')}
      </DashboardMenu>
      <DashboardMenu
        isActive={pathname.includes(`${baseUrl}/courses`)}
        onClick={(): void => {
          history.push(`${baseUrl}/courses`);
        }}
      >
        <AssignmentIcon style={iconStyle} />
        {t('managerMainMenu.courseManagement')}
      </DashboardMenu>
      <DashboardMenu
        isActive={pathname.includes(`${baseUrl}/assignments`)}
        onClick={(): void => {
          history.push(`${baseUrl}/assignments`);
        }}
      >
        <PlaylistAddCheckIcon style={iconStyle} />
        {t('managerMainMenu.taskManagement')}
      </DashboardMenu>
      <SupportWrapper>
        <MenuItem onClick={(): void => history.push('/')}>
          <HomeIcon style={iconStyle} />
          {t('managerMainMenu.mainPage')}
        </MenuItem>
      </SupportWrapper>
    </Menu>
  );
};

export default ManagerMainMenu;
