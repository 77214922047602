import React, { Fragment } from 'react';
import {
  CommentBackground,
  CommentButton,
  CommentDateText,
  CommentInput,
  CommentViewBox,
  FlexDiv,
  NickNameText,
  PostsForm,
} from 'components/atoms';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  coursePostCommentsCreateAPI,
  coursePostCommentsDeleteAPI,
  CoursePostCommentsDeleteAPIProps,
  coursePostCommentsFetchAPI,
  CoursePostDetailType,
} from 'apis';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../../../pages/LoadingPage';

const useStyles = makeStyles(() => ({
  icon: {
    color: 'rgb(113, 186, 216)',
    marginRight: '6px',
  },
  form: {
    padding: '32px 40px',
  },
}));

export type CommentSectionFormProps = {
  post?: CoursePostDetailType;
};

const FormContainer = styled(FlexDiv)`
  flex-direction: row;
`;

const CommentContainer = styled(FlexDiv)`
  margin-top: 15px;
  flex-wrap: nowrap;
`;

const CommentDeleteContainer = styled.div`
  margin-left: auto;
  color: rgba(255, 49, 75, 0.8);
  cursor: pointer;
  padding-right: 7px;
`;

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

type CommentFormData = {
  content: string;
};

const CommentSectionForm: React.FC<CommentSectionFormProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { courseId, postId } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['postComments', courseId, postId],
    coursePostCommentsFetchAPI,
  );
  const [mutate] = useMutation(coursePostCommentsCreateAPI);
  const [delMutate] = useMutation(coursePostCommentsDeleteAPI);
  const { register, handleSubmit, reset } = useForm<CommentFormData>();
  const onSubmit = async (formData: CommentFormData): Promise<void> => {
    try {
      await mutate({ ...formData, courseId, postId });
      toast.success(
        t('componentsOrganismsPostsCommentSectionForm.commentIsWritten'),
      );
      reset();
      refetch();
    } catch (e) {
      toast.warn(
        t('componentsOrganismsPostsCommentSectionForm.commentIsWritten'),
      );
    }
  };
  const onDelete = (
    props: CoursePostCommentsDeleteAPIProps,
  ) => async (): Promise<void> => {
    try {
      await delMutate({ ...props });
      toast.success(
        t('componentsOrganismsPostsCommentSectionForm.commentsHaveBeenDeleted'),
      );
      refetch();
    } catch (e) {
      toast.warn(
        t('componentsOrganismsPostsCommentSectionForm.thisIsTheWrongRequest'),
      );
    }
  };

  if (!data || isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <CommentBackground>
        {data.data.length < 1 && (
          <div>
            {t(
              'componentsOrganismsPostsCommentSectionForm.noCommentsWereCreated',
            )}
          </div>
        )}
        {data.data.map((comment) => {
          const { nickname } = comment;
          return (
            <Fragment key={comment.id}>
              <CommentContainer>
                <AccountCircleIcon className={classes.icon} />
                <NickNameText>
                  {comment.is_own
                    ? `${t(
                        'componentsOrganismsPostsCommentSectionForm.myComments',
                      )}`
                    : `${t(
                        'componentsOrganismsPostsCommentSectionForm.commentOfCommentnickName',
                        { nickname },
                      )}`}
                </NickNameText>
                <CommentDateText>
                  {moment(comment.created_at).format('YYYY-MM-DD HH:mm')}
                </CommentDateText>
                {comment.is_own && (
                  <CustomTooltip
                    title={
                      t('componentsOrganismsPostsCommentSectionForm.delete') ??
                      ''
                    }
                    placement="top"
                  >
                    <CommentDeleteContainer
                      onClick={onDelete({
                        postId,
                        commentId: comment.id,
                        courseId,
                      })}
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      {t('componentsOrganismsPostsCommentSectionForm.delete')}
                    </CommentDeleteContainer>
                  </CustomTooltip>
                )}
              </CommentContainer>
              <CommentViewBox style={{ marginTop: '4px' }}>
                {comment.content}
              </CommentViewBox>
            </Fragment>
          );
        })}
      </CommentBackground>
      <PostsForm className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <CommentInput
            placeholder={
              t(
                'componentsOrganismsPostsCommentSectionForm.writeYourOpinion',
              ) ?? ''
            }
            ref={register}
            name="content"
          />
          <CommentButton type="submit">
            {t('componentsOrganismsPostsCommentSectionForm.registration')}
          </CommentButton>
        </FormContainer>
      </PostsForm>
    </>
  );
};

export default CommentSectionForm;
