import { createAction } from 'typesafe-actions';

export enum COURSES {
  FETCH_COURSES = 'courses/FETCH_COURSES',
  FETCH_COURSES_SUCCESS = 'courses/FETCH_COURSES_SUCCESS',
  FETCH_COURSES_FAILURE = 'courses/FETCH_COURSES_FAILURE',
  CREATE_COURSE = 'courses/CREATE_COURSE',
  CREATE_COURSE_SUCCESS = 'courses/CREATE_COURSE_SUCCESS',
  CREATE_COURSE_FAILURE = 'courses/CREATE_COURSE_FAILURE',
  SET_CURRENT_COURSE = 'courses/SET_CURRENT_COURSE',
}

export const fetchCourses = createAction(COURSES.FETCH_COURSES)();
export const createCourse = createAction(COURSES.CREATE_COURSE)<FormData>();
export const setCurrentCourse = createAction(COURSES.SET_CURRENT_COURSE)<
  string
>();
