import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  CancelButton,
  Card,
  Field,
  FieldWrapper,
  Form,
  SaveButton,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from 'components/atoms';
import useProfiles from 'hooks/profiles/useProfiles';
import { updateProfileAPI, UserEditType } from 'apis';
import useProfileActions from 'hooks/profiles/useProfileActions';
import { Select } from 'components/organisms/authentications/SignUpForm';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../App/context';
import DaumPostDialog from '../../modals/DaumPostDialog';

const EditProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    user: { email, name, date_of_birth, address, attending_type, grade },
  } = useProfiles();
  const { is_private_info } = useContext(AppContext);
  const { register, handleSubmit, setValue, watch, getValues } = useForm<
    UserEditType
  >();
  const goToMyPage = (): void => {
    history.push('/my-page');
  };
  watch('attending_type');
  useEffect(() => {
    if (attending_type) {
      setValue('attending_type', attending_type);
    }
  }, [attending_type, setValue]);
  const [open, setOpen] = useState(false);
  const { dispatchFetchProfile } = useProfileActions();
  const onSubmit = async (user: UserEditType): Promise<void> => {
    try {
      await updateProfileAPI({ user });
      toast.success(
        t('componentsOrganismsProfilesEditProfileForm.modification'),
      );
      dispatchFetchProfile();
      goToMyPage();
    } catch (e) {
      const { status } = e.response;
      switch (status) {
        case 401:
          toast.warn(
            t('componentsOrganismsProfilesEditProfileForm.passwordsDoNotMatch'),
          );
          return;
        default:
          toast.warn(t('componentsOrganismsProfilesEditProfileForm.wrong'));
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TitleLarge>
        {t('componentsOrganismsProfilesEditProfileForm.myPage')}
      </TitleLarge>
      <Card>
        <TitleMedium>
          {t('componentsOrganismsProfilesEditProfileForm.profile')}
        </TitleMedium>
        <FieldWrapper>
          <TitleSmall>
            {t('componentsOrganismsProfilesEditProfileForm.emailAddress')}
          </TitleSmall>
          <Field disabled type="email" name="email" value={email} />
        </FieldWrapper>
        <FieldWrapper>
          <TitleSmall>
            {t('componentsOrganismsProfilesEditProfileForm.name')}
          </TitleSmall>
          <Field
            placeholder={
              t(
                'componentsOrganismsProfilesEditProfileForm.inputYourNamePlease',
              ) ?? ''
            }
            autoFocus
            required
            type="text"
            name="name"
            defaultValue={name}
            ref={register}
          />
        </FieldWrapper>
        {is_private_info && (
          <>
            <FieldWrapper>
              <TitleSmall>
                {t('componentsOrganismsProfilesEditProfileForm.school')}
              </TitleSmall>
              <Select
                required
                name="attending_type"
                ref={register}
                defaultValue={attending_type}
              >
                <option value="" disabled hidden>
                  {t(
                    'componentsOrganismsProfilesEditProfileForm.pleaseSelectASchool',
                  )}
                </option>
                <option value="N">
                  {t('componentsOrganismsProfilesEditProfileForm.none')}
                </option>
                <option value="E">
                  {t('componentsOrganismsProfilesEditProfileForm.ele')}
                </option>
                <option value="M">
                  {t('componentsOrganismsProfilesEditProfileForm.mid')}
                </option>
                <option value="H">
                  {t('componentsOrganismsProfilesEditProfileForm.high')}
                </option>
                <option value="U">
                  {t('componentsOrganismsProfilesEditProfileForm.univ')}
                </option>
              </Select>
            </FieldWrapper>
            {['E', 'M', 'H', 'U'].includes(
              getValues('attending_type') || '',
            ) && (
              <FieldWrapper>
                <TitleSmall>
                  {t('componentsOrganismsProfilesEditProfileForm.grade')}
                </TitleSmall>
                <Field
                  placeholder={
                    t(
                      'componentsOrganismsProfilesEditProfileForm.pleaseEnterTheGrade',
                    ) ?? ''
                  }
                  required
                  max={6}
                  min={1}
                  defaultValue={grade}
                  type="number"
                  name="grade"
                  ref={register}
                />
              </FieldWrapper>
            )}

            <FieldWrapper>
              <TitleSmall>
                {t('componentsOrganismsProfilesEditProfileForm.address')}
              </TitleSmall>
              <Field
                placeholder={
                  t(
                    'componentsOrganismsProfilesEditProfileForm.pleaseEnterTheAddress',
                  ) ?? ''
                }
                required
                onClick={(): void => {
                  setOpen(true);
                }}
                defaultValue={address}
                type="text"
                name="address"
                ref={register}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TitleSmall>
                {t('componentsOrganismsProfilesEditProfileForm.dateOfBirth')}
              </TitleSmall>
              <Field
                placeholder={
                  t(
                    'componentsOrganismsProfilesEditProfileForm.pleaseEnterYourBirthDate',
                  ) ?? ''
                }
                required
                type="text"
                name="date_of_birth"
                defaultValue={date_of_birth}
                onFocus={(e): void => {
                  e.currentTarget.type = 'date';
                }}
                ref={register}
              />
            </FieldWrapper>
          </>
        )}
        <FieldWrapper>
          <TitleSmall>
            {t('componentsOrganismsProfilesEditProfileForm.currentPassword')}
          </TitleSmall>
          <Field
            placeholder={t(
              'componentsOrganismsProfilesEditProfileForm.plzCurrentPw',
            )}
            required
            type="password"
            name="password"
            ref={register}
          />
        </FieldWrapper>
        <CancelButton type="button" onClick={goToMyPage}>
          {t('componentsOrganismsProfilesEditProfileForm.cancel')}
        </CancelButton>
        <SaveButton type="submit">
          {t('componentsOrganismsProfilesEditProfileForm.save')}
        </SaveButton>
      </Card>
      <DaumPostDialog open={open} setOpen={setOpen} setValue={setValue} />
    </Form>
  );
};

export default EditProfileForm;
