import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ButtonCancel, ContentsBodyDiv } from 'components/atoms';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import { CourseFileType } from '../../../../apis/courses';
import { parseEditorState } from '../../../../utils/parsers';
import { renderFilesWithFileFieldContainer } from '../../../../utils/renderFiles';
import { FileContainer } from '../../peerReviewMatching/EssaySection';
import { EditorType } from '../../../../apis/performances';

export type EssayViewProps = {
  handleClose: () => void;
  open: boolean;
  content: string;
  editor_type: EditorType;
  files: CourseFileType[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  @media (min-width: 768px) {
    min-width: 500px;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flex: 1,
      display: 'flex',
      padding: 10,
      flexDirection: 'column',
    },
    editor: {
      flex: 1,
    },
    title: {
      fontWeight: 400,
      fontSize: '20px',
      padding: '24px',
      color: '#173c51',
    },
  }),
);

const QEditor = styled(ReactQuill)`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  div.ql-container {
    display: flex;
    border: none;
    flex-direction: column;
    padding-bottom: 10px;
    overflow: auto;
    .ql-editor {
      flex: 1;
      line-height: 1.5;
      background-color: white;
      p {
        font-size: 1.5em;
      }
    }
  }
`;

const EssayView: React.FC<EssayViewProps> = ({
  handleClose,
  open,
  content,
  files,
  editor_type = EditorType.DRAFT,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const editorState = parseEditorState({ content });
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className={classes.title}>{t('modalsEssayView.view')}</div>
      <DialogContent>
        <ContentsBodyDiv>
          <Container>
            {editor_type === EditorType.DRAFT ? (
              <Editor
                editorState={editorState}
                localization={{
                  locale: 'ko',
                }}
                readOnly
                wrapperClassName={classes.wrapper}
                // class name modal editor는 scroll 이벤트 감지를 위한 클래스네임이므로 지우면 안됌
                editorClassName={`${classes.editor} modal-editor`}
                toolbarHidden
              />
            ) : (
              <QEditor value={content} readOnly modules={{ toolbar: null }} />
            )}
            {files.length > 0 && (
              <FileContainer style={{ paddingLeft: 0 }}>
                {renderFilesWithFileFieldContainer({ files })}
              </FileContainer>
            )}
          </Container>
        </ContentsBodyDiv>
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <ButtonCancel
          type="button"
          onClick={handleClose}
          style={{ marginRight: 0 }}
        >
          {t('modalsEssayView.close')}
        </ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};

export default EssayView;
