import styled from 'styled-components';
import BtnPurpleLg from '../BtnPurpleLg';

const BtnPurpleLgOutline = styled(BtnPurpleLg)`
  background: #fff;
  color: #5646ff;
  border: 1px solid #5646ff;
  outline: 0;

  & + & {
    margin-left: 1em;
  }
`;

export default BtnPurpleLgOutline;
