import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import chromeIcon from 'assets/images/google-chrome.png';
import { GlobalStyle } from '../../styles';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const ChromeImg = styled.img`
  height: 60px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '75px 45px 45px 75px',
      width: '50%',
      minWidth: 600,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    p: {
      fontSize: '20px',
    },
    smallP: {
      color: '#3C80EB',
    },
    divider: {
      marginTop: 20,
      display: 'flex',
      marginBottom: 15,
      alignItems: 'center',
    },
    hr: {
      flex: 1,
    },
    descWrapper: {},
    desc: {
      color: '#727272',
      textAlign: 'center',
      fontSize: '12px',
      whiteSpace: 'pre-wrap',
    },
    actionContainer: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btn: {
      cursor: 'pointer',
      color: 'white',
      outline: 'none',
      border: 'none',
      backgroundColor: '#1a73e8',
      paddingLeft: 15,
      paddingRight: 15,
      height: 40,
      '&:hover': {
        backgroundColor: 'rgba(26, 115, 232, 0.6)',
      },
    },
  }),
);

const IEPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const onClick = (): void => {
    window.open(`https://www.google.com/chrome/`);
  };
  return (
    <Container>
      <Paper className={classes.paper}>
        <p className={classes.p}>{t('pagesIEPage.classprepRecommends1')}</p>
        <p className={classes.p}>{t('pagesIEPage.classprepRecommends2')}</p>
        <p className={classes.smallP}>
          {t('pagesIEPage.classprepRecommends3')}
        </p>
        <div className={classes.divider}>
          <Divider variant="middle" className={classes.hr} />
          {t('pagesIEPage.goToChromeBrowserAndInstallation')}
          <Divider variant="middle" className={classes.hr} />
        </div>
        <div className={classes.descWrapper}>
          <div className={classes.desc}>
            {t('pagesIEPage.ifYouUseTheChromeBrowser')}
          </div>
        </div>
        <div className={classes.actionContainer}>
          <ChromeImg src={chromeIcon} alt="chrome" onClick={onClick} />
          <button type="button" className={classes.btn} onClick={onClick}>
            {t('pagesIEPage.chromeDownload')}
          </button>
        </div>
      </Paper>
      <GlobalStyle />
    </Container>
  );
};

export default IEPage;
