import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import CourseResults from 'templates/CourseResults';
import { useQuery } from 'react-query';
import Container from '../../components/organisms/courseResults/ContainerForm';
import TopSection from '../../components/organisms/courseResults/TopSectionForm';
import MidSection from '../../components/organisms/courseResults/MidSectionForm';
import BottomSection from '../../components/organisms/courseResults/BottomSectionForm';
import CourseResultContext from './context';
import {
  fetchAssignmentWeights,
  fetchCourseResults,
  fetchScoresByLearnerType,
} from '../../apis';
import LoadingPage from '../LoadingPage';

const CourseResultPage: React.FC = () => {
  const { courseId } = useParams();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: 'course-results',
    queryFn: () => {
      return Promise.all([
        fetchCourseResults('course-results', courseId),
        fetchAssignmentWeights('assignments-weight', courseId),
        fetchScoresByLearnerType('scores-by-learner', courseId),
      ]);
    },
  });

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <CourseResultContext.Provider
      value={{
        chartData: data[0].data,
        weights: data[1].data,
        tableData: data[2].data,
        refetch,
      }}
    >
      <CourseResults
        Container={Container}
        TopSection={TopSection}
        MidSection={MidSection}
        BottomSection={BottomSection}
      />
    </CourseResultContext.Provider>
  );
};

export default CourseResultPage;
