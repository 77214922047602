import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import NAForm from 'components/organisms/newAssignmnets/NAForm';
import { assignmentFetchAPI } from '../../apis';
import { IsEditableContext, switchStage } from './context';
import { generateStages } from '../../utils/parsers';
import LoadingPage from '../LoadingPage';

const EditAssignment: React.FC = () => {
  const { courseId, assignmentId } = useParams();
  const { data, isLoading } = useQuery(
    ['assignment', courseId, assignmentId],
    assignmentFetchAPI,
  );

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  const isEditable = data.data.current_stage.stage === '진행대기중';
  const { progresses, files } = data.data;
  const stages = generateStages(progresses);
  return (
    <IsEditableContext.Provider
      value={{
        isEditable,
        isEdit: true,
        currentStage: switchStage(data.data.current_stage.stage || ''),
        files: files || [],
      }}
    >
      <NAForm assignmentData={data.data} stages={stages} />
    </IsEditableContext.Provider>
  );
};

export default EditAssignment;
