import styled from 'styled-components';

const CardExplain = styled.div`
  height: 87px;
  overflow: hidden;
  margin: 0;
  line-height: 23px;
`;

export default CardExplain;
