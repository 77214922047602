import styled from 'styled-components';

const CardBorderedOnelineDiv = styled.div`
  font-size: 16px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
  align-items: center;
  overflow: hidden;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    display: flex;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export default CardBorderedOnelineDiv;
