import styled from 'styled-components';

const Textarea = styled.textarea`
  border: 0;
  outline: 0;
  width: 100%;
  margin: 10px;
`;

export default Textarea;
