import React from 'react';

interface AuthenticationProps {
  Wrapper: React.FC;
  Form: React.FC;
}

const Authentication: React.FC<AuthenticationProps> = ({ Wrapper, Form }) => {
  return (
    <Wrapper>
      <Form />
    </Wrapper>
  );
};

export default Authentication;
