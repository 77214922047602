import { createReducer } from 'typesafe-actions';
import { LOADING } from './actions';

export type LoadingStateType = {
  [type: string]: boolean;
};
const initialState = {};

const loading = createReducer<LoadingStateType>(initialState, {
  [LOADING.START_LOADING]: (state, action) => ({
    ...state,
    [action.payload]: true,
  }),
  [LOADING.FINISH_LOADING]: (state, action) => ({
    ...state,
    [action.payload]: false,
  }),
});

export default loading;
