import styled from 'styled-components';

const NAFormAction = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
`;

export default NAFormAction;
