import styled from 'styled-components';

const SchedulingRadio = styled.input`
  margin-right: 12px;
  & :not(:disabled) {
    cursor: pointer;
  }
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  :checked:after {
    content: ' ';
    display: block;
    background: #4a6cbb;
    margin: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;

export default SchedulingRadio;
