import styled from 'styled-components';

const DateTimeLabel = styled.div`
  padding-right: 25px;
  width: 200px;
  font-size: 16px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
`;

export default DateTimeLabel;
