import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  changePassword,
  signIn,
  signOut,
  signUp,
} from 'reducers/authentication';
import {
  SignInAPIProps,
  SignUpAPIProps,
  UpdatePasswordAPIProps,
} from '../../apis';

type UseAuthenticationActionsType = {
  postSignIn: (props: SignInAPIProps) => void;
  dispatchSignOut: () => void;
  dispatchSignUp: (props: SignUpAPIProps) => void;
  dispatchChangePassword: (props: UpdatePasswordAPIProps) => void;
};

const useAuthenticationActions = (): UseAuthenticationActionsType => {
  const dispatch = useDispatch();
  const postSignIn = useCallback(
    ({ email, password }: SignInAPIProps) =>
      dispatch(signIn({ email, password })),
    [dispatch],
  );
  const dispatchSignUp = useCallback(
    ({ email, password, name }: SignUpAPIProps) =>
      dispatch(signUp({ email, password, name })),
    [dispatch],
  );
  const dispatchChangePassword = useCallback(
    ({ oldPassword, newPassword }: UpdatePasswordAPIProps) =>
      dispatch(changePassword({ oldPassword, newPassword })),
    [dispatch],
  );
  const dispatchSignOut = useCallback(() => dispatch(signOut()), [dispatch]);
  return {
    postSignIn,
    dispatchSignOut,
    dispatchSignUp,
    dispatchChangePassword,
  };
};
export default useAuthenticationActions;
