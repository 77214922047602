import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/ko';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { isIE } from 'react-device-detect';
import { GlobalStyle, styledTheme } from './styles';
import App from './App';
import createStore from './store';
import * as serviceWorker from './serviceWorker';
import LoadingPage from './pages/LoadingPage';
import IEPage from './pages/IEPage';
import './i18n';

moment.locale('ko');
const store = createStore();

const queryConfig = {
  queries: {
    retry: false,
    cacheTime: 0,
  },
};
ReactDOM.render(
  !isIE ? (
    <BrowserRouter>
      <ReactQueryConfigProvider config={queryConfig}>
        <Suspense fallback={<LoadingPage />}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={styledTheme}>
              <GlobalStyle />
              <Provider store={store}>
                <App />
              </Provider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Suspense>
      </ReactQueryConfigProvider>
    </BrowserRouter>
  ) : (
    <IEPage />
  ),
  document.getElementById('root'),
);

export default store;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
