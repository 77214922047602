import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  LeftBox,
  RightBox,
  TitleInLeftBox,
  TitleInRightBox,
  BtnInstructor,
  BtnStudent,
} from 'components/atoms';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import styled from 'styled-components';
import ManualInstructor from 'assets/pdfs/클래스프렙_이용안내서_교수자.pdf';
import ManualStudent from 'assets/pdfs/클래스프렙_이용안내서_학생.pdf';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)`
  span {
    align-items: start;
  }
`;

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box p="auto">
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    height: 'auto',
  },
  rootPanel: {
    width: '100%',
  },
  tabs: { alignItems: 'start' },
  leftList: {
    color: '#828b9f',
    fontSize: '16px',
    padding: '0',
    minHeight: '0',
  },
  heading: {
    fontSize: '16px',
    marginTop: '10px',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.68)',
  },
  content: {
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  btnInstructor: {
    padding: '8px 10px',
    borderRadius: '6px',
    backgroundColor: '#5646ff',
    color: '#fff',
    minWidth: '266px',
    marginTop: '10px',
    fontSize: '15px',
    outline: 0,
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
  btnStudent: {
    padding: '8px 10px',
    borderRadius: '6px',
    backgroundColor: '#2f64de',
    color: '#fff',
    minWidth: '266px',
    marginTop: '10px',
    fontSize: '15px',
    outline: 0,
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));

const VerticalTabs: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          <LeftBox>
            <TitleInLeftBox>{t('helpsTabForm.generalUse')}</TitleInLeftBox>

            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              TabIndicatorProps={{ style: { background: 'none' } }}
            >
              <StyledTab
                className={classes.leftList}
                label={t('helpsTabForm.generalUse') ?? ''}
                id={a11yProps(0).id}
                aria-controls={a11yProps(0)['aria-controls']}
              />
              <StyledTab
                className={classes.leftList}
                label={t('helpsTabForm.etc') ?? ''}
                id={a11yProps(1).id}
                aria-controls={a11yProps(1)['aria-controls']}
              />
              <TitleInLeftBox>{t('app.student')}</TitleInLeftBox>
              <StyledTab
                className={classes.leftList}
                label={t('helpsTabForm.courseApplicationtaskPerformance') ?? ''}
                id={a11yProps(2).id}
                aria-controls={a11yProps(2)['aria-controls']}
              />
              <TitleInLeftBox>{t('app.professor')}</TitleInLeftBox>
              <StyledTab
                className={classes.leftList}
                label={t('helpsTabForm.lectureOpeningmanagementdeletion') ?? ''}
                id={a11yProps(3).id}
                aria-controls={a11yProps(3)['aria-controls']}
              />
              <StyledTab
                className={classes.leftList}
                label={
                  t('helpsTabForm.assignmentOpeningmanagementdeletion') ?? ''
                }
                id={a11yProps(4).id}
                aria-controls={a11yProps(4)['aria-controls']}
              />
            </Tabs>
          </LeftBox>
          <div style={{ display: 'inline-grid' }}>
            <BtnInstructor
              href={ManualInstructor}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('app.교수자')} {t('helpsTabForm.manualDownload')}
            </BtnInstructor>
            <BtnStudent
              href={ManualStudent}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('app.학생')} {t('helpsTabForm.manualDownload')}
            </BtnStudent>
          </div>
        </div>

        <RightBox>
          <TitleInRightBox>
            {t('helpsTabForm.courseApplicationtaskPerformance')}
          </TitleInRightBox>
          <TabPanel value={value} index={0}>
            <div className={classes.rootPanel}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q1')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q1Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q2')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q2Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.rootPanel}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q3')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q3Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q4')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q4Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} />
          <TabPanel value={value} index={3}>
            <div className={classes.rootPanel}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q5')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q5Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q6')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q6Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4} />
          <TabPanel value={value} index={5}>
            <div className={classes.rootPanel}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q7')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q7Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q8')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q8Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q9')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q9Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q10')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q10Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q11')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q11Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q12')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q12Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q13')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q13Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <div className={classes.rootPanel}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q14')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q14Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q15')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q15Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q16')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q16Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q17')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q17Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q18')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q18Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q19')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q19Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Q. {t('helpsTabForm.q20')}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.content}>
                    {t('helpsTabForm.q20Answer')}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </TabPanel>
        </RightBox>
      </div>
    </>
  );
};

export default VerticalTabs;
