import React from 'react';
import {
  Redirect,
  useParams,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { assignmentFetchAPI } from 'apis';
import MatchingPage from '../Matching';
import ContainerForm from '../../components/organisms/manageAssignment/ContainerForm';
import BorderContainerForm from '../../components/organisms/manageAssignment/BorderContainerForm';
import TopSectionForm from '../../components/organisms/manageAssignment/TopSectionForm';
import MidSectionForm from '../../components/organisms/manageAssignment/MidSectionForm';
import BottomSectionForm from '../../components/organisms/manageAssignment/BottomSectionForm';
import ManageAssignmentContext from './context';
import LoadingPage from '../LoadingPage';

const ManageAssignment: React.FC = () => {
  const { courseId, assignmentId, role } = useParams();
  const { path } = useRouteMatch();
  const { data, isLoading, refetch, isError } = useQuery(
    ['assignment', courseId, assignmentId],
    assignmentFetchAPI,
  );

  if (isError) {
    return <Redirect to={`/courses/${courseId}/${role}`} />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <ManageAssignmentContext.Provider value={{ ...data.data, refetch }}>
      <Switch>
        <Route
          path={path}
          exact
          render={() => (
            <ContainerForm>
              <BorderContainerForm>
                <TopSectionForm />
                <MidSectionForm />
                <BottomSectionForm />
              </BorderContainerForm>
            </ContainerForm>
          )}
        />
        <Route path={`${path}/matchings`} exact component={MatchingPage} />
      </Switch>
    </ManageAssignmentContext.Provider>
  );
};

export default React.memo(ManageAssignment);
