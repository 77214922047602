import React, { useContext } from 'react';
import { BasicDivTop100, FontSize15NavySpan } from 'components/atoms';
import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ScoreContext from '../../../../pages/Score/context';

const Title = styled.h2`
  color: #4a6cbb;
  margin-top: 16px;
  margin-bottom: 31px;
  font-size: 24px;
  font-weight: 300;
`;

const ContainerForm: React.FC = ({ children }) => {
  const history = useHistory();
  const { name } = useContext(ScoreContext);
  const { t } = useTranslation();

  return (
    <BasicDivTop100>
      <FontSize15NavySpan
        style={{ cursor: 'pointer' }}
        onClick={(): void => {
          history.goBack();
        }}
      >
        <ArrowBackIcon style={{ verticalAlign: 'middle' }} />
        {t('scoresContainerForm.goBack')}
      </FontSize15NavySpan>
      <Title>
        {name} {t('scoresContainerForm.viewScoreDetails')}
      </Title>
      {children}
    </BasicDivTop100>
  );
};

export default ContainerForm;
