import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  :not(:last-child) {
    margin-bottom: 10px;
  }
  background: transparent;
  font-size: 12px;
  color: unset;
  text-decoration: none;
  display: block;
  font-weight: 300;
`;

export default StyledLink;
