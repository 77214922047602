import { requests, ResponseType } from '../../utils';
import {
  CouresAdminItemType,
  CourseAdminPageResponse,
  CourseCreateFormType,
  CourseCreateResponse,
  CourseLearnerCountType,
  CourseLearnerInfoType,
  CourseLearnerType,
  CourseResponse,
  CourseResultType,
  CourseRoleType,
  CourseUpdateAPIProps,
  FetchScoresByLearnerType,
  LearnerCodeChangeType,
  LearnerCodeUpdateAPIProps,
} from './types';

export const courseAPI = (): Promise<ResponseType<CourseResponse[]>> => {
  return requests().get('/api/v1/users/course/');
};

export const courseFetchPageAPI = async (
  queryString: string,
): Promise<ResponseType<CourseAdminPageResponse>> => {
  return requests().get(`/api/v1/courses/pages/?${queryString}`);
};

export const allCourseFetchAPI = async (): Promise<
  ResponseType<CouresAdminItemType[]>
> => {
  return requests().get(`/api/v1/courses/`);
};

export const courseFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseResponse>> => {
  return requests().get(`/api/v1/courses/${courseId}/`);
};

export const courseCreateAPI = async ({
  formData,
}: CourseCreateFormType): Promise<ResponseType<CourseCreateResponse>> => {
  return requests().post('/api/v1/courses/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const courseUpdateAPI = async ({
  courseId,
  formData,
}: CourseUpdateAPIProps): Promise<ResponseType<void>> => {
  return requests().patch(`/api/v1/courses/${courseId}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const courseDeleteAPI = async ({
  courseId,
}: {
  courseId: number;
}): Promise<void> => {
  return requests().delete(`/api/v1/courses/${courseId}/`);
};

export const courseLearnersFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseLearnerType[]>> => {
  return requests().get(`/api/v1/courses/${courseId}/learners/`);
};

export const courseLearnersCountFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseLearnerCountType>> => {
  return requests().get(`/api/v1/courses/${courseId}/learners-count/`);
};

export const learnerCodeUpdateAPI = async ({
  code,
  courseId,
}: LearnerCodeUpdateAPIProps): Promise<ResponseType<LearnerCodeChangeType>> => {
  return requests().patch(`/api/v1/courses/${courseId}/code-change/`, { code });
};

export const learnerInfoFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseLearnerInfoType>> => {
  return requests().get(`/api/v1/courses/${courseId}/learner-info/`);
};

export const roleFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseRoleType>> => {
  return requests().get(`/api/v1/courses/${courseId}/role/`);
};

export const fetchCourseResults = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CourseResultType[]>> => {
  return requests().get(`/api/v1/courses/${courseId}/results/`);
};

export const fetchScoresByLearnerType = async (
  key: string,
  courseId: number,
): Promise<ResponseType<FetchScoresByLearnerType>> => {
  return requests().get(`/api/v1/courses/${courseId}/scores-by-learner/`);
};
