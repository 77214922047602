import styled from 'styled-components';

const SideTop = styled.div`
  @media (max-width: 767px) {
    flex-direction: row-reverse;
  }
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  color: white;
  z-index: 1;
  padding-left: 20px;
`;

export default SideTop;
