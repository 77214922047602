import styled from 'styled-components';
import Input from '../Input';

const TextArea = styled(Input).attrs({
  as: 'textarea',
})`
  overflow: auto;
  height: 6em;
`;

export default TextArea;
