import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  BottomButton,
  ButtonCancel,
  CommonDivFlex,
  Wrapper,
} from 'components/atoms';
import { useTranslation } from 'react-i18next';

const ButtonsSectionForm: React.FC = () => {
  const container = React.useRef(null);
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div>
      <Wrapper ref={container} />
      <Wrapper>
        <CommonDivFlex style={{ justifyContent: 'flex-end' }}>
          <CommonDivFlex>
            <ButtonCancel type="button" onClick={(): void => history.goBack()}>
              {t('componentsOrganismsPostWritingButtonsSectionForm.cancel')}
            </ButtonCancel>
            <BottomButton type="submit">
              {t('componentsOrganismsPostWritingButtonsSectionForm.save')}
            </BottomButton>
          </CommonDivFlex>
        </CommonDivFlex>
      </Wrapper>
    </div>
  );
};

export default ButtonsSectionForm;
