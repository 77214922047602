import React, { useContext, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { QuestionCard } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import CourseResultContext from '../../../../pages/CourseResults/context';

HighchartsMore(Highcharts);

const filters = [
  {
    label: 'totalScore',
    value: 'total',
  },
  { label: 'writingScore', value: 'essay_writing_score' },
  { label: 'evaluationAbilityScore', value: 'peer_review_score' },
  { label: 'metaCognitiveScore', value: 'metacognition_score' },
  { label: 'feedbackScore', value: 'feedback_review_score' },
  { label: 'retirementScore', value: 'revisement_score' },
];

const TopSectionForm: React.FC = (props: HighchartsReact.Props) => {
  const { chartData } = useContext(CourseResultContext);
  const [filter, setFilter] = useState('total');
  const { t } = useTranslation();
  const categories = chartData.map((item) => item.name);
  const totalAvg = chartData.map((item) => {
    const { scores } = item;
    return Object.keys(scores)
      .filter((key) => key.endsWith('avg'))
      .map((key) => scores[key as keyof typeof scores])
      .reduce((a, b) => {
        return a + b;
      }, 0);
  });
  const avgs = chartData.map((item) => {
    const { scores } = item;
    return scores[`${filter}_avg` as keyof typeof scores];
  });
  const options = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat: '{point.y:.1f}',
    },
    series: [
      {
        name: t('courseResultsTopSectionForm.participatingStudentAverage'),
        color: '#2f64de',
        type: 'column',
        data: filter === 'total' ? totalAvg : avgs,
      },
      // {
      //   name: '점수편차 +1σ',
      //   type: 'errorbar',
      //   data: filter === 'total' ? totalStd : stds,
      // },
    ],
  };
  return (
    <QuestionCard>
      <FormControl component="fieldset" style={{ padding: '20px' }}>
        <RadioGroup
          row
          defaultValue="total"
          value={filter}
          onChange={(e): void => {
            setFilter(e.target.value);
          }}
        >
          {filters.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio color="primary" />}
                label={t(`courseResultsTopSectionForm.${item.label}`)}
                style={{ paddingRight: '15px' }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </QuestionCard>
  );
};

export default TopSectionForm;
