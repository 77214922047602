import React from 'react';
import PostWriting from 'templates/PostWriting';
import AddFilesSectionForm from 'components/organisms/PostWriting/AddFilesSectionForm';
import ButtonsSectionForm from 'components/organisms/PostWriting/ButtonsSectionForm/';
import PostSectionForm from 'components/organisms/PostWriting/PostSectionForm/';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import PostEditContainer from '../../components/organisms/PostEditing/PostEditContainer';
import { coursePostDetailFetchAPI } from '../../apis';
import LoadingPage from '../LoadingPage';

const PostEditing: React.FC = () => {
  const { courseId, postId } = useParams();
  const { data, isLoading } = useQuery(
    ['post', courseId, postId],
    coursePostDetailFetchAPI,
  );

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <PostWriting
      Container={PostEditContainer}
      PostSection={PostSectionForm}
      AddFilesSection={AddFilesSectionForm}
      ButtonsSection={ButtonsSectionForm}
      post={{ post: data.data }}
    />
  );
};

export default PostEditing;
