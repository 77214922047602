import React, { useEffect } from 'react';
import { BasicDivFlexRight, MyPageContainer } from 'components/atoms';
import styled from 'styled-components';
import useLocalStorage from 'hooks/utils/useLocalStorage';
import { useTranslation } from 'react-i18next';
import useDashboardActions from '../../../../hooks/dashboard/useDashboardActions';
import useDashboard from '../../../../hooks/dashboard/useDashboard';
import CourseBoxContainer from '../CourseBoxContainer';
import useLoadings from '../../../../hooks/loadings/useLoadings';
import { DASHBOARD } from '../../../../reducers/dashboard';
import LoadingPage from '../../../../pages/LoadingPage';
import Tutorial from '../Tutorial';

const HideCourseClearButton = styled.button`
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: #9f9f9f;
  border: 1px solid #9f9f9f;
  padding: 10px 30px;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  background-color: #fff;
  outline: 0;
`;

const ContainerForm: React.FC = () => {
  const { dispatchFetchDashboard } = useDashboardActions();
  const { dashboard } = useDashboard();
  const loadings = useLoadings();
  useEffect(() => {
    dispatchFetchDashboard();
  }, [dispatchFetchDashboard]);

  const partialToken = window.localStorage.getItem('token')?.slice(0, 4);
  const storageKey = `dashboard-hide-courses-${partialToken}`;
  const [hideCourses, setHideCourses] = useLocalStorage<number[]>(
    storageKey,
    [],
  );
  const { t } = useTranslation();
  const hideCourseSet = new Set(hideCourses);

  if (loadings[DASHBOARD.FETCH_DASHBOARD]) {
    return <LoadingPage />;
  }

  const handleHideByCourse = (courseId: number) => (): void => {
    hideCourseSet.add(courseId);
    setHideCourses(Array.from(hideCourseSet));
  };
  const handleHideCoursesClear = () => {
    hideCourseSet.clear();
    setHideCourses([]);
  };
  return (
    <MyPageContainer style={{ height: '100%' }}>
      {dashboard.length > 0 ? (
        dashboard
          .filter((course) => !hideCourseSet.has(course.id))
          .map((course) => {
            return (
              <CourseBoxContainer
                course={course}
                handleHide={handleHideByCourse(course.id)}
                key={course.id}
              />
            );
          })
      ) : (
        <Tutorial />
      )}
      {hideCourseSet.size > 0 && (
        <BasicDivFlexRight>
          <HideCourseClearButton onClick={handleHideCoursesClear}>
            {t('dashboardContainerForm.hiddenCourse')}
          </HideCourseClearButton>
        </BasicDivFlexRight>
      )}
    </MyPageContainer>
  );
};

export default ContainerForm;
