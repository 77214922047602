import React, { useContext } from 'react';
import {
  BasicDivFlex,
  BasicDivFlexBetween,
  DivHover,
  TabButtonGray,
  TabButtonNoHover,
} from 'components/atoms';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useTranslation } from 'react-i18next';
import { STAGES } from '../../../../utils/constants';
import { switchStage } from '../../../../pages/EditAssignment/context';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';

type TabsRowProps = {
  isFold: boolean;
  onClickFold: () => void;
};

const TabsRow: React.FC<TabsRowProps> = ({ isFold, onClickFold }) => {
  const history = useHistory();
  const {
    url,
    params: { courseId, assignmentId, role },
  } = useRouteMatch();
  const baseUrl = `/courses/${courseId}/${role}/assignments/${assignmentId}/performances`;
  const {
    progresses,
    request_user_report: reportId,
    current_stage,
  } = useContext(PerformanceContext);
  const { t } = useTranslation();
  let lastPerformanceIndex = 0;
  const progressPrefixArray = progresses.map((progress, index) => {
    if (progress.performance?.pk) {
      lastPerformanceIndex = index;
    }
    return switchStage(progress.stage || '');
  });
  const currentPrefix = switchStage(current_stage.stage || '');
  let currentIndex =
    lastPerformanceIndex > progressPrefixArray.indexOf(currentPrefix)
      ? lastPerformanceIndex
      : progressPrefixArray.indexOf(currentPrefix);
  const preparation = progresses.find(
    (progress) => switchStage(progress.stage || '') === STAGES.PREPARATION,
  );
  const CustomTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      color: '#fff',
      maxWidth: 220,
      fontSize: '14px',
    },
  }))(Tooltip);

  const iconStyle = { fontSize: '30px', cursor: 'pointer' };

  if (
    (progressPrefixArray[currentIndex] === STAGES.ESSAY &&
      progresses.find(
        (progress) => switchStage(progress.stage || '') === STAGES.QUESTION,
      )) ||
    (progressPrefixArray[currentIndex] === STAGES.FEEDBACK_REVIEW &&
      progresses.find(
        (progress) => switchStage(progress.stage || '') === STAGES.REVISEMENT,
      ))
  ) {
    currentIndex += 1;
  }

  return (
    <BasicDivFlexBetween
      style={{ padding: '0 0 10px 10px', borderBottom: '3px solid #ececec' }}
    >
      <BasicDivFlex style={{ flex: 1, flexWrap: 'nowrap', overflow: 'auto' }}>
        <TabButtonGray
          onClick={(): void => history.push(`${baseUrl}/info`)}
          isActive={url.includes(`${baseUrl}/info`)}
        >
          {preparation?.performance?.is_completed && <CheckIcon />}
          {t('performAssignmentTabsRow.taskInformation')}
        </TabButtonGray>
        {progresses.slice(1, currentIndex + 1).map((progress) => {
          let targetUrl =
            reportId && progress.stage === '완료'
              ? `${baseUrl}/reports/${reportId}`
              : `${baseUrl}/${progress.performance?.pk}`;
          if (progress.stage !== '완료' && !progress.performance?.pk) {
            targetUrl = `${baseUrl}/${switchStage(progress.stage || '')}`;
          }
          const isActive = targetUrl.includes(url);
          const isCompleted =
            progress.stage === '완료'
              ? !!reportId
              : progress.performance?.is_completed ||
                progress.performance?.is_late;
          return (
            <TabButtonGray
              key={progress.stage}
              type="button"
              isActive={isActive}
              onClick={(): void => {
                history.push(targetUrl);
              }}
            >
              {isCompleted && <CheckIcon />}
              {t(`app.${progress.stage}`)}
            </TabButtonGray>
          );
        })}
        {progresses
          .slice(currentIndex + 1, progresses.length)
          .map((progress) => {
            let title = t(
              'performAssignmentTabsRow.theTaskStageHasNotBegunYetSoWeCannotProceed',
            );
            if (switchStage(progress.stage || '') === STAGES.COMPLETE) {
              title = t(
                'performAssignmentTabsRow.theAssignmentHasNotBeenCompletedYet',
              );
            }
            return (
              <TabButtonNoHover
                isActive={false}
                type="button"
                key={progress.stage}
              >
                <CustomTooltip placement="top" title={title}>
                  <span>{t(`app.${progress.stage}`)}</span>
                </CustomTooltip>
              </TabButtonNoHover>
            );
          })}
      </BasicDivFlex>
      <DivHover>
        {isFold ? (
          <ExpandMoreIcon onClick={onClickFold} style={iconStyle} />
        ) : (
          <KeyboardArrowUpIcon onClick={onClickFold} style={iconStyle} />
        )}
      </DivHover>
    </BasicDivFlexBetween>
  );
};

export default TabsRow;
