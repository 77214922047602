import React, { useState } from 'react';
import { BasicDivFlexBothCentered, BtnSkyblueLg } from 'components/atoms';
import styled from 'styled-components';
import Book from '@material-ui/icons/Book';
import CourseEnrollDialog from 'components/organisms/modals/CourseEnroll';
import ManualDialog from 'components/organisms/modals/ManualDialog';
import { useTranslation } from 'react-i18next';
import useProfiles from '../../../../hooks/profiles/useProfiles';

export const TxtSkyblue = styled.div`
  color: rgba(113, 186, 216, 1);
  font-size: 20px;
  margin-bottom: 15px;
`;

const Button = styled(BtnSkyblueLg)`
  width: 180px;
`;

const Tutorial: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);
  const { role } = useProfiles();
  const { t } = useTranslation();
  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };
  const handleOpenToggle2 = (state: boolean) => (): void => {
    setOpen2(state);
  };
  return (
    <>
      <ManualDialog handleClose={handleOpenToggle2(false)} open={open2} />
      <BasicDivFlexBothCentered style={{ height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <Book
            style={{
              color: 'rgba(113, 186, 216, 1)',
              fontSize: '88px',
              marginBottom: '20px',
            }}
          />
          <TxtSkyblue>
            {t('dashboardTutorial.thereIsNoRegisteredTask')}
          </TxtSkyblue>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {role === '학생' && (
              <Button
                onClick={handleOpenToggle(true)}
                style={{
                  marginBottom: '15px',
                }}
              >
                {t('dashboardTutorial.goToTheCourse')}
              </Button>
            )}
            <Button onClick={handleOpenToggle2(true)}>
              {t('dashboardTutorial.manualView')}
            </Button>
          </div>
          <CourseEnrollDialog
            handleClose={handleOpenToggle(false)}
            open={open}
          />
        </div>
      </BasicDivFlexBothCentered>
    </>
  );
};

export default Tutorial;
