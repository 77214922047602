import styled from 'styled-components';

const PaddingTop100Div = styled.div`
flex: 1,
margin-top: -3px,
overflow: 'auto',
// padding: 100px 40px 40px 40px,
padding: 40px;
`;

export default PaddingTop100Div;
