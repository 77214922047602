import styled from 'styled-components';

const UserBoxButton = styled.button`
  @media (max-width: 767px) {
    padding: 4.5px 0;
  }
  width: 107px;
  border-radius: 10px;
  font-size: 15px;
  letter-spacing: 0;
  padding: 2px 0;
  color: white;
  border: 1px solid white;
  display: inline-block;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  background: none;
  font-weight: 300;
  outline: 0;
  :hover {
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
  }
`;

export default UserBoxButton;
