import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

type LoadingPageProps = {
  isHeight100?: boolean;
};
const Container = styled.div<LoadingPageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isHeight100 }): string => (!isHeight100 ? '100vh' : '100%')};
  width: 100%;
  flex-direction: column;
`;

const LoadingPage: React.FC<LoadingPageProps> = ({ isHeight100 }) => {
  const color = '#2f64de';
  return (
    <Container isHeight100={isHeight100}>
      <ReactLoading type="bars" color={color} />
      <h3 style={{ color }}>Loading...</h3>
    </Container>
  );
};

export default LoadingPage;
