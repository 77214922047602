import React, { useContext } from 'react';
import { ColoredSquar, FontSize16Div, QuestionCard } from 'components/atoms';
import PersonalScoreContext from 'pages/ScoreProgress/context';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled(FontSize16Div)`
  color: #0658a9;
`;
const Tr = styled.tr`
  background-color: #ececec;
`;
const Th = styled.th`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 100px;
  border: 1px solid #fff;
  padding: 10px;
`;

const AssignmentTh = styled(Th)`
  min-width: 200px;
`;

const Td = styled.td`
  text-align: center;
  padding: 10px;
  border: 1px solid #ececec;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const TdB = styled.td`
  text-align: center;
  padding: 10px;
  background-color: #ececec;
  border: 1px solid #fff;
`;
const TdPointer = styled(Td)`
  cursor: pointer;
  text-decoration: underline;
  color: rgba(23, 60, 81, 1);
`;

const useStyles = makeStyles(() => ({
  mg20: { margin: '20px 0' },
  auto: { overflow: 'auto', padding: '10px 0' },
}));

const BottomTableForm: React.FC = () => {
  const data = useContext(PersonalScoreContext);
  const classes = useStyles();
  const history = useHistory();
  const {
    params: { courseId, role, enrollId },
  } = useRouteMatch();
  const { t } = useTranslation();
  const name = enrollId ? `${data.name}` : '나';

  return (
    <QuestionCard>
      <Title>
        {t('scoreProgressBottomTableForm.taskScoreTable', { name })}
      </Title>
      <div className={classes.mg20}>
        <ColoredSquar />
        {enrollId
          ? t('scoreProgressBottomTableForm.clickOnTheTaskName')
          : t('scoreProgressBottomTableForm.clickOnTheTaskName2')}
      </div>
      <div className={classes.auto}>
        <table style={{ width: '100%' }}>
          <thead>
            <Tr>
              <AssignmentTh>
                {t('scoreProgressBottomTableForm.projectTitle')}
              </AssignmentTh>
              <Th>{t('scoreProgressBottomTableForm.writing')}</Th>
              <Th>{t('scoreProgressBottomTableForm.evaluationAbility')}</Th>
              <Th>{t('scoreProgressBottomTableForm.metaCognitive')}</Th>
              <Th>{t('scoreProgressBottomTableForm.feedback')}</Th>
              <Th>{t('scoreProgressBottomTableForm.retirement')}</Th>
              <Th>{t('scoreProgressBottomTableForm.totalScore')}</Th>
            </Tr>
          </thead>
          <tbody>
            {data.assignments.map((assignment) => {
              return (
                <tr key={assignment.id}>
                  <TdPointer
                    onClick={(): void =>
                      history.push(
                        `/courses/${courseId}/${role}/assignments/${assignment.id}/reports/${assignment.report}`,
                      )
                    }
                  >
                    {assignment.name}
                  </TdPointer>
                  <Td>
                    {assignment.individual_score.essay_writing_score.toFixed(1)}
                  </Td>
                  <Td>
                    {assignment.individual_score.peer_review_score.toFixed(1)}
                  </Td>
                  <Td>
                    {assignment.individual_score.metacognition_score.toFixed(1)}
                  </Td>
                  <Td>
                    {assignment.individual_score.feedback_review_score.toFixed(
                      1,
                    )}
                  </Td>
                  <Td>
                    {assignment.individual_score.revisement_score.toFixed(1)}
                  </Td>
                  <TdB>{assignment.individual_score.total.toFixed(1)}</TdB>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </QuestionCard>
  );
};

export default BottomTableForm;
