import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PeerReviewOptionInput } from '../../../atoms/newAssignmnets';
import { reportUpdateAPI } from '../../../../apis/reports';
import ReportContext from '../../../../pages/Report/context';

interface Props extends DialogProps {
  onClose: () => void;
  max: number;
  current: number;
}

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
`;
const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
    padding: 0,
  },
  input: {
    width: '100%',
  },
  closeButton: {
    marginLeft: 'auto',
    color: theme.palette.grey[500],
  },
  initializeButton: {
    marginLeft: 6,
    color: '#0765e8',
    outline: 'none',
    fontSize: 16,

    border: 'none',
    background: 'none',
    marginRight: 'auto',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  saveButton: {
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
    color: '#0765e8',
    fontSize: 16,
    outline: 'none',
    border: '1px solid #0765e8',
    background: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#0765e8',
      color: 'white',
    },
  },
  action: {
    marginTop: 20,
    padding: 10,
  },
}));
const RevisementScoreEdit: React.FC<Props> = ({
  open,
  onClose,
  max,
  current,
}) => {
  const { refetch, origin_revisement_score } = useContext(ReportContext);
  const classes = useStyles();
  const [score, setScore] = useState(current);
  const { courseId, assignmentId, reportId } = useParams();
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" style={{ padding: 12 }}>
          {t('modalsRevisementScoreEdit.changeOfRetirementScore')}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Form
          id="edit-revisement"
          onSubmit={(e): void => {
            e.preventDefault();
            reportUpdateAPI({
              courseId,
              assignmentId,
              reportId,
              revisement_score: score,
            })
              .then(() => {
                toast.success(
                  t('modalsRevisementScoreEdit.iModifiedTheRetirementScore'),
                );
                refetch();
                onClose();
              })
              .catch(() => {
                toast.warn(
                  t('modalsRevisementScoreEdit.thisIsTheWrongRequest'),
                );
              });
          }}
        >
          <PeerReviewOptionInput
            required
            type="number"
            max={max}
            autoFocus
            step={0.1}
            value={score}
            onChange={(e): void => {
              const { value } = e.target;
              setScore((value as unknown) as number);
            }}
            className={classes.input}
            min={0}
          />
          <Typography variant="h6">
            {t('modalsRevisementScoreEdit.point')}
          </Typography>
        </Form>
      </DialogContent>
      <DialogActions className={classes.action}>
        <button
          type="button"
          className={classes.initializeButton}
          onClick={(): void => {
            reportUpdateAPI({
              courseId,
              assignmentId,
              reportId,
              revisement_score: origin_revisement_score,
            })
              .then(() => {
                toast.success(
                  t('modalsRevisementScoreEdit.initializedTheRetirementScore'),
                );
                refetch();
                onClose();
              })
              .catch(() => {
                toast.warn(
                  t('modalsRevisementScoreEdit.thisIsTheWrongRequest'),
                );
              });
          }}
        >
          {t('modalsRevisementScoreEdit.initialization')}
        </button>
        <button
          type="submit"
          form="edit-revisement"
          className={classes.saveButton}
        >
          {t('modalsRevisementScoreEdit.save')}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default RevisementScoreEdit;
