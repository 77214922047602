import { call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { startLoading, finishLoading } from 'reducers/loadings';

type ResponseType<T> = {
  data: T;
};

const createRequestSaga = <T, P>(
  type: string,
  request: (args: P) => Promise<T>,
  successActions: AnyAction[] = [],
  failActions: AnyAction[] = [],
): ((action: AnyAction) => Generator) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return function* requestSaga(action: AnyAction): Generator {
    yield put(startLoading(type));
    try {
      const response = (yield call(request, action.payload)) as ResponseType<T>;
      yield put({
        type: SUCCESS,
        payload: response.data,
      });
      while (successActions.length > 0) {
        const successAction = successActions.pop();
        if (successAction) {
          yield put(successAction);
        }
      }
    } catch (e) {
      yield put({
        type: FAILURE,
        payload: e.response.status,
        errors: e.response.data,
      });
      while (failActions.length > 0) {
        const failAction = failActions.pop();
        if (failAction) {
          yield put(failAction);
        }
      }
    }
    yield put(finishLoading(type));
  };
};

export default createRequestSaga;
