import React, { ChangeEvent, useContext } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import moment, { DurationInputArg2 } from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ManageMainContext from '../../../../pages/managers/ManagerMain/context';
import AppContext from '../../../../App/context';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    field: {
      margin: '1rem',
      flex: 1,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  };
});

export type UserSearchFormState = {
  q: string;
  last_login: string;
  role: string;
  group: number | string;
};
type UserSearchFormProps = {
  state: UserSearchFormState;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const UserSearchForm: React.FC<UserSearchFormProps> = ({ state, onChange }) => {
  const classes = useStyles();
  const dateFormat = 'YYYY-MM-DD';
  const groups = useContext(ManageMainContext);
  const { is_integrated_management } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <TextField
        label={t('userSearchForm.search') ?? '검색'}
        variant="outlined"
        name="q"
        onChange={onChange}
        value={state.q}
        className={classes.field}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t('userSearchForm.category') ?? '유형'}
        name="role"
        value={state.role}
        onChange={onChange}
        variant="outlined"
        className={classes.field}
        select
      >
        <MenuItem value="">{t('userSearchForm.all')}</MenuItem>
        <MenuItem value="L">{t('userSearchForm.student')}</MenuItem>
        <MenuItem value="I">{t('userSearchForm.professor')}</MenuItem>
        <MenuItem value="D">
          {t('userSearchForm.inactiveAccountWithdrawal')}
        </MenuItem>
      </TextField>
      {is_integrated_management && (
        <TextField
          label={t('userSearchForm.group') ?? '그룹'}
          name="group"
          value={state.group}
          onChange={onChange}
          variant="outlined"
          className={classes.field}
          select
        >
          <MenuItem value="">{t('userSearchForm.all')}</MenuItem>
          {groups.map((group) => {
            return (
              <MenuItem value={group.id} key={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <TextField
        label={t('userSearchForm.recentConnectionDate') ?? '최근 접속일'}
        variant="outlined"
        onChange={onChange}
        value={state.last_login}
        name="last_login"
        className={classes.field}
        select
      >
        <MenuItem value="">{t('userSearchForm.all')}</MenuItem>
        {['weeks', 'months'].map((unit) => {
          return [1, 2].map((amount) => {
            return (
              <MenuItem
                key={`${unit}-${amount}`}
                value={moment()
                  .subtract(amount, unit as DurationInputArg2)
                  .format(dateFormat)}
              >
                {amount}
                {unit === 'weeks'
                  ? t('userSearchForm.week', { count: amount })
                  : t('userSearchForm.month', { count: amount })}{' '}
                {t('userSearchForm.ago')}
              </MenuItem>
            );
          });
        })}
      </TextField>
    </div>
  );
};

export default UserSearchForm;
