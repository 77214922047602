import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { changeSideRole, toggleSidebar } from 'reducers/settings';

type UseSettingActionsType = {
  dispatchToggleSidebar: () => void;
  dispatchChangeSideRole: (payload: string) => void;
};

const useSettingActions = (): UseSettingActionsType => {
  const dispatch = useDispatch();
  const dispatchToggleSidebar = useCallback(() => dispatch(toggleSidebar()), [
    dispatch,
  ]);
  const dispatchChangeSideRole = useCallback(
    (payload: string) => dispatch(changeSideRole(payload)),
    [dispatch],
  );
  return { dispatchToggleSidebar, dispatchChangeSideRole };
};
export default useSettingActions;
