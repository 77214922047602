import { createContext, Dispatch, SetStateAction } from 'react';
import { EditorState } from 'draft-js';
import { EditorType, Performance } from '../../../apis';

export type SwitchPerformanceContextType = {
  currentEditState: EditorState;
  setCurrentEditState: Dispatch<SetStateAction<EditorState>>;
  refetch: () => void;
} & Performance;

const SwitchPerformanceContext = createContext<SwitchPerformanceContextType>({
  id: 0,
  is_completed: false,
  stage_object: {
    id: 0,
    late: false,
    files: [],
    created_at: '',
    updated_at: '',
    content: '',
    extra: {
      question: null,
      is_late: false,
    },
    plain_text: '',
    editor_type: EditorType.DRAFT,
    responses: [],
  },
  progress: {
    model: 'essaywriting',
    stage_object: {
      limit_type: 'L',
      max_length: 0,
      max_writing_score: 0,
    },
    start_date: '',
    end_date: '',
  },
  currentEditState: EditorState.createEmpty(),
  setCurrentEditState: () => {},
  refetch: () => {},
});

export default SwitchPerformanceContext;
