import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { updatePerformance } from '../../../../apis/performances';
import { serializeFormData } from '../../../../utils';

type OnFileCancelProps = {
  courseId: number;
  assignmentId: number;
  performanceId: number;
  fileName?: string;
  refetch: () => void;
  fileId: number;
};

export const onFileCancelHelper = async ({
  courseId,
  assignmentId,
  performanceId,
  fileName,
  refetch,
  fileId,
}: OnFileCancelProps): Promise<void> => {
  // eslint-disable-next-line no-alert
  const res = window.confirm(
    t('revisementBottomBarHelpers.wouldYouLikeToDeleteTheFilenameFile', {
      fileName,
    }),
  );
  if (res) {
    try {
      const data = new FormData();
      data.append('stage_object', JSON.stringify({ delFile: true, fileId }));

      await updatePerformance({
        data,
        courseId,
        assignmentId,
        performanceId,
      });

      toast.success(
        t('revisementBottomBarHelpers.theFilenameFileHasBeenDeleted', {
          fileName,
        }),
      );
      refetch();
    } catch (e) {
      toast.warn(t('revisementBottomBarHelpers.thisIsTheWrongRequest'));
    }
  }
};

export const fileGenerateHelper = ({
  courseId,
  assignmentId,
  performanceId,
  refetch,
}: Omit<OnFileCancelProps, 'fileId'>) => async (
  e: ChangeEvent<HTMLInputElement>,
): Promise<void> => {
  const { files: currentFiles } = e.target;
  try {
    const data = serializeFormData({
      currentFiles,
      stage_object: {},
    });

    await updatePerformance({
      data,
      courseId,
      assignmentId,
      performanceId,
    });

    toast.success(t('revisementBottomBarHelpers.theFileHasBeenCreated'));
    refetch();
  } catch (error) {
    toast.warn(t('revisementBottomBarHelpers.thisIsTheWrongRequest'));
  }
};
