import React, { useContext } from 'react';
import { TabButtonGray, TabButtonNoHover } from 'components/atoms';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import { switchStage } from '../../../../pages/EditAssignment/context';
import { STAGES } from '../../../../utils/constants';

type TabsRowProps = {
  isFold: boolean;
  onClickFold: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '10px 0',
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    menuBtn: {
      borderBottom: '3px solid #5646ff',
      color: '#5646ff',
      borderRadius: 0,
    },
  }),
);

const TabsRow: React.FC<TabsRowProps> = ({ isFold, onClickFold }) => {
  const history = useHistory();
  const {
    url,
    params: { courseId, assignmentId, role },
  } = useRouteMatch();
  const baseUrl = `/courses/${courseId}/${role}/assignments/${assignmentId}/performances`;
  const {
    progresses,
    request_user_report: reportId,
    current_stage,
  } = useContext(PerformanceContext);
  const { t } = useTranslation();
  let lastPerformanceIndex = 0;
  const progressPrefixArray = progresses.map((progress, index) => {
    if (progress.performance?.pk) {
      lastPerformanceIndex = index;
    }
    return switchStage(progress.stage || '');
  });
  const currentPrefix = switchStage(current_stage.stage || '');
  let currentIndex =
    lastPerformanceIndex > progressPrefixArray.indexOf(currentPrefix)
      ? lastPerformanceIndex
      : progressPrefixArray.indexOf(currentPrefix);
  const preparation = progresses.find(
    (progress) => switchStage(progress.stage || '') === STAGES.PREPARATION,
  );

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const iconStyle = { fontSize: '30px', cursor: 'pointer' };
  const { pageTitle } = useContext(PerformanceContext);

  if (
    (progressPrefixArray[currentIndex] === STAGES.ESSAY &&
      progresses.find(
        (progress) => switchStage(progress.stage || '') === STAGES.QUESTION,
      )) ||
    (progressPrefixArray[currentIndex] === STAGES.FEEDBACK_REVIEW &&
      progresses.find(
        (progress) => switchStage(progress.stage || '') === STAGES.REVISEMENT,
      ))
  ) {
    currentIndex += 1;
  }

  return (
    <>
      <div className={classes.root}>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.menuBtn}
          >
            <div style={{ fontSize: '16px' }}>{pageTitle}</div>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TabButtonGray
              onClick={(): void => {
                try {
                  history.push(`${baseUrl}/info`);
                  handleClose();
                } catch {
                  handleClose();
                }
              }}
              isActive={url.includes(`${baseUrl}/info`)}
              style={{ padding: '5px 15px 5px 10px' }}
            >
              {preparation?.performance?.is_completed && <CheckIcon />}
              {t('performAssignmentTabsRow.taskInformation')}
            </TabButtonGray>
            {progresses.slice(1, currentIndex + 1).map((progress) => {
              let targetUrl =
                reportId && progress.stage === '완료'
                  ? `${baseUrl}/reports/${reportId}`
                  : `${baseUrl}/${progress.performance?.pk}`;
              if (progress.stage !== '완료' && !progress.performance?.pk) {
                targetUrl = `${baseUrl}/${switchStage(progress.stage || '')}`;
              }
              const isActive = targetUrl.includes(url);
              const isCompleted =
                progress.stage === '완료'
                  ? !!reportId
                  : progress.performance?.is_completed ||
                    progress.performance?.is_late;
              return (
                <TabButtonGray
                  key={progress.stage}
                  type="button"
                  isActive={isActive}
                  style={{ padding: '5px 15px 5px 10px' }}
                  onClick={(): void => {
                    try {
                      history.push(targetUrl);
                      handleClose();
                    } catch (e) {
                      throw Error(e);
                    }
                  }}
                >
                  {isCompleted && <CheckIcon />}
                  {t(`app.${progress.stage}`)}
                </TabButtonGray>
              );
            })}
            {progresses
              .slice(currentIndex + 1, progresses.length)
              .map((progress) => {
                return (
                  <TabButtonNoHover
                    isActive={false}
                    type="button"
                    key={progress.stage}
                  />
                );
              })}
          </Menu>
        </div>
        <div style={{ position: 'absolute', right: '1rem' }}>
          {isFold ? (
            <ExpandMoreIcon onClick={onClickFold} style={iconStyle} />
          ) : (
            <KeyboardArrowUpIcon onClick={onClickFold} style={iconStyle} />
          )}
        </div>
      </div>
    </>
  );
};

export default TabsRow;
