import styled from 'styled-components';
import UserBoxButton from '../UserBoxButton';

const GenerateCourseButton = styled(UserBoxButton)`
  @media (min-width: 768px) {
    margin: 22px auto 36px auto;
  }
  @media (max-width: 767px) {
    margin: 22px auto 36px 21px;
  }
  display: flex;
  align-items: center;
  position: relative;
  outline: 0;

  :hover {
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
  }
`;

export default GenerateCourseButton;
