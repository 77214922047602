import styled from 'styled-components';

const NASaveButton = styled.button`
  :not(:last-child) {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    width: 125px;
  }
  @media (max-width: 767px) {
    :not(:last-child) {
      margin-right: 16px;
    }
    flex: 1;
    width: 100%;
  }

  display: inline-block;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  padding: 8px 24px 7px 24px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  background: none;
  color: #4a6cbb;
  border: 1px solid #4a6cbb;
  letter-spacing: -1px;
  outline: 0;
  &:hover {
    border-color: rgba(74, 108, 187, 0.7);
    color: rgba(74, 108, 187, 0.7);
  }
`;

export default NASaveButton;
