import React, { Fragment, ReactElement, useContext, useState } from 'react';
import { Section } from 'components/atoms';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Radar } from 'react-chartjs-2';
import { SCORE_TYPE } from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';
import { get_display_map } from '../../scores/TopForm';
import RevisementScoreEdit from '../../modals/RevisementScoreEdit';

const Title = styled.div`
  font-size: 16px;
  color: #173c51;
  font-weight: 400;
  padding-bottom: 5px;
`;

const Score = styled.span`
  font-weight: 400;
  margin-left: 5px;
  color: #5646ff;
`;

const TopSmDiv = styled.div`
  margin-top: 7px;
  font-weight: 300;
`;

const TopLgDiv = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
`;

const BorderedDiv = styled.div`
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
`;
const EditButton = styled.button`
  border: none;
  color: #0765e8;
  cursor: pointer;
  margin-left: 5px;
  font-size: 18px;
  background: none;
  outline: none;
  :hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles(() => ({
  mgTop: {
    marginTop: '30px',
  },
  table: {
    marginTop: '16px',
  },
  thLg: {
    textAlign: 'left',
    paddingBottom: '7.5px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  thSm: {
    paddingRight: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },

  tdSm: {
    fontSize: '15px',
    padding: '5.5px 3.5px',
  },
  tdLg: {
    paddingRight: '30px',
  },
  LeftDesktop: {
    flexWrap: 'wrap',
    display: 'block',
  },
  RightDesktop: {
    marginLeft: 'auto',
    alignItems: 'center',
    display: 'flex',
  },
  FlexDiv: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  FlexDivMobile: {
    flexWrap: 'wrap',
  },
}));

const ScoreSection: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { role } = useParams();
  const [open, setOpen] = useState(false);
  const onClick = (): void => {
    setOpen(true);
  };
  const {
    is_my_report,
    name,
    scores,
    essay_writing_score,
    peer_review_score,
    metacognition_score,
    feedback_review_score,
    revisement_score,
    essay_writing_score_avg,
    feedback_review_score_avg,
    peer_review_score_avg,
    metacognition_score_avg,
    peer_review_points,
    feedback_review_point,
    revisement_score_avg,
    is_average_essay_writing_score,
    is_average_feedback_review_score,
    origin_revisement_score,
    late,
    completed,
  } = useContext(ReportContext);
  const isEditable = role === 'instructor' || role === 'assistant';
  const scoreMap = {
    ES: {
      score: essay_writing_score.toFixed(1),
      positive: (score: number): React.ReactElement => {
        return (
          <span>
            {t('componentsOrganismsReportsScoreSection.asWeight', { score })}
            <br />
            {is_average_essay_writing_score &&
              !late &&
              t(
                'componentsOrganismsReportsScoreSection.averagePeerScoreWasGiven',
              )}
            {is_average_essay_writing_score &&
              late &&
              t('componentsOrganismsReportsScoreSection.delay')}
          </span>
        );
      },
      negative: (): string => {
        return t('componentsOrganismsReportsScoreSection.noEssay');
      },
    },
    PE: {
      score: peer_review_score.toFixed(1),
      positive: (score: number): string => {
        return t('componentsOrganismsReportsScoreSection.howAccurate', {
          score,
        });
      },
      negative: (): string => {
        return t('componentsOrganismsReportsScoreSection.noPeerReview');
      },
    },
    ME: {
      score: metacognition_score.toFixed(1),
      positive: (score: number): string => {
        return t('componentsOrganismsReportsScoreSection.writing', { score });
      },
      negative: (): string => {
        return t('componentsOrganismsReportsScoreSection.noOwnEssayEvaluation');
      },
    },
    FE: {
      score: feedback_review_score.toFixed(1),
      positive: (score: number): React.ReactElement => {
        return (
          <span>
            {t('componentsOrganismsReportsScoreSection.myFeedbackToPeers', {
              score,
            })}
            <br />
            {is_average_feedback_review_score &&
              t(
                'componentsOrganismsReportsScoreSection.averageFeedbackScoreWasGiven',
              )}
          </span>
        );
      },
      negative: (): string => {
        return t('componentsOrganismsReportsScoreSection.noPeerEssayReview');
      },
    },
    RE: {
      score: revisement_score.toFixed(1),
      positive: (score: number): string => {
        return t('componentsOrganismsReportsScoreSection.retirementScore', {
          score,
        });
      },
      negative: (): string => {
        return t(
          'componentsOrganismsReportsScoreSection.notParticipateInTheRetirement',
        );
      },
    },
  };
  const scorePreview = scores.map((score) => {
    const { type } = score;
    const prefix = type.slice(0, 2).toUpperCase() as keyof typeof scoreMap;
    const typeScore = (scoreMap[prefix].score as unknown) as number;
    const renderScore = (): ReactElement => {
      if (
        prefix === SCORE_TYPE.REVISEMENT &&
        revisement_score !== origin_revisement_score
      ) {
        return (
          <span>
            {revisement_score}
            {t('componentsOrganismsReportsScoreSection.pointModification')}
            {isEditable && (
              <EditButton type="button" onClick={onClick}>
                {t('componentsOrganismsReportsScoreSection.edit')}
              </EditButton>
            )}
          </span>
        );
      }
      if (prefix === SCORE_TYPE.REVISEMENT) {
        return (
          <span>
            {typeScore}
            {t('componentsOrganismsReportsScoreSection.point')}
            {isEditable && (
              <EditButton type="button" onClick={onClick}>
                {t('componentsOrganismsReportsScoreSection.edit')}
              </EditButton>
            )}
          </span>
        );
      }
      return (
        <span>
          {typeScore}
          {t('componentsOrganismsReportsScoreSection.point')}
        </span>
      );
    };
    return (
      <Fragment key={score.type}>
        <TopLgDiv>
          {get_display_map()[prefix]}{' '}
          {t('componentsOrganismsReportsScoreSection.totalScore')}{' '}
          <Score>{renderScore()}</Score>
        </TopLgDiv>
        <TopSmDiv>
          <span>
            {typeScore !== 0
              ? scoreMap[prefix].positive(score.max_score)
              : scoreMap[prefix].negative()}
          </span>
        </TopSmDiv>
      </Fragment>
    );
  });
  const feedbackReviewScore = scores.find(
    (score) => score.type === 'feedbackreview',
  );
  const maxScores = scores.reduce((obj: Record<string, number>, score) => {
    const type = score.type as
      | 'essaywriting'
      | 'peerreview'
      | 'metacognition'
      | 'feedbackreview'
      | 'revisement';
    return {
      ...obj,
      [type]: score.max_score,
    };
  }, {});
  const convertToPercent = (score: number, max: number): number | undefined =>
    max ? (100 * score) / max : undefined;
  const essay_writing_max_score = maxScores.essaywriting || 0;
  const peer_review_max_score = maxScores.peerreview || 0;
  const metacognition_max_score = maxScores.metacognition || 0;
  const feedback_review_max_score = maxScores.feedbackreview || 0;
  const revisement_max_score = maxScores.revisement || 0;

  const feedbackAvg = feedback_review_max_score
    ? [convertToPercent(feedback_review_score_avg, feedback_review_max_score)]
    : [convertToPercent(0, 100)];
  const feedbackScore = feedback_review_max_score
    ? [convertToPercent(feedback_review_score, feedback_review_max_score)]
    : [convertToPercent(0, 100)];

  const revisementAvg = revisement_max_score
    ? [convertToPercent(revisement_score_avg, revisement_max_score)]
    : [convertToPercent(0, 100)];
  const revisementScore = revisement_max_score
    ? [convertToPercent(revisement_score, revisement_max_score)]
    : [convertToPercent(0, 100)];

  const avgScores = [
    convertToPercent(essay_writing_score_avg, essay_writing_max_score),
    convertToPercent(peer_review_score_avg, peer_review_max_score),
    convertToPercent(metacognition_score_avg, metacognition_max_score),
    ...feedbackAvg,
    ...revisementAvg,
  ];
  const userScores = [
    convertToPercent(essay_writing_score, essay_writing_max_score),
    convertToPercent(peer_review_score, peer_review_max_score),
    convertToPercent(metacognition_score, metacognition_max_score),
    ...feedbackScore,
    ...revisementScore,
  ];
  const options = {
    credits: { enabled: false },
    legend: { position: 'bottom', labels: { fontSize: 13, paddingRight: 10 } },
    scale: { ticks: { min: 0, max: 100 }, pointLabels: { fontSize: 14 } },
    layout: { padding: 0 },
    title: {
      display: true,
      text:
        t('componentsOrganismsReportsScoreSection.conversionScore100') ?? '',
      fontSize: 16,
      padding: 10,
    },
    maintainAspectRatio: false,
    responsive: false,
  };
  const fixedScores = [
    SCORE_TYPE.WRITING,
    SCORE_TYPE.PEER_REVIEW,
    SCORE_TYPE.META,
    SCORE_TYPE.FEEDBACK_REVIEW,
    SCORE_TYPE.REVISEMENT,
  ];
  const graphData = {
    labels: fixedScores.map((score) => {
      return get_display_map()[score];
    }),
    datasets: [
      {
        label: t('componentsOrganismsReportsScoreSection.totalAverage'),
        backgroundColor: 'rgba(51,51,51,0.3)',
        borderWidth: 1,
        data: avgScores.map((s) => s?.toFixed(1)),
      },
      {
        label: is_my_report
          ? t('componentsOrganismsReportsScoreSection.me')
          : name,
        backgroundColor: 'rgba(86, 70, 255, 0.3)',
        borderWidth: 1,
        data: userScores.map((u) => u?.toFixed(1)),
      },
    ],
  };

  return (
    <Section>
      <div className={classes.FlexDiv}>
        <div className={classes.LeftDesktop}>
          <BorderedDiv>
            <Title>
              {t('componentsOrganismsReportsScoreSection.score')}
              {completed && (
                <>
                  <Score>
                    {(
                      essay_writing_score +
                      peer_review_score +
                      metacognition_score +
                      feedback_review_score +
                      revisement_score
                    ).toFixed(1)}
                  </Score>
                  {t('componentsOrganismsReportsScoreSection.point')}
                </>
              )}
            </Title>
            {completed ? (
              scorePreview
            ) : (
              <div style={{ marginTop: 20 }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon />
                  {t('componentsOrganismsReportsScoreSection.endOfTheTask')}
                </span>
              </div>
            )}
          </BorderedDiv>

          <div className={classes.mgTop}>
            <Title>
              {t('componentsOrganismsReportsScoreSection.rawScore')}
            </Title>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.thLg}>
                    {t(
                      'componentsOrganismsReportsScoreSection.evaluationScale',
                    )}
                  </th>
                  <th className={classes.thSm}>
                    {t('componentsOrganismsReportsScoreSection.averageScore')}
                  </th>
                  <th className={classes.thSm}>
                    {t('componentsOrganismsReportsScoreSection.standard')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {peer_review_points.map((data) => {
                  return (
                    <tr key={data.standard_id}>
                      <td className={classes.tdLg}>{data.question}</td>
                      <td className={classes.tdSm}>
                        {data.avg.toFixed(1)} / {data.max_point}
                      </td>
                      <td className={classes.tdSm}>
                        {`(${data.std.toFixed(1)})`}
                      </td>
                    </tr>
                  );
                })}

                {/* 피드백평가 단계 설정 여부에 따라 있다 없다 하는 부분 */}
                {feedbackReviewScore ? (
                  <>
                    <tr>
                      <td>
                        <Title style={{ margin: '30px 0 21px 0' }}>
                          {t(
                            'componentsOrganismsReportsScoreSection.feedbackEvaluationRawScore',
                          )}
                        </Title>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.thLg}>
                        {t(
                          'componentsOrganismsReportsScoreSection.evaluationScale',
                        )}
                      </td>
                      <td className={classes.thSm}>
                        {t(
                          'componentsOrganismsReportsScoreSection.averageScore',
                        )}
                      </td>
                      <td className={classes.thSm}>
                        {t('componentsOrganismsReportsScoreSection.standard')}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tdLg}>
                        {t('componentsOrganismsReportsScoreSection.howHelpful')}
                      </td>
                      <td className={classes.tdSm}>
                        {feedback_review_point.avg?.toFixed(1)} /{' '}
                        {feedbackReviewScore?.max_point}
                      </td>
                      <td className={classes.tdSm}>
                        {`(${feedback_review_point.std.toFixed(1)})`}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={classes.RightDesktop}>
          {completed && (
            <Radar
              data={graphData}
              options={options}
              width={450}
              height={450}
            />
          )}
        </div>
      </div>
      <RevisementScoreEdit
        open={open}
        current={revisement_score}
        max={revisement_max_score}
        onClose={(): void => {
          setOpen(false);
        }}
      />
    </Section>
  );
};

export default ScoreSection;
