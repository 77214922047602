import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from 'styled-components';
import moment from 'moment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useTranslation } from 'react-i18next';
import {
  BasicDivFlex,
  BasicDivTop100,
  FontSize14Div,
  TitleMedium,
  TitleSmall,
  ContentMedium,
  InfoRow,
} from '../../../components/atoms';
import {
  useManageDetailStyles,
  AssignmentManageCard,
  LearnerName,
  MiniBar,
} from '../../../components/atoms/mains/DetailPageStyles';
import { AssignmentInfoType, AssignmentManagementType } from '../../../apis';

type AssignmentDetailPresenterProps = {
  assignment: AssignmentInfoType;
  learners: AssignmentManagementType;
};

const StyledSpan = styled.span``;
const FlexDiv = styled.div`
  display: flex;
  border-bottom: 1px solid #ececec;
  padding: 10px 0;
`;
const TxtStatus = styled.div`
  width: 150px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 400;
`;
const TxtGray = styled.div`
  margin-right: 15px;
  color: #ccc;
  font-weight: 300;
`;
const TxtBlack = styled.div`
  margin-right: 20px;
  color: black;
  font-weight: 300;
`;
const FileDiv = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(23, 60, 81, 0.5);
  padding: 7px 15px 7px 10px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`;
const A = styled.a`
  display: flex;
  align-items: center;
`;
const FontMd = styled(TitleMedium)`
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 20px;
`;

const AssignmentDetail: React.FC<AssignmentDetailPresenterProps> = ({
  assignment,
  learners,
}) => {
  const classes = useManageDetailStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();

  const isInQuestionWriting = !!assignment.progresses.find(
    (progress) => progress.stage === '질문작성',
  );
  const isInRevisement = !!assignment.progresses.find(
    (progress) => progress.stage === '퇴고',
  );

  return (
    <BasicDivTop100>
      <FontSize14Div style={{ marginBottom: '10px' }}>
        <StyledSpan
          className={classes.pointer}
          onClick={(): void =>
            history.push('/manager/assignments', { ...state })
          }
        >
          <ArrowBackIcon className={classes.center} />
          {t('pagesManagersAssignmentDetailPresenter.goToList')}
        </StyledSpan>
      </FontSize14Div>
      <BasicDivFlex className={classes.betweenBasic}>
        <div style={{ flex: 1 }}>
          <AssignmentManageCard>
            <FontMd>
              {t('pagesManagersAssignmentDetailPresenter.taskInformation')}
            </FontMd>
            <InfoRow className={classes.borderB}>
              <TitleSmall>
                {t('pagesManagersAssignmentDetailPresenter.projectTitle')}
              </TitleSmall>
              <ContentMedium>{assignment.name}</ContentMedium>
            </InfoRow>
            <InfoRow className={classes.borderB}>
              <TitleSmall>{t('app.교수자')}</TitleSmall>
              <ContentMedium>
                {assignment.instructor.name} / {assignment.instructor.email}
              </ContentMedium>
            </InfoRow>
            <InfoRow className={classes.borderB}>
              <TitleSmall>
                {t('pagesManagersAssignmentDetailPresenter.assignment')}
              </TitleSmall>
              <ContentMedium style={{ whiteSpace: 'break-spaces' }}>
                {assignment.description}
              </ContentMedium>
            </InfoRow>
            <InfoRow>
              <TitleSmall>
                {t('pagesManagersAssignmentDetailPresenter.attachments')}
              </TitleSmall>
              {assignment.files && assignment.files.length > 0 && (
                <div>
                  {assignment.files.map((file) => {
                    const fileArray = file.file.split('/');
                    return (
                      <FileDiv key={file.file}>
                        <AttachFileIcon />
                        <A href={file.file}>
                          {decodeURI(fileArray[fileArray.length - 1])}
                        </A>
                      </FileDiv>
                    );
                  })}
                </div>
              )}
            </InfoRow>
            <InfoRow style={{ paddingTop: '20px' }}>
              <TitleSmall>
                {t('pagesManagersAssignmentDetailPresenter.timeline')}
              </TitleSmall>

              {assignment.progresses
                .filter(
                  (progress) =>
                    !['진행대기중', '질문작성', '퇴고', '완료'].includes(
                      progress.stage || '',
                    ),
                )
                .map((timeline) => {
                  return (
                    <Fragment key={timeline.stage}>
                      <FlexDiv key={timeline.stage}>
                        <TxtStatus>
                          {t(`app.${timeline.stage}`)}
                          {timeline.stage === '에세이작성' &&
                            isInQuestionWriting &&
                            ` / ${t('app.질문작성')}`}
                          {timeline.stage === '피드백평가' &&
                            isInRevisement &&
                            ` / ${t('app.퇴고')}`}
                        </TxtStatus>
                        <TxtGray>
                          {t(
                            'pagesManagersAssignmentDetailPresenter.startDate',
                          )}
                        </TxtGray>
                        <TxtBlack>
                          {timeline.start_date
                            ? moment(timeline.start_date).format(
                                'YYYY-MM-DD HH:mm',
                              )
                            : t(
                                'pagesManagersAssignmentDetailPresenter.thereIsNoInformationEntered',
                              )}
                        </TxtBlack>
                        <TxtGray>
                          {t('pagesManagersAssignmentDetailPresenter.endDate')}
                        </TxtGray>
                        <TxtBlack>
                          {timeline.end_date
                            ? moment(timeline.end_date).format(
                                'YYYY-MM-DD HH:mm',
                              )
                            : t(
                                'pagesManagersAssignmentDetailPresenter.thereIsNoInformationEntered',
                              )}
                        </TxtBlack>
                      </FlexDiv>
                    </Fragment>
                  );
                })}
            </InfoRow>
          </AssignmentManageCard>
        </div>
        <div style={{ minWidth: '30%' }}>
          <AssignmentManageCard>
            <FontMd style={{ marginBottom: '5px' }}>
              {t('pagesManagersAssignmentDetailPresenter.studentInformation')}
            </FontMd>
            <div className={classes.learnersTitle}>
              {t(
                'pagesManagersAssignmentDetailPresenter.participatingStudent',
                { count: learners.headers[0].finished_performances },
              )}
              <MiniBar>|</MiniBar>
              {t('pagesManagersAssignmentDetailPresenter.registeredStudent', {
                count: learners.headers[0].performances,
              })}
            </div>
            {learners.names.map((name) => {
              return (
                <div key={name.pk} className={classes.mgB2}>
                  <LearnerName>{name.name}</LearnerName>
                </div>
              );
            })}
          </AssignmentManageCard>
        </div>
      </BasicDivFlex>
    </BasicDivTop100>
  );
};

export default AssignmentDetail;
