import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { BtnPurpleLg } from 'components/atoms/commons';
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import ManualInstructor from 'assets/pdfs/클래스프렙_이용안내서_교수자.pdf';
import ManualStudent from 'assets/pdfs/클래스프렙_이용안내서_학생.pdf';
import { BtnInstructor, BtnStudent } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import useProfiles from '../../../../hooks/profiles/useProfiles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    margin: '0 auto',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '24px',
  },
}));

type DownloadBtnProps = {
  role: string;
};
const DownloadBtn: React.FC<DownloadBtnProps> = ({ role }) => {
  const { t } = useTranslation();
  switch (role) {
    case '교수자':
      return (
        <BtnInstructor
          style={{ margin: '0' }}
          href={ManualInstructor}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('modalsManualDialog.professorsManualDownload')}
        </BtnInstructor>
      );
    case '학생':
      return (
        <BtnStudent
          style={{ margin: '0' }}
          href={ManualStudent}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('modalsManualDialog.studentManualDownload')}
        </BtnStudent>
      );

    default:
      return <button type="button">-</button>;
  }
};

export type DialogProps = {
  handleClose: () => void;
  open: boolean;
};

const ManualDialog: React.FC<DialogProps> = ({ handleClose, open }) => {
  const classes = useStyles();

  const { role } = useProfiles();

  const [pages, setPages] = useState(0);
  const { t } = useTranslation();
  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setPages(numPages);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <div className={classes.header}>
        Classprep {t(`app.${role}`)} {t('modalsManualDialog.usageGuide')}
      </div>
      <DialogContent className={classes.root}>
        <>
          <div>
            <div style={{ width: '100%' }}>
              <Document
                file={role === '학생' ? ManualStudent : ManualInstructor}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e: any) => {
                  throw Error(e.message);
                }}
              >
                {Array.from(new Array(pages), (el, index) => (
                  <Page pageNumber={index + 1} key={`page-${index}`} />
                ))}
              </Document>
            </div>
          </div>
        </>
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <DownloadBtn role={role} />
        <BtnPurpleLg type="button" onClick={handleClose}>
          {t('modalsManualDialog.confirm')}
        </BtnPurpleLg>
      </DialogActions>
    </Dialog>
  );
};

export default ManualDialog;
