import styled from 'styled-components';

const SideTopToggleMenu = styled.div`
  @media (min-width: 768px) {
    margin-right: 24px;
  }
  @media (max-width: 767px) {
    margin-left: 3.5px;
    margin-right: 11.5px;
  }

  cursor: pointer;
`;

export default SideTopToggleMenu;
