import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { CourseResponse } from '../../apis';

interface CourseProps {
  Container: React.FC;
  TopSection: React.FC<CourseResponse>;
  MidSection: React.FC;
  BottomSection: React.FC;
  courseData: CourseResponse;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps): React.ReactElement {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number): Record<string, string> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#5646ff',
  },
  bar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  fontColor: {
    color: '#5646ff',
  },
}));

const Course: React.FC<CourseProps> = ({
  Container,
  TopSection,
  MidSection,
  BottomSection,
  courseData,
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  return isDesktop ? (
    <Container>
      <TopSection {...courseData} />
      <MidSection />
      <BottomSection />
    </Container>
  ) : (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.bar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            variant="fullWidth"
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              className={classes.fontColor}
              label={t('templatesCourse.lectures') ?? ''}
              {...a11yProps(0)}
            />
            <Tab
              className={classes.fontColor}
              label={t('templatesCourse.noticeBoard') ?? ''}
              {...a11yProps(1)}
            />
            <Tab
              className={classes.fontColor}
              label={t('templatesCourse.assignment') ?? ''}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <Container>
          <TabPanel value={value} index={0}>
            <TopSection {...courseData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MidSection />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BottomSection />
          </TabPanel>
        </Container>
      </div>
    </>
  );
};

export default Course;
