import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  background-color: #3978df;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 18px;
`;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    margin: '0 auto',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '24px 0 0 24px',
  },
  explain: {
    textAlign: 'center',
    padding: '8px 24px',
    whiteSpace: 'pre-wrap',
  },
  left: {
    width: '49.5%',
    fontSize: '16px',
    border: '1px solid #3978DF',
  },
  right: {
    width: '49.5%',
    fontSize: '16px',
    border: '1px solid #5646ff',
  },
  contentLeft: {
    textAlign: 'center',
  },
  contentRight: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    padding: '10px',
  },
  button: {
    color: 'rgba(0,0,0,0.5)',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '5px 15px',
    fontSize: '0.875rem',
    outline: 0,
    minWidth: '64px',
    borderRadius: '4px',
    background: 'none',
    margin: '8px 0',
    cursor: 'pointer',
  },
  divider: {
    borderBottom: '1px solid #3978DF',
    padding: '10px',
  },
}));

export type DialogProps = {
  handleClose: () => void;
  open: boolean;
};

const PaymentInfo: React.FC<DialogProps> = ({ handleClose, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <div className={classes.header}>
        {t('modalsPaymentInfo.paymentInformation')}
      </div>
      <div className={classes.explain}>{t('modalsPaymentInfo.callMe')}</div>

      <DialogContent className={classes.root}>
        <div className={classes.left}>
          <Title>{t('modalsPaymentInfo.personalUseInstructor')}</Title>
          <div className={classes.contentLeft}>
            <div className={classes.divider}>
              {t('modalsPaymentInfo.300000WonPerSemester')}
              <br />
              {t('modalsPaymentInfo.100PeopleInProfessorsLessThan3Classes')}
            </div>
            <div style={{ padding: '10px' }}>
              {t('modalsPaymentInfo.500000WonPerSemester')}
              <br />
              {t('modalsPaymentInfo.200PeopleInProfessorsLessThan5Classes')}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <Title style={{ backgroundColor: '#5646ff' }}>
            {t('modalsPaymentInfo.introduction')}
          </Title>
          <div className={classes.contentRight}>
            {t('modalsPaymentInfo.contentRight1')}
            <br />
            {t('modalsPaymentInfo.contentRight2')}
            <br />
            {t('modalsPaymentInfo.contentRight3')}
            <br />
            {t('modalsPaymentInfo.contentRight4')}
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 0' }}>
        <button type="button" onClick={handleClose} className={classes.button}>
          {t('modalsPaymentInfo.confirm')}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentInfo;
