import styled from 'styled-components';

const TitleInRightBox = styled.h4`
  color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 400;
`;

export default TitleInRightBox;
