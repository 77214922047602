import React, { useContext } from 'react';
import {
  useManageDetailStyles,
  DetailPageCard,
  LeftDiv,
  RightDiv,
  FieldWrapper,
  Field,
  MyPageContainer,
  TitleLarge,
  TitleMedium,
  WhiteBar,
  BtnGrayLgOutline,
  BtnPurpleLgOutline,
} from 'components/atoms';
import { DatePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UserCreationProps, createUser } from 'apis/users';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ErrorParagraph from 'components/atoms/authentications/ErrorParagraph';
import AppContext from 'App/context';
import ManageMainContext from '../ManagerMain/context';

const UserCreation: React.FC = () => {
  const classes = useManageDetailStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { expire_date, is_integrated_management } = useContext(AppContext);
  const groups = useContext(ManageMainContext);
  const { t } = useTranslation();
  const { handleSubmit, control, errors, watch, register } = useForm<
    UserCreationProps
  >({
    defaultValues: {
      role: 'L',
      expire_date,
      group_id: groups[0]?.id,
    },
  });
  const userTypes = [
    { value: 'I', label: '교수자' },
    { value: 'L', label: '학생' },
  ];
  const onSubmit = handleSubmit(
    async (data: UserCreationProps): Promise<void> => {
      try {
        await createUser(data);
        toast.success(t('pagesManagersUserCreation.userInformationIsStored'));
        const urlParts = url.split('/');
        const userListUrl = urlParts.slice(0, urlParts.length - 1).join('/');
        history.push(userListUrl);
      } catch (e) {
        if (e.response.status === 406) {
          toast.warn(
            t(
              'pagesManagersUserCreation.duplicateEmailPleaseUseAnotherEmailAddress',
            ),
          );
        } else {
          toast.warn(t('pagesManagersUserCreation.thisIsTheWrongRequest'));
        }
      }
    },
  );
  const password = React.useRef({});
  password.current = watch('password', '');
  const role = watch('role', 'L');
  const isInstructor = role === 'I';
  return (
    <MyPageContainer>
      <TitleLarge>{t('pagesManagersUserCreation.addUser')}</TitleLarge>
      <form onSubmit={onSubmit}>
        <DetailPageCard style={{ padding: '0' }}>
          <div className={classes.between}>
            <LeftDiv style={{ width: '45%' }}>
              <TitleMedium>
                {t('pagesManagersUserCreation.userInformation')}
              </TitleMedium>
              <FieldWrapper>
                <Field
                  placeholder={
                    t('pagesManagersUserCreation.emailAddress') ?? ''
                  }
                  autoFocus
                  required
                  type="email"
                  name="email"
                  ref={register}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  placeholder={t('pagesManagersUserCreation.name') ?? ''}
                  required
                  type="text"
                  name="name"
                  ref={register}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  placeholder={
                    t('pagesManagersUserCreation.passwordAtLeast6Letters') ?? ''
                  }
                  required
                  type="password"
                  name="password"
                  ref={register({
                    required: 'You must specify a password',
                    minLength: {
                      value: 6,
                      message: t(
                        'pagesManagersUserCreation.thePasswordMustBeEnteredAtLeast6Digits',
                      ),
                    },
                  })}
                />
                {errors.password && (
                  <ErrorParagraph>{errors.password.message}</ErrorParagraph>
                )}
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  placeholder={t('pagesManagersUserCreation.password') ?? ''}
                  required
                  type="password"
                  name="passwordCheck"
                  ref={register({
                    validate: (value) => {
                      if (value === password.current) return true;
                      const msg = t(
                        'pagesManagersUserCreation.itDoesNotMatchThePassword',
                      );
                      return msg;
                    },
                  })}
                />
                {errors.passwordCheck && (
                  <ErrorParagraph>
                    {errors.passwordCheck.message}
                  </ErrorParagraph>
                )}
              </FieldWrapper>
            </LeftDiv>

            <RightDiv style={{ width: '45%' }}>
              <TitleMedium>
                {t('pagesManagersUserCreation.accountInformation')}
              </TitleMedium>
              <Controller
                select
                name="role"
                control={control}
                as={TextField}
                label={t('pagesManagersUserCreation.category') ?? ''}
                SelectProps={{ native: true }}
                variant="outlined"
                className={classes.w80}
              >
                {userTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(`app.${option.label}`)}
                  </option>
                ))}
              </Controller>
              {isInstructor && (
                <Controller
                  control={control}
                  as={DatePicker}
                  value={undefined}
                  name="expire_date"
                  rules={{ required: true }}
                  required
                  error={!!errors.expire_date}
                  format="YYYY-MM-DD"
                  label={
                    t(
                      'pagesManagersUserCreation.teacherAccountExpirationDate',
                    ) ?? ''
                  }
                  maxDate={undefined}
                  className={classes.date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {is_integrated_management && (
                <Controller
                  select
                  name="group_id"
                  control={control}
                  as={TextField}
                  label={t('pagesManagersUserCreation.group') ?? ''}
                  SelectProps={{ native: true }}
                  variant="outlined"
                  className={classes.date}
                >
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Controller>
              )}
            </RightDiv>
          </div>
          <WhiteBar>
            <BtnGrayLgOutline
              type="button"
              onClick={(): void => history.goBack()}
              style={{ marginRight: '10px' }}
            >
              {t('pagesManagersUserCreation.cancellation')}
            </BtnGrayLgOutline>
            <BtnPurpleLgOutline>
              {t('pagesManagersUserCreation.save')}
            </BtnPurpleLgOutline>
          </WhiteBar>
        </DetailPageCard>
      </form>
    </MyPageContainer>
  );
};

export default UserCreation;
