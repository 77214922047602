import styled from 'styled-components';

const SectionLabelNormal = styled.div`
  width: 129px;
  padding: 4px;
  color: #4a6cbb;
  font-weight: 400;
  border: 1px solid #4a6cbb;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  margin-left: -14px;
  ::before {
    counter-increment: section;
    content: counter(section);
    font-size: 11px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4a6cbb;
    display: inline-block;
    margin-right: 11px;
    line-height: 20px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
  }
`;

export default SectionLabelNormal;
