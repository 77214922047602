import styled from 'styled-components';

const BtnSkyblueLgOutline = styled.button`
  color: rgba(113, 186, 216, 1);
  border: 1px solid rgba(113, 186, 216, 1);
  border-radius: 8px;
  transition: 0.3s;
  background: none;
  text-align: center;
  cursor: pointer;
  line-height: 1.4;
  font-size: 15px;
  outline: 0;
  padding: 10px;
  :hover {
    color: rgba(113, 186, 216, 0.5);
    border: 1px solid rgba(113, 186, 216, 0.5);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export default BtnSkyblueLgOutline;
