import React from 'react';
import { CoverBox } from 'components/atoms';
import { useMediaQuery } from 'react-responsive';

const ContainerForm: React.FC = ({ children }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  return (
    <>
      {isDesktop ? (
        <CoverBox>{children}</CoverBox>
      ) : (
        <div style={{ padding: '20px' }}>{children}</div>
      )}
    </>
  );
};

export default ContainerForm;
