import styled from 'styled-components';

const TopLink = styled.span`
  font-size: 13px;
  margin-left: 14px;
  margin-right: 22px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.35);
  cursor: pointer;
`;

export default TopLink;
