import styled from 'styled-components';

type SideMenuProps = {
  isWideSidebar?: boolean;
  sideRole?: string;
};

const SideMenu = styled.nav<SideMenuProps>`
  @media (max-width: 767px) {
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: ${({ isWideSidebar }): string => (isWideSidebar ? '0' : '-280px')};
    transition: left 0.3s;
  }
  @media (min-width: 768px) {
    width: ${({ isWideSidebar }): string => (isWideSidebar ? '280px' : '57px')};
    transition: width 0.3s;
  }
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 280px;
  overflow: hidden;
  background: ${({ sideRole }): string =>
    sideRole === 'learner' ? '#3978DF' : '#6C5DFF '};
`;

export default SideMenu;
