import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  assignmentsFetchAPI,
  courseFetchAPI,
  courseLearnersFetchAPI,
} from 'apis';
import {
  useManageDetailStyles,
  BasicDivTop100,
  FontSize14Div,
  Circle,
} from 'components/atoms';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { AssignmentManageCard } from '../../../components/atoms/mains/DetailPageStyles';

import renderFiles from '../../../utils/renderFiles';
import LoadingPage from '../../LoadingPage';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  padding: 10px;
`;
const StyledLabel = styled.label``;
const StyledValue = styled.div`
  overflow: auto;
`;
const AssignmentList = styled.ul`
  li {
    display: flex;
    align-items: center;

    input {
      margin-right: 1em;
    }
  }
`;
const CountData = styled.span`
  margin-left: 1em;
  color: gray;
  font-size: 12px;
`;
const StyledSpan = styled.span``;

const CourseDetail: React.FC = () => {
  const classes = useManageDetailStyles();
  const history = useHistory();
  const { courseId } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();

  const courseResponse = useQuery(['course', courseId], courseFetchAPI);
  const courseAssignmentsResponse = useQuery(
    ['assignments', courseId],
    assignmentsFetchAPI,
  );
  const courseLeanersResponse = useQuery(
    ['AllLearners', courseId],
    courseLearnersFetchAPI,
  );

  if (
    courseResponse.isLoading ||
    !courseResponse.data ||
    courseAssignmentsResponse.isLoading ||
    !courseAssignmentsResponse.data ||
    courseLeanersResponse.isLoading ||
    !courseLeanersResponse.data
  ) {
    return <LoadingPage />;
  }

  const {
    data: { data: course },
  } = courseResponse;
  const {
    data: { data: assignments },
  } = courseAssignmentsResponse;
  const {
    data: { data: learners },
  } = courseLeanersResponse;
  const activeLeaners = learners.filter((learner) => learner.is_active);

  return (
    <BasicDivTop100>
      <FontSize14Div>
        <StyledSpan
          className={classes.pointer}
          onClick={(): void => history.push('/manager/courses', { ...state })}
        >
          <ArrowBackIcon className={classes.center} />
          {t('pagesManagersCourseDetail.moveToTheListOfCourses')}
        </StyledSpan>
      </FontSize14Div>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ flex: 1 }}>
          <AssignmentManageCard>
            <div className={classes.subtitle}>
              {t('pagesManagersCourseDetail.lectures')}
            </div>
            <StyledGrid>
              <StyledLabel>
                {t('pagesManagersCourseDetail.lectureName')}
              </StyledLabel>
              <StyledValue>
                <span>{course.name}</span>
              </StyledValue>
              <StyledLabel>
                {t('pagesManagersCourseDetail.lectures')}
              </StyledLabel>
              <StyledValue>
                <div style={{ whiteSpace: 'break-spaces' }}>
                  {course.description}
                </div>
              </StyledValue>
              <StyledLabel>
                {t('pagesManagersCourseDetail.teachingInformation')}
              </StyledLabel>
              <StyledValue>
                <span style={{ marginRight: '10px' }}>
                  {course.instructor.name}
                </span>
                <span>{course.instructor.email}</span>
              </StyledValue>
              <StyledLabel>
                {t('pagesManagersCourseDetail.newStudentRegistration')}
              </StyledLabel>
              <StyledValue>
                <span>
                  {course.is_open
                    ? t('pagesManagersCourseDetail.possible')
                    : t('pagesManagersCourseDetail.impossible')}
                </span>
              </StyledValue>
              <StyledLabel>
                {t('pagesManagersCourseDetail.lectureCode')}
              </StyledLabel>
              <StyledValue>
                <span>{course.code}</span>
              </StyledValue>
              <StyledLabel>
                {t('pagesManagersCourseDetail.syllabus')}
              </StyledLabel>
              <StyledValue>
                <span>{renderFiles(course.files, [])}</span>
              </StyledValue>
            </StyledGrid>
          </AssignmentManageCard>
        </div>
        <div style={{ minWidth: '40%' }}>
          <AssignmentManageCard>
            <div className={classes.subtitle}>
              {t('pagesManagersCourseDetail.taskInformation')}
              <CountData>
                {t('pagesManagersCourseDetail.gunAssignmentslength', {
                  total: assignments.length,
                  completed: assignments.filter(
                    (assignment) => assignment.current_stage.stage === '완료',
                  ).length,
                })}
              </CountData>
            </div>
            <AssignmentList>
              {assignments.map((assignment) => {
                const isCompleted = assignment.current_stage.stage === '완료';
                return (
                  <li key={assignment.id}>
                    <Checkbox
                      disabled
                      checked={isCompleted}
                      inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                    />
                    {isCompleted ? (
                      <span>
                        <span
                          style={{
                            color: 'gray',
                            textDecoration: 'line-through',
                          }}
                        >
                          {`${assignment.name}`}
                        </span>
                        {` (${t('app.완료')})`}
                      </span>
                    ) : (
                      <span>{assignment.name}</span>
                    )}
                  </li>
                );
              })}
            </AssignmentList>
          </AssignmentManageCard>
          <AssignmentManageCard style={{ marginTop: '10px' }}>
            <div className={classes.subtitle}>
              {t('pagesManagersCourseDetail.studentInformation')}
              <CountData>
                {t('pagesManagersCourseDetail.general', {
                  length: activeLeaners.length,
                })}
              </CountData>
            </div>
            <ul>
              {activeLeaners.map((learner) => (
                <li key={learner.id}>
                  <Circle />
                  <span className={classes.subject}>
                    {`${learner.user.name} (${learner.code})`}
                  </span>
                </li>
              ))}
            </ul>
          </AssignmentManageCard>
        </div>
      </div>
    </BasicDivTop100>
  );
};

export default CourseDetail;
