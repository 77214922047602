import React from 'react';
import Supports from 'templates/Supports';
import ContainerForm from 'components/organisms/supports/ContainerForm';
import CardSectionForm from 'components/organisms/supports/CardSectionForm/';

const SupportsPage: React.FC = () => {
  return <Supports Container={ContainerForm} CardSection={CardSectionForm} />;
};

export default SupportsPage;
