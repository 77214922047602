import styled from 'styled-components';

const ProjectFilterIcon = styled.button`
  margin-right: 10px;
  border-radius: 12px;
  font-size: 12px;
  padding: 0.2rem 0.1rem;
  text-align: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  background-color: #5646ff;
  border: 1px solid #5646ff;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  outline: 0;
`;

export default ProjectFilterIcon;
