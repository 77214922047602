import React, { useContext } from 'react';
import {
  BlankTh,
  BlockDiv,
  BorderLeftTh,
  BorderRightTh,
  BorderTopBottomTh,
  CommonDivFlex,
  DivBottom,
  ManageTdAssignment,
  ManageTdName,
  RedText,
  SmallGrayText,
  StageStatusText,
  TextInTh,
} from 'components/atoms';
import _ from 'lodash';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
  assignmentFetchManagement,
  fetchAttachments,
  fetchStagesOfLearners,
  patchLateParticipation,
} from 'apis';
import { CustomTooltip } from 'components/molecules/newAssignments/ScoreWeight';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeExcel } from '../../../../utils/parsers';
import ManageAssignmentContext from '../../../../pages/ManageAssignment/context';
import LoadingPage from '../../../../pages/LoadingPage';

const useStyles = makeStyles(() => ({
  leftBtns: {
    width: '130px',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    color: 'rgba(6, 88, 169, 0.7)',
    border: '1px solid rgba(6, 88, 169, 0.7)',
    padding: '8px 24px 7px 24px',
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '14px',
    transition: '0.3s',
    whiteSpace: 'nowrap',
    marginRight: '4px',
  },
  rightBtn: {
    width: '130px',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    color: '#fff',
    background: 'rgba(6, 88, 169, 0.7)',
    padding: '8px 24px 7px 24px',
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}));

const BlueText = styled(RedText)`
  color: #0097e6;
  cursor: pointer;
  :hover {
    color: #00a8ff;
  }
`;

const CursorSpan = styled.span`
  cursor: pointer;
`;

interface CustomMenuItem {
  anchorEl: null | HTMLElement;
}
const BottomSectionForm: React.FC = () => {
  const [popover1, setPopover1] = React.useState<CustomMenuItem>({
    anchorEl: null,
  });
  const { courseId, assignmentId, role } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['management', courseId, assignmentId],
    assignmentFetchManagement,
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  const { name: assignmentName, current_stage } = useContext(
    ManageAssignmentContext,
  );
  const { t } = useTranslation();

  const classes = useStyles();
  if (!data || isLoading) {
    return <LoadingPage />;
  }
  const { headers, rows, names } = data.data;
  const essayFinishedCount =
    headers.find((header) => header.short === 'ES')?.finished_performances || 0;

  let isResetableMatching = false;
  let tooltipText = '';
  if (current_stage.stage === '동료평가') {
    if (essayFinishedCount > 0) {
      tooltipText = t(
        'manageAssignmentBottomSectionForm.youCanSpecifyAFellowEvaluatorForTheEssaySubmitted',
      );
      isResetableMatching = true;
    } else {
      tooltipText = t('manageAssignmentBottomSectionForm.noStudent');
    }
  } else {
    tooltipText = t('manageAssignmentBottomSectionForm.notMatchedStage');
  }

  const merge = _.zip(names, rows);
  const onApprove = (performanceId: number) => async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    const answer = window.confirm(
      t('manageAssignmentBottomSectionForm.wouldYouLikeToApprove'),
    );
    if (answer) {
      try {
        await patchLateParticipation({ courseId, assignmentId, performanceId });
        await refetch();
        toast.success(
          t(
            'manageAssignmentBottomSectionForm.iApprovedTheParticipationOfTheTask',
          ),
        );
      } catch (e) {
        toast.warn(
          t('manageAssignmentBottomSectionForm.thisIsTheWrongRequest'),
        );
      }
    }
  };
  const exportClose = (): void => setPopover1({ ...popover1, anchorEl: null });
  return (
    <DivBottom>
      <StageStatusText>
        {t(
          'manageAssignmentBottomSectionForm.participatingStudentAssignmentSituation',
        )}
      </StageStatusText>
      <SmallGrayText style={{ textAlign: 'center' }}>
        {t('manageAssignmentBottomSectionForm.clickOnTheName')}
      </SmallGrayText>
      <BlockDiv>
        <table>
          <thead>
            <tr>
              <BlankTh />
              <BorderLeftTh>
                {t(`app.${headers[0].progress}`)}
                <TextInTh>{`(${headers[0].finished_performances}/${headers[0].performances})`}</TextInTh>
              </BorderLeftTh>
              {headers.slice(1, headers.length - 1).map((header) => {
                return (
                  <BorderTopBottomTh key={header.progress}>
                    {t(`app.${header.progress}`)}
                    <TextInTh>
                      {`(${header.finished_performances}/${header.performances})`}
                    </TextInTh>
                  </BorderTopBottomTh>
                );
              })}
              <BorderRightTh>
                {t(`app.${headers[headers.length - 1].progress}`)}
                <TextInTh>
                  {`(${headers[headers.length - 1].finished_performances}/${
                    headers[headers.length - 1].performances
                  })`}
                </TextInTh>
              </BorderRightTh>
            </tr>
          </thead>
          <tbody>
            {merge.map(([name, row]) => {
              return (
                <tr key={name?.pk}>
                  <ManageTdName>
                    <CursorSpan
                      onClick={(event): void => {
                        event.preventDefault();
                        if (name?.report) {
                          history.push(`${url}/reports/${name?.report}`);
                        } else {
                          toast.warn(
                            t('manageAssignmentBottomSectionForm.noReport', {
                              name: name?.name,
                            }),
                          );
                        }
                      }}
                    >
                      {name?.name}
                    </CursorSpan>
                    {name?.objection && (
                      <RedText>
                        {`[${t(
                          'manageAssignmentBottomSectionForm.receivedFromTheObjection',
                        )}]`}
                      </RedText>
                    )}
                    {name?.is_essay_late && (
                      <RedText style={{ color: '#8c7ae6' }}>
                        {`[${t('manageAssignmentBottomSectionForm.late')}${
                          name?.essay_late_time
                            ? `(${moment(name?.essay_late_time).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )})`
                            : ''
                        }]`}
                      </RedText>
                    )}
                    {name?.is_late?.performance_id && (
                      <BlueText
                        onClick={onApprove(name.is_late.performance_id)}
                      >
                        {`[${t('manageAssignmentBottomSectionForm.approval')}]`}
                      </BlueText>
                    )}
                  </ManageTdName>
                  {row?.map((item) => {
                    const {
                      short,
                      is_contain,
                      is_completed,
                      finished,
                      total,
                      is_late,
                    } = item;
                    const renderDisplay = (): React.ReactElement | string => {
                      if (is_completed) {
                        if (is_late) {
                          return <CheckIcon style={{ color: 'red' }} />;
                        }
                        return <CheckIcon />;
                      }
                      if (is_contain) {
                        if (total === 0) {
                          return <CloseIcon />;
                        }
                        return `${finished} / ${total}`;
                      }
                      return '-';
                    };
                    return (
                      <ManageTdAssignment key={short}>
                        {renderDisplay()}
                      </ManageTdAssignment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </BlockDiv>
      <CommonDivFlex style={{ marginTop: '30px', marginBottom: '10px' }}>
        <div>
          <Button
            className={classes.leftBtns}
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
              setPopover1({ ...popover1, anchorEl: event.currentTarget })
            }
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            {t('manageAssignmentBottomSectionForm.export')}
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={popover1.anchorEl}
            keepMounted
            open={Boolean(popover1.anchorEl)}
            onClose={exportClose}
          >
            <MenuItem
              onClick={async (): Promise<void> => {
                try {
                  const response = await fetchStagesOfLearners(
                    'stages-of-learners',
                    courseId,
                    assignmentId,
                  );
                  exportClose();
                  makeExcel(response.data.report, response.data.assignment);
                } catch (e) {
                  toast.warn(
                    t(
                      'manageAssignmentBottomSectionForm.thisIsTheWrongRequest',
                    ),
                  );
                }
              }}
            >
              {t('manageAssignmentBottomSectionForm.excel')}
            </MenuItem>
            <MenuItem
              onClick={async (): Promise<void> => {
                try {
                  exportClose();
                  await fetchAttachments(
                    courseId,
                    assignmentId,
                    assignmentName,
                  );
                  toast.success(
                    t(
                      'manageAssignmentBottomSectionForm.allAttachmentsHaveBeenDownloaded',
                    ),
                  );
                } catch (e) {
                  toast.warn(
                    t(
                      'manageAssignmentBottomSectionForm.thisIsTheWrongRequest',
                    ),
                  );
                }
              }}
            >
              {t('manageAssignmentBottomSectionForm.allAttachments')}
            </MenuItem>
          </Menu>
          <Button
            onClick={(): void =>
              history.push(
                `/courses/${courseId}/${role}/assignments/${assignmentId}/score`,
              )
            }
            className={classes.leftBtns}
          >
            {t('manageAssignmentBottomSectionForm.scoreDetails')}
          </Button>
          <CustomTooltip title={tooltipText} placement="top">
            <span>
              <Button
                onClick={(): void => history.push(`${url}/matchings`)}
                className={classes.leftBtns}
                disabled={!isResetableMatching}
              >
                {t('manageAssignmentBottomSectionForm.assignment')}
              </Button>
            </span>
          </CustomTooltip>
        </div>
        <div>
          <Button
            className={classes.rightBtn}
            onClick={(): void =>
              history.push(
                `/courses/${courseId}/${role}/assignments/${assignmentId}/edit`,
              )
            }
          >
            {t('manageAssignmentBottomSectionForm.edit')}
          </Button>
        </div>
      </CommonDivFlex>
    </DivBottom>
  );
};

export default BottomSectionForm;
