import React from 'react';
import { BasicDivFlexBetween, BtnRoundSmOutlinePurple } from 'components/atoms';
import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TopBarDiv = styled.div`
  padding: 14px 56px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`;

const BtnBack = styled(BtnRoundSmOutlinePurple)`
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const TopBarSection: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  return (
    <TopBarDiv style={{ paddingTop: 14, paddingLeft: 10 }}>
      <BasicDivFlexBetween>
        <BtnBack
          onClick={(): void => {
            const pathParts = url.split('/');
            const targetPath = pathParts
              .slice(0, pathParts.length - 2)
              .join('/');
            history.push(targetPath);
          }}
        >
          <ArrowBackIcon style={{ verticalAlign: 'middle' }} />
          {t(
            'componentsOrganismsPeerReviewMatchingTopBarSection.returnToTheList',
          )}
        </BtnBack>
      </BasicDivFlexBetween>
    </TopBarDiv>
  );
};

export default TopBarSection;
