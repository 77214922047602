import styled from 'styled-components';

const GrayBackground = styled.div`
  background-color: rgba(23, 60, 81, 0.2);
  padding: 28px 40px;
  margin-top: -3px;
  border-top: 3px solid rgba(0, 0, 0, 0.08);
  overflow: auto;
  height: 100%;
  @media (max-width: 767px) {
    padding: 14px 20px;
  }
`;
export default GrayBackground;
