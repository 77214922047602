import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import diagonal from '../../assets/images/diagonal.svg';
import { CoverLine, LogoImg } from '../../components/atoms/authentications';
import logo from '../../assets/images/logo-typo.svg';
import { AddButton } from '../../components/atoms/newAssignmnets';

const ErrorLayout = styled.div`
  background: #5646ff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: white;
  @media (min-width: 768px) {
    position: absolute;
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    justify-content: center;
    margin: 24px 0;
  }
`;

const MessageBox = styled.div`
  background: white;
  text-align: center;
  max-width: 670px;
  margin: auto;
  padding: 60px 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.h2`
  text-align: center;
  color: #5646ff;
  margin: 30px 0;
  @media (max-width: 767px) {
    font-size: 32px;
  }
  @media (min-width: 768px) {
    font-size: 35px;
  }
`;

const Desc = styled.div`
  text-align: center;
  margin-top: 45px;
  font-size: 16px;
  white-space: pre-wrap;
`;

const Button = styled(AddButton)`
  width: 300px;
  text-align: center;
  padding: 10px 50px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 46px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
`;

const Error404: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <ErrorLayout>
      <LogoContainer>
        <CoverLine src={diagonal} />
        <LogoImg src={logo} alt="classprep" />
      </LogoContainer>
      <MessageBox>
        <Message>{t('pagesError404.thePageCannotBeFound')}</Message>
        <Desc>
          {t('pagesError404.pleaseCheckThePathAgain')}
          <br />
          <br />
          <Link to="/supports">{t('pagesError404.supports')}</Link>
        </Desc>
        <Button onClick={(): void => history.push('/')}>
          {t('pagesError404.goHome')}
        </Button>
      </MessageBox>
    </ErrorLayout>
  );
};

export default Error404;
