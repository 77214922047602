import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { FetchReportReviewerResponseType } from 'apis';
import { useMediaQuery } from 'react-responsive';
import {
  Question,
  ScoreText,
  useReportStyles,
} from '../../../atoms/reports/containers';

const StyledRating = withStyles({
  iconFilled: {
    color: '#eb5b5b',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

type InstructorReviewCardProps = {
  response: FetchReportReviewerResponseType;
};

const InstructorReviewCard: React.FC<InstructorReviewCardProps> = ({
  response,
}) => {
  const classes = useReportStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <>
      {isDesktop ? (
        <>
          <Question key={response.id}>
            <span>{response.standard.question}</span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              className={classes.inline}
            >
              <StyledRating
                name="customized-color"
                max={response.standard.max_point}
                value={response.edited_point}
                readOnly
              />
              <ScoreText>
                {response.edited_point} / {response.standard.max_point}
              </ScoreText>
            </Box>
          </Question>
        </>
      ) : (
        <>
          <Question key={response.id}>
            <span>{response.standard.question}</span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              style={{ marginLeft: '-0.2rem' }}
            >
              <StyledRating
                name="customized-color"
                max={response.standard.max_point}
                value={response.edited_point}
                readOnly
              />
              <ScoreText>
                {response.edited_point} / {response.standard.max_point}
              </ScoreText>
            </Box>
          </Question>
        </>
      )}
    </>
  );
};

export default InstructorReviewCard;
