import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  ButtonForInstructor,
  ButtonForStudent,
  CardContainer,
  CardContainerWrap,
  CardExplain,
  CardTitle,
  CommonDivFlex,
  CommonDivFlexEnd,
  IconDoing,
  IconDone,
  IconTodo,
  SmallGrayText,
} from '../../atoms';
import {
  assignmentCreateLateParticipation,
  AssignmentResponseType,
  ProgressType,
} from '../../../apis/assignments';
import { switchStage } from '../../../pages/EditAssignment/context';
import { STAGES } from '../../../utils/constants';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

type ProjectCardProps = {
  assignment: AssignmentResponseType;
  courseRole: string;
  courseId: number;
  refetch: () => void;
};

const DisabledButton = styled(ButtonForStudent)`
  color: lightgray;
  border-color: lightgray;
`;

const InDiv = styled.div`
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  max-height: 70px;
  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

const getNextProgress = (assignment: AssignmentResponseType): ProgressType => {
  const {
    current_stage: { stage },
    progresses,
  } = assignment;
  const excludeQuestion = progresses.filter((progress) => {
    const s = switchStage(progress.stage || '');
    return s !== STAGES.QUESTION;
  });
  const currentProgress = excludeQuestion.find(
    (progress) => progress.stage === stage,
  );
  const index = currentProgress ? excludeQuestion.indexOf(currentProgress) : 0;
  return excludeQuestion[index + 1];
};

const MyIcon = ({ assignment }: { assignment: AssignmentResponseType }) => {
  const {
    current_stage: { stage },
    is_waiting_next_stage,
  } = assignment;
  const { t } = useTranslation();
  const prefix = switchStage(stage || '');
  if (prefix === STAGES.COMPLETE) {
    return <IconDone>{t(`app.${stage}`)}</IconDone>;
  }
  if (prefix === STAGES.PREPARATION) {
    return <IconTodo>{t(`app.${stage}`)}</IconTodo>;
  }
  if (is_waiting_next_stage) {
    return (
      <IconDoing>
        {`${t(`app.${getNextProgress(assignment).stage}`)} ${t(
          'projectCard.waiting',
        )}`}
      </IconDoing>
    );
  }
  return (
    <IconDoing>
      {t(`app.${stage}`)} {t('projectCard.proceeding')}
    </IconDoing>
  );
};

const MySchedule = ({ assignment }: { assignment: AssignmentResponseType }) => {
  const {
    current_stage: { stage },
  } = assignment;
  const prefix = switchStage(stage || '');
  const { t } = useTranslation();

  if (!assignment.is_auto_schedule) {
    return <>{t('projectCard.manual')}</>;
  }

  if (prefix === STAGES.COMPLETE) {
    return <>{t('projectCard.end')}</>;
  }

  const format = 'YYYY-MM-DD HH:mm';
  const progress = getNextProgress(assignment);
  const start = `${moment(progress.start_date).format(format)} ${t(
    `app.${progress.stage}`,
  )} ${t('projectCard.start')}`;
  if (prefix === STAGES.PREPARATION) {
    return <>{start}</>;
  }
  if (!assignment.is_waiting_next_stage) {
    return (
      <>
        {`${moment(assignment.current_stage.end_date).format(format)} ${t(
          `app.${assignment.current_stage.stage}`,
        )} ${t('projectCard.end2')}`}
      </>
    );
  }
  return <>{start}</>;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  courseRole,
  courseId,
  refetch,
  assignment,
}) => {
  const {
    id,
    current_stage: { stage },
    description,
    name,
    status,
    report_id,
    performance_id,
  } = assignment;
  const { t } = useTranslation();
  const learners: string[] = [
    t('projectCard.participationInAssignments'),
    t('projectCard.assignment'),
    t('projectCard.waitingForApprovalForParticipation'),
  ];
  const isManager = ['assistant', 'instructor'].includes(courseRole);
  const history = useHistory();
  const prefix = `/courses/${courseId}/${courseRole}/assignments/${id}`;

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const onClick = async (): Promise<string | number | void> => {
    if (status === 1) {
      if (stage === '완료' && report_id) {
        return history.push(`${prefix}/performances/reports/${report_id}`);
      }
      if (performance_id) {
        return history.push(`${prefix}/performances/${performance_id}`);
      }

      return history.push(`${prefix}/performances/info`);
    }
    if (status === 2) {
      return toast.info(
        t('projectCard.accessAfterTheApprovalOfTheProfessorsParticipation'),
      );
    }
    try {
      await assignmentCreateLateParticipation(courseId, id);
      refetch();
      return toast.success(t('projectCard.success'));
    } catch (e) {
      return toast.warn(t('projectCard.thisIsTheWrongRequest'));
    }
  };
  const renderButton = (): React.ReactElement => {
    if (status === 1) {
      return (
        <ButtonForStudent onClick={onClick}>
          {stage === '완료' ? t('projectCard.viewResults') : learners[status]}
        </ButtonForStudent>
      );
    }
    if (stage === '완료') {
      return (
        <CustomTooltip
          title={t('projectCard.youCannotParticipateInTheCompletedTask') ?? ''}
          placement="top"
        >
          <DisabledButton type="button">
            {t('projectCard.completedTask')}
          </DisabledButton>
        </CustomTooltip>
      );
    }
    return (
      <ButtonForStudent onClick={onClick}>{learners[status]}</ButtonForStudent>
    );
  };
  return (
    <CardContainerWrap>
      <CardContainer>
        <CommonDivFlex style={{ alignItems: 'baseline' }}>
          <MyIcon assignment={assignment} />
          <SmallGrayText style={{ display: 'flex', alignItems: 'center' }}>
            <MySchedule assignment={assignment} />
            {courseRole === 'instructor' && (
              <>
                &nbsp; / &nbsp;
                {assignment.is_public ? (
                  <>
                    {t('newAssignmentsScheduling.publicSettingOn')}
                    <VisibilityOutlined />
                  </>
                ) : (
                  <>
                    {t('newAssignmentsScheduling.publicSettingOff')}
                    <VisibilityOffOutlined />
                  </>
                )}
              </>
            )}
          </SmallGrayText>
        </CommonDivFlex>
        <CardTitle>{name}</CardTitle>
        {isDesktop ? (
          <CardExplain>
            <InDiv>{description}</InDiv>
          </CardExplain>
        ) : (
          <div style={{ height: '18px' }} />
        )}
        <CommonDivFlexEnd style={{ marginBottom: 0 }}>
          {isManager ? (
            <ButtonForInstructor
              onClick={(): void => {
                history.push(prefix);
              }}
            >
              {t('projectCard.taskManagement')}
            </ButtonForInstructor>
          ) : (
            renderButton()
          )}
        </CommonDivFlexEnd>
      </CardContainer>
    </CardContainerWrap>
  );
};

export default ProjectCard;
