import styled from 'styled-components';

const SideHr = styled.hr`
  @media (min-width: 768px) {
    margin: 0 26px;
  }
  @media (max-width: 767px) {
    margin: 0 12px;
  }
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
`;

export default SideHr;
