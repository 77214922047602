import React from 'react';

interface HelpProps {
  Container: React.FC;
  Tab: React.FC;
  Title: React.FC;
}

const Help: React.FC<HelpProps> = ({ Container, Title, Tab }) => {
  return (
    <Container>
      <Title />
      <Tab />
    </Container>
  );
};

export default Help;
