import { requests, ResponseType } from 'utils';
import {
  CoursePostCommentsCreateAPIProps,
  CoursePostCommentsDeleteAPIProps,
  CoursePostCreateAPIProps,
  CoursePostCreateAPIResponse,
  CoursePostDetailType,
  CoursePostResponse,
  CoursePostUpdateAPIProps,
  PostCommentType,
} from './types';

export const coursePostCreateAPI = async ({
  courseId,
  coursePost,
}: CoursePostCreateAPIProps): Promise<
  ResponseType<CoursePostCreateAPIResponse>
> => {
  return requests().post(`/api/v1/courses/${courseId}/posts/`, coursePost, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const coursePostUpdateAPI = async ({
  courseId,
  postId,
  coursePost,
}: CoursePostUpdateAPIProps): Promise<ResponseType<CoursePostDetailType>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/posts/${postId}/`,
    coursePost,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const coursePostsFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<CoursePostResponse[]>> => {
  return requests().get(`/api/v1/courses/${courseId}/posts/`);
};

export const coursePostDetailFetchAPI = async (
  key: string,
  courseId: number,
  postId: number,
): Promise<ResponseType<CoursePostDetailType>> => {
  return requests().get(`/api/v1/courses/${courseId}/posts/${postId}/`);
};

export const coursePostCommentsFetchAPI = async (
  key: string,
  courseId: number,
  postId: number,
): Promise<ResponseType<PostCommentType[]>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/posts/${postId}/comments/`,
  );
};

export const coursePostCommentsDeleteAPI = async ({
  courseId,
  postId,
  commentId,
}: CoursePostCommentsDeleteAPIProps): Promise<ResponseType<void>> => {
  return requests().delete(
    `/api/v1/courses/${courseId}/posts/${postId}/comments/${commentId}/`,
  );
};

export const coursePostCommentsCreateAPI = async ({
  courseId,
  postId,
  content,
}: CoursePostCommentsCreateAPIProps): Promise<
  ResponseType<PostCommentType>
> => {
  return requests().post(
    `/api/v1/courses/${courseId}/posts/${postId}/comments/`,
    { content },
  );
};

export const coursePostDeleteAPI = async (
  courseId: number,
  postId: number,
): Promise<void> => {
  return requests().delete(`/api/v1/courses/${courseId}/posts/${postId}/`);
};
