import styled from 'styled-components';

const ManageTdName = styled.td`
  margin-right: auto;
  width: 100%;
  font-size: 15px;
  min-width: 100px;
  padding: 13px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export default ManageTdName;
