import { convertFromRaw, EditorState } from 'draft-js';
import XLSX from 'xlsx';
import { t } from 'i18next';
import { Stage } from '../pages/EditAssignment/types';
import { switchStage } from '../pages/EditAssignment/context';
import { ProgressType } from '../apis/assignments';
import { FetchScoresByLearnerType } from '../apis/courses';

export type ParseEditorStateProps = {
  content: string;
};

export const parseEditorState = ({
  content,
}: ParseEditorStateProps): EditorState => {
  let editorState;
  try {
    editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(content)),
    );
  } catch (e) {
    editorState = EditorState.createEmpty();
  }
  return editorState;
};

export const generateStages = (progresses: ProgressType[]): Stage => {
  const stages: Stage = {};
  progresses.forEach((progress) => {
    const stage = switchStage(progress.stage || '');
    if (stage && progress) {
      stages[stage as keyof Stage] = progress as never;
    }
  });
  return stages;
};

export const makeExcel = (
  data: (string | null | number)[][],
  title: string,
): void => {
  const wb = XLSX.utils.book_new();
  const excelData = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(
    wb,
    excelData,
    title.replace(/[\\/*[\]:?]/gi, '_').slice(0, 31),
  );
  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const makeCourseTableExcel = (
  courseName: string,
  data: FetchScoresByLearnerType,
): void => {
  const common = [
    t('utilsParsers.email'),
    t('utilsParsers.academicLife'),
    t('utilsParsers.studentID'),
  ];
  const wb = XLSX.utils.book_new();
  const rootHeader = [
    ...common,
    ...data.headers.map((header) => header.name),
    t('utilsParsers.essayMisilCity'),
    t('utilsParsers.colleagueevaluation'),
    t('utilsParsers.feedbackEvaluation'),
    t('utilsParsers.retirementMisilCity'),
    t('utilsParsers.totalScore'),
    t('utilsParsers.conversionScore'),
  ];
  const rootRows = data.rows.map((row) => {
    return [
      row.email,
      row.name,
      row.code,
      ...row.assignments.map((a) => a.total),
      ...row.not_performed.map((n) => `${n.not_performed} / ${n.total}`),
      row.total,
      row.conversion_score,
    ];
  });
  const excelData = XLSX.utils.aoa_to_sheet([rootHeader, ...rootRows]);
  XLSX.utils.book_append_sheet(
    wb,
    excelData,
    courseName.replace(/[\\/*[\]:?]/gi, '_').slice(0, 31),
  );

  data.headers.forEach((header, index) => {
    const h = [
      ...common,
      t('utilsParsers.totalScore'),
      t('utilsParsers.writing'),
      t('utilsParsers.evaluationAbility'),
      t('utilsParsers.metaCognitive'),
      t('utilsParsers.feedback'),
      t('utilsParsers.retirement'),
    ];
    const r = data.rows.map((row) => {
      const assignment = row.assignments.find((a) => a.pk === header.pk);
      return [
        row.email,
        row.name,
        row.code,
        assignment?.total,
        assignment?.essay_writing_score,
        assignment?.peer_review_score,
        assignment?.metacognition_score,
        assignment?.feedback_review_score,
        assignment?.revisement_score,
      ];
    });
    const e = XLSX.utils.aoa_to_sheet([h, ...r]);
    XLSX.utils.book_append_sheet(
      wb,
      e,
      `${index + 1}.${
        header.name !== courseName
          ? header.name
          : `${courseName}-${header.name}`
      }`
        .replace(/[\\/*[\]:?]/gi, '_')
        .slice(0, 31),
    );
  });
  XLSX.writeFile(wb, `${courseName}.xlsx`);
};
