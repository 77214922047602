import { createReducer } from 'typesafe-actions';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { LoginCheckState } from './types';
import { LOGIN_CHECK } from './actions';

const initialState: LoginCheckState = {
  loginCheck: {
    is_staff: false,
  },
  errors: [],
};

const loginCheck = createReducer<LoginCheckState>(initialState, {
  [LOGIN_CHECK.FETCH_LOGIN_CHECK_SUCCESS]: (state, action) => {
    return {
      ...state,
      loginCheck: action.payload,
    };
  },
  [LOGIN_CHECK.FETCH_LOGIN_CHECK_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersLoginCheckReducer.itIsNotACertifiedAccount'));
    errorNotify();
    return {
      ...state,
    };
  },
});

export default loginCheck;
