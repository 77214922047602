import { createReducer } from 'typesafe-actions';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ProfilesState } from './types';
import { PROFILES } from './actions';

const initialState: ProfilesState = {
  user: {
    email: '',
    name: '',
    courses: [],
    enrolls: [],
    address: '',
    attending_type: '',
    date_of_birth: '',
    display_attending_type: '',
    grade: 1,
  },
  standardScores: [],
  role: '',
};

const profiles = createReducer<ProfilesState>(initialState, {
  [PROFILES.FETCH_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [PROFILES.FETCH_PROFILE_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersProfilesReducer.youDoNotHavePermission'));
    errorNotify();
    return {
      ...state,
    };
  },
  [PROFILES.FETCH_STANDARD_SCORES_SUCCESS]: (state, action) => {
    return {
      ...state,
      standardScores: action.payload,
    };
  },
  [PROFILES.FETCH_STANDARD_SCORES_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(
        t('reducersProfilesReducer.iCouldntGetAStandardScoreForEachTask'),
      );
    errorNotify();
    return {
      ...state,
    };
  },
});

export default profiles;
