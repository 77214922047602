import styled from 'styled-components';

const FileLabel = styled.label`
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  outline: none;
  font-size: 15px;
  letter-spacing: -0.8px;
  cursor: pointer;
`;

export default FileLabel;
