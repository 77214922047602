import React, { Fragment, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  BasicDivFlexBothCentered,
  CircleBorderBox,
  CustomDivider,
  Mgt15,
  Mgt25,
  QuestionCard,
  SpaceDiv,
  useReportStyles,
} from 'components/atoms';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';
import PeerReviewCard from '../../../molecules/reports/PeerReviewCard';
import InstructorReviewCard from '../../../molecules/reports/InstructorReviewCard';
import FeedbackReviewCard from '../../../molecules/reports/FeedbackReviewCard';

const StyledSpan = styled.span``;
const Heading = styled.div`
  font-size: 15px;
  color: rgba(23, 60, 81, 0.5);
`;

const FeedbackSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    params: { courseId, assignmentId, role },
  } = useRouteMatch();
  const isClickable = ['instructor', 'assistant'].includes(role);
  const classes = useReportStyles();
  const {
    name,
    peer_review_performance: { reviewers, targets },
    is_my_report,
    pr_performance_id,
  } = useContext(ReportContext);
  const reviewersExcludeSelf = reviewers.filter(
    (reviewer) => reviewer.reviewer !== reviewer.target,
  );
  const targetsExcludeSelf = targets.filter(
    (target) => target.reviewer !== target.target,
  );
  const selfReviewP = reviewers.find(
    (reviewer) =>
      reviewer.reviewer === reviewer.target &&
      reviewer.responses.every((response) => !!response.point),
  );
  const openReportWindow = (reportId: number) => {
    return (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      window.open(
        `/courses/${courseId}/${role}/assignments/${assignmentId}/reports/${reportId}`,
      );
    };
  };
  const clickableNickname = (
    reportId: number,
    reportName: string,
  ): React.ReactElement => {
    return (
      <StyledSpan
        className={classes.blue}
        style={{
          cursor: isClickable ? 'pointer' : 'default',
        }}
        onClick={isClickable ? openReportWindow(reportId) : (): void => {}}
      >
        {reportName}
      </StyledSpan>
    );
  };

  return (
    <>
      {/* 1. 내가 받은 동료평가와 이에 대한 피드백 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading>
            {is_my_report
              ? t(
                  'componentsOrganismsReportsMobileFeedbackSection.myEvaluationAndFeedback',
                )
              : t(
                  'componentsOrganismsReportsMobileFeedbackSection.notMyEvaluationAndFeedback',
                  { name },
                )}
          </Heading>
        </AccordionSummary>
        <AccordionDetails style={{ width: '100%', display: 'block' }}>
          <QuestionCard style={{ padding: '10px 18px' }}>
            <>
              {reviewersExcludeSelf.length > 0 ? (
                reviewersExcludeSelf.map((peer) => {
                  const isMyReview = peer.reviewer === pr_performance_id;
                  const isExistEditedScore = peer.responses.some(
                    (data) => data.edited_point,
                  );

                  return (
                    <div key={peer.id}>
                      <div style={{ margin: '10px 0' }}>
                        {isMyReview ? (
                          <span>
                            {t(
                              'componentsOrganismsReportsMobileFeedbackSection.iGive',
                            )}
                          </span>
                        ) : (
                          <>
                            {clickableNickname(
                              peer.reviewer_report,
                              peer.reviewer_name,
                            )}
                            {t(
                              'componentsOrganismsReportsMobileFeedbackSection.someoneGive',
                            )}
                          </>
                        )}
                        {is_my_report
                          ? t(
                              'componentsOrganismsReportsMobileFeedbackSection.myEvaluation',
                            )
                          : t(
                              'componentsOrganismsReportsMobileFeedbackSection.notMyEvaluation',
                              { name },
                            )}
                      </div>
                      <div>
                        {peer.responses.map((response) => {
                          return (
                            <CircleBorderBox
                              key={response.id}
                              style={{ padding: '24px 24px 4px 24px' }}
                            >
                              <PeerReviewCard response={response} />
                            </CircleBorderBox>
                          );
                        })}
                        <>
                          <SpaceDiv>
                            {peer.feedbackreviewmatching?.point !== null && (
                              <>
                                <div className={classes.mgTB}>
                                  {is_my_report
                                    ? t(
                                        'componentsOrganismsReportsMobileFeedbackSection.myFeedback',
                                      )
                                    : t(
                                        'componentsOrganismsReportsMobileFeedbackSection.notMyFeedback',
                                        { name },
                                      )}
                                </div>
                                <FeedbackReviewCard
                                  feedbackreviewmatching={
                                    peer.feedbackreviewmatching
                                  }
                                />
                              </>
                            )}
                          </SpaceDiv>
                        </>
                        {isExistEditedScore && (
                          <CircleBorderBox>
                            <div className={classes.red}>
                              {t(
                                'componentsOrganismsReportsMobileFeedbackSection.adjustment',
                              )}
                            </div>
                            {peer.responses.map((response) => {
                              return (
                                <InstructorReviewCard
                                  key={response.id}
                                  response={response}
                                />
                              );
                            })}
                            <Mgt25 className={classes.reason}>
                              {t(
                                'componentsOrganismsReportsMobileFeedbackSection.reasonsForAdjustment',
                              )}
                            </Mgt25>
                            <Mgt15>{peer.instructor_comment}</Mgt15>
                          </CircleBorderBox>
                        )}
                      </div>
                      <CustomDivider />
                    </div>
                  );
                })
              ) : (
                <BasicDivFlexBothCentered>
                  {t(
                    'componentsOrganismsReportsMobileFeedbackSection.noColleagueEvaluation',
                  )}
                </BasicDivFlexBothCentered>
              )}
            </>
          </QuestionCard>
        </AccordionDetails>
      </Accordion>

      {/* 2. 자기평가 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading>
            {is_my_report
              ? t(
                  'componentsOrganismsReportsMobileFeedbackSection.mySelfEvaluation',
                )
              : t(
                  'componentsOrganismsReportsMobileFeedbackSection.notMySelfEvaluation',
                  { name },
                )}
          </Heading>
        </AccordionSummary>
        <AccordionDetails style={{ width: '100%', display: 'block' }}>
          <QuestionCard style={{ padding: '24px' }}>
            {selfReviewP ? (
              selfReviewP.responses.map((response) => {
                return <PeerReviewCard key={response.id} response={response} />;
              })
            ) : (
              <BasicDivFlexBothCentered style={{ padding: '0 20px 20px 20px' }}>
                <span style={{ fontSize: '14px' }}>
                  {t(
                    'componentsOrganismsReportsMobileFeedbackSection.noSelfEvaluation',
                  )}
                </span>
              </BasicDivFlexBothCentered>
            )}
          </QuestionCard>
        </AccordionDetails>
      </Accordion>

      {/* 3. 내가 쓴 다른사람 평가 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading>
            {is_my_report
              ? t(
                  'componentsOrganismsReportsMobileFeedbackSection.myPeerReview',
                )
              : t(
                  'componentsOrganismsReportsMobileFeedbackSection.notMyPeerReview',
                  { name },
                )}
          </Heading>
        </AccordionSummary>
        <AccordionDetails style={{ width: '100%', display: 'block' }}>
          <QuestionCard>
            {targetsExcludeSelf.length > 0 ? (
              <div>
                {targetsExcludeSelf.map((target) => {
                  const { feedbackreviewmatching } = target;
                  const amITarget = target.target === pr_performance_id;

                  return (
                    <Fragment key={target.id}>
                      <div style={{ margin: '10px 0' }}>
                        {is_my_report
                          ? t(
                              'componentsOrganismsReportsMobileFeedbackSection.iGiveEvaluation',
                            )
                          : t(
                              'componentsOrganismsReportsMobileFeedbackSection.toSomeoneEvaluation',
                              { name },
                            )}
                        {amITarget ? (
                          <span>
                            {t(
                              'componentsOrganismsReportsMobileFeedbackSection.me',
                            )}
                          </span>
                        ) : (
                          <>
                            {clickableNickname(
                              target.target_report,
                              target.target_name,
                            )}
                          </>
                        )}
                        {t(
                          'componentsOrganismsReportsMobileFeedbackSection.evaluation',
                        )}
                      </div>
                      <div>
                        {target.responses.map((response) => {
                          return (
                            <CircleBorderBox
                              key={response.id}
                              style={{ padding: '24px 24px 4px 24px' }}
                            >
                              <PeerReviewCard response={response} />
                            </CircleBorderBox>
                          );
                        })}
                      </div>

                      <SpaceDiv>
                        <div className={classes.mgTB}>
                          {t(
                            'componentsOrganismsReportsMobileFeedbackSection.feedbackAboutEvaluation',
                            { name: target.target_name },
                          )}
                        </div>
                        <FeedbackReviewCard
                          feedbackreviewmatching={feedbackreviewmatching}
                        />
                      </SpaceDiv>
                      {!!target.objection && (
                        <CircleBorderBox>
                          <div className={classes.red}>
                            {t(
                              'componentsOrganismsReportsMobileFeedbackSection.objection',
                            )}
                          </div>
                          <Mgt15>{target.objection?.comment}</Mgt15>
                        </CircleBorderBox>
                      )}
                      <CustomDivider />
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              <BasicDivFlexBothCentered>
                <span style={{ fontSize: '14px' }}>
                  {t(
                    'componentsOrganismsReportsMobileFeedbackSection.noPeerReview',
                  )}
                </span>
              </BasicDivFlexBothCentered>
            )}
          </QuestionCard>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FeedbackSection;
