import styled from 'styled-components';

const StarDiv = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #173c51;
`;
export default StarDiv;
