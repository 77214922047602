import {
  PeerReviewMatchingTargetType,
  PeerReviewMatchingResultType,
} from 'apis/performances';
import { requests, ResponseType } from 'utils/requests';
import { PeerReviewMatchingUpdateAPIProps } from './types';

export const peerReviewMatchingFetchAPI = async (
  key: string,
  matchingId: number,
): Promise<ResponseType<PeerReviewMatchingTargetType>> => {
  return requests().get(
    `/api/v1/performances/peerreview-matching/${matchingId}/`,
  );
};

export const peerReviewMatchingUpdateAPI = async ({
  id,
  responses,
}: PeerReviewMatchingUpdateAPIProps): Promise<
  ResponseType<PeerReviewMatchingTargetType & PeerReviewMatchingResultType>
> => {
  return requests().patch(`/api/v1/performances/peerreview-matching/${id}/`, {
    responses,
  });
};

export const peerReviewMatchingInstructorFetchAPI = async (
  key: string,
  matchingId: number,
): Promise<ResponseType<PeerReviewMatchingTargetType>> => {
  return requests().get(
    `/api/v1/performances/peerreview-matching/${matchingId}/instructor/`,
  );
};

export const peerReviewMatchingInstructorUpdateAPI = async ({
  id,
  responses,
  instructor_comment,
}: PeerReviewMatchingUpdateAPIProps): Promise<
  ResponseType<PeerReviewMatchingTargetType>
> => {
  return requests().patch(
    `/api/v1/performances/peerreview-matching/${id}/instructor/`,
    {
      responses,
      instructor_comment,
    },
  );
};
