import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
  },
  editor: {
    flex: 1,
  },
  x: {
    color: '#fff',
    textAlign: 'end',
    padding: '10px',
  },
  xStyle: {
    fontSize: '35px',
    cursor: 'pointer',
  },
  flex: { display: 'flex' },
  overlayModal: {
    display: 'flex',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  modal: {
    position: 'relative',
    background: 'none',
    overflow: 'visible',
    display: 'block',
    margin: 'auto',
    borderRadius: '3px',
    maxHeight: '90vh',
  },
  essay: {
    maxWidth: '794px',
    minWidth: '500px',
    marginRight: '20px',
    marginBottom: '30px',
    overflow: 'auto',
  },
  form: {
    overflow: 'auto',
  },
  card: {
    marginBottom: '16px',
    backgroundColor: '#fff',
    padding: '20px 16px 4px 16px',
    borderRadius: '6px',
    maxWidth: '350px',
    maxHeight: '350px',
    overflow: 'auto',
  },
  zoom: {
    padding: '8% 12%',
    background: '#fff',
    transition: '0.3s',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
    height: '100%',
  },
  height100: {
    height: '100%',
  },
  name: {
    color: '#173c51',
    fontWeight: 400,
    fontSize: '18px',
    paddingBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  content: {
    color: 'rgb(34, 34, 34)',
    paddingTop: '20px',
    fontSize: '14px',
    lineHeight: '1.7',
    maxHeight: '80%',
  },
  standard: {
    fontSize: '16px',
    color: '#4a6cbb',
    marginBottom: '12px',
  },
  smTitle: {
    marginBottom: '7px',
    color: 'rgba(6, 88, 169, 0.7)',
  },
  textarea: {
    borderColor: 'rgba(0, 0, 0, 0.35)',
    margin: '10px 5px',
    width: '300px',
    borderRadius: '6px',
    padding: '10px',
  },
  reason: {
    fontSize: '16px',
    color: '#173c51',
    paddingBottom: '5px',
  },
  comment: {
    margin: '0 0px 15px 00px',
    border: '1px solid rgba(0, 0, 0, 0.35)',
    padding: '10px',
    backgroundColor: '#ececec',
    maxHeight: '150px',
    overflow: 'auto',
  },
}));

export default useStyles;
