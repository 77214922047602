import styled, { css, FlattenInterpolation } from 'styled-components';

type MenuItemProps = {
  isActive?: boolean;
};

const MenuItem = styled.a<MenuItemProps>`
  display: flex;
  align-items: center;
  padding: 10px 26px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: rgba(255, 255, 255, 0.9);
  }

  ${({ isActive }): FlattenInterpolation<MenuItemProps> => css`
    color: ${isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)'};
    background-color: ${isActive ? '#0d1b4c' : 'transparent'};
    font-weight: ${isActive ? 400 : 300};
  `}
`;

export default MenuItem;
