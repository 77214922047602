import styled from 'styled-components';

const FileAddBtn = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  font-size: 15px;
  letter-spacing: -0.8px;
  cursor: pointer;
  margin-right: 12px;
  align-items: center;
`;

export default FileAddBtn;
