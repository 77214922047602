import { createContext } from 'react';
import { CourseResponse } from 'apis';

const CourseContext = createContext<CourseResponse>({
  code: '',
  instructor: {
    name: '',
    email: '',
  },
  name: 'string',
  description: '',
  is_open: false,
  files: [],
});

export default CourseContext;
