import styled, { css, FlattenInterpolation } from 'styled-components';
import FilterIconSelected from '../FilterIconSelected';

type FilterIconProps = {
  isActive?: boolean;
};

const FilterIcon = styled(FilterIconSelected)<FilterIconProps>`
  ${({ isActive }): FlattenInterpolation<FilterIconProps> => css`
    background: ${isActive ? '#5646ff' : '#fff'};
    border: ${isActive ? '1px solid #5646ff' : '1px solid #a8b7be'};
    color: ${isActive ? '#fff' : '#a8b7be;'};
  `}
`;

export default FilterIcon;
