import styled from 'styled-components';

const PeerReviewTd = styled.td`
  @media (min-width: 768px) {
    &:first-child {
      width: 70%;
      padding-right: 32px;
    }
    &:last-child {
      width: 30%;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 767px) {
    &:first-child {
      margin-bottom: 8px;
    }
    display: block;
  }
`;

export default PeerReviewTd;
