import React, { useContext } from 'react';
import { FontSize13Div } from 'components/atoms';
import PerformanceContext from 'pages/PerformAssignment/PerformanceMain/context';
import { AssignmentProgresses } from 'components/molecules/commons';
import { useTranslation } from 'react-i18next';

const TimelineRow: React.FC = () => {
  const {
    is_auto_schedule,
    progresses,
    current_stage,
    is_waiting_next_stage,
    refetch,
    now,
  } = useContext(PerformanceContext);
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px 0' }}>
      <FontSize13Div>
        {t('performAssignmentTimelineRow.taskPerformanceStage')}
      </FontSize13Div>
      <AssignmentProgresses
        isAutoSchedule={is_auto_schedule}
        progresses={progresses}
        currentStage={current_stage}
        isWaitingNextStage={is_waiting_next_stage}
        refetch={refetch}
        now={now}
      />
    </div>
  );
};

export default TimelineRow;
