import { requests, ResponseType } from 'utils';

type UserType = {
  email: string;
  name: string;
  address: string;
  attending_type: string;
  date_of_birth: string;
  grade: number;
  display_attending_type: string;
  courses: ProfileCourseType[];
  enrolls: ProfileEnrollType[];
};

type ProfileCourseType = {
  id: number;
  name: string;
};

type ProfileEnrollType = {
  id: number;
  course: ProfileCourseType;
};

export type ProfileResponse = {
  user: UserType;
  role: string;
};

export type StandardScoreResponse = {
  assignment: string;
  standard_score: number;
};

const PROFILE_BASE_URL = '/api/v1/users';

export const profileAPI = (): Promise<ResponseType<ProfileResponse>> => {
  return requests().get(`${PROFILE_BASE_URL}/profile/`);
};

export const fetchStandardScoreAPI = (): Promise<
  ResponseType<StandardScoreResponse[]>
> => {
  return requests().get(`${PROFILE_BASE_URL}/standard-scores/`);
};

export type UserEditType = {
  name: string;
  password: string;
  date_of_birth?: string;
  address?: string;
  attending_type?: string;
  grade?: number;
};

export type ProfileEditAPIProps = {
  role?: string;
  user: UserEditType;
};

export const updateProfileAPI = async ({
  role,
  user,
}: ProfileEditAPIProps): Promise<ResponseType<ProfileEditAPIProps>> => {
  return requests().patch(`${PROFILE_BASE_URL}/profile/`, { role, user });
};
