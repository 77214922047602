import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  BtnSkyblueLgOutline,
  CardBorderedOnelineDiv,
  MgFt16Div,
  QuestionCard,
  QuestionDiv,
} from 'components/atoms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CourseFileType, EditorType, assignmentLearnerInfo } from 'apis';
import ReportContext from '../../../../pages/Report/context';
import EssayViewModal from '../../modals/EssayView';
import EssayViewCompareModal from '../../modals/EssayViewCompare';
// import { useMediaQuery } from 'react-responsive';

const CustomBtn = styled(BtnSkyblueLgOutline)`
  white-space: nowrap;
  margin-right: 5px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 0.2rem;
  }
`;
const PurpleBtn = styled(CustomBtn)`
  color: #5646ff;
  border: 1px solid #5646ff;
`;
const BlueBtn = styled(CustomBtn)`
  color: #0765e8;
  border: 1px solid #0765e8;
`;
const AnswerDiv = styled(QuestionDiv)`
  font-weight: 300;
  line-height: 1.4;
  font-size: 15px;
`;
const Div = styled.div`
  margin-bottom: 15px;
`;

export type CurrentEssay = {
  essay: string;
  editor_type: EditorType;
  files?: CourseFileType[];
};

const EssaySection: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { name, is_my_report, questions, essay, revisement } = useContext(
    ReportContext,
  );

  // 퇴고단계가 존재하는지 확인하기 위함
  const {
    params: { courseId, assignmentId },
  } = useRouteMatch();
  const { data: assignmentData } = useQuery(
    ['assignmentInfo', courseId, assignmentId],
    assignmentLearnerInfo,
  );
  const isExsistRevisement = assignmentData?.data.progresses.some(
    (a) => a.stage === '퇴고',
  );

  // fix lint
  // const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const isExistQuestion = questions.responses.some(
    (data) => data.response.length > 0,
  );
  const [currentEssay, setCurrentEssay] = useState<CurrentEssay>({
    essay: '',
    editor_type: EditorType.DRAFT,
    files: [],
  });
  const handleOpenToggle = (state: boolean, type: string) => (): void => {
    if (type === 'origin') {
      setCurrentEssay({
        essay: essay.content,
        files: essay.files,
        editor_type: essay.editor_type,
      });
    } else if (type === 'revise') {
      setCurrentEssay({
        essay: revisement.content,
        files: revisement.files,
        editor_type: revisement.editor_type,
      });
    }
    setOpen(state);
  };
  const handleOpenToggle2 = (state: boolean) => (): void => {
    setOpen2(state);
  };

  const disabled = !essay.content && essay.files.length === 0;

  return (
    <>
      <CardBorderedOnelineDiv>
        <MgFt16Div style={{ marginBottom: 0 }}>
          {is_my_report
            ? t('componentsOrganismsReportsEssaySection.myEssay')
            : t('componentsOrganismsReportsEssaySection.notMyEssay', { name })}
        </MgFt16Div>
        <div style={{ marginLeft: 'auto' }}>
          {isExsistRevisement ? (
            <PurpleBtn
              onClick={handleOpenToggle(true, 'origin')}
              disabled={disabled}
            >
              {t('componentsOrganismsReportsEssaySection.before')}
              {open}
            </PurpleBtn>
          ) : (
            <PurpleBtn
              onClick={handleOpenToggle(true, 'origin')}
              disabled={disabled}
            >
              {t('componentsOrganismsReportsEssaySection.body')}
              {open}
            </PurpleBtn>
          )}

          {isExsistRevisement && (
            <>
              <CustomBtn
                onClick={handleOpenToggle(true, 'revise')}
                disabled={
                  !revisement.content &&
                  (revisement.files?.length === 0 || !revisement.files)
                }
              >
                {t('componentsOrganismsReportsEssaySection.after')}
                {open}
              </CustomBtn>
              <BlueBtn
                onClick={handleOpenToggle2(true)}
                disabled={!essay.content && !revisement.content}
              >
                {t('componentsOrganismsReportsEssaySection.comparison')}
                {open2}
              </BlueBtn>
            </>
          )}
        </div>
      </CardBorderedOnelineDiv>

      {isExistQuestion ? (
        <QuestionCard style={{ padding: '24px 24px 9px 24px' }}>
          <MgFt16Div style={{ marginBottom: '20px' }}>
            {is_my_report
              ? t('componentsOrganismsReportsEssaySection.myQ')
              : t('componentsOrganismsReportsEssaySection.notMyQ', { name })}
          </MgFt16Div>
          {questions.responses.map((data) => {
            return (
              <Div key={data.id}>
                <QuestionDiv>{data.quiz.quiz}</QuestionDiv>
                <AnswerDiv>{data.response}</AnswerDiv>
              </Div>
            );
          })}
        </QuestionCard>
      ) : null}
      <EssayViewModal
        editor_type={currentEssay.editor_type}
        content={currentEssay.essay || ''}
        files={currentEssay.files || []}
        handleClose={handleOpenToggle(false, '')}
        open={open}
      />
      <EssayViewCompareModal
        content={
          essay.editor_type === EditorType.DRAFT
            ? essay.content || ''
            : essay.plain_text
        }
        content2={
          revisement.editor_type === EditorType.DRAFT
            ? revisement.content || ''
            : revisement.plain_text
        }
        content_editor_type={essay.editor_type}
        content2_editor_type={revisement.editor_type}
        handleClose={handleOpenToggle2(false)}
        open={open2}
      />
    </>
  );
};

export default EssaySection;
