import { createContext } from 'react';
import {
  EditorType,
  PeerReviewMatchingTargetType,
} from '../../../apis/performances';

const PeerReviewMatchingContext = createContext<PeerReviewMatchingTargetType>({
  id: 0,
  responses: [],
  is_completed: false,
  target_name: '',
  target_essay: {
    id: 0,
    content: '',
    plain_text: '',
    editor_type: EditorType.DRAFT,
    files: [],
  },
  target: 0,
  reviewer: 0,
});

export default PeerReviewMatchingContext;
