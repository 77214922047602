import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from 'reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();
export default (): Store => {
  const middleware: Middleware[] = [sagaMiddleware];
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(...middleware)),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
