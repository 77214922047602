import { requests, ResponseType } from 'utils/requests';
import {
  BasePerformanceAPIProps,
  Performance,
  UpdatePerformanceProps,
} from './types';

export const fetchPerformance = (
  key: string,
  courseId: number,
  assignmentId: number,
  performanceId: number,
): Promise<ResponseType<Performance>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/performances/${performanceId}/`,
  );
};

export const updatePerformance = async ({
  data,
  courseId,
  assignmentId,
  performanceId,
}: UpdatePerformanceProps): Promise<ResponseType<Performance>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/performances/${performanceId}/`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const patchLateParticipation = async ({
  courseId,
  assignmentId,
  performanceId,
}: BasePerformanceAPIProps): Promise<ResponseType<void>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/performances/${performanceId}/late-participation/`,
  );
};

export const patchLateEssay = async ({
  data,
  courseId,
  assignmentId,
  performanceId,
}: UpdatePerformanceProps): Promise<ResponseType<Performance>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/performances/${performanceId}/late-essay/`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};
