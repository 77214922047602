import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { createCourse, fetchCourses, setCurrentCourse } from 'reducers/courses';

type UseCoursesActionsType = {
  dispatchFetchCourses: () => void;
  dispatchCreateCourse: (props: FormData) => void;
  dispatchSetCurrentCourse: (course: string) => void;
};

const useCoursesActions = (): UseCoursesActionsType => {
  const dispatch = useDispatch();
  const dispatchFetchCourses = useCallback(() => dispatch(fetchCourses()), [
    dispatch,
  ]);
  const dispatchCreateCourse = useCallback(
    (props: FormData) => dispatch(createCourse(props)),
    [dispatch],
  );
  const dispatchSetCurrentCourse = useCallback(
    (course: string) => dispatch(setCurrentCourse(course)),
    [dispatch],
  );
  return {
    dispatchFetchCourses,
    dispatchCreateCourse,
    dispatchSetCurrentCourse,
  };
};
export default useCoursesActions;
