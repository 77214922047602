import React, { useContext, useEffect, useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { EditorContainer } from 'components/organisms/essayWriting/EditorSection';
import styled, { css, FlattenInterpolation } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import IconButton from '@material-ui/core/IconButton';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';
import QuillEditorEssayWritingContext from '../../../../pages/PerformAssignment/QuillEditorEssayWriting/context';
import SwitchPerformanceContext from '../../../../pages/PerformAssignment/SwitchPage/context';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import { switchStage } from '../../../../pages/EditAssignment/context';
import { STAGES } from '../../../../utils/constants';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: [] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
];

type EditorProps = {
  isMobile?: boolean;
  zoom?: number;
};

const minZoom = 0;
const maxZoom = 2;
const minWidthPercent = 75;
const getWidthPercent = (zoom: number) => {
  // 75 ~ 100% 의 값을 zoom 단계에 따라 일정한 구간으로 나눔
  const levelCount = maxZoom - minZoom;
  const levelValue = (100 - minWidthPercent) / levelCount;
  return 100 - (maxZoom - zoom) * levelValue;
};
const baseFontSizeRate = 1.5;
const getFontSizeRate = (zoom: number) => {
  const scaleUpValue = getWidthPercent(zoom) - minWidthPercent;
  const scaleUpRate = scaleUpValue / minWidthPercent;
  return baseFontSizeRate * (1 + scaleUpRate);
};

const Editor = styled(ReactQuill)<EditorProps>`
  ${({ isMobile, zoom = 0 }): FlattenInterpolation<EditorProps> => css`
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    div.ql-container {
      background-color: rgba(23, 60, 81, 0.2);
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      overflow: auto;
      .ql-editor {
        flex: 1;
        width: ${isMobile ? '100%' : `${getWidthPercent(zoom)}%`};
        margin: ${isMobile ? 0 : '0 auto'};
        padding: ${isMobile ? '15px 20px 90px 20px' : '72px 90px 90px 72px'};
        line-height: 1.5;
        background-color: white;
        p {
          font-size: ${getFontSizeRate(zoom)}em;
        }
      }
    }
  `}
`;

const Container = styled(EditorContainer)`
  position: relative;
`;

const ZoomButtonSection = styled.div`
  position: fixed;
  width: 60px;
  top: 43%;
  right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 1px solid gray;
  border-radius: 5px;
  z-index: 1;
`;

const getReadOnly = (
  prefix: string,
  is_completed: boolean,
  is_waiting_next_stage: boolean,
  isRevisement?: boolean,
): boolean => {
  if (isRevisement) {
    return prefix === STAGES.COMPLETE;
  }

  return (
    (prefix !== STAGES.ESSAY && is_completed) ||
    prefix === STAGES.COMPLETE ||
    (is_waiting_next_stage && is_completed)
  );
};

type QuillEditorProps = {
  editorRef: React.RefObject<ReactQuill> | null;
  setPercentWidth: React.Dispatch<React.SetStateAction<number>> | null;
} & Omit<EditorProps, 'zoom'> &
  ReactQuillProps;

export const QuillEditor: React.FC<QuillEditorProps> = ({
  isMobile,
  editorRef,
  setPercentWidth = null,
  value,
  readOnly,
  onChange,
  modules,
  theme,
}: QuillEditorProps) => {
  const [zoom, setZoom] = useState(minZoom);
  useEffect(() => {
    if (setPercentWidth) {
      setPercentWidth(getWidthPercent(zoom));
    }
  }, [zoom, setPercentWidth]);
  return (
    <>
      {!isMobile && (
        <ZoomButtonSection>
          <IconButton
            onClick={() => {
              setZoom((prev) => Math.max(prev - 1, minZoom));
            }}
            disabled={zoom === minZoom}
          >
            <ZoomOutRoundedIcon fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => {
              setZoom((prev) => Math.min(prev + 1, maxZoom));
            }}
            disabled={zoom === maxZoom}
          >
            <ZoomInRoundedIcon fontSize="large" />
          </IconButton>
        </ZoomButtonSection>
      )}
      <Editor
        isMobile={isMobile}
        zoom={zoom}
        ref={editorRef}
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        modules={modules}
        theme={theme}
      />
    </>
  );
};

const QuillEditorSection: React.FC = () => {
  const { editorRef, value, setValue, isRevisement } = useContext(
    QuillEditorEssayWritingContext,
  );
  const { is_completed } = useContext(SwitchPerformanceContext);
  const { is_waiting_next_stage, current_stage } = useContext(
    PerformanceContext,
  );
  const prefix = switchStage(current_stage.stage || '');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const readOnly = getReadOnly(
    prefix,
    is_completed,
    is_waiting_next_stage,
    isRevisement,
  );

  return (
    <Container>
      <QuillEditor
        isMobile={isMobile}
        editorRef={editorRef}
        setPercentWidth={null}
        value={value}
        readOnly={readOnly}
        onChange={setValue}
        modules={{
          toolbar: toolbarOptions,
        }}
        theme="snow"
      />
    </Container>
  );
};

export default QuillEditorSection;
