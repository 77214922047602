import { createAction } from 'typesafe-actions';
import { SignInPayloadType } from './types';
import { SignUpAPIProps, UpdatePasswordAPIProps } from '../../apis';

export enum AUTHENTICATION {
  SIGN_IN = 'authentication/SIGN_IN',
  SIGN_IN_SUCCESS = 'authentication/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = 'authentication/SIGN_IN_FAILURE',
  SIGN_UP = 'authentication/SIGN_UP',
  SIGN_UP_SUCCESS = 'authentication/SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = 'authentication/SIGN_UP_FAILURE',
  SIGN_OUT = 'authentication/SIGN_OUT',
  CHANGE_PASSWORD = 'authentication/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'authentication/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'authentication/CHANGE_PASSWORD_FAILURE',
}

export const signIn = createAction(AUTHENTICATION.SIGN_IN)<SignInPayloadType>();
export const signOut = createAction(AUTHENTICATION.SIGN_OUT)();
export const signUp = createAction(AUTHENTICATION.SIGN_UP)<SignUpAPIProps>();
export const changePassword = createAction(AUTHENTICATION.CHANGE_PASSWORD)<
  UpdatePasswordAPIProps
>();
