import React from 'react';
import {
  PostWritingContainer,
  PostWritingContentDiv,
  PostWritingContentTextarea,
  PostWritingTitleDiv,
  PostWritingTitleInput,
  Wrapper,
} from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const PostSectionForm: React.FC = () => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PostWritingContainer>
        <PostWritingTitleDiv>
          <PostWritingTitleInput
            placeholder={
              t('componentsOrganismsPostWritingPostSectionForm.enter') ?? ''
            }
            name="title"
            required
            maxLength={50}
            ref={register}
          />
        </PostWritingTitleDiv>
        <PostWritingContentDiv>
          <PostWritingContentTextarea
            rows={10}
            ref={register}
            required
            name="content"
            placeholder={
              t('componentsOrganismsPostWritingPostSectionForm.please') ?? ''
            }
          />
        </PostWritingContentDiv>
      </PostWritingContainer>
    </Wrapper>
  );
};

export default PostSectionForm;
