import styled from 'styled-components';

const PostWritingTitleInput = styled.input`
  border: none;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  overflow: visible;
  line-height: 1.15;
  ::placeholder {
    color: #bebebf;
    font-weight: 300;
  }
`;

export default PostWritingTitleInput;
