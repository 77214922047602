import styled from 'styled-components';
import ButtomButton from '../../courses/BottomButton';

const ButtonPassword = styled(ButtomButton)`
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  margin-right: 10px;
  outline: 0;
`;

export default ButtonPassword;
