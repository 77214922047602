import { combineReducers } from 'redux';
import loadings from './loadings/reducer';
import authentication from './authentication';
import settings from './settings';
import profiles from './profiles';
import courses from './courses';
import loginCheck from './loginCheck';
import dashboard from './dashboard';

const rootReducer = combineReducers({
  loadings,
  authentication,
  settings,
  profiles,
  courses,
  loginCheck,
  dashboard,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
