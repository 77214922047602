import React from 'react';

interface CourseEditingProps {
  Container: React.FC;
  Contents: React.FC;
}

const CourseEditing: React.FC<CourseEditingProps> = ({
  Container,
  Contents,
}) => {
  return (
    <Container>
      <Contents />
    </Container>
  );
};

export default CourseEditing;
