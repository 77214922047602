import React from 'react';
import { Wrapper } from 'components/atoms';
import { FormContext, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createSupportAPI } from 'apis';
import { useTranslation } from 'react-i18next';

type FormDataType = {
  content: string;
  course: number;
};

const ContainerForm: React.FC = ({ children }) => {
  const methods = useForm<FormDataType>({});
  const { t } = useTranslation();
  const [mutate, { isLoading }] = useMutation(createSupportAPI);
  if (isLoading) {
    return <div>{t('supportsContainerForm.dataTransmission')}</div>;
  }
  const onSubmit = async (data: FormDataType): Promise<void> => {
    try {
      await mutate({ ...data });
      methods.reset();
      toast.success(t('supportsContainerForm.iHaveSentInquiriesAndSupport'));
    } catch (e) {
      toast.warn(t('supportsContainerForm.thisIsTheWrongRequest'));
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Wrapper style={{ padding: '40px' }}>{children}</Wrapper>
      </form>
    </FormContext>
  );
};

export default React.memo(ContainerForm);
