import styled from 'styled-components';

const TextareaRounded = styled.textarea`
  width: 100%;
  border: 1px solid #bcbcbc;
  padding: 20px;
  border-radius: 6px;
  margin-top: 15px;
  outline: 0;
  ::placeholder {
    color: #cccccc;
  }
`;

export default TextareaRounded;
