import React, { ChangeEvent, useContext } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ManageMainContext from '../../../../pages/managers/ManagerMain/context';
import AppContext from '../../../../App/context';

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
    },
    field: {
      margin: '1rem',
      flex: 1,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  };
});

export type AssignmentSearchFormState = {
  q: string;
  group: number | string;
  is_completed: string;
};
type AssignmentSearchFormProps = {
  state: AssignmentSearchFormState;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const AssignmentSearchForm: React.FC<AssignmentSearchFormProps> = ({
  state,
  onChange,
}) => {
  const classes = useStyles();
  const groups = useContext(ManageMainContext);
  const { is_integrated_management } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <TextField
        label={t('assignmentSearchForm.search') ?? '검색'}
        variant="outlined"
        name="q"
        onChange={onChange}
        value={state.q}
        className={classes.field}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {is_integrated_management && (
        <TextField
          label={t('assignmentSearchForm.group') ?? '그룹'}
          name="group"
          value={state.group}
          onChange={onChange}
          variant="outlined"
          className={classes.field}
          select
        >
          <MenuItem value="">전체</MenuItem>
          {groups.map((group) => {
            return (
              <MenuItem value={group.id} key={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <TextField
        label={t('assignmentSearchForm.whetherItIsCompleted') ?? '완료여부'}
        variant="outlined"
        onChange={onChange}
        value={state.is_completed}
        name="is_completed"
        className={classes.field}
        select
      >
        <MenuItem value="">{t('assignmentSearchForm.all')}</MenuItem>
        <MenuItem value="true">Y</MenuItem>
        <MenuItem value="false">N</MenuItem>
      </TextField>
    </div>
  );
};

export default AssignmentSearchForm;
