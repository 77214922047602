import React from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import {
  FileLabel,
  InputLabel,
  PostWritingContainerWithMg,
} from 'components/atoms';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parseFiles } from '../../../molecules/newAssignments/DefaultInfo';

const AddFilesSectionForm: React.FC = () => {
  const { register, getValues, watch } = useFormContext();
  watch('files');
  const files = getValues('files');
  const parsedFiles = parseFiles(files);

  const { t } = useTranslation();

  return (
    <PostWritingContainerWithMg>
      <InputLabel>
        {t('componentsOrganismsPostWritingAddFilesSectionForm.refer')}
      </InputLabel>
      <FileLabel>
        <AttachmentIcon />
        <span>
          {parsedFiles.length < 1
            ? t('componentsOrganismsPostWritingAddFilesSectionForm.addFile')
            : parsedFiles}
        </span>
        <input
          type="file"
          ref={register}
          name="files"
          style={{ display: 'none' }}
        />
      </FileLabel>
    </PostWritingContainerWithMg>
  );
};

export default AddFilesSectionForm;
