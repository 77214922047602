import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import useSettings from 'hooks/settings/useSettings';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useSettingActions from 'hooks/settings/useSettingActions';
import { Logo, ToggleMenu, Topbar } from '../../../atoms/mains';
import useCourses from '../../../../hooks/courses/useCourses';
import logo from '../../../../assets/images/logo-typo.svg';

const MainTopBar: React.FC = () => {
  const { dispatchToggleSidebar } = useSettingActions();
  const { isWideSidebar } = useSettings();

  const { currentCourse } = useCourses();

  return (
    <Topbar>
      <ToggleMenu onClick={dispatchToggleSidebar}>
        {isWideSidebar ? <ArrowBackIos /> : <ArrowForwardIosIcon />}
      </ToggleMenu>
      {currentCourse ? (
        <div style={{ fontSize: '16px' }}>{currentCourse}</div>
      ) : (
        <Logo src={logo} alt="logo" />
      )}
    </Topbar>
  );
};

export default MainTopBar;
