import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../utils/sagaHelpers';
import { COURSES } from '../reducers/courses';
import { courseAPI, courseCreateAPI } from '../apis';

const fetchCoursesSaga = createRequestSaga(COURSES.FETCH_COURSES, courseAPI);
const createCourseSaga = createRequestSaga(
  COURSES.CREATE_COURSE,
  courseCreateAPI,
);

export default function* coursesSaga(): Generator {
  yield takeLatest(COURSES.FETCH_COURSES, fetchCoursesSaga);
  yield takeLatest(COURSES.CREATE_COURSE, createCourseSaga);
}
