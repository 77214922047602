import React from 'react';
import { Wrapper } from 'components/atoms';
import { useHistory, useParams } from 'react-router-dom';
import { FormContext, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { coursePostUpdateAPI } from 'apis';
import { serializeFormData } from 'utils';
import { FormDataType } from 'components/organisms/PostWriting/ContainerForm';
import { useTranslation } from 'react-i18next';
import { CommentSectionFormProps } from '../../posts/CommentSectionForm';

const PostEditContainer: React.FC<CommentSectionFormProps> = ({
  children,
  post,
}) => {
  const methods = useForm<FormDataType>({
    defaultValues: {
      title: post?.title,
      content: post?.content,
    },
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { courseId, postId } = useParams();
  const onSubmit = async (data: FormDataType): Promise<void> => {
    const formData = serializeFormData(data);
    try {
      await coursePostUpdateAPI({ courseId, postId, coursePost: formData });
      toast.success(
        t(
          'componentsOrganismsPostEditingPostEditContainer.thePostHasBeenChanged',
        ),
      );
      history.goBack();
    } catch (e) {
      toast.warn(
        t(
          'componentsOrganismsPostEditingPostEditContainer.thisIsTheWrongRequest',
        ),
      );
    }
  };
  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Wrapper style={{ padding: '54px 40px' }}>{children}</Wrapper>
      </form>
    </FormContext>
  );
};

export default PostEditContainer;
