import styled from 'styled-components';

const BtnSkyblueLg = styled.button`
  color: #fff;
  border: none;
  border-radius: 8px;
  transition: 0.3s;
  background: rgba(113, 186, 216, 1);
  text-align: center;
  cursor: pointer;
  line-height: 1.4;
  font-size: 15px;
  padding: 10px;
  outline: 0;
`;

export default BtnSkyblueLg;
