import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  EditorType,
  fetchPerformance,
  WritingEssayPerformanceType,
} from 'apis/performances';
import { convertFromRaw, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import QuestionsWriting from 'pages/PerformAssignment/QuestionsWriting';
import Book from '@material-ui/icons/Book';
import styled from 'styled-components';
import { TxtSkyblue } from 'components/organisms/dashboard/Tutorial';
import EssayWriting from '../EssayWriting';
import PeerReview from '../PeerReview';
import FeedbackReview from '../FeedbackReview';
import Revisement from '../Revisement';
import SwitchPerformanceContext from './context';
import { switchStage } from '../../EditAssignment/context';
import PerformanceContext from '../PerformanceMain/context';
import LoadingPage from '../../LoadingPage';
import { STAGES } from '../../../utils/constants';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type PerformanceProps = {
  stage: string;
};

const Performance: React.FC<PerformanceProps> = ({ stage }) => {
  switch (stage) {
    case STAGES.ESSAY:
      return <EssayWriting />;
    case STAGES.QUESTION:
      return <QuestionsWriting />;
    case STAGES.PEER_REVIEW:
      return <PeerReview />;
    case STAGES.FEEDBACK_REVIEW:
      return <FeedbackReview />;
    case STAGES.REVISEMENT:
      return <Revisement />;
    default:
      return <Redirect to="/" />;
  }
};

const SwitchPage: React.FC = () => {
  const { courseId, assignmentId, performanceId, role } = useParams();
  const { data, isError, isLoading, refetch } = useQuery(
    ['performance', courseId, assignmentId, performanceId],
    fetchPerformance,
    { enabled: false },
  );
  const { refetch: infoRefetch, setFold, progresses } = useContext(
    PerformanceContext,
  );
  const preparation = progresses.find(
    (progress) => switchStage(progress.stage || '') === STAGES.PREPARATION,
  );
  // 상단바 fold
  useEffect(() => {
    setFold(true);
  }, [setFold]);

  const isEmpty = [
    STAGES.PEER_REVIEW,
    STAGES.FEEDBACK_REVIEW,
    STAGES.ESSAY,
    STAGES.QUESTION,
    STAGES.REVISEMENT,
  ].includes(performanceId);
  const [currentEditState, setEditState] = useState(EditorState.createEmpty());
  const { t } = useTranslation();
  useEffect(() => {
    if (!isEmpty) {
      refetch().then();
    }
  }, [isEmpty, refetch]);
  useEffect(() => {
    if (data) {
      const { stage_object } = data.data as WritingEssayPerformanceType;
      if (
        stage_object.content &&
        stage_object.editor_type === EditorType.DRAFT
      ) {
        try {
          setEditState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(stage_object.content)),
            ),
          );
        } catch (e) {
          setEditState(EditorState.createEmpty());
        }
      }
    }
  }, [data]);

  if (isEmpty) {
    const style = {
      color: 'rgba(113, 186, 216, 1)',
      fontSize: '88px',
      marginBottom: '20px',
    };
    const renderStatus = (): string => {
      switch (performanceId) {
        case STAGES.PEER_REVIEW:
          return t('pagesPerformAssignmentSwitchPage.thereIsNoEssayToEvaluate');
        case STAGES.FEEDBACK_REVIEW:
          return t(
            'pagesPerformAssignmentSwitchPage.thereIsNoColleagueEvaluationToEvaluate',
          );
        case STAGES.ESSAY:
          return t('pagesPerformAssignmentSwitchPage.thereIsNoEssay');
        case STAGES.REVISEMENT:
          return t(
            'pagesPerformAssignmentSwitchPage.youCannotProceedWithTheRetirementStage',
          );
        default:
          return t(
            'pagesPerformAssignmentSwitchPage.youCanWriteAQuestionOnlyDuringThePeriodOfWriting',
          );
      }
    };
    return (
      <Container>
        <Book style={style} />
        <TxtSkyblue>{renderStatus()}</TxtSkyblue>
      </Container>
    );
  }

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!preparation?.performance?.is_completed) {
    return (
      <Redirect
        to={`/courses/${courseId}/${role}/assignments/${assignmentId}/performances/info`}
      />
    );
  }

  if (!data || isLoading) {
    return <LoadingPage isHeight100 />;
  }

  const {
    progress: { stage },
  } = data.data;
  return (
    <SwitchPerformanceContext.Provider
      value={{
        ...data.data,
        currentEditState,
        setCurrentEditState: setEditState,
        refetch: (): void => {
          refetch().then();
          infoRefetch();
        },
      }}
    >
      <Performance stage={switchStage(stage || '')} />
    </SwitchPerformanceContext.Provider>
  );
};

export default React.memo(SwitchPage);
