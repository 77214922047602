import styled from 'styled-components';
import ButtonPassword from '../ButtonPassword';

const ButtonEdit = styled(ButtonPassword)`
  color: #5646ff;
  border: 1px solid #5646ff;
  background: none;
`;

export default ButtonEdit;
