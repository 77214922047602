import React from 'react';

interface ScoreProgress {
  Container: React.FC;
  TopGraph: React.FC;
  BottomTable: React.FC;
}

const ScoreProgress: React.FC<ScoreProgress> = ({
  Container,
  TopGraph,
  BottomTable,
}) => {
  return (
    <Container>
      <TopGraph />
      <BottomTable />
    </Container>
  );
};

export default ScoreProgress;
