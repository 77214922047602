import styled from 'styled-components';

const ContentsBodyText = styled.div`
  line-height: 2;
  font-weight: 300;
  color: #173c51;
  font-size: 15px;
`;

export default ContentsBodyText;
