import React, { useContext } from 'react';
import { Section, SectionLabelNormal } from 'components/atoms/newAssignmnets';
import EssayOption from 'components/molecules/newAssignments/EssayOption';
import QuestionOption from 'components/molecules/newAssignments/QuestionOption';
import PeerReviewOption from 'components/molecules/newAssignments/PeerReviewOption';
import FeedbackReviewOption from 'components/molecules/newAssignments/FeedbackReviewOption';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

const SettingByStage: React.FC = () => {
  const { getValues } = useFormContext();
  const { essay, feedback, peer, question } = getValues();
  const { currentStage } = useContext(IsEditableContext);
  const index = stageIndex.indexOf(currentStage);
  const { t } = useTranslation();

  return (
    <Section>
      <SectionLabelNormal>
        {t('componentsOrganismsNewAssignmnetsSettingByStage.stepByStepSetting')}
      </SectionLabelNormal>
      {essay && (
        <fieldset disabled={index > 0}>
          <EssayOption />
        </fieldset>
      )}
      {question && (
        <fieldset disabled={index > 0}>
          <QuestionOption />
        </fieldset>
      )}
      {peer && (
        <fieldset disabled={index > 2}>
          <PeerReviewOption />
        </fieldset>
      )}
      {feedback && (
        <fieldset disabled={index > 4}>
          <FeedbackReviewOption />
        </fieldset>
      )}
    </Section>
  );
};

export default SettingByStage;
