import React from 'react';
import PostWriting from 'templates/PostWriting';
import ContainerForm from 'components/organisms/PostWriting/ContainerForm';
import AddFilesSectionForm from 'components/organisms/PostWriting/AddFilesSectionForm';
import ButtonsSectionForm from 'components/organisms/PostWriting/ButtonsSectionForm/';
import PostSectionForm from 'components/organisms/PostWriting/PostSectionForm/';

const PostWritingPage: React.FC = () => {
  return (
    <PostWriting
      Container={ContainerForm}
      PostSection={PostSectionForm}
      AddFilesSection={AddFilesSectionForm}
      ButtonsSection={ButtonsSectionForm}
    />
  );
};

export default PostWritingPage;
