import styled from 'styled-components';

const OtherTitleText = styled.span`
  color: #5646ff;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
`;

export default OtherTitleText;
