import React, { Fragment, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
  BasicDivFlex,
  BasicDivTop100,
  BtnPurpleLg,
  FontSize14Div,
} from '../../../components/atoms';
import {
  Bar,
  Circle,
  DetailPageCard,
  LeftDiv,
  RightDiv,
  useManageDetailStyles,
} from '../../../components/atoms/mains/DetailPageStyles';
import {
  patchUserDetail,
  PatchUserParams,
  UserDetailType,
} from '../../../apis/users';
import AppContext from '../../../App/context';
import ManageMainContext from '../ManagerMain/context';

type UserDetailPresenterProps = {
  user: UserDetailType;
};

const StyledSpan = styled.span``;

const UserDetailPresenter: React.FC<UserDetailPresenterProps> = ({ user }) => {
  const classes = useManageDetailStyles();
  const history = useHistory();
  const { is_private_info, is_integrated_management } = useContext(AppContext);
  const groups = useContext(ManageMainContext);
  const { userId } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { handleSubmit, control, getValues, errors, watch } = useForm<
    PatchUserParams
  >({
    defaultValues: {
      role: user.role.value,
      expire_date: user.expire_date,
      group_id: user.group?.id,
    },
  });
  watch('role');
  const userTypes = [
    { value: 'I', label: '교수자' },
    { value: 'L', label: '학생' },
  ];
  const onSubmit = async (formData: PatchUserParams): Promise<void> => {
    try {
      await patchUserDetail({ userId, params: formData });
      toast.success(
        t('pagesManagersUserDetailPresenter.userInformationIsStored'),
      );
    } catch (e) {
      toast.warn(
        t('pagesManagersUserDetailPresenter.iSentTheWrongInformation'),
      );
    }
  };
  const onDelete = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      const response = window.confirm(
        t('pagesManagersUserDetailPresenter.theUserAccountIsDisabling'),
      );
      if (response) {
        await patchUserDetail({ userId, params: { is_active: false } });
        toast.success(
          t('pagesManagersUserDetailPresenter.theUserAccountIsDisabled'),
        );
        history.push('/manager/users', { ...state });
      }
    } catch (e) {
      toast.warn(
        t('pagesManagersUserDetailPresenter.iSentTheWrongInformation'),
      );
    }
  };

  return (
    <BasicDivTop100>
      <FontSize14Div>
        <StyledSpan
          className={classes.pointer}
          onClick={(): void => history.push('/manager/users', { ...state })}
        >
          <ArrowBackIcon className={classes.center} />
          {t('pagesManagersUserDetailPresenter.moveToTheUserList')}
        </StyledSpan>
      </FontSize14Div>
      <BasicDivFlex>
        <div className={classes.w60}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DetailPageCard style={{ padding: '0' }}>
              <div className={classes.firstCard}>
                <LeftDiv>
                  <div className={classes.title}>
                    {t('pagesManagersUserDetailPresenter.userInformation')}
                  </div>
                  <TextField
                    disabled
                    value={user.name}
                    label={t('pagesManagersUserDetailPresenter.name') ?? ''}
                    variant="filled"
                    className={classes.input}
                  />
                  <TextField
                    disabled
                    label={t('pagesManagersUserDetailPresenter.email') ?? ''}
                    value={user.email}
                    variant="filled"
                    className={classes.input}
                  />
                  {is_private_info && (
                    <>
                      <TextField
                        disabled
                        value={user.display_attending_type}
                        label={
                          t('pagesManagersUserDetailPresenter.school') ?? ''
                        }
                        variant="filled"
                        className={classes.input}
                      />
                      {user.attending_type !== 'N' && (
                        <TextField
                          disabled
                          value={user.grade}
                          label={
                            t('pagesManagersUserDetailPresenter.grade') ?? ''
                          }
                          variant="filled"
                          className={classes.input}
                        />
                      )}
                      <TextField
                        disabled
                        label={
                          t('pagesManagersUserDetailPresenter.address') ?? ''
                        }
                        value={user.address}
                        variant="filled"
                        className={classes.input}
                      />

                      <TextField
                        disabled
                        label={
                          t('pagesManagersUserDetailPresenter.dateOfBirth') ??
                          ''
                        }
                        value={user.date_of_birth}
                        variant="filled"
                        className={classes.input}
                      />
                    </>
                  )}
                </LeftDiv>
                <RightDiv>
                  <div className={classes.title}>
                    {t('pagesManagersUserDetailPresenter.accountInformation')}
                  </div>
                  <Controller
                    select
                    name="role"
                    control={control}
                    as={TextField}
                    label={t('pagesManagersUserDetailPresenter.category') ?? ''}
                    SelectProps={{ native: true }}
                    variant="outlined"
                    className={classes.w80}
                  >
                    {userTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {t(`app.${option.label}`)}
                      </option>
                    ))}
                  </Controller>
                  {getValues('role') === 'I' && (
                    <Controller
                      control={control}
                      as={DatePicker}
                      value={getValues().expire_date}
                      name="expire_date"
                      rules={{ required: true }}
                      required
                      error={!!errors.expire_date}
                      format="YYYY-MM-DD"
                      label={
                        t(
                          'pagesManagersUserDetailPresenter.teacherAccountExpirationDate',
                        ) ?? ''
                      }
                      maxDate={user.maximum_expire_date || undefined}
                      className={classes.date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}

                  {is_integrated_management && (
                    <Controller
                      select
                      name="group_id"
                      control={control}
                      as={TextField}
                      label={t('pagesManagersUserDetailPresenter.group') ?? ''}
                      SelectProps={{ native: true }}
                      variant="outlined"
                      className={classes.date}
                    >
                      {groups.map((group) => {
                        return (
                          <option value={group.id} key={group.id}>
                            {group.name}
                          </option>
                        );
                      })}
                    </Controller>
                  )}
                </RightDiv>
              </div>
              <Bar>
                <BtnPurpleLg>
                  {t('pagesManagersUserDetailPresenter.save')}
                </BtnPurpleLg>
                <div className={classes.pointer}>
                  <DeleteIcon className={classes.trash} />
                  <StyledSpan className={classes.trash} onClick={onDelete}>
                    {t('pagesManagersUserDetailPresenter.delete')}
                  </StyledSpan>
                </div>
              </Bar>
            </DetailPageCard>
          </form>
        </div>
        <div className={classes.w40}>
          <DetailPageCard>
            <div className={classes.title}>
              {t('pagesManagersUserDetailPresenter.connectionInformation')}
            </div>
            <div className={classes.flex}>
              <div className={classes.w50}>
                <div>
                  <QueryBuilderIcon className={classes.center} />
                  {t('pagesManagersUserDetailPresenter.joinedAt')}
                </div>
                <div className={classes.mgT}>
                  {moment(user.date_joined).format('YYYY-MM-DD')}
                </div>
              </div>
              <div className={classes.w50}>
                <div>
                  <QueryBuilderIcon className={classes.center} />
                  {t('pagesManagersUserDetailPresenter.recentConnectionDate')}
                </div>
                <div className={classes.mgT}>
                  {user.last_login
                    ? moment(user.last_login).format('YYYY-MM-DD')
                    : t('pagesManagersUserDetailPresenter.doesntExist')}
                </div>
              </div>
            </div>
          </DetailPageCard>
          {user.courses.length > 0 && (
            <DetailPageCard>
              <div className={classes.title}>
                {t('pagesManagersUserDetailPresenter.openingCourse')}
              </div>
              {user.courses.map((course) => {
                return (
                  <Fragment key={course.id}>
                    <Circle />
                    <span className={classes.subject}>
                      {`${course.name}(${t(
                        'pagesManagersUserDetailPresenter.assignment',
                      )} ${course.assignments_count})`}
                    </span>
                  </Fragment>
                );
              })}
            </DetailPageCard>
          )}
          {user.enrolls.length > 0 && (
            <DetailPageCard>
              <div className={classes.title}>
                {t('pagesManagersUserDetailPresenter.course')}
              </div>
              {user.enrolls.map((enroll) => {
                return (
                  <Fragment key={enroll.id}>
                    <Circle />
                    <span className={classes.subject}>
                      {`${enroll.course.name} (${t(
                        'pagesManagersUserDetailPresenter.assignment',
                      )} ${enroll.course.assignments_count})`}
                    </span>
                  </Fragment>
                );
              })}
            </DetailPageCard>
          )}
        </div>
      </BasicDivFlex>
    </BasicDivTop100>
  );
};

export default UserDetailPresenter;
