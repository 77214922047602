import styled from 'styled-components';

const DesktopOnlyParagraph = styled.div`
  margin-top: 40px;
  font-size: 17px;
  line-height: 30px;
  white-space: pre-wrap;
  @media (max-width: 767px) {
    display: none;
  }
`;

export default DesktopOnlyParagraph;
