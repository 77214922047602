import React from 'react';

interface FeedbackReviewProps {
  Container: React.FC;
  EssayView: React.FC;
  ReviewCard: React.FC;
}

const FeedbackReview: React.FC<FeedbackReviewProps> = ({
  Container,
  EssayView,
  ReviewCard,
}) => {
  return (
    <Container>
      <EssayView />
      <ReviewCard />
    </Container>
  );
};

export default FeedbackReview;
