import styled from 'styled-components';

const IconDoing = styled.div`
  margin-bottom: 16px;
  border-radius: 12px;
  padding: 5.5px 17px 4.5px 17px;
  font-size: 12px;
  color: white;
  display: inline-block;
  background-color: #2cc4eb;
`;

export default IconDoing;
