import styled from 'styled-components';

const DesktopOnly = styled.h4`
  margin-bottom: 35px;
  color: #4a6cbb;
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 767px) {
    display: none;
  }
`;

export default DesktopOnly;
