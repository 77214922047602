import styled from 'styled-components';

const StageCheckbox = styled.input`
  margin-right: 10px;
  :checked {
    background: #2f64de;
    border-color: #2f64de;
    :after {
      content: ' ';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 6px;
      height: 3px;
      border-left: 1px solid white;
      border-bottom: 1px solid white;
      transform: rotate(-45deg);
    }
  }
  :hover {
    border-color: #2f64de;
  }
  :disabled {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: lightgray;
  }
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  position: relative;
`;

export default StageCheckbox;
