import React, { useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Field,
  FieldWrapper,
  Form,
  TitleLarge,
  TitleSmall,
  CancelButton,
  SaveButton,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { UpdatePasswordAPIProps } from 'apis';
import useAuthenticationActions from 'hooks/authentication/useAuthenticationActions';
import { useTranslation } from 'react-i18next';
import ErrorParagraph from '../../../atoms/authentications/ErrorParagraph';

export type PasswordChangeProps = {
  handleClose: () => void;
  open: boolean;
};
export type PasswordChangeFormData = {
  passwordCheck: string;
} & UpdatePasswordAPIProps;

const PasswordChangeDialog: React.FC<PasswordChangeProps> = ({
  handleClose,
  open,
}) => {
  const { dispatchChangePassword } = useAuthenticationActions();
  const { register, errors, handleSubmit, watch } = useForm<
    PasswordChangeFormData
  >({});
  const password = useRef({});
  const { t } = useTranslation();
  password.current = watch('newPassword', '');
  const onSubmit = (values: UpdatePasswordAPIProps): void => {
    dispatchChangePassword(values);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <TitleLarge style={{ marginBottom: 0 }}>
            {t('modalsPasswordChange.changePassword')}
          </TitleLarge>
        </DialogTitle>
        <DialogContent>
          <FieldWrapper>
            <TitleSmall>{t('modalsPasswordChange.currentPassword')}</TitleSmall>
            <Field
              placeholder={
                t('modalsPasswordChange.pleaseEnterYourCurrentPassword') ?? ''
              }
              required
              type="password"
              name="oldPassword"
              ref={register}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TitleSmall>{t('modalsPasswordChange.newPassword')}</TitleSmall>
            <Field
              placeholder={
                t('modalsPasswordChange.pleaseEnterANewPassword') ?? ''
              }
              required
              type="password"
              name="newPassword"
              ref={register({
                required: 'You must specify a password',
                minLength: {
                  value: 6,
                  message:
                    t(
                      'modalsPasswordChange.thePasswordMustBeEnteredAtLeast6Digits',
                    ) ?? '',
                },
              })}
            />
            {errors.newPassword && (
              <ErrorParagraph>{errors.newPassword.message}</ErrorParagraph>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <TitleSmall>
              {t('modalsPasswordChange.newPasswordConfirmation')}
            </TitleSmall>
            <Field
              placeholder={
                t('modalsPasswordChange.pleaseEnterANewPassword') ?? ''
              }
              required
              type="password"
              name="passwordCheck"
              ref={register({
                validate: (value) => {
                  if (value === password.current) return true;
                  const msg = t(
                    'modalsPasswordChange.itDoesNotMatchThePassword',
                    '',
                  );
                  return msg;
                },
              })}
            />
            {errors.passwordCheck && (
              <ErrorParagraph>{errors.passwordCheck.message}</ErrorParagraph>
            )}
          </FieldWrapper>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 16px 24px' }}>
          <CancelButton style={{ marginRight: 0 }} onClick={handleClose}>
            {t('modalsPasswordChange.cancellation')}
          </CancelButton>
          <SaveButton type="submit">
            {t('modalsPasswordChange.save')}
          </SaveButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default PasswordChangeDialog;
