import styled from 'styled-components';
import StageOptionInput from '../StageOptionInput';

const PeerReviewOptionInput = styled(StageOptionInput)`
  @media (min-width: 768px) {
    width: 100%;
  }
`;

export default PeerReviewOptionInput;
