import styled from 'styled-components';

const CourseTitle = styled.h1`
  @media (max-width: 767px) {
    margin: 0.2em 0;
  }
  margin: 0.67em 0;
  font-size: 24px;
  font-weight: 400;
`;

export default CourseTitle;
