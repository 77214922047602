import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { FetchReportReviewerResponseType } from 'apis';
import { useMediaQuery } from 'react-responsive';
import {
  Answer,
  Question,
  ScoreText,
  useReportStyles,
} from '../../../atoms/reports/containers';

type PeerReviewCardProps = {
  response: FetchReportReviewerResponseType;
};

const PeerReviewCard: React.FC<PeerReviewCardProps> = ({ response }) => {
  const classes = useReportStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <>
      {isDesktop ? (
        <>
          <Question key={response.id}>
            <span>{response.standard.question}</span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              className={classes.inline}
            >
              <Rating
                name="read-only"
                max={response.standard.max_point}
                value={response.point}
                readOnly
              />
              <ScoreText>
                {response.point} / {response.standard.max_point}
              </ScoreText>
            </Box>
          </Question>
          <Answer>{response.comment}</Answer>
        </>
      ) : (
        <>
          <Question key={response.id} style={{ display: 'block' }}>
            <span>{response.standard.question}</span>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              style={{ marginLeft: '-0.2rem' }}
            >
              <Rating
                name="read-only"
                max={response.standard.max_point}
                value={response.point}
                readOnly
              />
              <ScoreText>
                {response.point} / {response.standard.max_point}
              </ScoreText>
            </Box>
          </Question>
          <Answer>{response.comment}</Answer>
        </>
      )}
    </>
  );
};

export default PeerReviewCard;
