import styled from 'styled-components';

const FontSize18Div = styled.div`
  color: #0658a9;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export default FontSize18Div;
