import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DivMid, StageStatusText } from 'components/atoms';
import { toast } from 'react-toastify';
import ManageAssignmentContext from 'pages/ManageAssignment/context';
import { assignmentMoveToNextStage } from 'apis';
import { AssignmentProgresses } from 'components/molecules/commons';
import { useTranslation } from 'react-i18next';

const MidSectionForm: React.FC = () => {
  const { courseId, assignmentId } = useParams();
  const {
    is_auto_schedule,
    progresses,
    current_stage,
    is_waiting_next_stage,
    refetch: assignmentRefetch,
    now,
  } = useContext(ManageAssignmentContext);
  const { t } = useTranslation();
  const moveToNextStage = async (): Promise<void> => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        t('manageAssignmentMidSectionForm.wouldYouReallyProceedToTheNextStep'),
      )
    ) {
      try {
        await assignmentMoveToNextStage(courseId, assignmentId);
        toast.success(
          t('manageAssignmentMidSectionForm.theStageOfTheTaskHasBeenChanged'),
        );
        assignmentRefetch();
      } catch (e) {
        if (e.response.status === 406) {
          toast.warn(e.response.data.detail);
        } else {
          toast.warn(t('manageAssignmentMidSectionForm.fail'));
        }
      }
    }
  };
  return (
    <DivMid>
      <StageStatusText>
        {t('manageAssignmentMidSectionForm.taskProgress')}
      </StageStatusText>
      <AssignmentProgresses
        isAutoSchedule={is_auto_schedule}
        progresses={progresses}
        currentStage={current_stage}
        isWaitingNextStage={is_waiting_next_stage}
        isReadOnly={false}
        moveToNextStage={moveToNextStage}
        refetch={assignmentRefetch}
        now={now}
      />
    </DivMid>
  );
};

export default React.memo(MidSectionForm);
