import styled from 'styled-components';

const AssignmentName = styled.h5`
  margin-top: 4px;
  font-size: 22px;
  font-weight: 300;
  color: #4a6cbb;
  padding-bottom: 23px;
`;

export default AssignmentName;
