import { takeLatest } from 'redux-saga/effects';
import { dashboardAPI } from 'apis';
import createRequestSaga from '../utils/sagaHelpers';
import { DASHBOARD } from '../reducers/dashboard';

const fetchDashboardSaga = createRequestSaga(
  DASHBOARD.FETCH_DASHBOARD,
  dashboardAPI,
);

export default function* dashboardSaga(): Generator {
  yield takeLatest(DASHBOARD.FETCH_DASHBOARD, fetchDashboardSaga);
}
