import styled from 'styled-components';

const BasicDivFlexRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
`;

export default BasicDivFlexRight;
