import styled from 'styled-components';

const NAInnerAction = styled.div`
  @media (max-width: 767px) {
    display: flex;
    margin-top: 16px;
  }
  @media (min-width: 768px) {
    margin-left: auto;
  }
`;

export default NAInnerAction;
