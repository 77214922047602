import React from 'react';
import { Column } from 'material-table';
import { t } from 'i18next';
import { CouresAdminItemType } from '../../../apis/courses';

export const cellStyle: React.CSSProperties = {
  textAlign: 'center',
};

export const getCourseColumns: () => Column<CouresAdminItemType>[] = () => [
  {
    title: t('pagesManagersCoursesColumns.group'),
    field: 'group_name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersCoursesColumns.lecturer'),
    field: 'name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('app.교수자'),
    field: 'instructor_name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersCoursesColumns.numberOfRegisteredStudents'),
    field: 'learners_count',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersCoursesColumns.numberOfTasks'),
    field: 'assignments_count',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersCoursesColumns.numberOfCompletedTasks'),
    field: 'completed_assignments_count',
    cellStyle,
    sorting: false,
  },
];
