import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import useAuthenticationActions from '../../../../hooks/authentication/useAuthenticationActions';
import useSettings from '../../../../hooks/settings/useSettings';
import { SideMenu, SignOut } from '../../../atoms/mains';
import MenuSideTop from '../../../molecules/mains/MenuSideTop';
import ManagerMainMenu from '../ManagerMainMenu';

const ManagerMainSide: React.FC = () => {
  const iconStyle = { marginRight: 5 };
  const { dispatchSignOut } = useAuthenticationActions();
  const { isWideSidebar } = useSettings();
  const { t } = useTranslation();
  return (
    <SideMenu isWideSidebar={isWideSidebar}>
      <MenuSideTop />
      {isWideSidebar && (
        <PerfectScrollbar>
          <ManagerMainMenu />
          <SignOut onClick={dispatchSignOut}>
            <ExitToAppIcon style={iconStyle} />
            {t('managerMainSide.logOut')}
          </SignOut>
        </PerfectScrollbar>
      )}
    </SideMenu>
  );
};

export default ManagerMainSide;
