import { requests, ResponseType } from 'utils';

export type LoginCheckResponse = {
  is_staff: boolean;
};

export const loginCheckAPI = (): Promise<ResponseType<LoginCheckResponse>> => {
  return requests().get('/api/v1/users/login-check/');
};

export const isStaffAPI = (): Promise<ResponseType<void>> => {
  return requests().get('/api/v1/users/is-staff/');
};
