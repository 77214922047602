import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { BtnGrayLgOutline, BtnPurpleLg } from 'components/atoms';
import Divider from '@material-ui/core/Divider';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { peerReviewMatchingInstructorUpdateAPI } from 'apis/peerReviews';
import {
  PeerReviewMatchingResponseType,
  PeerReviewMatchingTargetType,
} from 'apis/performances';
import { StandardType } from 'apis';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ScoreContext from 'pages/Score/context';
import { Editor } from 'react-draft-wysiwyg';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { parseEditorState } from '../../../../utils/parsers';
import useStyles from './styles';

type ContainerProps = {
  handleClose: () => void;
  open: boolean;
  name: string;
  data: PeerReviewMatchingTargetType;
};
type PeerReviewMatchingInstructorUpdateAPIProps = {
  id: number;
  responses: PeerReviewMatchingResponseType[];
  instructor_comment?: string;
};

const assignPoint = (response: PeerReviewMatchingResponseType): number => {
  if (response.edited_point) {
    return response.edited_point;
  }
  return response.point;
};

const Container: React.FC<ContainerProps> = ({
  handleClose,
  open,
  name,
  data,
}) => {
  const classes = useStyles();

  const { refetch: scoreRefetch } = useContext(ScoreContext);
  const { t } = useTranslation();

  const { handleSubmit, register, control, setValue, errors } = useForm<
    PeerReviewMatchingInstructorUpdateAPIProps
  >({
    defaultValues: {
      id: data.id,
      responses: data.responses.map((response) => {
        return {
          ...response,
          edited_point: assignPoint(response),
        };
      }),
      instructor_comment: data.instructor_comment || '',
    },
  });
  const onSubmit = async (
    formData: PeerReviewMatchingInstructorUpdateAPIProps,
  ): Promise<void> => {
    const originalPoints = data.responses.map(
      (response: PeerReviewMatchingResponseType) => response.point,
    );
    formData.responses.forEach((response, idx) => {
      if (
        !response.edited_point ||
        response.edited_point.toString() === originalPoints[idx]?.toString()
      ) {
        response.edited_point = null;
      }
    });
    try {
      await peerReviewMatchingInstructorUpdateAPI(formData);
      toast.success(
        t('modalsScoreEditContainer.evaluationCorrectionIsReflected'),
      );
      handleClose();
      scoreRefetch();
    } catch (e) {
      toast.warn(t('modalsScoreEditContainer.thisIsTheWrongRequest'));
    }
  };
  const { content } = data.target_essay;
  const editorState = parseEditorState({ content });
  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <DialogContent className={classes.overlayModal}>
        <div className={classes.modal}>
          <div className={classes.x}>
            <ClearIcon
              className={classes.xStyle}
              type="button"
              onClick={handleClose}
            />
          </div>

          <div className={classes.flex}>
            <div className={classes.essay}>
              <div className={classes.height100}>
                <div className={classes.zoom}>
                  <div className={classes.name}>
                    {t('modalsScoreEditContainer.essayScoreAdjustment', {
                      name,
                    })}
                  </div>
                  <Divider />
                  <div className={classes.content}>
                    <Editor
                      editorState={editorState}
                      localization={{
                        locale: 'ko',
                      }}
                      readOnly
                      wrapperClassName={classes.wrapper}
                      editorClassName={classes.editor}
                      toolbarHidden
                    />
                  </div>
                </div>
              </div>
            </div>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="id" ref={register} />
              {data.responses.map(
                (response: PeerReviewMatchingResponseType, order: number) => {
                  const { id, point, standard, comment } = response;
                  return (
                    <div key={id} className={classes.card}>
                      <div className={classes.standard}>
                        {(standard as StandardType).question}
                      </div>
                      <input
                        type="hidden"
                        name={`responses[${order}].id`}
                        value={id}
                        ref={register}
                      />
                      <div className={classes.smTitle}>
                        {t('modalsScoreEditContainer.scoreBeforeChange')}
                      </div>
                      <Box
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <Rating
                          readOnly
                          defaultValue={point}
                          max={(standard as StandardType).max_point}
                          disabled
                        />
                      </Box>
                      <div className={classes.smTitle}>
                        {t('modalsScoreEditContainer.scoreAfterChange')}
                      </div>
                      <Box
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <Controller
                          as={Rating}
                          rules={{ required: true }}
                          name={`responses[${order}].edited_point`}
                          max={(standard as StandardType).max_point}
                          control={control}
                        />
                        {errors.responses && errors.responses[order] && (
                          <div style={{ color: '#eb5b5b' }}>
                            {t(
                              'modalsScoreEditContainer.pleaseSelectAStarRatingToChange',
                            )}
                          </div>
                        )}
                      </Box>
                      <div className={classes.comment}>{comment}</div>
                    </div>
                  );
                },
              )}
              <div className={classes.card}>
                <div className={classes.reason}>
                  {t('modalsScoreEditContainer.reasonsForAdjustment')}
                </div>
                <textarea
                  className={classes.textarea}
                  name="instructor_comment"
                  ref={register}
                  rows={5}
                  required
                  placeholder={
                    t(
                      'modalsScoreEditContainer.pleaseEnterTheReasonsForTheScoreAdjustment',
                    ) ?? ''
                  }
                />
              </div>
              <DialogActions>
                <BtnGrayLgOutline
                  type="button"
                  onClick={(): void => {
                    Array.from(Array(data.responses.length), (_, i) =>
                      setValue(`responses[${i}].edited_point`, 0),
                    );
                    setValue('instructor_comment', '');
                    handleSubmit(onSubmit)();
                  }}
                >
                  {t('modalsScoreEditContainer.initialization')}
                </BtnGrayLgOutline>
                <BtnPurpleLg type="submit">
                  {t('modalsScoreEditContainer.save')}
                </BtnPurpleLg>
              </DialogActions>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Container;
