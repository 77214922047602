import styled from 'styled-components';

const NumIconSmOutline = styled.div`
  font-size: 13px;
  margin: auto 0;
  z-index: 1;
  padding: 3px 20px 3px 4px;
  border-radius: 14px;
  border: solid 1px #0658a9;
  color: #0658a9;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  counter-increment: section;
  ::before {
    content: counter(section);
    margin-right: 7px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #0658a9;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
  }
  @media (max-width: 767px) {
    margin-top: 3px;
  }
`;

export default NumIconSmOutline;
