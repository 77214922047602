import React, { useContext } from 'react';
import {
  CursoredA,
  FontSize15NavySpan,
  FontSize26H2,
  BasicDivFlexBetween,
} from 'components/atoms';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PerformanceContext from 'pages/PerformAssignment/PerformanceMain/context';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCourses from '../../../../hooks/courses/useCourses';

const TitleRow: React.FC = () => {
  const { courseId, role } = useParams();
  const history = useHistory();
  const { name } = useContext(PerformanceContext);
  const { currentCourse } = useCourses();
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <BasicDivFlexBetween>
          <CursoredA
            onClick={(): void => {
              history.push(`/courses/${courseId}/${role}`);
            }}
          >
            <ArrowBackIcon
              style={{
                verticalAlign: 'middle',
                color: '#0658a9',
                marginRight: '8px',
              }}
            />
            <FontSize15NavySpan style={{ verticalAlign: 'middle' }}>
              <strong>{currentCourse}</strong>{' '}
              {t('performAssignmentTitleRow.returnToThePage')}
            </FontSize15NavySpan>
          </CursoredA>
        </BasicDivFlexBetween>

        <FontSize26H2 style={{ paddingTop: '15px' }}>{name}</FontSize26H2>
      </div>
    </div>
  );
};

export default TitleRow;
