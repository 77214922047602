import React from 'react';
import Post from 'templates/Post';
import ContainerForm from 'components/organisms/posts/ContainerForm';
import CommentSectionForm from 'components/organisms/posts/CommentSectionForm/';
import PostSectionForm from 'components/organisms/posts/PostSectionForm/';
import VoteSectionForm from 'components/organisms/posts/VoteSectionForm/';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { coursePostDetailFetchAPI } from '../../apis';
import LoadingPage from '../LoadingPage';

const PostPage: React.FC = () => {
  const { courseId, postId } = useParams();
  const { data, isLoading } = useQuery(
    ['post', courseId, postId],
    coursePostDetailFetchAPI,
  );
  if (!data || isLoading) {
    return <LoadingPage />;
  }

  return (
    <Post
      Container={ContainerForm}
      CommentSection={CommentSectionForm}
      PostSection={PostSectionForm}
      VoteSection={VoteSectionForm}
      post={{ post: data?.data }}
    />
  );
};

export default PostPage;
