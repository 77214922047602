import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CourseEnrollCategory, CourseEnrollTitle } from 'components/atoms';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { createEnrollAPI } from 'apis';
import { useTranslation } from 'react-i18next';
import useProfileActions from '../../../../hooks/profiles/useProfileActions';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '80vw',
    padding: '24px',
  },
  input: {
    width: '340px',
    padding: '4px 0 8px 0',
  },
  button: {
    color: '#5646ff',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    padding: '5px 15px',
    fontSize: '0.875rem',
    outline: 0,
    minWidth: '64px',
    borderRadius: '4px',
  },
}));

export type CourseEnrollProps = {
  handleClose: () => void;
  open: boolean;
};

export type EnrollFormType = {
  course_code: string;
  code: string;
};

const CourseEnrollDialog: React.FC<CourseEnrollProps> = ({
  handleClose,
  open,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { control, handleSubmit } = useForm<EnrollFormType>({
    defaultValues: {
      course_code: '',
      code: '',
    },
  });
  const { dispatchFetchProfile } = useProfileActions();
  const { t } = useTranslation();
  const onSubmit = async (formData: EnrollFormType): Promise<void> => {
    try {
      const response = await createEnrollAPI({ ...formData });
      toast.success(
        t('modalsCourseEnroll.theCourseApplicationHasBeenCompleted'),
      );
      dispatchFetchProfile();
      history.push(`/courses/${response.data.course}/learner`);
      handleClose();
    } catch (e) {
      toast.warn(
        t([
          `modalsCourseEnroll.error.${e.response.status}`,
          'modalsCourseEnroll.error.else',
        ]),
      );
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle style={{ padding: '24px 24px 0 24px' }}>
          <CourseEnrollTitle>
            {t('modalsCourseEnroll.enrolment')}
          </CourseEnrollTitle>
        </DialogTitle>
        <DialogContent className={classes.root}>
          <CourseEnrollCategory>
            {t('modalsCourseEnroll.lectureCode')}
          </CourseEnrollCategory>
          <Controller
            control={control}
            name="course_code"
            required
            placeholder={t('modalsCourseEnroll.pleaseEnterLectureCode') ?? ''}
            className={classes.input}
            as={<TextField />}
            style={{ marginBottom: '15px' }}
          />
          <CourseEnrollCategory>
            {t('modalsCourseEnroll.studentID')}
          </CourseEnrollCategory>
          <Controller
            control={control}
            name="code"
            required
            inputProps={{ maxLength: 50 }}
            placeholder={
              t('modalsCourseEnroll.pleaseEnterTheStudentNumber') ?? ''
            }
            className={classes.input}
            as={<TextField />}
          />
        </DialogContent>
        <DialogActions style={{ padding: '0 24px 24px 24px' }}>
          <Button type="submit" variant="outlined" className={classes.button}>
            {t('modalsCourseEnroll.application')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CourseEnrollDialog;
