import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  BtnRoundSmOutlineGray,
  BtnRoundSmOutlinePurple,
  PeerReviewBackgroundDiv,
} from 'components/atoms';
import SwitchPerformanceContext from 'pages/PerformAssignment/SwitchPage/context';
import { PeerReviewMatchingTargetType, PeerReviewStageObjectType } from 'apis';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  font-size: 15px;
`;
const TBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
`;
const Tr = styled.tr`
  display: table-row;
  box-sizing: border-box;
`;

const Td = styled.td`
  display: table-cell;
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ContentA = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-size: 17px;
`;

type EssayRowProps = {
  onClick: () => void;
  data: PeerReviewMatchingTargetType;
};

const EssayRow: React.FC<EssayRowProps> = ({
  onClick,
  data,
}: EssayRowProps) => {
  const { id, reviewer, target, target_name, is_completed } = data;
  const { t } = useTranslation();

  return (
    <Tr key={id} onClick={onClick}>
      <Td>
        {is_completed ? (
          <BtnRoundSmOutlinePurple>
            {t(
              'componentsOrganismsPeerReviewWhiteBackCardForm.assessmentComplete',
            )}
          </BtnRoundSmOutlinePurple>
        ) : (
          <BtnRoundSmOutlineGray>
            {t(
              'componentsOrganismsPeerReviewWhiteBackCardForm.evaluationWaiting',
            )}
          </BtnRoundSmOutlineGray>
        )}
        <ContentA>
          <span>
            {reviewer === target
              ? t('componentsOrganismsPeerReviewWhiteBackCardForm.my')
              : t('componentsOrganismsPeerReviewWhiteBackCardForm.notMy', {
                  target_name,
                })}
          </span>
        </ContentA>
      </Td>
    </Tr>
  );
};

const WhiteBackCardForm: React.FC = () => {
  const { stage_object } = useContext(SwitchPerformanceContext);
  const { targets } = stage_object as PeerReviewStageObjectType;
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const isCompletedOthersEssay = targets
    .filter((target) => target.reviewer !== target.target)
    .every((target) => target.is_completed);

  const renderRow = (
    target: PeerReviewMatchingTargetType,
  ): React.ReactElement => {
    const onClick = (): void => {
      if (target.reviewer === target.target && !isCompletedOthersEssay) {
        toast.warn(
          `${t('componentsOrganismsPeerReviewWhiteBackCardForm.must')}`,
        );
      } else {
        history.push(`${url}/peer-review/${target.id}`);
      }
    };
    return <EssayRow data={target} key={target.target} onClick={onClick} />;
  };

  return (
    <PeerReviewBackgroundDiv>
      <Table>
        <TBody>
          {targets
            .filter((target) => target.target !== target.reviewer)
            .map(renderRow)}
          {targets
            .filter((target) => target.target === target.reviewer)
            .map(renderRow)}
        </TBody>
      </Table>
    </PeerReviewBackgroundDiv>
  );
};

export default WhiteBackCardForm;
