import React from 'react';
import Mypage from 'templates/Mypage';
import useProfiles from 'hooks/profiles/useProfiles';
import TopTextForm from '../../components/organisms/myPages/TopTextForm';
import BottomGraphForm from '../../components/organisms/myPages/BottomGraphForm';
import ContainerForm from '../../components/organisms/myPages/ContainerForm';

const MemberInfo: React.FC = () => {
  const { role } = useProfiles();
  const isLearner = role === '학생';
  return (
    <Mypage
      Container={ContainerForm}
      TopText={TopTextForm}
      BottomGraph={isLearner ? BottomGraphForm : null}
    />
  );
};

export default MemberInfo;
