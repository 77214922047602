import { CourseFileType } from '../courses';
import {
  EssayWritingType,
  FeedbackReviewType,
  PeerReviewType,
  QuestionWritingType,
  StandardType,
} from '../assignments';
import { ObjectionType } from '../objections';

export type CommonPerformanceType = {
  id: number;
  is_completed: boolean;
};
export type CommonDateType = {
  created_at: string;
  updated_at: string;
};

export enum EditorType {
  DRAFT = 'D',
  QUILL = 'Q',
}

export type WritingEssayStageObjectType = {
  id: number;
  late: boolean;
  files: CourseFileType[];
  content: string;
  editor_type: EditorType;
  plain_text: string;
  extra: {
    is_late: boolean;
    question: null | number;
  };
} & CommonDateType;

export type QuestionQuizType = {
  id: number;
  quiz: string;
};

export type QuestionResponseType = {
  id: number;
  quiz: QuestionQuizType;
  response: string;
} & CommonDateType;

export type QuestionWritingStageObjectType = {
  id: number;
  responses: QuestionResponseType[];
};
export type QuestionWritingPerformanceType = {
  stage_object: QuestionWritingStageObjectType;
  progress: QuestionWritingType;
} & CommonPerformanceType;
export type WritingEssayPerformanceType = {
  stage_object: WritingEssayStageObjectType;
  progress: EssayWritingType;
} & CommonPerformanceType;

export type PeerReviewMatchingResponseType = {
  id: number;
  standard?: StandardType;
  matching?: number;
  point: number;
  edited_point?: number | null;
  comment: string;
};
export type TargetEssayType = {
  content: string;
  editor_type: EditorType;
  plain_text: string;
  files: CourseFileType[];
  id: number;
};
export type PeerReviewMatchingTargetType = {
  id: number;
  target_name: string;
  target: number;
  reviewer: number;
  is_completed: boolean;
  responses: PeerReviewMatchingResponseType[];
  instructor_comment?: string | null;
  target_essay: TargetEssayType;
};
export type NotCompletedPeerReview = {
  id: number;
  reviewer: number;
  target: number;
};
export type PeerReviewMatchingResultType = {
  not_completed_list: NotCompletedPeerReview[];
};
export type PeerReviewStageObjectType = {
  id: number;
  reviewers?: PeerReviewMatchingTargetType[];
  targets: PeerReviewMatchingTargetType[];
};
export type PeerReviewPerformanceType = {
  stage_object: PeerReviewStageObjectType;
  progress: PeerReviewType;
} & CommonPerformanceType;

export type FeedbackPeerReviewMatchingResponseType = {
  id: number;
  matching: number;
  comment: string;
  standard: StandardType;
};
export type FeedbackPeerReviewMatchingType = {
  id: number;
  reviewer_name: string;
  reviewer: number;
  target: number;
  responses: FeedbackPeerReviewMatchingResponseType[];
  objection: ObjectionType | null;
};
export type FeedbackReviewMatchingTargetType = {
  id: number;
  point: number;
  comment?: string;
  feedback: number;
  matching: FeedbackPeerReviewMatchingType;
  revisement?: number;
};
export type FeedbackReviewStageObjectType = {
  id: number;
  essay: TargetEssayType;
  targets: FeedbackReviewMatchingTargetType[];
  max_point: number;
};
export type FeedbackReviewPerformanceType = {
  stage_object: FeedbackReviewStageObjectType;
  progress: FeedbackReviewType;
} & CommonPerformanceType;

export type BasePerformanceAPIProps = {
  courseId: number;
  assignmentId: number;
  performanceId: number;
};

export type Performance =
  | WritingEssayPerformanceType
  | QuestionWritingPerformanceType
  | PeerReviewPerformanceType
  | FeedbackReviewPerformanceType;

export type UpdatePerformanceProps = {
  data: FormData;
} & BasePerformanceAPIProps;
