import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-size: 15px;
    letter-spacing: -1px;
    font-family: 'NanumBarunGothic', sans-serif;
    line-height: 1.5;

  }
  * {
    box-sizing: border-box;
  }

  body, body *, input, textarea, select, button{
    font-family: inherit !important;
    line-height: 1.5
  }
  body{
    color: #173c51;
  }
  `;

export default GlobalStyle;
