import styled from 'styled-components';

const ToggleMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 20px;
  cursor: pointer;
`;

export default ToggleMenu;
