import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  BasicDivBlock,
  BtnGrayLgOutline,
  BtnPurpleLgOutline,
  Form,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  deleteObjectionAPI,
  fetchObjectionAPI,
  patchObjectionAPI,
} from '../../../../apis/objections';

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid rgb(168, 183, 190);
  margin: 15px 0;
  overflow: auto;
  line-height: 1.15;
`;

const TitleSm = styled.p`
  font-size: 15px;
  margin-top: 30px;
  font-weight: 300;
`;

const TitleMd = styled.div`
  margin: 1em 0;
  font-size: 16px;
  font-weight: 400;
`;

export type ObjectionEditProps = {
  handleClose: () => void;
  open: boolean;
  objectionId: number;
  refetch: () => void;
};

type FormType = {
  comment: string;
};

const DeleteButton = styled(BtnPurpleLgOutline)`
  color: rgba(199, 96, 112, 0.85);
  border-color: rgba(199, 96, 112, 0.85);
`;

const ObjectionEdit: React.FC<ObjectionEditProps> = ({
  handleClose,
  open,
  objectionId,
  refetch,
}) => {
  const { courseId, assignmentId } = useParams();
  const { data, refetch: detailRefetch } = useQuery(
    ['objection', courseId, assignmentId, objectionId],
    fetchObjectionAPI,
    { enabled: false },
  );
  const { register, handleSubmit, setValue } = useForm<FormType>();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setValue('comment', data.data.comment);
    }
  }, [data, setValue]);
  useEffect(() => {
    if (open) {
      detailRefetch().then();
    }
  }, [open, detailRefetch]);
  const onSubmit = async (formData: FormType): Promise<void> => {
    try {
      await patchObjectionAPI({
        objectionId,
        courseId,
        assignmentId,
        ...formData,
      });
      toast.success(t('modalsObjectionEdit.success'));
      refetch();
      handleClose();
    } catch (e) {
      toast.warn(t('modalsObjectionEdit.thisIsTheWrongRequest'));
    }
  };
  const onDelete = async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-alert
      const response = window.confirm(
        t('modalsObjectionEdit.wouldYouLikeToWithdraw'),
      );
      if (response) {
        await deleteObjectionAPI({
          objectionId,
          courseId,
          assignmentId,
        });
        toast.success(t('modalsObjectionEdit.theObjectionWasWithdrawn'));
        refetch();
        handleClose();
      }
    } catch (e) {
      toast.warn(t('modalsObjectionEdit.thisIsTheWrongRequest'));
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <BasicDivBlock>
        <TitleMd>{t('modalsObjectionEdit.areYouRequest')}</TitleMd>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TitleSm>{t('modalsObjectionEdit.requestRequest')}</TitleSm>
            <Textarea rows={5} ref={register} name="comment" />
          </div>
          <DialogActions>
            <DeleteButton type="button" onClick={onDelete}>
              {t('modalsObjectionEdit.withdrawal')}
            </DeleteButton>
            <BtnPurpleLgOutline type="submit">
              {t('modalsObjectionEdit.submission')}
            </BtnPurpleLgOutline>
            <BtnGrayLgOutline type="button" onClick={handleClose}>
              {t('modalsObjectionEdit.cancellation')}
            </BtnGrayLgOutline>
          </DialogActions>
        </Form>
      </BasicDivBlock>
    </Dialog>
  );
};

export default React.memo(ObjectionEdit);
