import styled from 'styled-components';

const ScoreContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    max-width: 80px;
    min-width: 80px;
    margin: 5px;
  }
`;

export default ScoreContainer;
