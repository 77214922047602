import styled from 'styled-components';

const ScoreLabel = styled.h6`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.35);
  font-size: 15px;
  padding: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

export default ScoreLabel;
