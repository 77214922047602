import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PeerReviewOptionSelect,
  StageContainer,
  StageOptionLabel,
  StageTitle,
} from '../../../atoms/newAssignmnets';

const SCALES = Array.from(Array(9), (_, i) => i + 2);

const FeedbackReviewOption: React.FC = () => {
  const { register } = useFormContext();
  const { t } = useTranslation();
  return (
    <StageContainer>
      <StageTitle>
        {t('newAssignmentsFeedbackReviewOption.feedback')}
      </StageTitle>
      <StageOptionLabel>
        {t('newAssignmentsFeedbackReviewOption.feedbackEvaluationPerfectScore')}
      </StageOptionLabel>
      <PeerReviewOptionSelect name="feedbackMaxPoint" ref={register}>
        {SCALES.map((count) => {
          return (
            <option value={count} key={count}>
              {count}
            </option>
          );
        })}
      </PeerReviewOptionSelect>
    </StageContainer>
  );
};

export default FeedbackReviewOption;
