import styled from 'styled-components';

const CardContainerWrap = styled.div`
  @media (max-width: 767px) {
    width: 100%;
  }
  display: flex;
  flex-wrap: wrap;
`;

export default CardContainerWrap;
