import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import DvrOutlinedIcon from '@material-ui/icons/DvrOutlined';
import { useTranslation } from 'react-i18next';
import {
  CourseBox,
  CourseBoxDiv,
  CourseTitle,
  FilterIcon,
  IconWithBorder,
} from '../../../atoms/dashboard';
import { FlexDiv } from '../../../atoms/posts';
import { CourseType } from '../../../../apis/dashboard';
import ProjectCard from '../../../molecules/ProjectCard';
import useDashboardActions from '../../../../hooks/dashboard/useDashboardActions';

const CourseHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HideButton = styled.span`
  color: #a8b7be;
  cursor: pointer;
  font-size: 0.625em;
`;

type CourseBoxContainerProps = {
  course: CourseType;
  handleHide: () => void;
};

const CourseBoxContainer: React.FC<CourseBoxContainerProps> = ({
  course,
  handleHide,
}) => {
  const [filterStatus, setFilterStatus] = React.useState(1);
  const { dispatchFetchDashboard } = useDashboardActions();
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = (index: number) => (): void => {
    setFilterStatus(index);
  };
  const role = course.is_instructor ? 'instructor' : 'learner';
  return (
    <CourseBox>
      <CourseBoxDiv>
        <CourseHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CourseTitle>{course.name}</CourseTitle>
            <IconWithBorder
              onClick={(): void => {
                history.push(`/courses/${course.id}/${role}`);
              }}
            >
              <DvrOutlinedIcon />
            </IconWithBorder>
          </div>
          <HideButton onClick={handleHide}>
            {t('dashboardCourseBoxContainer.hide')}
          </HideButton>
        </CourseHeader>
      </CourseBoxDiv>
      <CourseBoxDiv>
        <FilterIcon isActive={filterStatus === 0} onClick={handleClick(0)}>
          {t('dashboardCourseBoxContainer.overallTask')}
        </FilterIcon>
        <FilterIcon isActive={filterStatus === 1} onClick={handleClick(1)}>
          {t('dashboardCourseBoxContainer.proceeding')}
        </FilterIcon>
        <FilterIcon isActive={filterStatus === 2} onClick={handleClick(2)}>
          {t('dashboardCourseBoxContainer.completion')}
        </FilterIcon>
      </CourseBoxDiv>
      <FlexDiv>
        {course.assignments
          .filter((assignment) => {
            switch (filterStatus) {
              case 0:
                return true;
              case 1:
                return assignment.current_stage?.stage !== '완료';
              case 2:
                return assignment.current_stage?.stage === '완료';
              default:
                return true;
            }
          })
          .map((assignment) => {
            return (
              <ProjectCard
                assignment={assignment}
                refetch={dispatchFetchDashboard}
                key={assignment.id}
                courseId={course.id}
                courseRole={role}
              />
            );
          })}
      </FlexDiv>
    </CourseBox>
  );
};

export default React.memo(CourseBoxContainer);
