import React from 'react';

interface ReportProps {
  Container: React.FC;
  ScoreSection: React.FC;
  EssaySection: React.FC;
  FeedbackSection: React.FC;
  CommentSection: React.FC;
  OthersSection: React.FC;
}

const Report: React.FC<ReportProps> = ({
  Container,
  ScoreSection,
  EssaySection,
  FeedbackSection,
  CommentSection,
  OthersSection,
}) => {
  return (
    <Container>
      <ScoreSection />
      <EssaySection />
      <CommentSection />
      <FeedbackSection />
      <OthersSection />
    </Container>
  );
};

export default Report;
