import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchDashboard } from 'reducers/dashboard';

type UseDashboardActionsType = {
  dispatchFetchDashboard: () => void;
};
const useDashboardActions = (): UseDashboardActionsType => {
  const dispatch = useDispatch();
  const dispatchFetchDashboard = useCallback(() => dispatch(fetchDashboard()), [
    dispatch,
  ]);
  return { dispatchFetchDashboard };
};

export default useDashboardActions;
