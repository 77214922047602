import React, { useContext, useState } from 'react';
import { QuestionCard } from 'components/atoms';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PersonalScoreContext from 'pages/ScoreProgress/context';
import { useTranslation } from 'react-i18next';

const filters = [
  { label: 'totalScore', value: 'total' },
  { label: 'writingScore', value: 'essay_writing_score' },
  { label: 'evaluationAbilityScore', value: 'peer_review_score' },
  { label: 'metaCognitiveScore', value: 'metacognition_score' },
  { label: 'feedbackScore', value: 'feedback_review_score' },
  { label: 'retirement', value: 'revisement_score' },
];

const TopGraphForm: React.FC = (props: HighchartsReact.Props) => {
  const [filter, setFilter] = useState('total');
  const data = useContext(PersonalScoreContext);
  const { t } = useTranslation();

  const categories = data.assignments.map((item) => item.name);
  const all = data.assignments.map((item) => {
    const { all_score } = item;
    return [all_score[`${filter}` as keyof typeof all_score]];
  });
  const individual = data.assignments.map((item) => {
    const { individual_score } = item;
    return [individual_score[`${filter}` as keyof typeof individual_score]];
  });

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        categories,
      },
    ],
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat: '{point.y:.1f}',
    },
    series: [
      {
        name: data.name,
        type: 'column',
        data: individual,
        color: '#173c51',
      },
      {
        name: t('scoreProgressTopGraphForm.participatingStudentAverage') ?? '',
        type: 'column',
        data: all,
        color: '#cccccc',
      },
    ],
  };

  return (
    <QuestionCard>
      <FormControl component="fieldset" style={{ padding: '20px' }}>
        <RadioGroup
          row
          defaultValue="total"
          value={filter}
          onChange={(e): void => {
            setFilter(e.target.value);
          }}
        >
          {filters.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio color="primary" />}
                label={t(`scoreProgressTopGraphForm.${item.label}`) ?? ''}
                style={{ paddingRight: '15px' }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </QuestionCard>
  );
};

export default TopGraphForm;
