import styled from 'styled-components';

const FoldButton = styled.button`
  width: fit-content;
  margin-bottom: 10px;
  color: #808080;
  font-size: 16px;
  padding: 5px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  display: flex;
  outline: 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;

export default FoldButton;
