import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BtnPurpleLgOutline, QuestionCard } from 'components/atoms';
import styled from 'styled-components';
import ReportContext from 'pages/Report/context';
import { useTranslation } from 'react-i18next';

const Div = styled.div`
  padding: 30px 10px 15px 20px;
`;
const Btn = styled(BtnPurpleLgOutline)`
  margin-right: 10px;
`;
const Ft20 = styled.div`
  font-size: 20px;
  color: #173c51;
  font-weight: 400;
`;

const OthersSection: React.FC = () => {
  const history = useHistory();
  const {
    url,
    params: { role },
  } = useRouteMatch();
  const {
    is_my_report,
    peer_review_performance: { targets },
  } = useContext(ReportContext);
  const otherTargets = targets.filter(
    (target) => target.reviewer !== target.target,
  );
  const urlParts = url.split('/');
  const reportListUrl = urlParts.slice(0, urlParts.length - 1).join('/');

  const { t } = useTranslation();

  return (
    <>
      {is_my_report && role === 'learner' && otherTargets.length > 0 && (
        <QuestionCard>
          <Ft20>{t('componentsOrganismsReportsOthersSection.byOthers')}</Ft20>
          <Div>
            {otherTargets.map((other) => {
              return (
                <Btn
                  key={other.id}
                  onClick={(): void => {
                    history.push(`${reportListUrl}/${other.target_report}`);
                  }}
                >
                  {other.target_name}
                </Btn>
              );
            })}
          </Div>
        </QuestionCard>
      )}
    </>
  );
};

export default OthersSection;
