import React, { useContext } from 'react';
import {
  AssignmentInfoDiv,
  AssignmentInfoTitle,
  CardBorderedDiv,
} from 'components/atoms';
import styled from 'styled-components';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MainContext from 'pages/PerformAssignment/PerformanceMain/context';
import { useTranslation } from 'react-i18next';

const FileDiv = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(23, 60, 81, 0.5);
  padding: 7px 15px 7px 10px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`;
const A = styled.a`
  display: flex;
  align-items: center;
`;

const FileAttatchment: React.FC = () => {
  const { files } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <AssignmentInfoDiv>
      <CardBorderedDiv>
        <AssignmentInfoTitle>
          {t('assignmentInfoFileAttatchmentForm.attachments')}
        </AssignmentInfoTitle>
        <div>
          {files && files.length > 0 && (
            <div>
              {files.map((file) => {
                const fileArray = file.file.split('/');
                return (
                  <FileDiv key={file.file}>
                    <AttachFileIcon />
                    <A href={file.file}>
                      {decodeURI(fileArray[fileArray.length - 1])}
                    </A>
                  </FileDiv>
                );
              })}
            </div>
          )}
        </div>
      </CardBorderedDiv>
    </AssignmentInfoDiv>
  );
};

export default FileAttatchment;
