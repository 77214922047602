import styled from 'styled-components';

const BasicTextarea = styled.textarea`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  padding: 10px 8px;
  font-size: 18px;
  width: 100%;
  overflow: auto;
  ::placeholder {
    color: #bcbcbc;
    font-weight: 300;
  }
`;

export default BasicTextarea;
