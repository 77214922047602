import { ResponseType } from 'utils/requests';
import { requests } from '../../utils';
import {
  CreateObjectionAPIProps,
  ObjectionBaseUrlProps,
  ObjectionDetailUrlProps,
  ObjectionType,
  PatchObjectionAPIProps,
} from './types';

const getDetailUrl = ({
  courseId,
  assignmentId,
  objectionId,
}: ObjectionDetailUrlProps): string => {
  return `/api/v1/courses/${courseId}/assignments/${assignmentId}/objections/${objectionId}/`;
};

const getBaseUrl = ({
  courseId,
  assignmentId,
}: ObjectionBaseUrlProps): string => {
  return `/api/v1/courses/${courseId}/assignments/${assignmentId}/objections/`;
};

export const createObjectionAPI = async ({
  courseId,
  assignmentId,
  comment,
  matching,
}: CreateObjectionAPIProps): Promise<ResponseType<ObjectionType>> => {
  return requests().post(getBaseUrl({ courseId, assignmentId }), {
    comment,
    matching,
  });
};

export const fetchObjectionAPI = async (
  key: string,
  courseId: number,
  assignmentId: number,
  objectionId: number,
): Promise<ResponseType<ObjectionType>> => {
  return requests().get(getDetailUrl({ courseId, assignmentId, objectionId }));
};

export const deleteObjectionAPI = async ({
  courseId,
  assignmentId,
  objectionId,
}: ObjectionDetailUrlProps): Promise<ResponseType<ObjectionType>> => {
  return requests().delete(
    getDetailUrl({ courseId, assignmentId, objectionId }),
  );
};

export const patchObjectionAPI = async ({
  courseId,
  assignmentId,
  objectionId,
  comment,
}: PatchObjectionAPIProps): Promise<ResponseType<ObjectionType>> => {
  return requests().patch(
    getDetailUrl({ courseId, assignmentId, objectionId }),
    { comment },
  );
};
