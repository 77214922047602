import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HelpIcon from '@material-ui/icons/Help';
import Build from '@material-ui/icons/Build';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useTranslation } from 'react-i18next';
import PaymentInfo from '../../../organisms/modals/PaymentInfo';
import { MenuItem, SupportWrapper } from '../../../atoms/mains';
import PrivateContext from '../../../../routes/Private/context';

type MainSupportProps = {
  iconStyle: React.CSSProperties;
};
const MainSupport: React.FC<MainSupportProps> = ({ iconStyle }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { is_staff } = useContext(PrivateContext);

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };

  return (
    <>
      <SupportWrapper>
        <MenuItem
          onClick={(): void => history.push('/helps')}
          isActive={pathname === '/helps'}
        >
          <LibraryBooksIcon style={iconStyle} />
          {t('mainSupport.manual')}
        </MenuItem>
        <MenuItem
          isActive={pathname === '/supports'}
          onClick={(): void => history.push('/supports')}
        >
          <HelpIcon style={iconStyle} />
          {t('mainSupport.inquiryAndSupport')}
        </MenuItem>
        <MenuItem onClick={handleOpenToggle(true)}>
          <CreditCardIcon style={iconStyle} />
          {t('mainSupport.fare')}
        </MenuItem>
        {is_staff && (
          <MenuItem onClick={(): void => history.push('/manager')}>
            <Build style={iconStyle} />
            {t('mainSupport.administratorPage')}
          </MenuItem>
        )}
      </SupportWrapper>
      <PaymentInfo handleClose={handleOpenToggle(false)} open={open} />
    </>
  );
};

export default MainSupport;
