import styled from 'styled-components';

const StageLabel = styled.label`
  @media (min-width: 768px) {
    &:not(last-child) {
      margin-right: 35px;
    }
  }
  @media (max-width: 767px) {
    &:not(last-child) {
      margin-bottom: 20px;
    }
    width: 49%;
  }
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
`;

export default StageLabel;
