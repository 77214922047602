import React, { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {
  CommonDiv,
  CommonDivFlex,
  CommonDivFlexEnd,
  CommonDivider,
  TopContainer,
  TopExplain,
  TopLink,
  TopManageButton,
  TopTitleText,
  BasicDivFlexRight,
} from 'components/atoms';

import { courseLearnersCountFetchAPI, CourseResponse } from 'apis';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import StudentsManageDialog from '../../modals/StudentsManage';
import MyClassInfo from '../../../molecules/courses/MyClassInfo';
import CourseContext from '../../../../pages/Course/context';
import renderFiles from '../../../../utils/renderFiles';

const useStyles = makeStyles(() => ({
  th: {
    color: '#173c51',
    height: '41px',
    paddingRight: '10px',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  flex: { display: 'flex', alignItems: 'center' },
  tdColor: {
    color: '#5646ff',
    fontSize: '15px',
  },
  tdBlack: {
    color: '#173c51',
    fontSize: '15px',
  },
  tdName: {
    fontWeight: 'bold',
    paddingRight: '5px',
  },
  codeBtn: {
    fontSize: '16px',
    padding: '5px 14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '16px',
    borderColor: '#5646ff',
    color: '#5646ff',
  },
  paddingR100: { paddingRight: '100px' },
  paddingBoth: { padding: '0 10px', fontWeight: 400 },
  paddingB20: { display: 'flex', alignItems: 'center' },
  editText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  outlineIcon: {
    verticalAlign: 'middle',
    paddingRight: '5px',
    fontSize: '24px',
  },
  peopleIcon: {
    color: '#5646ff',
    fontSize: '30px',
    paddingRight: '5px',
  },
  manage: {
    alignItems: 'center',
    marginBottom: 0,
  },
}));

const TopSectionForm: React.FC<CourseResponse> = ({
  name,
  instructor,
  description,
  code,
}) => {
  const classes = useStyles();

  const [open2, setOpen2] = useState(false);
  const handleOpenToggle2 = (state: boolean) => (): void => {
    setOpen2(state);
  };
  const {
    url,
    params: { role, courseId },
  } = useRouteMatch();
  const { data, refetch } = useQuery(
    ['learner-count', courseId],
    courseLearnersCountFetchAPI,
  );
  const { files } = useContext(CourseContext);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <TopContainer>
        <CommonDivFlex style={{ marginBottom: 0 }}>
          <TopTitleText>{name}</TopTitleText>
          <CommonDivFlexEnd className={classes.manage}>
            {(role === 'instructor' || role === 'assistant') && (
              <>
                <TopLink onClick={handleOpenToggle2(true)}>
                  {t('coursesMobileTopSectionForm.management')}
                </TopLink>
                <StudentsManageDialog
                  countRefetch={refetch}
                  handleClose={handleOpenToggle2(false)}
                  open={open2}
                />
              </>
            )}
            <CommonDivFlex className={classes.manage}>
              <PeopleAltIcon className={classes.peopleIcon} />
              <TopExplain>{data?.data.count}</TopExplain>
            </CommonDivFlex>
          </CommonDivFlexEnd>
        </CommonDivFlex>
        <CommonDiv style={{ marginBottom: '35px' }}>
          <TopExplain>{description}</TopExplain>
        </CommonDiv>
        <div>
          <div className={classes.paddingB20}>
            <span className={classes.th}>
              {t('coursesMobileTopSectionForm.teachingInformation')}
            </span>
            <span className={classes.tdColor}>
              <span className={classes.tdName}>{instructor.name}</span>
              <span>{`(${instructor.email})`}</span>
            </span>
          </div>

          <div>
            <div className={classes.flex}>
              <div className={classes.th}>
                {t('coursesMobileTopSectionForm.lectureCode')}
              </div>
              <div className={classes.paddingR100}>
                <span className={classes.codeBtn}>{code}</span>
              </div>
            </div>
          </div>

          <div className={classes.flex}>
            <div className={classes.th}>
              {t('coursesMobileTopSectionForm.lecturePlan')}
            </div>
            <div className={classes.tdBlack}>{renderFiles(files, [])}</div>
          </div>
          <BasicDivFlexRight style={{ marginTop: '8px' }}>
            {role === 'learner' && <MyClassInfo />}
          </BasicDivFlexRight>
        </div>
        {role !== 'learner' && (
          <CommonDivFlexEnd>
            <TopManageButton
              onClick={(): void => history.push(`${url}/results`)}
            >
              {t('coursesMobileTopSectionForm.confirmTheResultsOfTheCourse')}
            </TopManageButton>
            <TopManageButton onClick={(): void => history.push(`${url}/edit`)}>
              <SettingsOutlinedIcon className={classes.outlineIcon} />
              {t('coursesMobileTopSectionForm.courseManagement')}
            </TopManageButton>
          </CommonDivFlexEnd>
        )}
      </TopContainer>
      <CommonDivider />
    </>
  );
};

export default React.memo(TopSectionForm);
