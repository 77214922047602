import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import { Private, Public } from 'routes';
import { toast, ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query';
import { pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import Maintenance from 'components/atoms/commons/Maintenance';
import SelectLanguage from 'components/molecules/commons/SelectLanguage';
import useAuthentication from '../hooks/authentication/useAuthentication';
import { fetchConfigure } from '../apis';
import LoadingPage from '../pages/LoadingPage';
import AppContext from './context';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  body: {
    textAlign: 'center',
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  const { data, isLoading, isError } = useQuery(
    ['configuration'],
    fetchConfigure,
  );
  const {
    signIn: { isLoggedIn },
  } = useAuthentication();
  const { t } = useTranslation();
  useEffect(() => {
    if (isMobile) {
      toast.warn(t('app.classprepIsOptimizedForThePCScreen'), {
        position: 'bottom-center',
        autoClose: false,
        className: classes.body,
        closeOnClick: false,
      });
    }
  }, [classes, t]);
  if (isError) {
    localStorage.removeItem('token');
    return <div>{t('app.communicationWithTheServerIsNotSmooth')}</div>;
  }
  if (!data || isLoading) {
    return <LoadingPage />;
  }

  return (
    <AppContext.Provider value={data.data}>
      <SelectLanguage />
      {isLoggedIn ? <Private /> : <Public />}
      <ToastContainer position="top-center" hideProgressBar />
      {/* <Maintenance /> */}
    </AppContext.Provider>
  );
};
export default React.memo(App);
