import styled from 'styled-components';

const QuestionInput = styled.input`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.08);
  padding: 9px 14px;
  font-size: 15px;
`;

export default QuestionInput;
