import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const lngs = [
  { value: 'ko', children: '🇰🇷' },
  { value: 'en', children: '🇺🇸' },
];

const key = 'i18nextLng';

const SelectLanguage = () => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      changeLanguage(event.target.value);
      localStorage.setItem(key, event.target.value);
    },
    [changeLanguage],
  );
  useEffect(() => {
    const lng = localStorage.getItem(key);
    if (lng !== null) {
      changeLanguage(lng);
    } else {
      localStorage.setItem(key, 'ko');
    }
  }, [changeLanguage]);
  return (
    <select
      value={language}
      onChange={handleChange}
      style={{ position: 'fixed', bottom: 5, right: 5, zIndex: 999 }}
    >
      {lngs.map(({ value, children }) => (
        <option key={value} value={value}>
          {children}
        </option>
      ))}
    </select>
  );
};

export default SelectLanguage;
