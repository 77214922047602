import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import AppContext from '../../App/context';
import LoadingPage from '../../pages/LoadingPage';

const Public: React.FC = () => {
  const { is_sign_up_available } = useContext(AppContext);

  return (
    <Switch>
      <Route path="/sign-in" exact component={SignIn} />
      {is_sign_up_available && (
        <Route path="/sign-up" exact component={SignUp} />
      )}
      <Route
        path="/password"
        exact
        render={() => {
          document.location.href = '/accounts/password_reset/';
          return <LoadingPage />;
        }}
      />
      <Redirect path="*" to="/sign-in" />
    </Switch>
  );
};

export default Public;
