import React from 'react';
import { Column } from 'material-table';
import moment from 'moment';
import { t } from 'i18next';
import { UserListItemType } from '../../../apis/users';

export const cellStyle: React.CSSProperties = {
  textAlign: 'center',
};

export const getUserColumns: () => Column<UserListItemType>[] = () => [
  {
    title: t('pagesManagersUsersColumns.name'),
    field: 'name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersUsersColumns.email'),
    field: 'email',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersUsersColumns.category'),
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      return t(`app.${row.role.display}`);
    },
  },
  {
    title: t('pagesManagersUsersColumns.group'),
    field: 'group.name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersUsersColumns.recentConnectionDate'),
    field: 'last_login',
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      if (row.last_login) {
        return moment(row.last_login).format('YYYY-MM-DD');
      }
      return t('pagesManagersUsersColumns.noAccessRecord');
    },
  },
  {
    title: t('pagesManagersUsersColumns.openingCourse'),
    field: 'courses_count',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersUsersColumns.course'),
    field: 'enrolls_count',
    cellStyle,
    sorting: false,
  },
];
