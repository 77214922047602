import styled from 'styled-components';

const AddButton = styled.button`
  border: none;
  background: none;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  font-weight: 300;
  margin-left: -5px;
  padding: 0;
  :hover {
    color: rgba(23, 60, 81, 0.7);
  }
`;

export default AddButton;
