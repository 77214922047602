import React from 'react';
import { MyPageContainer, ManageText } from 'components/atoms';
import { useTranslation } from 'react-i18next';

const ContainerForm: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <MyPageContainer>
      <ManageText>
        {t('manageAssignmentContainerForm.assignmentManaging')}
      </ManageText>
      {children}
    </MyPageContainer>
  );
};

export default ContainerForm;
