import styled from 'styled-components';
import RightBox from '../RightBox';

const LeftBox = styled(RightBox)`
  @media (min-width: 768px) {
    border-top: 2px solid #4a6cbb;
  }
  width: auto;
`;

export default LeftBox;
