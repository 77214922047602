import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import {
  CommonDivFlexEnd,
  FieldWrapper,
  Form,
  SaveButton,
  StudentManageCheckbox,
} from 'components/atoms';
import {
  courseLearnersFetchAPI,
  CourseLearnerType,
  learnerStatusUpdateAPI,
} from 'apis';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export type StudentsManageProps = {
  handleClose: () => void;
  open: boolean;
  countRefetch: () => void;
};

const SwitchCell = styled.td`
  @media (min-width: 768px) {
    padding: 17px 50px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 13px;
`;

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      display: 'flex',
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      color: '#fff',
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#2f64de',
          borderColor: '#2f64de',
        },
      },
    },
    thumb: {
      width: 24,
      height: 24,
      boxShadow: 'none',
    },
    track: {
      border: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    checked: {},
  }),
)(Switch);

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 400,
    fontSize: '20px',
    padding: '24px',
    color: '#173c51',
  },
  tr: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    borderSpacing: '2px',
  },
  name: {
    textAlign: 'left',
    lineHeight: 1,
    fontSize: '15px',
    fontWeight: 400,
  },
  number: {
    marginTop: '3px',
    fontWeight: 300,
    fontSize: '12px',
    textAlign: 'left',
  },
  assistantCell: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    fontSize: '16px',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
    height: 300,
  },
  nothing: {
    padding: '5px 20px 20px 20px',
  },
}));

const StudentsManageDialog: React.FC<StudentsManageProps> = ({
  handleClose,
  open,
  countRefetch,
}) => {
  const color = '#2f64de';
  const classes = useStyles();
  const { courseId, role } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['AllLearners', courseId],
    courseLearnersFetchAPI,
    { enabled: false },
  );
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<CourseLearnerType[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setState(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      refetch().then();
    }
  }, [open, refetch]);

  const handleChange = (type: 'is_assistant' | 'is_active') => (
    enrollId: number,
  ) => async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      setLoading(true);
      const response = await learnerStatusUpdateAPI({
        enrollId,
        [type]: event.target.checked,
      });
      const { is_active, is_assistant } = response.data;
      const targetIndex = state.findIndex((learner) => learner.id === enrollId);
      setState((prevState) => {
        return [
          ...prevState.slice(0, targetIndex),
          { ...prevState[targetIndex], is_active, is_assistant },
          ...prevState.slice(targetIndex + 1, prevState.length),
        ];
      });
      countRefetch();
    } catch (e) {
      toast.warn(t('modalsStudentsManage.fail'));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={classes.title}>
        {t('modalsStudentsManage.studentManagement')}
      </div>
      <DialogContent>
        {isLoading ? (
          <div className={classes.loading}>
            <ReactLoading type="bars" color={color} />
            <h3 style={{ color }}>Loading...</h3>
          </div>
        ) : (
          <fieldset disabled={loading || isLoading}>
            <Form>
              <FieldWrapper>
                <table>
                  <tbody>
                    {state.length !== 0 ? (
                      state.map((learner) => (
                        <tr className={classes.tr} key={learner.id}>
                          <th>
                            <div className={classes.name}>
                              {learner.user.name}
                            </div>
                            <div className={classes.number}>{learner.code}</div>
                          </th>
                          <SwitchCell>
                            <div>
                              <Typography component="div">
                                <Grid
                                  component="label"
                                  container
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    {t('modalsStudentsManage.withdrawal')}
                                  </Grid>
                                  <Grid item>
                                    <AntSwitch
                                      onChange={handleChange('is_active')(
                                        learner.id,
                                      )}
                                      checked={learner.is_active}
                                    />
                                  </Grid>
                                  <Grid item>
                                    {t('modalsStudentsManage.participation')}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </div>
                          </SwitchCell>
                          {role === 'instructor' && (
                            <td className={classes.assistantCell}>
                              <StudentManageCheckbox
                                type="checkbox"
                                checked={learner.is_assistant}
                                onChange={handleChange('is_assistant')(
                                  learner.id,
                                )}
                              />
                              {t('modalsStudentsManage.assistantRegistration')}
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className={classes.nothing}>
                          {t(
                            'modalsStudentsManage.thereAreNoStudentsWhoHaveAppliedFor',
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </FieldWrapper>
              <CommonDivFlexEnd>
                <SaveButton
                  type="button"
                  onClick={handleClose}
                  style={{ marginTop: '8px' }}
                >
                  {t('modalsStudentsManage.close')}
                </SaveButton>
              </CommonDivFlexEnd>
            </Form>
          </fieldset>
        )}
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default StudentsManageDialog;
