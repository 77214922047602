import styled from 'styled-components';

const StudentManageCheckbox = styled.input`
  margin-right: 10px;
  padding: 0;
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

export default StudentManageCheckbox;
