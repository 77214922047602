import React from 'react';
import {
  BackText,
  CommentButton,
  ContentText,
  DateText,
  StrongPaddingText,
  TitleText,
} from 'components/atoms';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import styled from 'styled-components';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CommentSectionFormProps } from '../CommentSectionForm';
import useCourses from '../../../../hooks/courses/useCourses';
import { coursePostDeleteAPI } from '../../../../apis/posts';

const FileUploadContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const FileAnchor = styled.a`
  margin-right: 5px;
  text-decoration: none;
  color: inherit;
`;

const ModifierSection = styled.div`
  margin-top: 27px;
  display: flex;
  justify-content: flex-end;
`;

const ModifierButton = styled(CommentButton)`
  margin-left: 10px;
  background: none;
  color: #71bad8;
  outline: none;

  :hover {
    border-color: rgba(113, 186, 216, 0.5);
    color: rgba(113, 186, 216, 0.5);
  }
`;

const DeleteButton = styled(ModifierButton)`
  border-color: rgba(255, 49, 75, 1);
  color: rgba(255, 49, 75, 0.7);
  :hover {
    border-color: rgba(255, 49, 75, 0.7);
    color: rgba(255, 49, 75, 0.3);
  }
`;

const PostSectionForm: React.FC<CommentSectionFormProps> = ({
  children,
  post,
}) => {
  const history = useHistory();
  const { currentCourse } = useCourses();
  const {
    url,
    params: { courseId, postId },
  } = useRouteMatch();

  const { t } = useTranslation();

  return (
    <div style={{ padding: '32px 40px 24px 40px' }}>
      <BackText
        onClick={(): void => {
          history.goBack();
        }}
      >
        <ArrowBackIcon style={{ verticalAlign: 'middle' }} />
        <span>
          <StrongPaddingText>{currentCourse}</StrongPaddingText>
          {t('componentsOrganismsPostsPostSectionForm.returnToThePage')}
        </span>
      </BackText>
      <TitleText>{post?.title}</TitleText>
      <DateText>{moment(post?.created_at).format('YYYY-MM-DD HH:mm')}</DateText>
      <Divider style={{ margin: '24px 0 32px 0' }} />
      <ContentText>{post?.content}</ContentText>
      {post?.files && post.files.length > 0 && (
        <FileUploadContainer>
          <AttachmentIcon />
          {post.files.map((file) => {
            const fileArray = file.file.split('/');
            return (
              <FileAnchor
                href={file.file}
                download
                key={file.id}
                target="_blank"
              >
                {decodeURI(fileArray[fileArray.length - 1])}
              </FileAnchor>
            );
          })}
        </FileUploadContainer>
      )}
      {post?.is_own && (
        <ModifierSection>
          <ModifierButton
            onClick={(): void => {
              history.push(`${url}/edit`);
            }}
          >
            {t('componentsOrganismsPostsPostSectionForm.edit')}
          </ModifierButton>
          <DeleteButton
            onClick={async (): Promise<void> => {
              // eslint-disable-next-line no-alert
              const answer = window.confirm(
                t('componentsOrganismsPostsPostSectionForm.continue'),
              );
              if (answer) {
                try {
                  await coursePostDeleteAPI(courseId, postId);
                  toast.success(
                    t('componentsOrganismsPostsPostSectionForm.done'),
                  );
                  history.goBack();
                } catch (e) {
                  toast.warn(
                    t('componentsOrganismsPostsPostSectionForm.wrong'),
                  );
                }
              }
            }}
          >
            {t('componentsOrganismsPostsPostSectionForm.delete')}
          </DeleteButton>
        </ModifierSection>
      )}
      {children}
    </div>
  );
};

export default React.memo(PostSectionForm);
