import React, { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ScoreContainer,
  ScoreInput,
  ScoreLabel,
  ScoreRow,
  Section,
  SectionLabelNormal,
} from '../../../atoms/newAssignmnets';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';
import { SCORE_TYPE } from '../../../../utils/constants';

type ScoreType = {
  label: string;
  desc: string;
};

export const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

export const WEIGHT_STANDARD: { [key: string]: ScoreType } = {
  [SCORE_TYPE.WRITING]: {
    label: 'newAssignmentsScoreWeight.writing',
    desc: 'newAssignmentsScoreWeight.writingDescription',
  },
  [SCORE_TYPE.PEER_REVIEW]: {
    label: 'newAssignmentsScoreWeight.evaluationAbility',
    desc: 'newAssignmentsScoreWeight.evaluationAbilityDescription',
  },
  [SCORE_TYPE.META]: {
    label: 'newAssignmentsScoreWeight.metaCognitive',
    desc: 'newAssignmentsScoreWeight.metaCognitiveDescription',
  },
  [SCORE_TYPE.FEEDBACK_REVIEW]: {
    label: 'newAssignmentsScoreWeight.feedback',
    desc: 'newAssignmentsScoreWeight.feedbackDescription',
  },
  [SCORE_TYPE.REVISEMENT]: {
    label: 'newAssignmentsScoreWeight.retirement',
    desc: 'newAssignmentsScoreWeight.retirementDescription',
  },
};

const ScoreWeight: React.FC = () => {
  const { register, getValues, watch, unregister } = useFormContext();
  const totalValues = getValues();
  const { feedback, revise } = totalValues;
  const total = Object.keys(WEIGHT_STANDARD)
    .filter((key) => totalValues[key])
    .map((key) => totalValues[key])
    .reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
  const isError = total !== 100 ? { color: 'red' } : {};
  const { currentStage } = useContext(IsEditableContext);
  const { t } = useTranslation();
  const index = stageIndex.indexOf(currentStage);
  return (
    <Section>
      <SectionLabelNormal>
        {t('newAssignmentsScoreWeight.score')}
      </SectionLabelNormal>
      <span style={{ ...isError }}>
        {`${t('newAssignmentsScoreWeight.totalScores')}: ${total}`}
      </span>
      <fieldset disabled={index === stageIndex.length - 1}>
        <ScoreRow>
          {Object.keys(WEIGHT_STANDARD)
            .filter((key) => {
              if (
                (!feedback && key === SCORE_TYPE.FEEDBACK_REVIEW) ||
                (!revise && key === SCORE_TYPE.REVISEMENT)
              ) {
                unregister(key);
                return false;
              }
              return true;
            })
            .map((key) => {
              watch(key);
              return (
                <ScoreContainer key={key}>
                  <CustomTooltip
                    title={
                      t(WEIGHT_STANDARD[key].desc, {
                        weight: totalValues[key],
                      }) ?? ''
                    }
                    placement="top"
                  >
                    <ScoreLabel>{t(WEIGHT_STANDARD[key].label)}</ScoreLabel>
                  </CustomTooltip>
                  <ScoreInput
                    type="number"
                    min={5}
                    max={100}
                    name={key}
                    required
                    ref={register({
                      min: 5,
                      max: 100,
                    })}
                  />
                </ScoreContainer>
              );
            })}
        </ScoreRow>
      </fieldset>
    </Section>
  );
};

export default ScoreWeight;
