import styled from 'styled-components';

const CardBorderedDiv = styled.div`
  border-color: rgba(6, 88, 169, 0.2);
  flex: 1;
  padding: 19px 16px;
  font-size: 15px;
  color: #173c51;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
  margin-bottom: 10px;
`;

export default CardBorderedDiv;
