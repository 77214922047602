import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FontSize16Div,
  RoundedCardBox,
  StarDiv,
  StarTextDiv,
} from '../../atoms';

type peerReviewCardProps = {
  id: number;
  order: number;
  standard: string;
  maxPoint: number;
  point: number;
  comment: string;
  isReadOnly?: boolean;
};

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid rgba(23, 60, 81, 0.2);
  border-radius: 6px;
  padding: 4px;
  font-size: 14px;
`;

const PeerReviewCard: React.FC<peerReviewCardProps> = ({
  id,
  order,
  standard,
  maxPoint,
  point,
  comment,
  isReadOnly,
}) => {
  const { register, control, getValues, watch } = useFormContext();
  const defaultValue = point || Math.round(maxPoint / 2);
  watch(`responses[${order}].point`);

  return (
    <RoundedCardBox>
      <FontSize16Div>{standard}</FontSize16Div>
      <input
        type="hidden"
        name={`responses[${order}].id`}
        value={id}
        ref={register}
      />
      <StarDiv style={{ marginTop: '8px' }}>
        <Box
          component="fieldset"
          mb={3}
          borderColor="transparent"
          style={{ marginBottom: '14px' }}
        >
          <Controller
            as={<Rating />}
            name={`responses[${order}].point`}
            defaultValue={defaultValue}
            max={maxPoint}
            control={control}
            disabled={isReadOnly}
          />
        </Box>
        <StarTextDiv>
          {getValues()[`responses[${order}].point`] || defaultValue} /{' '}
          {maxPoint}
        </StarTextDiv>
      </StarDiv>
      <Textarea
        name={`responses[${order}].comment`}
        defaultValue={comment}
        ref={register}
        required
        rows={4}
        disabled={isReadOnly}
      />
    </RoundedCardBox>
  );
};

export default PeerReviewCard;
