import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  RadioContainer,
  RadioLabel,
  SchedulingRadio,
  StageContainer,
  StageOptionInput,
  StageOptionLabel,
  StageTitle,
} from '../../../atoms/newAssignmnets';

const EssayOption: React.FC = () => {
  const { register, watch } = useFormContext();
  const limitType = watch('limitType', 'L');
  const { t } = useTranslation();

  return (
    <StageContainer>
      <StageTitle>{t('newAssignmentsEssayOption.essayWritten')}</StageTitle>
      <StageOptionLabel>
        {t('newAssignmentsEssayOption.restrictionsOnTheNumberOfEssays')}
      </StageOptionLabel>
      <RadioContainer>
        <RadioLabel>
          <SchedulingRadio
            type="radio"
            name="limitType"
            value="L"
            ref={register}
          />
          {t('newAssignmentsEssayOption.limitNumberOfLetters')}
        </RadioLabel>
        <RadioLabel>
          <SchedulingRadio
            type="radio"
            name="limitType"
            value="W"
            ref={register}
          />
          {t('newAssignmentsEssayOption.limitForTheNumberOfWords')}
        </RadioLabel>
      </RadioContainer>
      <StageOptionInput
        type="number"
        name="essayLimit"
        ref={register({
          max: 20000,
          min: 0,
        })}
      />
      {limitType === 'L' && t('newAssignmentsEssayOption.letter')}
      {limitType === 'W' && t('newAssignmentsEssayOption.word')}
    </StageContainer>
  );
};

export default EssayOption;
