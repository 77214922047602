import React from 'react';
import Score from 'templates/Score';
import ContainerForm from 'components/organisms/scores/ContainerForm';
import TopForm from 'components/organisms/scores/TopForm/';
import MidForm from 'components/organisms/scores/MidForm/';
import BottomForm from 'components/organisms/scores/BottomForm/';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ScoreContext from './context';
import { fetchMaxScore } from '../../apis/assignments';
import LoadingPage from '../LoadingPage';

const ScorePage: React.FC = () => {
  const { courseId, assignmentId } = useParams();
  const { data, isLoading, isError, refetch } = useQuery(
    ['score', courseId, assignmentId],
    fetchMaxScore,
  );

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <ScoreContext.Provider value={{ ...data.data, refetch }}>
      <Score
        Container={ContainerForm}
        Top={TopForm}
        Mid={MidForm}
        Bottom={BottomForm}
      />
    </ScoreContext.Provider>
  );
};

export default ScorePage;
