import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  BasicDivFlexRight,
  BtnPurpleLgOutline,
  CoverBox,
  TopContainer,
  TopTitleText,
} from 'components/atoms';
import CourseSearchForm, {
  CourseSearchFormState,
} from 'components/molecules/managers/CourseSearchForm';
import queryString from 'query-string';
import { allCourseFetchAPI, courseFetchPageAPI } from 'apis';
import MaterialGrid from 'components/organisms/commons/MaterialGrid';
import { Pagination } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import { toast } from 'react-toastify';
import { makeExcel } from 'utils/parsers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getCourseColumns } from './columns';
import useDebounce from '../../../hooks/utils/useDebounce';

type StateType = {
  q: string;
  group: string;
  page?: number;
};

const generateQueryString = (
  state: CourseSearchFormState,
  page: number,
  dQ: string,
): string => {
  const { group } = state;
  let query: Record<string, string | boolean | number> = {
    page,
    page_size: 10,
  };
  if (dQ) {
    query = { ...query, q: dQ };
  }
  if (group) {
    query = { ...query, instructor__group: group };
  }
  return queryString.stringify(query);
};

const Courses: React.FC = () => {
  const { url } = useRouteMatch();
  const location = useLocation<StateType>();
  const [page, setPage] = useState(location?.state?.page || 1);
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const initialState = location.state || {
    q: '',
    group: '',
  };
  const [state, setState] = useState(initialState);
  const debouncedQ = useDebounce(state.q, 500);
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const query = generateQueryString(state, page, debouncedQ);
  const { data, isLoading, isError } = useQuery([query], courseFetchPageAPI);

  useEffect(() => {
    if (isError) {
      setPage(1);
    }
  }, [isError, setPage]);

  return (
    <CoverBox>
      <TopContainer>
        <TopTitleText>
          {t('pagesManagersCourses.courseManagement')}
        </TopTitleText>
        <BasicDivFlexRight>
          <BtnPurpleLgOutline
            onClick={async (): Promise<void> => {
              try {
                const response = await allCourseFetchAPI();
                const header = [
                  t('pagesManagersCourses.group'),
                  t('pagesManagersCourses.lecturer'),
                  t('app.교수자'),
                  t('pagesManagersCourses.lecturerOpeningDate'),
                  t('pagesManagersCourses.numberOfRegisteredStudents'),
                  t('pagesManagersCourses.numberOfTasks'),
                  t('pagesManagersCourses.numberOfCompletedTasks'),
                  t('pagesManagersCourses.projectTitle'),
                  t('pagesManagersCourses.lectures'),
                ];
                const rows = response.data.map((row) => [
                  row.group_name,
                  row.name,
                  row.instructor_name,
                  moment(row.created_at).format('YYYY-MM-DD'),
                  row.enrolls_count,
                  row.assignments_count,
                  row.completed_assignments_count,
                  row.assignments_joined_name,
                  row.description,
                ]);
                makeExcel(
                  [header, ...rows],
                  t('pagesManagersCourses.administrator_courseManagement'),
                );
              } catch (e) {
                toast.warn(t('pagesManagersCourses.thisIsTheWrongRequest'));
              }
            }}
          >
            <GetAppIcon style={{ verticalAlign: 'middle' }} />
            {t('pagesManagersCourses.exportList')}
          </BtnPurpleLgOutline>
        </BasicDivFlexRight>
      </TopContainer>
      <CourseSearchForm state={state} onChange={onChange} />
      {React.useMemo(
        () =>
          language && (
            <MaterialGrid
              columns={getCourseColumns()}
              onRowClick={(e, rowData): void => {
                history.push(`${url}/${rowData?.id}`, {
                  ...state,
                  page,
                });
              }}
              isLoading={isLoading}
              components={{
                Pagination: (paginationProps): ReactElement => {
                  const styles = {
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                  };
                  return (
                    <td style={styles}>
                      <Pagination
                        color="primary"
                        count={Math.ceil(paginationProps.count / 10)}
                        onChange={(e, newPage): void => {
                          setPage(newPage);
                        }}
                        page={page}
                      />
                    </td>
                  );
                },
              }}
              page={page}
              data={data?.data.results || []}
              totalCount={data?.data.count}
            />
          ),
        [page, history, url, isLoading, data, state, language],
      )}
    </CoverBox>
  );
};

export default Courses;
