import styled from 'styled-components';

const Topbar = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 64px;
  background: #2f64de;
  padding: 0 20px;
  color: white;
`;

export default Topbar;
