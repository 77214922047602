import styled from 'styled-components';
import bg from 'assets/images/sign-up-bg.png';

type BgType = {
  login_image?: string;
};

const Bg = styled.div<BgType>`
  @media (min-width: 768px) {
    height: 100vh;
    background-image: ${({ login_image }): string =>
      login_image ? `url(${login_image})` : `url(${bg})`};
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 767px) {
    min-height: 100vh;
    flex-direction: column;
  }
  display: flex;
`;

export default Bg;
