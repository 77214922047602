import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  BtnPurpleLgOutline,
  QuestionCard,
  TextareaRounded,
} from 'components/atoms';
import { reportUpdateAPI } from 'apis';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';

const RightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 10px 0;
`;
const Div = styled.div`
  padding: 30px 20px 16px 0px;
`;
const Ft20 = styled.div`
  font-size: 20px;
  color: #173c51;
  font-weight: 400;
`;
const Heading = styled.div`
  font-size: 15px;
  color: rgba(23, 60, 81, 0.5);
`;

export type ReportCommentFormType = { content: string };
const CommentSection: React.FC = () => {
  const { t } = useTranslation();
  const { courseId, assignmentId, reportId, role } = useParams();
  const { register, handleSubmit } = useForm<ReportCommentFormType>();
  const { comment: content } = useContext(ReportContext);

  const onSubmit = async (data: ReportCommentFormType): Promise<void> => {
    const comment = {
      content: data.content,
    };
    try {
      await reportUpdateAPI({
        courseId,
        assignmentId,
        reportId,
        comment,
      });

      toast.success(
        t('componentsOrganismsReportsMobileCommentSection.iWroteAComment'),
      );
    } catch (e) {
      toast.warn(t('componentsOrganismsReportsMobileCommentSection.wrong'));
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Heading>
          {t('componentsOrganismsReportsMobileCommentSection.teachersComment')}
        </Heading>
      </AccordionSummary>

      <AccordionDetails style={{ width: '100%', display: 'block' }}>
        <QuestionCard>
          <Ft20>
            {t(
              'componentsOrganismsReportsMobileCommentSection.teachersComment',
            )}
          </Ft20>
          {role === 'instructor' ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextareaRounded
                rows={5}
                name="content"
                placeholder={
                  t(
                    'componentsOrganismsReportsMobileCommentSection.enterACommentForTheStudent',
                  ) ?? ''
                }
                ref={register}
                defaultValue={content?.content}
              />
              <RightDiv>
                <BtnPurpleLgOutline type="submit">
                  {t('componentsOrganismsReportsMobileCommentSection.save')}
                </BtnPurpleLgOutline>
              </RightDiv>
            </form>
          ) : (
            <>
              <Div>
                {content ? (
                  <>{content?.content}</>
                ) : (
                  <>
                    <ErrorOutlineIcon style={{ verticalAlign: 'middle' }} />
                    <span style={{ fontSize: '14px' }}>
                      {t(
                        'componentsOrganismsReportsMobileCommentSection.noComment',
                      )}
                    </span>
                  </>
                )}
              </Div>
            </>
          )}
        </QuestionCard>
      </AccordionDetails>
    </Accordion>
  );
};

export default CommentSection;
