import styled from 'styled-components';

const TitleLarge = styled.div`
  font-size: 20px;
  color: #173c51;
  font-weight: 400;
  margin-bottom: 20px;
`;

export default TitleLarge;
