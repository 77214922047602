import React from 'react';
import Course from 'templates/Course';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import ContainerForm from '../../components/organisms/courses/ContainerForm';
import TopSectionForm from '../../components/organisms/courses/TopSectionForm';
import MidSectionForm from '../../components/organisms/courses/MidSectionForm';
import BottomSectionForm from '../../components/organisms/courses/BottomSectionForm';
import { courseFetchAPI } from '../../apis';
import CourseContext from './context';
import LoadingPage from '../LoadingPage';
import MobileContainerForm from '../../components/organisms/coursesMobile/ContainerForm';
import MobileTopSectionForm from '../../components/organisms/coursesMobile/TopSectionForm';
import MobileBottomSectionForm from '../../components/organisms/coursesMobile/BottomSectionForm';

const CoursePage: React.FC = () => {
  const { courseId } = useParams();
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const { data, error, isLoading } = useQuery(
    ['course', courseId],
    courseFetchAPI,
  );
  if (error) {
    history.push('/404');
  }
  if (!data?.data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <CourseContext.Provider value={data.data}>
      {isDesktop ? (
        <Course
          courseData={data.data}
          Container={ContainerForm}
          TopSection={TopSectionForm}
          MidSection={MidSectionForm}
          BottomSection={BottomSectionForm}
        />
      ) : (
        <Course
          courseData={data.data}
          Container={MobileContainerForm}
          TopSection={MobileTopSectionForm}
          MidSection={MidSectionForm}
          BottomSection={MobileBottomSectionForm}
        />
      )}
    </CourseContext.Provider>
  );
};

export default CoursePage;
