import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  BasicDivBlock,
  BtnGrayLgOutline,
  BtnPurpleLgOutline,
  Form,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { createObjectionAPI } from '../../../../apis/objections';

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid rgb(168, 183, 190);
  margin: 15px 0;
  overflow: auto;
  line-height: 1.15;
`;

const TitleSm = styled.p`
  font-size: 15px;
  margin-top: 30px;
  font-weight: 300;
`;

const TitleMd = styled.div`
  margin: 1em 0;
  font-size: 16px;
  font-weight: 400;
`;

export type ObjectionProps = {
  handleClose: () => void;
  open: boolean;
  matchingId: number;
  refetch: () => void;
};

type FormType = {
  comment: string;
};

const Objection: React.FC<ObjectionProps> = ({
  handleClose,
  open,
  matchingId,
  refetch,
}) => {
  const { register, handleSubmit } = useForm<FormType>();
  const { courseId, assignmentId } = useParams();
  const { t } = useTranslation();
  const onSubmit = async (data: FormType): Promise<void> => {
    try {
      await createObjectionAPI({
        matching: matchingId,
        courseId,
        assignmentId,
        ...data,
      });
      toast.success(t('modalsObjection.appealsHaveBeenRegistered'));
      refetch();
      handleClose();
    } catch (e) {
      toast.warn(t('modalsObjection.thisIsTheWrongRequest'));
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <BasicDivBlock>
        <TitleMd>
          {t('modalsObjection.wouldYouLikeToReviewThisFeedback')}
        </TitleMd>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TitleSm>{t('modalsObjection.requestRequest')}</TitleSm>
            <Textarea rows={5} ref={register} name="comment" />
          </div>
          <DialogActions>
            <BtnPurpleLgOutline type="submit">
              {t('modalsObjection.submission')}
            </BtnPurpleLgOutline>
            <BtnGrayLgOutline type="button" onClick={handleClose}>
              {t('modalsObjection.cancellation')}
            </BtnGrayLgOutline>
          </DialogActions>
        </Form>
      </BasicDivBlock>
    </Dialog>
  );
};

export default React.memo(Objection);
