import styled from 'styled-components';
import BtnInstructor from '../BtnInstructor';

const BtnStudent = styled(BtnInstructor)`
  background-color: #2f64de;
  :hover {
    background: rgba(47, 100, 222, 0.8);
  }
`;

export default BtnStudent;
