import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useManageDetailStyles = makeStyles(() => ({
  center: { verticalAlign: ' middle', marginRight: '5px' },
  pointer: { cursor: 'pointer' },
  title: { fontSize: '18px', color: '#173c51', marginBottom: '25px' },
  subtitle: { fontSize: '18px', color: '#173c51', marginBottom: '12px' },
  w40: { width: '40%' },
  w50: { width: '50%' },
  w60: { width: '60%' },
  w80: { width: '80%' },
  mgT: { marginTop: '15px' },
  mgB: { marginBottom: '15px' },
  mgB2: { marginBottom: '5px' },
  input: { width: '80%', marginBottom: '10px !important' },
  borderB: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: '10px',
  },
  flex: { display: 'flex', alignItems: 'middle' },
  date: { marginTop: '20px !important', width: '80%' },
  smT: { fontSize: '16px', margin: '10px 0 15px 0' },
  subject: { margin: '7px 0' },
  firstCard: { display: 'flex', padding: '24px' },
  trash: { color: 'red', verticalAlign: 'middle', marginRight: '4px' },
  grayTxt: { color: '#acacac', fontSize: '14px' },
  betweenBasic: { justifyContent: 'space-between' },
  learnersTitle: { fontSize: '18px', margin: '15px 0 25px 0' },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
  },
}));

export const DetailPageCard = styled.div`
  font-size: 16px;
  margin-top: 25px;
  padding: 24px;
  font-size: 15px;
  color: #173c51;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
  margin-right: 10px;
`;

export const LeftDiv = styled.div`
  width: 60%;
`;
export const RightDiv = styled.div`
  width: 40%;
`;
export const Circle = styled.div`
  width: 7px;
  height: 7px;
  background: #888888;
  display: inline-block;
  margin-right: 5px;
`;
export const Bar = styled.div`
  display: flex;
  padding: 15px 24px;
  background-color: #ececec;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 6px;
  border-top: 1px solid #bcbcbc;
`;

export const WhiteBar = styled(Bar)`
  background-color: #fff;
  justify-content: start;
  border: none;
  padding: 0 0 24px 24px;
`;

export const AssignmentManageCard = styled.div`
  font-size: 16px;
  margin-top: 25px;
  padding: 24px;
  font-size: 15px;
  color: #173c51;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
  margin-right: 10px;
`;
export const LearnerName = styled.span`
  font-size: 16px;
`;
export const MiniBar = styled.span`
  font-size: 12px;
  color: #555;
  padding: 0 10px;
`;
