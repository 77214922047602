const serializeFormData = <T extends object>(data: T): FormData => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const dataKey = key as keyof T;
    const value = data[dataKey];
    if (value instanceof FileList) {
      Array.from((value as unknown) as FileList).forEach((file, index) => {
        formData.append(`file-${index}`, file, file.name);
      });
    } else if (typeof value === 'string') {
      formData.append(key, value);
    } else if (typeof value === 'boolean') {
      formData.append(key, value.toString());
    } else {
      formData.append(key, JSON.stringify(value));
    }
  });
  return formData;
};

export default serializeFormData;
