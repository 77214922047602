import { FeedbackReviewMatchingTargetType } from 'apis/performances';
import { requests, ResponseType } from 'utils/requests';
import { FeedbackReviewMatchingUpdateAPIProps } from './types';

const feedbackReviewMatchingUpdateAPI = async ({
  id,
  point,
  comment,
}: FeedbackReviewMatchingUpdateAPIProps): Promise<
  ResponseType<FeedbackReviewMatchingTargetType>
> => {
  return requests().patch(
    `/api/v1/performances/feedbackreview-matching/${id}/`,
    { point, comment },
  );
};

export default feedbackReviewMatchingUpdateAPI;
