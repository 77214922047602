import { Moment } from 'moment';
import { AssignmentType, ProgressType, QuizType, StandardType } from 'apis';
import { SCORE_TYPE } from '../../../../utils/constants';

export type AssignmentFormData = {
  name: string;
  description: string;
  essay: boolean;
  peer: boolean;
  adjust: boolean;
  feedback: boolean;
  revise: boolean;
  question: boolean;
  limitType: string;
  essayLimit: number;
  [SCORE_TYPE.WRITING]: number;
  [SCORE_TYPE.PEER_REVIEW]: number;
  [SCORE_TYPE.META]: number;
  [SCORE_TYPE.FEEDBACK_REVIEW]?: number;
  [SCORE_TYPE.REVISEMENT]?: number;
  isAuto: string;
  isDirectlyStarting: boolean;
  isPrevious: boolean;
  files?: FileList;
  quizs?: QuizType[];
  feedbackMaxPoint: number;
  peerReviewCount: number;
  standards?: StandardType[];
  essayScheduleStart?: null | Moment;
  essayScheduleEnd?: null | Moment;
  peerScheduleStart?: null | Moment;
  peerScheduleEnd?: null | Moment;
  adjustScheduleStart?: null | Moment;
  adjustScheduleEnd?: null | Moment;
  feedbackScheduleStart?: null | Moment;
  feedbackScheduleEnd?: null | Moment;
  isPublic?: boolean;
};

const serializeAssignment = (formData: AssignmentFormData): AssignmentType => {
  const {
    name,
    description,
    isAuto,
    isDirectlyStarting,
    essay,
    adjust,
    peer,
    question,
    feedback,
    revise,
    quizs,
    isPrevious,
    files,
  } = formData;
  const auto = isAuto === 'true';
  const nullDate = { start_date: null, end_date: null };
  const essaySchedule = auto
    ? {
        start_date: formData.essayScheduleStart,
        end_date: formData.essayScheduleEnd,
      }
    : nullDate;
  const peerReviewSchedule = auto
    ? {
        start_date: isPrevious
          ? formData.essayScheduleEnd
          : formData.peerScheduleStart,
        end_date: formData.peerScheduleEnd,
      }
    : nullDate;
  const adjustmentSchedule = auto
    ? {
        start_date: isPrevious
          ? formData.peerScheduleEnd
          : formData.adjustScheduleStart,
        end_date: formData.adjustScheduleEnd,
      }
    : nullDate;
  const feedbackReviewSchedule = auto
    ? {
        start_date: isPrevious
          ? formData.adjustScheduleEnd || formData.peerScheduleEnd
          : formData.feedbackScheduleStart,
        end_date: formData.feedbackScheduleEnd,
      }
    : nullDate;

  const essayProgress = essay
    ? [
        {
          model: 'essaywriting',
          stage_object: {
            limit_type: formData.limitType,
            max_length: formData.essayLimit,
            max_writing_score: formData[SCORE_TYPE.WRITING],
          },
          ...essaySchedule,
        },
      ]
    : [];
  const questionProgress = question
    ? [
        {
          ...essaySchedule,
          model: 'questionwriting',
          stage_object: {
            quizs,
          },
        },
      ]
    : [];
  const peerReviewProgress = peer
    ? [
        {
          model: 'peerreview',
          stage_object: {
            peer_count: formData.peerReviewCount,
            max_review_score: formData[SCORE_TYPE.PEER_REVIEW],
            max_metacognition_score: formData[SCORE_TYPE.META],
            standards: formData.standards,
          },
          ...peerReviewSchedule,
        },
      ]
    : [];
  const adjustmentProgress = adjust
    ? [{ ...adjustmentSchedule, stage_object: {}, model: 'adjustment' }]
    : [];
  const feedbackReviewProgress = feedback
    ? [
        {
          model: 'feedbackreview',
          stage_object: {
            max_review_score: formData.FE,
            max_point: formData.feedbackMaxPoint,
          },
          ...feedbackReviewSchedule,
        },
      ]
    : [];
  const revisementProgress = revise
    ? [
        {
          model: 'revisement',
          stage_object: {
            max_revisement_score: formData[SCORE_TYPE.REVISEMENT],
          },
          ...feedbackReviewSchedule,
        },
      ]
    : [];
  const progresses = [
    ...essayProgress,
    ...questionProgress,
    ...peerReviewProgress,
    ...adjustmentProgress,
    ...feedbackReviewProgress,
    ...revisementProgress,
  ];

  return {
    name,
    description,
    is_auto_schedule: auto,
    is_directly_starting: isDirectlyStarting || false,
    is_continue_from_previous_step: isPrevious || false,
    progresses: progresses as ProgressType[],
    files,
    is_public: formData.isPublic ?? true,
  };
};

export default serializeAssignment;
