import styled from 'styled-components';

const NumIconDash = styled.div`
  background: rgba(0, 0, 0, 0.5);
  margin: auto 0;
  width: 12px;
  height: 1px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export default NumIconDash;
