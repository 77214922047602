import styled from 'styled-components';

const BorderLeftTh = styled.th`
  border-left: 1px solid #4a6cbb;
  border-top: 1px solid #4a6cbb;
  border-bottom: 1px solid #4a6cbb;
  min-width: 80px;
  padding: 13px 0;
  text-align: center;
  color: #173c51;
  font-weight: 300;
  font-size: 14px;
`;

export default BorderLeftTh;
