import React, { useContext } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  BasicDivPdTBBlock,
  BtnPurpleLg,
  Form,
  Textarea,
} from 'components/atoms';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import {
  FeedbackReviewMatchingTargetType,
  feedbackReviewMatchingUpdateAPI,
  FeedbackReviewMatchingUpdateAPIProps,
} from 'apis';
import PerformanceContext from 'pages/PerformAssignment/PerformanceMain/context';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SwitchPerformanceContext from '../../../pages/PerformAssignment/SwitchPage/context';

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  color: #173c51;
`;

const RoundDiv = styled.div`
  border: 1px solid rgba(6, 88, 169, 0.5);
  border-radius: 6px;
  margin-top: 22px;
  padding: 5px;
  display: flex;
`;

type FeedbackReviewFormType = {
  data: {
    maxPoint: number;
    target: FeedbackReviewMatchingTargetType;
  };
};

const FeedbackReviewForm: React.FC<FeedbackReviewFormType> = ({
  data: {
    maxPoint,
    target: {
      id,
      matching: { reviewer_name: name },
      point,
      comment,
    },
  },
}) => {
  const comparePoint = point || 0;
  const ratingName = `point${id}`;
  const history = useHistory();
  const { courseId, assignmentId, role } = useParams();
  const { url } = useRouteMatch();
  const { current_stage, is_waiting_next_stage, refetch } = useContext(
    PerformanceContext,
  );
  const { refetch: switchRefetch } = useContext(SwitchPerformanceContext);

  const isOnProgressStage = current_stage.stage === '피드백평가';
  const isReadOnly =
    isOnProgressStage && !is_waiting_next_stage ? undefined : true;
  const { register, handleSubmit, control, errors, watch, getValues } = useForm<
    FeedbackReviewMatchingUpdateAPIProps
  >({
    defaultValues: {
      comment,
      [ratingName]: comparePoint,
      id,
    },
  });
  watch('comment');
  watch(ratingName);

  const { t } = useTranslation();
  const onSubmit = async (
    formData: FeedbackReviewMatchingUpdateAPIProps,
  ): Promise<void> => {
    const { id: formId, comment: formComment } = formData;
    const formPoint = formData[`point${formId}` as keyof typeof formData];
    const data = {
      id: formId,
      point: formPoint as number,
      comment: formComment,
    };
    try {
      const response = await feedbackReviewMatchingUpdateAPI(data);
      refetch();
      switchRefetch();
      toast.success(t('feedbackReviewForm.success'));
      setTimeout(() => {
        if (response.data.revisement && !(url in localStorage)) {
          // eslint-disable-next-line no-alert
          localStorage.setItem(url, '');
          const answer = window.confirm(
            t('feedbackReviewForm.wouldYouLikeToGoToThatStage'),
          );
          if (answer) {
            history.push(
              `/courses/${courseId}/${role}/assignments/${assignmentId}/performances/${response.data.revisement}`,
            );
          }
        }
      }, 1000);
    } catch (e) {
      toast.warn(t('feedbackReviewForm.error'));
    }
  };
  const isChange =
    parseInt(getValues(ratingName), 10) !== comparePoint ||
    comment !== getValues('comment');
  const ratingError = errors[ratingName as keyof typeof errors];
  return (
    <BasicDivPdTBBlock>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          {t('feedbackReviewForm.howHelpfulWasTheEvaluationOfName', { name })}
        </Title>
        <input type="hidden" name="id" ref={register} />
        <Box
          component="fieldset"
          mb={3}
          borderColor="transparent"
          style={{ display: 'flex' }}
        >
          <Controller
            as={Rating}
            name={ratingName}
            rules={{
              validate: (value): boolean => {
                return value >= 1 && value <= maxPoint;
              },
            }}
            max={maxPoint}
            control={control}
            disabled={isReadOnly}
          />
          {ratingError && (
            <span style={{ color: '#F65882' }}>
              {t(
                'feedbackReviewForm.theStarRatingShouldHaveAValueOf1Maxpoint',
                { maxPoint },
              )}
            </span>
          )}
        </Box>
        <RoundDiv>
          <Textarea
            name="comment"
            required
            ref={register}
            disabled={isReadOnly}
          />
          <BtnPurpleLg
            type="submit"
            disabled={isReadOnly || !isChange || getValues(ratingName) === 0}
          >
            {t('feedbackReviewForm.saveAndSubmission')}
          </BtnPurpleLg>
        </RoundDiv>
      </Form>
    </BasicDivPdTBBlock>
  );
};

export default FeedbackReviewForm;
