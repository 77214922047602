import React, { useContext } from 'react';
import {
  NAField,
  RadioContainer,
  RadioLabel,
  SchedulingRadio,
  Section,
  SectionLabelNormal,
  StageCheckbox,
  StageLabel,
} from 'components/atoms/newAssignmnets';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateTimeRow from '../DateTimeRow';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

type ScheduleType = { [key: string]: string };

const Scheduling: React.FC = () => {
  const { register, watch, getValues } = useFormContext();
  const { t } = useTranslation();
  const { question, revise, feedback, adjust } = getValues();
  watch('isAuto');
  watch('isPrevious');
  const { isAuto, isPrevious } = getValues();
  const auto = isAuto === 'true';
  const previous = isPrevious === undefined || isPrevious;
  const feedbackSchedule: ScheduleType = feedback
    ? {
        feedbackSchedule: `${t('newAssignmentsScheduling.피드백평가')}${
          revise ? ` / ${t('newAssignmentsScheduling.퇴고')}` : ''
        }`,
      }
    : {};
  const adjustSchedule: ScheduleType = adjust
    ? { adjustSchedule: t('newAssignmentsScheduling.edit') }
    : {};
  const schedules: ScheduleType = {
    essaySchedule: `${t('newAssignmentsScheduling.에세이작성')}${
      question ? ` / ${t('newAssignmentsScheduling.질문작성')}` : ''
    }`,
    peerSchedule: t('newAssignmentsScheduling.동료평가'),
    ...adjustSchedule,
    ...feedbackSchedule,
  };
  const { currentStage } = useContext(IsEditableContext);
  const index = stageIndex.indexOf(currentStage);
  return (
    <Section>
      <SectionLabelNormal style={{ marginBottom: '10px' }}>
        {t('newAssignmentsScheduling.scheduling')}
      </SectionLabelNormal>
      <fieldset disabled={index === stageIndex.length - 1}>
        <NAField>
          <RadioContainer>
            <RadioLabel>
              <SchedulingRadio
                type="radio"
                name="isAuto"
                value="true"
                ref={register}
              />
              {t('newAssignmentsScheduling.automaticSetting')}
            </RadioLabel>
            <RadioLabel>
              <SchedulingRadio
                type="radio"
                name="isAuto"
                value="false"
                ref={register}
              />
              {t('newAssignmentsScheduling.manualSetting')}
            </RadioLabel>
          </RadioContainer>
          {!auto && (
            <>
              <StageLabel style={{ marginBottom: 20 }}>
                <StageCheckbox
                  type="checkbox"
                  name="isDirectlyStarting"
                  ref={register}
                  defaultChecked
                />
                {t('newAssignmentsScheduling.startImmediatelyAfterCreation')}
              </StageLabel>
            </>
          )}
          {auto && (
            <StageLabel style={{ marginBottom: 20 }}>
              <StageCheckbox type="checkbox" name="isPrevious" ref={register} />
              {t('newAssignmentsScheduling.followingThePreviousStage')}
            </StageLabel>
          )}
          {auto &&
            Object.keys(schedules).map((key) => {
              return (
                <DateTimeRow
                  key={key}
                  name={key}
                  disabled={!['essaySchedule'].includes(key) && previous}
                  label={schedules[key]}
                />
              );
            })}
        </NAField>
      </fieldset>
    </Section>
  );
};

export default React.memo(Scheduling);
