import React from 'react';
import { VoteContainer, PostsForm } from 'components/atoms';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledRadioGroup = styled(RadioGroup)`
  label {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const VoteSectionForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <VoteContainer>
        <div style={{ fontSize: '14px', marginBottom: '16px' }}>
          {t('ComponentsOrganismsPostsVoteSectionForm.vote')}
        </div>
        <PostsForm>
          <FormControl component="fieldset">
            <StyledRadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value={t('componentsOrganismsPostsVoteSectionForm.first') ?? ''}
                control={<Radio color="primary" />}
                label={t('componentsOrganismsPostsVoteSectionForm.first') ?? ''}
              />
              <FormControlLabel
                value={
                  t('componentsOrganismsPostsVoteSectionForm.second') ?? ''
                }
                control={<Radio color="primary" />}
                label={
                  t('componentsOrganismsPostsVoteSectionForm.second') ?? ''
                }
              />
            </StyledRadioGroup>
          </FormControl>
        </PostsForm>
      </VoteContainer>
    </>
  );
};

export default VoteSectionForm;
