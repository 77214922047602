import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../utils/sagaHelpers';
import { PROFILES } from '../reducers/profiles';
import { profileAPI, fetchStandardScoreAPI } from '../apis';

const fetchProfileSaga = createRequestSaga(PROFILES.FETCH_PROFILE, profileAPI);
const fetchStandardScoresSaga = createRequestSaga(
  PROFILES.FETCH_STANDARD_SCORES,
  fetchStandardScoreAPI,
);

export default function* profileSaga(): Generator {
  yield takeLatest(PROFILES.FETCH_PROFILE, fetchProfileSaga);
  yield takeLatest(PROFILES.FETCH_STANDARD_SCORES, fetchStandardScoresSaga);
}
