import { createContext } from 'react';
import { PersonalScoreResponse } from '../../apis/enrolls';

const PersonalScoreContext = createContext<PersonalScoreResponse>({
  id: 0,
  name: '',
  assignments: [],
});

export default PersonalScoreContext;
