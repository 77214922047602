import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import DaumPostcode from 'react-daum-postcode';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCancel } from '../../../atoms/postWriting';

type DaumPostDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setValue: (key: string, value: string) => void;
};

const DaumPostDialog: React.FC<DaumPostDialogProps> = ({
  open,
  setOpen,
  setValue,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={(): void => setOpen(false)} fullWidth>
      <DialogTitle>{t('modalsDaumPostDialog.address')}</DialogTitle>
      <DialogContent>
        <DaumPostcode
          onComplete={(data): void => {
            setValue('address', data.address);
            setOpen(false);
          }}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px 16px 24px' }}>
        <ButtonCancel
          type="button"
          onClick={(): void => {
            setOpen(false);
          }}
          style={{ marginRight: 0 }}
        >
          {t('modalsDaumPostDialog.close')}
        </ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};

export default DaumPostDialog;
