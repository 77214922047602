import styled from 'styled-components';

const MgFt16Div = styled.div`
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 400;
  color: #173c51;
`;

export default MgFt16Div;
