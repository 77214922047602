import React, { Fragment, useEffect, useState } from 'react';
import {
  FlexDiv,
  NumIconDash,
  NumIconLg,
  NumIconLgRightSide,
  NumIconLgWithoutNum,
  NumIconLightOutline,
  NumIconSm,
  NumIconSmOutline,
  NumIconSmWithoutNum,
  PaddingRightSpan,
} from 'components/atoms';
import FastForwardIcon from '@material-ui/icons/FastForward';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CurrentStageType, ProgressType } from 'apis';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import styled from 'styled-components';

interface TimeProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const CustomFlexDiv = styled(FlexDiv)`
  @media (max-width: 767px) {
    display: grid;
  }
`;

const makeRenderer = (setCompleted: (c: boolean) => void) => {
  return (props: TimeProps): React.ReactElement => {
    const { days, hours, minutes, seconds, completed } = props;
    if (completed) {
      setCompleted(true);
      return <span>{i18next.t('assignmentProgresses.deadline')}</span>;
    }

    return (
      <span>
        {days * 24 + hours}:{minutes}:{seconds}
      </span>
    );
  };
};

type RenderStageProps = {
  progress: TranslatedProgressType;
  isReadOnly: boolean;
  isAutoSchedule: boolean;
  nextProgress?: ProgressType;
  moveToNextStage: (() => Promise<void>) | undefined;
  renderer: React.FC<TimeProps>;
  now: () => number;
};

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

const CurrentStage = ({
  progress,
  isReadOnly,
  isAutoSchedule,
  nextProgress,
  moveToNextStage,
  renderer,
  now,
}: RenderStageProps) => {
  const { t } = useTranslation();
  if (nextProgress && isAutoSchedule) {
    return (
      <>
        <NumIconSmOutline>
          <CustomTooltip
            placement="top"
            title={
              moment(progress.start_date).format('MM/DD HH:mm ~ ') +
              moment(progress.end_date).format('MM/DD HH:mm')
            }
          >
            <span>{progress.tranStage}</span>
          </CustomTooltip>
        </NumIconSmOutline>
        <NumIconDash />
        {isReadOnly ? (
          <NumIconSmWithoutNum>
            <CustomTooltip
              placement="top"
              title={moment(nextProgress.start_date).format(
                `${t('assignmentProgresses.startTheNextStage')} MM/DD HH:mm`,
              )}
            >
              <span>{t('assignmentProgresses.진행준비중')}</span>
            </CustomTooltip>
          </NumIconSmWithoutNum>
        ) : (
          <NumIconLgWithoutNum>
            <span>{t('assignmentProgresses.진행준비중')}</span>
            <NumIconLgRightSide>
              <PaddingRightSpan>
                {nextProgress.start_date && (
                  <Countdown
                    date={nextProgress.start_date as string}
                    now={now}
                    renderer={renderer}
                  />
                )}
              </PaddingRightSpan>
            </NumIconLgRightSide>
          </NumIconLgWithoutNum>
        )}
      </>
    );
  }
  const isCompleteStage = progress.stage === '완료';

  if (isReadOnly) {
    return (
      <NumIconSm>
        {isAutoSchedule && !isCompleteStage ? (
          <CustomTooltip
            placement="top"
            title={
              moment(progress.start_date).format('MM/DD HH:mm ~ ') +
              moment(progress.end_date).format('MM/DD HH:mm')
            }
          >
            <span>{progress.tranStage}</span>
          </CustomTooltip>
        ) : (
          <span>{progress.tranStage}</span>
        )}
      </NumIconSm>
    );
  }
  if (isAutoSchedule) {
    return isCompleteStage ? (
      <NumIconSm>
        <span>{progress.tranStage}</span>
      </NumIconSm>
    ) : (
      <NumIconLg>
        {progress.tranStage}
        <NumIconLgRightSide>
          <PaddingRightSpan>
            {progress.end_date && (
              <Countdown
                date={progress.end_date as string}
                now={now}
                renderer={renderer}
              />
            )}
          </PaddingRightSpan>
        </NumIconLgRightSide>
      </NumIconLg>
    );
  }
  return isCompleteStage ? (
    <NumIconSm>
      <span>{progress.tranStage}</span>
    </NumIconSm>
  ) : (
    <NumIconLg onClick={moveToNextStage}>
      {progress.tranStage}
      <NumIconLgRightSide>
        <PaddingRightSpan>
          {isAutoSchedule && progress.end_date && (
            <Countdown
              date={progress.end_date as string}
              now={now}
              renderer={renderer}
            />
          )}
          {!isAutoSchedule &&
            (progress.stage === '진행대기중'
              ? t('assignmentProgresses.startNow')
              : t('assignmentProgresses.proceedToTheNextStage'))}
        </PaddingRightSpan>
        <FastForwardIcon />
      </NumIconLgRightSide>
    </NumIconLg>
  );
};

type AssignmentProgressesProps = {
  isAutoSchedule: boolean;
  progresses: ProgressType[];
  currentStage: CurrentStageType;
  isWaitingNextStage: boolean;
  isReadOnly?: boolean;
  moveToNextStage?: () => Promise<void>;
  refetch: () => void;
  now: string;
};

type TranslatedProgressType = {
  tranStage: string;
} & ProgressType;

const AssignmentProgresses: React.FC<AssignmentProgressesProps> = ({
  isAutoSchedule,
  progresses,
  currentStage,
  isWaitingNextStage,
  isReadOnly = true,
  moveToNextStage,
  refetch,
  now,
}: AssignmentProgressesProps) => {
  const { t } = useTranslation();
  const isExistQuestionWriting = !!progresses.find(
    (progress) => progress.stage === '질문작성',
  );
  const isExistRevisement = !!progresses.find(
    (progress) => progress.stage === '퇴고',
  );
  const filteredProgresses: TranslatedProgressType[] = progresses
    .filter((progress) => {
      switch (progress.stage) {
        case '에세이작성':
          return true;
        case '질문작성':
          // 질문작성 단계 제외
          return false;
        case '진행대기중':
          // 이미 지난 진행대기중 단계 제외
          return progress.stage === currentStage.stage;
        case '피드백평가':
          return true;
        case '퇴고':
          // 퇴고작성 단계 제외
          return false;
        case '완료':
          // 아직 완료가 아니면 완료 단계 제외
          return progress.stage === currentStage.stage;
        default:
          return true;
      }
    })
    .map((progress) => {
      if (isExistQuestionWriting && progress.stage === '에세이작성') {
        return {
          ...progress,
          tranStage: `${t(`assignmentProgresses.${progress.stage}`)} / ${t(
            'assignmentProgresses.질문작성',
          )}`,
        };
      }
      if (isExistRevisement && progress.stage === '피드백평가') {
        return {
          ...progress,
          tranStage: `${t(`assignmentProgresses.${progress.stage}`)} / ${t(
            'assignmentProgresses.퇴고',
          )}`,
        };
      }
      return {
        ...progress,
        tranStage: t(`assignmentProgresses.${progress.stage}`),
      };
    });

  const currentIndex = filteredProgresses.findIndex((progress) => {
    const currentTranStage = t(`assignmentProgresses.${currentStage.stage}`);
    return progress.tranStage.includes(currentTranStage);
  });

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (completed) {
      (refetch as () => Promise<void>)().then(() => {
        setCompleted(false);
      });
    }
  }, [completed, refetch]);

  const timeDeltaBetweenServer = Date.now() - Date.parse(now);
  const getServerNow = (): number => timeDeltaBetweenServer + Date.now();

  return (
    <CustomFlexDiv>
      {filteredProgresses.map((progress, idx, elements) => {
        const isFirst: boolean = idx === 0;
        return (
          <Fragment key={progress.stage}>
            {!isFirst && <NumIconDash />}
            {idx < currentIndex && (
              // 자동일 땐 툴팁에 시간정보, 수동일 땐 툴팁 없음
              <NumIconSmOutline>
                {isAutoSchedule ? (
                  <CustomTooltip
                    placement="top"
                    title={
                      moment(progress.start_date).format('MM/DD HH:mm ~ ') +
                      moment(progress.end_date).format('MM/DD HH:mm')
                    }
                  >
                    <span>{progress.tranStage}</span>
                  </CustomTooltip>
                ) : (
                  <span>{progress.tranStage}</span>
                )}
              </NumIconSmOutline>
            )}
            {idx === currentIndex && (
              <CurrentStage
                {...{
                  progress,
                  isReadOnly,
                  isAutoSchedule,
                  nextProgress: isWaitingNextStage
                    ? elements[idx + 1]
                    : undefined,
                  moveToNextStage,
                  renderer: makeRenderer(setCompleted),
                  now: getServerNow,
                }}
              />
            )}
            {idx > currentIndex &&
              (isAutoSchedule ? (
                <NumIconLightOutline>
                  <CustomTooltip
                    placement="top"
                    title={
                      moment(progress.start_date).format('MM/DD HH:mm ~ ') +
                      moment(progress.end_date).format('MM/DD HH:mm')
                    }
                  >
                    <span>{progress.tranStage}</span>
                  </CustomTooltip>
                </NumIconLightOutline>
              ) : (
                <NumIconLightOutline>
                  <span>{progress.tranStage}</span>
                </NumIconLightOutline>
              ))}
          </Fragment>
        );
      })}
    </CustomFlexDiv>
  );
};

export default React.memo(AssignmentProgresses);
