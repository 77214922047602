import React, { useContext } from 'react';
import { CommonDiv, FontSize18Div, Section } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import histogramBellcurve from 'highcharts/modules/histogram-bellcurve';
import ScoreContext from '../../../../pages/Score/context';

histogramBellcurve(Highcharts);

const categories = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const MidForm: React.FC = () => {
  const { reports, current_stage } = useContext(ScoreContext);
  const { t } = useTranslation();
  const reportsData = reports.map((report) => {
    const {
      essay_writing_score,
      feedback_review_score,
      metacognition_score,
      peer_review_score,
      revisement_score,
    } = report;
    return (
      essay_writing_score +
      feedback_review_score +
      metacognition_score +
      peer_review_score +
      revisement_score
    );
  });
  const isCompleted = current_stage.stage === '완료';
  const data = categories
    .slice(0, categories.length - 1)
    .map((category, index) => {
      return reportsData.filter((score) => {
        if (categories[index + 1] === 100) {
          return category <= score && score <= categories[index + 1];
        }
        return category <= score && score < categories[index + 1];
      }).length;
    });

  const options = {
    credits: {
      enabled: false,
    },
    title: '',
    chart: {
      type: 'column',
    },
    xAxis: [
      {
        title: { text: t('scoresMidForm.score') },
        alignTicks: false,
        crosshair: true,
        categories,
      },
    ],

    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },

    series: [],
  };
  const newOptions = {
    ...options,
    series: [
      ...options.series,
      {
        type: 'histogram',
        name: t('scoresMidForm.studentScoreDistribution'),
        data,
      },
    ],
  };
  return (
    <CommonDiv>
      <Section>
        <FontSize18Div style={{ marginBottom: '10px' }}>
          {t('scoresMidForm.fullStudentScoreAnalysis')}
        </FontSize18Div>
        {isCompleted ? (
          <HighchartsReact highcharts={Highcharts} options={newOptions} />
        ) : (
          <div style={{ marginTop: '15px' }}>
            {t('scoresMidForm.notCompleted')}
          </div>
        )}
      </Section>
    </CommonDiv>
  );
};

export default MidForm;
