import React, { useEffect } from 'react';
import Main from 'pages/Main';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { loginCheckAPI } from 'apis';
import Error404 from '../../pages/Error404';
import useAuthenticationActions from '../../hooks/authentication/useAuthenticationActions';
import PrivateContext from './context';
import useProfileActions from '../../hooks/profiles/useProfileActions';
import ManagerMain from '../../pages/managers/ManagerMain';
import LoadingPage from '../../pages/LoadingPage';

const Private: React.FC = () => {
  const { data, isLoading, isError } = useQuery(['login-check'], loginCheckAPI);
  const { dispatchSignOut } = useAuthenticationActions();
  const { dispatchFetchProfile } = useProfileActions();
  useEffect(() => {
    if (data) {
      dispatchFetchProfile();
    }
  }, [data, dispatchFetchProfile]);
  if (isError) {
    dispatchSignOut();
  }
  if (!data || isLoading) {
    return <LoadingPage />;
  }

  const {
    data: { is_staff },
  } = data;

  return (
    <PrivateContext.Provider value={{ is_staff }}>
      <Switch>
        <Route path="/404" exact component={Error404} />
        <Route path="/manager" component={ManagerMain} />
        <Route path="/" component={Main} />
      </Switch>
    </PrivateContext.Provider>
  );
};

export default React.memo(Private);
