import styled from 'styled-components';

const ButtonForInstructor = styled.button`
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: #0765e8;
  border: 1px solid #0765e8;
  padding: 10px 30px;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;
  background-color: #fff;
  outline: 0;
`;

export default ButtonForInstructor;
