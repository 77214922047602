import React, { Fragment, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  BasicDivFlexBothCentered,
  CircleBorderBox,
  CustomDivider,
  Mgt15,
  Mgt25,
  QuestionCard,
  SpaceDiv,
  useReportStyles,
} from 'components/atoms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';
import PeerReviewCard from '../../../molecules/reports/PeerReviewCard';
import InstructorReviewCard from '../../../molecules/reports/InstructorReviewCard';
import FeedbackReviewCard from '../../../molecules/reports/FeedbackReviewCard';

const StyledSpan = styled.span``;

const FeedbackSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    params: { courseId, assignmentId, role },
  } = useRouteMatch();
  const isClickable = ['instructor', 'assistant'].includes(role);
  const classes = useReportStyles();
  const {
    name,
    peer_review_performance: { reviewers, targets },
    is_my_report,
    pr_performance_id,
  } = useContext(ReportContext);
  const reviewersExcludeSelf = reviewers.filter(
    (reviewer) => reviewer.reviewer !== reviewer.target,
  );
  const targetsExcludeSelf = targets.filter(
    (target) => target.reviewer !== target.target,
  );
  const selfReviewP = reviewers.find(
    (reviewer) =>
      reviewer.reviewer === reviewer.target &&
      reviewer.responses.every((response) => !!response.point),
  );
  const openReportWindow = (reportId: number) => {
    return (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      window.open(
        `/courses/${courseId}/${role}/assignments/${assignmentId}/reports/${reportId}`,
      );
    };
  };
  const clickableNickname = (
    reportId: number,
    reportName: string,
  ): React.ReactElement => {
    return (
      <StyledSpan
        className={classes.blue}
        style={{
          cursor: isClickable ? 'pointer' : 'default',
        }}
        onClick={isClickable ? openReportWindow(reportId) : (): void => {}}
      >
        {reportName}
      </StyledSpan>
    );
  };

  return (
    <>
      {/* 1. 내가 받은 동료평가와 이에 대한 피드백 */}
      <QuestionCard className={classes.mgT}>
        <>
          <div className={classes.ft20}>
            {is_my_report
              ? t(
                  'componentsOrganismsReportsFeedbackSection.myPeerReviewAndFeedback',
                )
              : t(
                  'componentsOrganismsReportsFeedbackSection.notMyPeerReviewAndFeedback',
                  { name },
                )}
          </div>
          <>
            {reviewersExcludeSelf.length > 0 ? (
              reviewersExcludeSelf.map((peer) => {
                const isMyReview = peer.reviewer === pr_performance_id;
                const isExistEditedScore = peer.responses.some(
                  (data) => data.edited_point,
                );

                return (
                  <div key={peer.id}>
                    <div
                      className={classes.mgT}
                      style={{ margin: '40px 0 20px 0' }}
                    >
                      {isMyReview ? (
                        <span>
                          {t('componentsOrganismsReportsFeedbackSection.iGive')}
                        </span>
                      ) : (
                        <>
                          {clickableNickname(
                            peer.reviewer_report,
                            peer.reviewer_name,
                          )}
                          {t(
                            'componentsOrganismsReportsFeedbackSection.someoneGive',
                          )}
                        </>
                      )}
                      {is_my_report
                        ? t(
                            'componentsOrganismsReportsMobileFeedbackSection.myEvaluation',
                          )
                        : t(
                            'componentsOrganismsReportsMobileFeedbackSection.notMyEvaluation',
                            { name },
                          )}
                    </div>
                    <div>
                      {peer.responses.map((response) => {
                        return (
                          <CircleBorderBox
                            key={response.id}
                            style={{ padding: '24px 24px 4px 24px' }}
                          >
                            <PeerReviewCard response={response} />
                          </CircleBorderBox>
                        );
                      })}
                      <>
                        <SpaceDiv>
                          {peer.feedbackreviewmatching?.point !== null && (
                            <>
                              <div className={classes.mgTB}>
                                {is_my_report
                                  ? t(
                                      'componentsOrganismsReportsFeedbackSection.myFeedback',
                                    )
                                  : t(
                                      'componentsOrganismsReportsFeedbackSection.notMyFeedback',
                                      { name },
                                    )}
                              </div>
                              <FeedbackReviewCard
                                feedbackreviewmatching={
                                  peer.feedbackreviewmatching
                                }
                              />
                            </>
                          )}
                        </SpaceDiv>
                      </>
                      {isExistEditedScore && (
                        <CircleBorderBox>
                          <div className={classes.red}>
                            {t(
                              'componentsOrganismsReportsFeedbackSection.adjustment',
                            )}
                          </div>
                          {peer.responses.map((response) => {
                            return (
                              <InstructorReviewCard
                                key={response.id}
                                response={response}
                              />
                            );
                          })}
                          <Mgt25 className={classes.reason}>
                            {t(
                              'componentsOrganismsReportsFeedbackSection.reason',
                            )}
                          </Mgt25>
                          <Mgt15>{peer.instructor_comment}</Mgt15>
                        </CircleBorderBox>
                      )}
                    </div>
                    <CustomDivider />
                  </div>
                );
              })
            ) : (
              <BasicDivFlexBothCentered>
                {t(
                  'componentsOrganismsReportsFeedbackSection.noColleagueEvaluation',
                )}
              </BasicDivFlexBothCentered>
            )}
          </>
        </>
      </QuestionCard>

      {/* 2. 자기평가 */}
      <QuestionCard className={classes.mgT} style={{ padding: '24px' }}>
        <div className={classes.ft20} style={{ marginBottom: '25px' }}>
          {is_my_report
            ? t('componentsOrganismsReportsFeedbackSection.mySelfEvaluation')
            : t(
                'componentsOrganismsReportsFeedbackSection.notMySelfEvaluation',
                { name },
              )}
        </div>
        {selfReviewP ? (
          selfReviewP.responses.map((response) => {
            return <PeerReviewCard key={response.id} response={response} />;
          })
        ) : (
          <BasicDivFlexBothCentered style={{ padding: '0 20px 20px 20px' }}>
            <span style={{ fontSize: '14px' }}>
              {t('componentsOrganismsReportsFeedbackSection.noSelfEvaluation')}
            </span>
          </BasicDivFlexBothCentered>
        )}
      </QuestionCard>

      {/* 3. 내가 쓴 다른사람 평가 */}
      <QuestionCard className={classes.mgT}>
        <div className={classes.ft20}>
          {is_my_report
            ? t('componentsOrganismsReportsFeedbackSection.myPeerReview')
            : t('componentsOrganismsReportsFeedbackSection.notMyPeerReview', {
                name,
              })}
        </div>
        {targetsExcludeSelf.length > 0 ? (
          <div>
            {targetsExcludeSelf.map((target) => {
              const { feedbackreviewmatching } = target;
              const amITarget = target.target === pr_performance_id;

              return (
                <Fragment key={target.id}>
                  <div
                    className={classes.mgT}
                    style={{ margin: '40px 0px 20px 0' }}
                  >
                    {is_my_report
                      ? t(
                          'componentsOrganismsReportsFeedbackSection.iGiveEvaluation',
                        )
                      : t(
                          'componentsOrganismsReportsFeedbackSection.toSomeoneEvaluation',
                          { name },
                        )}{' '}
                    {amITarget ? (
                      <span>
                        {t('componentsOrganismsReportsFeedbackSection.me')}
                      </span>
                    ) : (
                      <>
                        {clickableNickname(
                          target.target_report,
                          target.target_name,
                        )}
                        {t(
                          'componentsOrganismsReportsFeedbackSection.evaluation',
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    {target.responses.map((response) => {
                      return (
                        <CircleBorderBox
                          key={response.id}
                          style={{ padding: '24px 24px 4px 24px' }}
                        >
                          <PeerReviewCard response={response} />
                        </CircleBorderBox>
                      );
                    })}
                  </div>

                  <SpaceDiv>
                    <div className={classes.mgTB}>
                      {t(
                        'componentsOrganismsReportsFeedbackSection.feedbackAboutEvaluation',
                        { name: target.target_name },
                      )}
                    </div>
                    <FeedbackReviewCard
                      feedbackreviewmatching={feedbackreviewmatching}
                    />
                  </SpaceDiv>
                  {!!target.objection && (
                    <CircleBorderBox>
                      <div className={classes.red}>
                        {t(
                          'componentsOrganismsReportsFeedbackSection.objection',
                        )}
                      </div>
                      <Mgt15>{target.objection?.comment}</Mgt15>
                    </CircleBorderBox>
                  )}
                  <CustomDivider />
                </Fragment>
              );
            })}
          </div>
        ) : (
          <BasicDivFlexBothCentered>
            <span style={{ fontSize: '14px' }}>
              {t('componentsOrganismsReportsFeedbackSection.noColleague')}
            </span>
          </BasicDivFlexBothCentered>
        )}
      </QuestionCard>
    </>
  );
};

export default FeedbackSection;
