import { requests, ResponseType } from 'utils';
import { FetchReportResponseType } from './types';

export const reportFetchAPI = async (
  key: string,
  courseId: number,
  assignmentId: number,
  reportId: number,
  mode?: string,
): Promise<ResponseType<FetchReportResponseType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/reports/${reportId}/${
      mode ? `?mode=${mode}` : ''
    }`,
  );
};

export const reportPatchAPI = async (
  key: string,
  courseId: number,
  assignmentId: number,
  reportId: number,
): Promise<ResponseType<FetchReportResponseType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/reports/${reportId}/`,
  );
};

type UpdateReportCommentProps = {
  courseId: number;
  assignmentId: number;
  reportId: number;
  comment?: {
    content: string;
  };
  revisement_score?: number | null;
};

export const reportUpdateAPI = async ({
  courseId,
  assignmentId,
  reportId,
  comment,
  revisement_score,
}: UpdateReportCommentProps): Promise<
  ResponseType<UpdateReportCommentProps>
> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/reports/${reportId}/`,
    { comment, revisement_score },
  );
};
