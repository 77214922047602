import styled from 'styled-components';

const ButtonPassword = styled.button`
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 24px 7px 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 8px;
  line-height: 1.4;
  transition: 0.3;
  white-space: nowrap;
  letter-spacing: -1px;
  background: none;
  outline: 0;
  margin-top: 33px;
`;

export default ButtonPassword;
