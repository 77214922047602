import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import AssignmentDetailPresenter from './presenter';
import LoadingPage from '../../LoadingPage';
import {
  assignmentLearnerInfo,
  assignmentFetchManagement,
} from '../../../apis';

const AssignmentDetail: React.FC = () => {
  const { courseId, assignmentId } = useParams();

  const { data, isLoading } = useQuery(
    ['assignmentInfo', courseId, assignmentId],
    assignmentLearnerInfo,
  );

  const { data: learnersData } = useQuery(
    ['learnersInfo', courseId, assignmentId],
    assignmentFetchManagement,
  );

  if (!data || isLoading) {
    return <LoadingPage />;
  }

  if (!learnersData) {
    return <LoadingPage />;
  }

  return (
    <AssignmentDetailPresenter
      assignment={data.data}
      learners={learnersData.data}
    />
  );
};

export default AssignmentDetail;
