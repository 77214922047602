import React from 'react';
import { BackText, BasicDivTop100, ManageText } from 'components/atoms';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import useCourses from '../../../../hooks/courses/useCourses';

const ContainerForm: React.FC = ({ children }) => {
  const history = useHistory();
  const { currentCourse } = useCourses();
  const { t } = useTranslation();
  return (
    <BasicDivTop100>
      <BackText
        onClick={(): void => {
          history.goBack();
        }}
      >
        <ArrowBackIcon
          style={{ verticalAlign: 'middle', marginRight: '5px' }}
        />
        {t('courseResultsContainerForm.returnToTheCoursePage')}
      </BackText>
      <ManageText style={{ marginTop: '15px' }}>
        {currentCourse}{' '}
        {t(
          'courseResultsContainerForm.confirmationOfTheResultsOfTheAssignment',
        )}
      </ManageText>
      {children}
    </BasicDivTop100>
  );
};

export default ContainerForm;
