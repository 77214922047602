import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteButtonIcon = styled(DeleteIcon)`
  font-size: 20px;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.35);
`;

export default DeleteButtonIcon;
