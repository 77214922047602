import styled from 'styled-components';

const DivMid = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 24px;
  // padding-bottom: 32px;
`;

export default DivMid;
