import React, { useContext } from 'react';
import {
  BtnPurpleLg,
  ColoredSquar,
  FontSize15NavySpan,
  Form,
  QuestionCard,
} from 'components/atoms';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CourseResultContext from '../../../../pages/CourseResults/context';
import {
  patchAssignmentWeights,
  PatchWeightType,
} from '../../../../apis/assignments';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Card = styled.div`
  width: 250px;
  margin-right: 20px;
  padding: 5px 30px 10px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const Txt = styled.div`
  margin: 10px 0;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type FormType = {
  weights: PatchWeightType[];
};

const MidSectionForm: React.FC = () => {
  const { weights, refetch } = useContext(CourseResultContext);
  const { courseId } = useParams();
  const { control, handleSubmit, register, watch } = useForm<FormType>({
    defaultValues: { weights },
  });
  const { t } = useTranslation();
  watch('weights');
  const { fields } = useFieldArray({
    control,
    name: 'weights',
  });
  const onSubmit = async (formData: FormType): Promise<void> => {
    const total = formData.weights
      .map((item) => parseInt(item.weight, 10))
      .reduce((a, b) => a + b, 0);
    if (total > 100 || total < 0) {
      toast.warn(t('courseResultsMidSectionForm.totalValueError', { total }));
    } else {
      try {
        await patchAssignmentWeights({
          courseId,
          weights: formData.weights,
        });
        toast.success(t('courseResultsMidSectionForm.theWeightHasChanged'));
        refetch();
      } catch (e) {
        toast.warn(t('courseResultsMidSectionForm.thisIsTheWrongRequest'));
      }
    }
  };
  return (
    <QuestionCard>
      <FontSize15NavySpan style={{ fontSize: '18px' }}>
        {t('courseResultsMidSectionForm.scoreWeightByAssignment')}
      </FontSize15NavySpan>
      <div style={{ marginTop: '15px' }}>
        <ColoredSquar />
        {t('courseResultsMidSectionForm.desc1')}
      </div>
      <div style={{ marginTop: '8px' }}>
        <ColoredSquar />
        {t('courseResultsMidSectionForm.desc2')}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          {fields.map((item, index) => {
            return (
              <Card key={item.id}>
                <FontSize15NavySpan style={{ paddingTop: '10px' }}>
                  <span>
                    {t('app.assignment')} {index + 1}
                  </span>
                </FontSize15NavySpan>
                <Txt>{item.name}</Txt>
                <input
                  type="hidden"
                  value={item.id}
                  ref={register()}
                  name={`weights[${index}].id`}
                />
                <input
                  style={{ height: '30px' }}
                  ref={register()}
                  name={`weights[${index}].weight`}
                  type="number"
                />
              </Card>
            );
          })}
        </Container>
        <Container style={{ marginTop: '25px' }}>
          <BtnPurpleLg>
            {t('courseResultsMidSectionForm.reflection')}
          </BtnPurpleLg>
        </Container>
      </Form>
    </QuestionCard>
  );
};

export default MidSectionForm;
