import React from 'react';
import { Column } from 'material-table';
import moment from 'moment';
import { t } from 'i18next';
import { AssignmentListItemType } from '../../../apis/assignments';

export const cellStyle: React.CSSProperties = {
  textAlign: 'center',
};

export const getAssignmentColumns: () => Column<
  AssignmentListItemType
>[] = () => [
  {
    title: t('pagesManagersAssignmentsColumns.group'),
    field: 'group_name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersAssignmentsColumns.projectTitle'),
    field: 'name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('app.교수자'),
    field: 'instructor_name',
    cellStyle,
    sorting: false,
  },
  {
    title: t('pagesManagersAssignmentsColumns.task'),
    field: 'created_at',
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      if (row.created_at) {
        return moment(row.created_at).format('YYYY-MM-DD');
      }
      return t('pagesManagersAssignmentsColumns.noRecord');
    },
  },
  {
    title: t('pagesManagersAssignmentsColumns.startingDate'),
    field: 'schedule_dates.start_date',
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      if (row?.schedule_dates?.start_date) {
        return moment(row.schedule_dates.start_date).format('YYYY-MM-DD');
      }
      return t('pagesManagersAssignmentsColumns.noRecord');
    },
  },
  {
    title: t('pagesManagersAssignmentsColumns.taskTerminationDate'),
    field: 'schedule_dates.end_date',
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      if (row?.schedule_dates?.end_date) {
        return moment(row.schedule_dates.end_date).format('YYYY-MM-DD');
      }
      return t('pagesManagersAssignmentsColumns.noRecord');
    },
  },
  {
    title: t('pagesManagersAssignmentsColumns.whetherItIsCompleted'),
    field: 'is_completed',
    cellStyle,
    sorting: false,
    render: (row): string | null => {
      if (row.is_completed) {
        return 'Y';
      }
      return 'N';
    },
  },
  {
    title: t('pagesManagersAssignmentsColumns.numberOfRegisteredStudents'),
    field: 'learners_count',
    cellStyle,
    sorting: false,
  },
];
