import React, { useContext, useEffect, useState } from 'react';
import {
  BasicDivFlexBetween,
  BtnSkyblueLgOutline,
  EssayWritingBottomBarDiv,
  FileLabel,
  FontSize18Span,
} from 'components/atoms';
import { convertToRaw } from 'draft-js';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Clear';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import SwitchPerformanceContext, {
  SwitchPerformanceContextType,
} from 'pages/PerformAssignment/SwitchPage/context';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  patchLateEssay,
  updatePerformance,
  WritingEssayPerformanceType,
} from '../../../../apis';
import { ResponseType, serializeFormData } from '../../../../utils';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import { switchStage } from '../../../../pages/EditAssignment/context';
import { fileGenerateHelper, onFileCancelHelper } from './helpers';
import { STAGES } from '../../../../utils/constants';

export const OverrideFileLabel = styled(FileLabel)`
  text-decoration: none;
  margin-top: 0;
`;
export const OverrideField = styled(BtnSkyblueLgOutline).attrs({
  as: 'div',
})`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
export const FileField = styled.a`
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
`;
export const FileFieldContainer = styled.div`
  border-radius: 8px;
  border: 1px solid;
  padding: 10px;
  margin: 5px 20px 5px 0;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  overflow: auto;
  :hover {
    color: #0090ff;
  }
`;
export const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    margin-left: auto;
  }
`;
export const OCancelItem = styled(CancelIcon)`
  color: rgba(200, 90, 90, 0.6);
  :hover {
    color: rgba(200, 90, 90, 0.9);
  }
  margin-left: 15px;
`;
export const LateButton = styled(BtnSkyblueLgOutline)`
  color: rgba(252, 68, 68, 1);
  border: rgba(252, 68, 68, 1) 1px solid;
  :hover {
    color: rgba(252, 68, 68, 0.5);
    border: rgba(252, 68, 68, 0.5) 1px solid;
  }
`;
export const OrangeSpan = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: orange;
`;

const BottomBar: React.FC = () => {
  const {
    currentEditState,
    refetch,
    stage_object: { files, content },
    progress: {
      stage_object: { limit_type, max_length },
    },
    is_completed,
  } = useContext(SwitchPerformanceContext) as WritingEssayPerformanceType &
    SwitchPerformanceContextType;
  const { current_stage, is_waiting_next_stage } = useContext(
    PerformanceContext,
  );
  const [change, setChange] = useState(true);
  const [fileChange, setFileChange] = useState(false);
  const { courseId, assignmentId, performanceId, role } = useParams();
  const { url } = useRouteMatch();
  const currentText = currentEditState.getCurrentContent().getPlainText();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { t } = useTranslation();

  let currentLength = 0;
  if (currentText !== '') {
    if (limit_type === 'W') {
      currentLength = currentText.split(/\s+/).length;
    } else {
      currentLength = currentText.length;
    }
  }
  const style = max_length < currentLength ? { color: 'red' } : {};
  const history = useHistory();
  useEffect(() => {
    try {
      const originContent = JSON.parse(content);
      const currentContent = convertToRaw(currentEditState.getCurrentContent());

      if (
        JSON.stringify(originContent) === JSON.stringify(currentContent) &&
        !fileChange
      ) {
        setChange(false);
      } else {
        setChange(true);
      }
    } catch (e) {
      setChange(true);
    }
  }, [currentEditState, content, fileChange]);
  const prefix = switchStage(current_stage.stage);
  const isLate =
    (prefix !== STAGES.ESSAY && !is_completed && prefix !== STAGES.COMPLETE) ||
    (prefix === STAGES.ESSAY && is_waiting_next_stage && !is_completed);

  const onClick = async (): Promise<void> => {
    if (currentLength > max_length) {
      toast.warn(t('essayWritingBottomBar.itExceededTheMaximumEssay'));
    } else {
      const data = serializeFormData({
        stage_object: {
          content: convertToRaw(currentEditState.getCurrentContent()),
          currentLength,
        },
      });
      try {
        let response: ResponseType<WritingEssayPerformanceType>;
        if (!isLate) {
          response = (await updatePerformance({
            data,
            courseId,
            assignmentId,
            performanceId,
          })) as ResponseType<WritingEssayPerformanceType>;
        } else {
          response = (await patchLateEssay({
            data,
            courseId,
            assignmentId,
            performanceId,
          })) as ResponseType<WritingEssayPerformanceType>;
        }
        toast.success(t('essayWritingBottomBar.essayHasBeenSubmitted'));
        setFileChange(false);
        setTimeout(() => {
          if (
            response.data.stage_object.extra.question &&
            !(url in localStorage)
          ) {
            localStorage.setItem(url, '');
            // eslint-disable-next-line no-alert
            const answer = window.confirm(
              t('essayWritingBottomBar.theQuestionOfTheQuestionRemains'),
            );
            if (answer) {
              history.push(
                `/courses/${courseId}/${role}/assignments/${assignmentId}/performances/${response.data.stage_object.extra.question}`,
              );
            }
          }
        }, 1000);
        refetch();
      } catch (e) {
        toast.warn(t('essayWritingBottomBar.thisIsTheWrongRequest'));
      }
    }
  };
  const onFileCancel = (fileName: string, fileId: number) => async (): Promise<
    void
  > => {
    await onFileCancelHelper({
      courseId,
      assignmentId,
      performanceId,
      fileName,
      refetch,
      isLate,
      fileId,
    });
    setFileChange(true);
  };

  const isEditable =
    switchStage(current_stage.stage) === 'ES' && !is_waiting_next_stage;
  const renderFileBtn = (): null | React.ReactElement => {
    if (!isEditable && !isLate) {
      return null;
    }
    return (
      <OverrideFileLabel>
        <input
          type="file"
          name="files"
          multiple
          accept="application/pdf,.mp4"
          style={{ display: 'none' }}
          onChange={async (e): Promise<void> => {
            await fileGenerateHelper({
              courseId,
              assignmentId,
              performanceId,
              refetch,
              isLate,
            })(e);
            setFileChange(true);
          }}
        />
        <OverrideField>
          <AttachFileIcon />
          {t('essayWritingBottomBar.pdfMp4FileUpload')}
        </OverrideField>
      </OverrideFileLabel>
    );
  };
  return (
    <fieldset disabled={!isEditable && !isLate}>
      <EssayWritingBottomBarDiv>
        <BasicDivFlexBetween>
          <div>
            <FontSize18Span style={style}>{currentLength}</FontSize18Span>
            <span> / </span>
            <span>{max_length}</span>
            {limit_type === 'L' && t('essayWritingBottomBar.letter')}
            {limit_type === 'W' && t('essayWritingBottomBar.word')}
          </div>
          {!isEditable && !isLate && (
            <div>
              {isDesktop ? (
                <OrangeSpan>
                  {t('essayWritingBottomBar.youCanOnlyModifyTheEssayPeriod')}
                </OrangeSpan>
              ) : (
                <OrangeSpan style={{ fontSize: '13px' }}>
                  {t('essayWritingBottomBar.youCanOnlyModifyTheEssayPeriod')}
                </OrangeSpan>
              )}
            </div>
          )}
          <ActionContainer>
            {files.length > 0 &&
              files.map((file) => {
                const fileArray = file.file.split('/');
                const name = decodeURI(fileArray[fileArray.length - 1]);
                return (
                  <FileFieldContainer key={file.file}>
                    <AttachFileIcon />
                    <FileField download href={file.file} target="_blank">
                      {name}
                    </FileField>
                    {(isEditable || isLate) && (
                      <OCancelItem
                        onClick={onFileCancel(name, file?.id || 0)}
                      />
                    )}
                  </FileFieldContainer>
                );
              })}
            {renderFileBtn()}
            {isLate ? (
              <LateButton
                style={{ padding: '10px 50px' }}
                onClick={onClick}
                disabled={!change}
                type="button"
              >
                {change
                  ? t('essayWritingBottomBar.delayedSubmission')
                  : t('essayWritingBottomBar.delayedSubmissionIsStored')}
              </LateButton>
            ) : (
              <BtnSkyblueLgOutline
                style={{ padding: '10px 50px' }}
                onClick={onClick}
                disabled={!change}
                type="button"
              >
                {change
                  ? t('essayWritingBottomBar.saveAndSubmission')
                  : t('essayWritingBottomBar.stored')}
              </BtnSkyblueLgOutline>
            )}
          </ActionContainer>
        </BasicDivFlexBetween>
      </EssayWritingBottomBarDiv>
    </fieldset>
  );
};

export default BottomBar;
