import React from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { EssayWritingBottomBarDiv } from 'components/atoms';
import { t } from 'i18next';
import { CourseFileType } from '../apis';
import {
  DownloadAnchor,
  DownloadText,
} from '../components/organisms/courseEditing/ContentsForm/styles';
import {
  FileField,
  FileFieldContainer,
} from '../components/organisms/essayWriting/BottomBar';

const renderFiles = (
  files: CourseFileType[] | undefined,
  parsedFiles: string[],
): string | React.ReactNode | null => {
  if (files && files.length > 0) {
    return files.map((file) => {
      const fileArray = file.file.split('/');
      return (
        <DownloadAnchor
          download
          key={file.file}
          href={file.file}
          target="_blank"
        >
          <AttachmentIcon />
          <DownloadText>
            {decodeURI(fileArray[fileArray.length - 1])}
          </DownloadText>
        </DownloadAnchor>
      );
    });
  }
  if (parsedFiles.length < 1) {
    return t('utilsRenderFiles.doesntExist');
  }
  return null;
};

export type RenderFilesWithFileContainerProps = {
  files: CourseFileType[];
  isPeerReview?: boolean;
};

export const renderFilesWithFileFieldContainer = ({
  files,
  isPeerReview,
}: RenderFilesWithFileContainerProps): React.ReactNode => {
  return files.map((file) => {
    const fileArray = file.file.split('/');
    const name = decodeURI(fileArray[fileArray.length - 1]);
    return (
      <div style={{ width: '100%' }} key={file.file}>
        <EssayWritingBottomBarDiv>
          <FileFieldContainer>
            <FileField download href={file.file} target="_blank">
              {isPeerReview && `${t('utilsRenderFiles.viewFileOriginal')} : `}
              {!isPeerReview && <AttachFileIcon />}
              {name}
            </FileField>
          </FileFieldContainer>
        </EssayWritingBottomBarDiv>
      </div>
    );
  });
};

export default renderFiles;
