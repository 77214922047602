import React, { useContext, Fragment } from 'react';
import {
  AssignmentInfoDiv,
  AssignmentInfoTitle,
  CardBorderedDiv,
} from 'components/atoms';
import styled from 'styled-components';
import MainContext from 'pages/PerformAssignment/PerformanceMain/context';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const FlexDiv = styled.div`
  display: flex;
  border-bottom: 1px solid #ececec;
  padding: 10px 0;
`;

const TxtStatus = styled.div`
  width: 150px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 400;
`;

const TxtGray = styled.div`
  margin-right: 15px;
  color: #ccc;
  font-weight: 300;
`;

const TxtBlack = styled.div`
  margin-right: 20px;
  color: black;
  font-weight: 300;
`;

const TimelineForm: React.FC = () => {
  const { progresses } = useContext(MainContext);
  const isInQuestionWriting = !!progresses.find(
    (progress) => progress.stage === '질문작성',
  );
  const isInRevisement = !!progresses.find(
    (progress) => progress.stage === '퇴고',
  );

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { t } = useTranslation();

  return (
    <AssignmentInfoDiv>
      <CardBorderedDiv>
        <AssignmentInfoTitle>
          {t('assignmentInfoTimelineForm.timeline')}
        </AssignmentInfoTitle>
        {progresses
          .filter(
            (progress) =>
              !['진행대기중', '질문작성', '퇴고', '완료'].includes(
                progress.stage || '',
              ),
          )
          .map((timeline) => {
            return (
              <Fragment key={timeline.stage}>
                {isDesktop ? (
                  <FlexDiv key={timeline.stage}>
                    <TxtStatus>
                      {t(`app.${timeline.stage}`)}
                      {timeline.stage === '에세이작성' &&
                        isInQuestionWriting &&
                        ` / ${t('app.질문작성')}`}
                      {timeline.stage === '피드백평가' &&
                        isInRevisement &&
                        ` / ${t('app.퇴고')}`}
                    </TxtStatus>
                    <TxtGray>
                      {t('assignmentInfoTimelineForm.startDate')}
                    </TxtGray>
                    <TxtBlack>
                      {timeline.start_date
                        ? moment(timeline.start_date).format('YYYY-MM-DD HH:mm')
                        : t(
                            'assignmentInfoTimelineForm.thereIsNoInformationEntered',
                          )}
                    </TxtBlack>
                    <TxtGray>{t('assignmentInfoTimelineForm.endDate')}</TxtGray>
                    <TxtBlack>
                      {timeline.end_date
                        ? moment(timeline.end_date).format('YYYY-MM-DD HH:mm')
                        : t(
                            'assignmentInfoTimelineForm.thereIsNoInformationEntered',
                          )}
                    </TxtBlack>
                  </FlexDiv>
                ) : (
                  <FlexDiv style={{ display: 'block' }} key={timeline.stage}>
                    <TxtStatus style={{ marginBottom: '0.5rem' }}>
                      {timeline.stage}
                      {timeline.stage === '에세이작성' &&
                        isInQuestionWriting &&
                        ' / 질문작성'}
                      {timeline.stage === '피드백평가' &&
                        isInRevisement &&
                        ' / 퇴고'}
                    </TxtStatus>
                    <div style={{ display: 'flex' }}>
                      <TxtGray>
                        {t('assignmentInfoTimelineForm.startDate')}
                      </TxtGray>
                      <TxtBlack>
                        {timeline.start_date
                          ? moment(timeline.start_date).format(
                              'YYYY-MM-DD HH:mm',
                            )
                          : t(
                              'assignmentInfoTimelineForm.thereIsNoInformationEntered',
                            )}
                      </TxtBlack>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <TxtGray>
                        {t('assignmentInfoTimelineForm.endDate')}
                      </TxtGray>
                      <TxtBlack>
                        {timeline.end_date
                          ? moment(timeline.end_date).format('YYYY-MM-DD HH:mm')
                          : t(
                              'assignmentInfoTimelineForm.thereIsNoInformationEntered',
                            )}
                      </TxtBlack>
                    </div>
                  </FlexDiv>
                )}
              </Fragment>
            );
          })}
      </CardBorderedDiv>
    </AssignmentInfoDiv>
  );
};

export default TimelineForm;
