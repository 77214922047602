import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import UserDetailPresenter from './presenter';
import { fetchUserDetail } from '../../../apis/users';
import LoadingPage from '../../LoadingPage';

const UserDetail: React.FC = () => {
  const { userId } = useParams();
  const { data, isLoading } = useQuery([userId], fetchUserDetail);

  if (!data || isLoading) {
    return <LoadingPage />;
  }

  return <UserDetailPresenter user={data.data} />;
};

export default UserDetail;
