import React, { useContext } from 'react';
import {
  AssignmentInfoDiv,
  CardBorderedDiv,
  AssignmentInfoTitle,
  AssignmentInfoContent,
} from 'components/atoms';
import MainContext from 'pages/PerformAssignment/PerformanceMain/context';
import { useTranslation } from 'react-i18next';

const DescriptionForm: React.FC = () => {
  const { description } = useContext(MainContext);
  const { t } = useTranslation();

  return (
    <AssignmentInfoDiv style={{ marginTop: '40px' }}>
      <CardBorderedDiv>
        <AssignmentInfoTitle>
          {t('assignmentInfoDescriptionForm.assignment')}
        </AssignmentInfoTitle>
        <AssignmentInfoContent>{description}</AssignmentInfoContent>
      </CardBorderedDiv>
    </AssignmentInfoDiv>
  );
};

export default DescriptionForm;
