import React, { useContext } from 'react';
import {
  AddButton,
  DeleteButtonIcon,
  MobileOnlyOptionLabel,
  PeerReviewOptionInput,
  PeerReviewOptionSelect,
  PeerReviewOptionTable,
  PeerReviewOptionThead,
  PeerReviewTd,
  PeerReviewTh,
  StageContainer,
  StageOptionLabel,
  StageTitle,
} from 'components/atoms/newAssignmnets';
import AddIcon from '@material-ui/icons/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NoneStyleButton } from '../QuestionOption';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

const COUNTS = Array.from(Array(6), (_, i) => i + 1);
const SCALES = Array.from(Array(8), (_, i) => i + 3);

const PeerReviewOption: React.FC = () => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'standards',
  });
  const { t } = useTranslation();
  const { currentStage } = useContext(IsEditableContext);
  const currentIndex = stageIndex.indexOf(currentStage);
  const handleClick = (): void => {
    append({ name: 'standards' });
  };
  const onDeleteScale = (scaleId: number) => (): void => {
    remove(scaleId);
  };
  return (
    <StageContainer>
      <StageTitle>{t('newAssignmentsPeerReviewOption.colleague')}</StageTitle>
      <StageOptionLabel>
        {t('newAssignmentsPeerReviewOption.numberOfColleaguesEvaluators')}
      </StageOptionLabel>
      <PeerReviewOptionSelect ref={register} name="peerReviewCount">
        {COUNTS.map((count) => {
          return (
            <option value={count} key={count}>
              {count}
            </option>
          );
        })}
      </PeerReviewOptionSelect>
      <MobileOnlyOptionLabel>
        {t('newAssignmentsPeerReviewOption.fellowEvaluationScalePerfectScore')}
      </MobileOnlyOptionLabel>
      <PeerReviewOptionTable>
        <PeerReviewOptionThead>
          <tr>
            <PeerReviewTh>
              <StageOptionLabel>
                {t('newAssignmentsPeerReviewOption.colleagueScale')}
              </StageOptionLabel>
            </PeerReviewTh>
            <PeerReviewTh>
              {t('newAssignmentsPeerReviewOption.score')}
            </PeerReviewTh>
          </tr>
        </PeerReviewOptionThead>
        <tbody>
          {fields.map((item, index) => {
            return (
              <tr key={item.id}>
                <PeerReviewTd>
                  <PeerReviewOptionInput
                    ref={register()}
                    required
                    maxLength={100}
                    name={`standards[${index}].question`}
                    placeholder={t(
                      'newAssignmentsPeerReviewOption.exHowCreativeIsIt',
                      'ex) 얼마나 창의적인 글인가?',
                    )}
                  />
                </PeerReviewTd>
                <PeerReviewTd>
                  <PeerReviewOptionSelect
                    required
                    ref={register()}
                    name={`standards[${index}].max_point`}
                  >
                    {SCALES.map((count) => {
                      return (
                        <option value={count} key={count}>
                          {count}
                        </option>
                      );
                    })}
                  </PeerReviewOptionSelect>
                  <NoneStyleButton
                    disabled={currentIndex > 2}
                    style={{ fontSize: 25 }}
                    onClick={onDeleteScale(index)}
                  >
                    <DeleteButtonIcon />
                  </NoneStyleButton>
                </PeerReviewTd>
              </tr>
            );
          })}
        </tbody>
      </PeerReviewOptionTable>
      <AddButton onClick={handleClick}>
        <AddIcon style={{ fontSize: 25 }} />
        {t('newAssignmentsPeerReviewOption.addTheEvaluationScale')}
      </AddButton>
    </StageContainer>
  );
};

export default PeerReviewOption;
