import React, { useContext } from 'react';
import {
  CommonDiv,
  FontSize15P,
  FontSize18Div,
  ScoreContainer,
  ScoreInput,
  ScoreLabel,
  ScoreRow,
  Section,
} from 'components/atoms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ScoreContext from '../../../../pages/Score/context';
import {
  CustomTooltip,
  WEIGHT_STANDARD,
} from '../../../molecules/newAssignments/ScoreWeight';

type DISPLAY_MAP_TYPE = {
  [key: string]: string;
};

export const get_display_map: () => DISPLAY_MAP_TYPE = () => ({
  ES: i18next.t('scoresTopForm.writing') ?? '',
  PE: i18next.t('scoresTopForm.evaluationAbility') ?? '',
  ME: i18next.t('scoresTopForm.metaCognitive') ?? '',
  FE: i18next.t('app.피드백평가') ?? '',
  RE: i18next.t('app.퇴고') ?? '',
});

const CustomScoreRow = styled(ScoreRow)`
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const TopForm: React.FC = () => {
  const { scores } = useContext(ScoreContext);
  const { t } = useTranslation();
  const standardScore = scores.find((score) => score.standards);
  const standards = standardScore?.standards || [];
  return (
    <CommonDiv>
      <Section>
        <FontSize18Div>{t('scoresTopForm.evaluationStandard')}</FontSize18Div>
        {standards.map((standard) => {
          return (
            <FontSize15P key={standard.id}>{standard.question}</FontSize15P>
          );
        })}
        <FontSize18Div style={{ paddingTop: '15px', marginBottom: '5px' }}>
          {t('scoresTopForm.evaluation')}
        </FontSize18Div>
        <CustomScoreRow>
          {scores.map((score) => {
            const { type, max_score } = score;
            const prefix = type.slice(0, 2).toUpperCase();
            return (
              <ScoreContainer key={type}>
                <CustomTooltip
                  title={
                    t(WEIGHT_STANDARD[prefix].desc, { weight: max_score }) ?? ''
                  }
                  placement="top"
                >
                  <ScoreLabel>{t(WEIGHT_STANDARD[prefix].label)}</ScoreLabel>
                </CustomTooltip>
                <ScoreInput defaultValue={max_score} disabled />
              </ScoreContainer>
            );
          })}
        </CustomScoreRow>
      </Section>
    </CommonDiv>
  );
};

export default TopForm;
