import React from 'react';
import Help from 'templates/Help';
import TabForm from '../../components/organisms/helps/TabForm';
import TitleForm from '../../components/organisms/helps/TitleForm';
import ContainerForm from '../../components/organisms/helps/ContainerForm';

const HelpPage: React.FC = () => {
  return <Help Container={ContainerForm} Tab={TabForm} Title={TitleForm} />;
};

export default HelpPage;
