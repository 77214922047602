import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import logo from 'assets/images/logo-typo.svg';
import useSettingActions from 'hooks/settings/useSettingActions';
import useSettings from 'hooks/settings/useSettings';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  SideTop,
  SideTopLogo,
  SideTopToggleMenu,
  MiniItem,
} from '../../../atoms/mains';
import useAuthenticationActions from '../../../../hooks/authentication/useAuthenticationActions';

const MenuSideTop: React.FC = () => {
  const { dispatchToggleSidebar } = useSettingActions();
  const { isWideSidebar } = useSettings();
  const history = useHistory();
  const { dispatchSignOut } = useAuthenticationActions();

  const icon = { fontSize: '28px' };
  const ht90 = { height: '90%' };
  const ht10 = { height: '10%' };

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { t } = useTranslation();

  return (
    <>
      <SideTop>
        {isWideSidebar && <SideTopLogo src={logo} alt="logo" />}
        <SideTopToggleMenu onClick={dispatchToggleSidebar}>
          {isWideSidebar ? <ArrowBackIos /> : <ArrowForwardIosIcon />}
        </SideTopToggleMenu>
      </SideTop>

      {isDesktop && !isWideSidebar && (
        <>
          <div style={ht90}>
            <MiniItem onClick={(): void => history.push('/my-page')}>
              <PersonIcon style={icon} />
            </MiniItem>
            <MiniItem onClick={(): void => history.push('/')}>
              <HomeIcon style={icon} />
            </MiniItem>
          </div>
          <div style={ht10}>
            <MiniItem onClick={dispatchSignOut}>
              <Tooltip
                placement="top"
                title={t('menuSideTop.logOut') ?? '로그아웃'}
              >
                <ExitToAppIcon style={icon} />
              </Tooltip>
            </MiniItem>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(MenuSideTop);
