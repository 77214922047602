import React from 'react';
import { CommentSectionFormProps } from '../../components/organisms/posts/CommentSectionForm';

interface PostProps {
  Container: React.FC;
  PostSection: React.FC<CommentSectionFormProps>;
  CommentSection: React.FC<CommentSectionFormProps>;
  VoteSection: React.FC<CommentSectionFormProps>;
  post?: CommentSectionFormProps;
}

const Post: React.FC<PostProps> = ({
  Container,
  PostSection,
  CommentSection,
  post,
}) => {
  return (
    <Container>
      <PostSection {...post} />
      <CommentSection {...post} />
    </Container>
  );
};

export default Post;
