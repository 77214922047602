import { requests, ResponseType } from 'utils';
import { AssignmentResponseType } from './assignments';

export type CourseType = {
  id: number;
  name: string;
  is_instructor: boolean;
  assignments: AssignmentResponseType[];
};
export type DashboardResponse = CourseType[];

const dashboardAPI = (): Promise<ResponseType<DashboardResponse>> => {
  return requests().get('/api/v1/users/dashboard/');
};

export default dashboardAPI;
