import styled from 'styled-components';

const Field = styled.input`
  @media (min-width: 768px) {
    background: transparent;
  }
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #828b9f;
  font-size: 18px;
  padding: 10px 8px;
  font-weight: 300;
  ::placeholder {
    color: #bebebf;
    font-weight: 300;
  }
`;

export default Field;
