import React, { useContext } from 'react';
import {
  BasicDivFlexBetween,
  ButtonCancel,
  ButtonDelete,
  ButtonSave,
  CommonDiv,
  Field,
  FieldWrapper,
  FileLabel,
  Form,
} from 'components/atoms';
import Divider from '@material-ui/core/Divider';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import CourseContext from 'pages/CourseEditing/context';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { toast } from 'react-toastify';
import { serializeFormData } from 'utils';
import { courseDeleteAPI, courseUpdateAPI } from 'apis';
import useProfileActions from 'hooks/profiles/useProfileActions';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import renderFiles from '../../../../utils/renderFiles';
import { Textarea, useStyles } from './styles';
import { parseFiles } from '../../../molecules/newAssignments/DefaultInfo';

type FormData = {
  name: string;
  description: string;
  files: FileList;
  is_open: boolean;
};

const CustomButtonCancel = styled(ButtonCancel)`
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
`;

export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      display: 'flex',
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      color: '#fff',
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#2f64de',
          borderColor: '#2f64de',
        },
      },
    },
    thumb: {
      width: 24,
      height: 24,
      boxShadow: 'none',
    },
    track: {
      border: 'rgba(0, 0, 0, 0.2)',
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    checked: {},
  }),
)(Switch);

const ContentsForm: React.FC = () => {
  const classes = useStyles();
  const { name, description, instructor, code, files, is_open } = useContext(
    CourseContext,
  );
  const { courseId, role } = useParams();
  const { dispatchFetchProfile } = useProfileActions();
  const history = useHistory();
  const { register, handleSubmit, watch, getValues, control } = useForm<
    FormData
  >({
    defaultValues: {
      name,
      description,
      is_open,
    },
  });
  const { t } = useTranslation();

  watch('files');
  const onSubmit = async (form: FormData): Promise<void> => {
    try {
      const formData = serializeFormData(form);
      await courseUpdateAPI({ formData, courseId });
      dispatchFetchProfile();
      toast.success(t('courseEditingContentsForm.success'));
      history.goBack();
    } catch (e) {
      toast.warn(t('courseEditingContentsForm.thisIsTheWrongRequest'));
    }
  };
  const newFiles = getValues('files');
  const parsedFiles = parseFiles(newFiles);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper>
        <h6 className={classes.thTitle}>
          {t('courseEditingContentsForm.lectureName')}
        </h6>
        <Field name="name" ref={register} className={classes.input} />
      </FieldWrapper>
      <FieldWrapper>
        <h6 className={classes.thTitle}>
          {t('courseEditingContentsForm.lectures')}
        </h6>
        <Textarea rows={10} name="description" ref={register} />
      </FieldWrapper>
      <CommonDiv style={{ marginTop: '32px' }}>
        <table>
          <tbody>
            <tr>
              <th className={classes.th}>
                {t('courseEditingContentsForm.teachingInformation')}
              </th>
              <td className={classes.tdColor}>
                <span className={classes.tdName}>{instructor.name}</span>
                <span>{instructor.email}</span>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>
                {t('courseEditingContentsForm.newStudentRegistration')}
              </th>

              <td className={classes.tdBlack}>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      {t('courseEditingContentsForm.impossible')}
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        as={AntSwitch}
                        name="is_open"
                      />
                    </Grid>
                    <Grid item>{t('courseEditingContentsForm.possible')}</Grid>
                  </Grid>
                </Typography>
              </td>
            </tr>
            <tr>
              <th className={classes.th}>
                {t('courseEditingContentsForm.lectureCode')}
              </th>
              <td className={classes.tdBlack}>
                <span className={classes.codeBtn}>{code}</span>
              </td>
            </tr>
            <tr>
              <th className={classes.th2}>
                {t('courseEditingContentsForm.lecturePlan')}
              </th>
              <td className={classes.tdBlack}>
                {renderFiles(files, parsedFiles)}
                <FileLabel
                  style={{ verticalAlign: 'middle', marginLeft: '10px' }}
                >
                  <AttachmentIcon />
                  <span>
                    {parsedFiles.length < 1
                      ? `${
                          files && files.length > 0
                            ? t('courseEditingContentsForm.fileChange')
                            : t('courseEditingContentsForm.addFile')
                        }`
                      : parsedFiles}
                  </span>
                  <input
                    type="file"
                    ref={register}
                    name="files"
                    style={{ display: 'none' }}
                  />
                </FileLabel>
              </td>
            </tr>
          </tbody>
        </table>
      </CommonDiv>
      <Divider />
      <BasicDivFlexBetween className={classes.paddingT20}>
        {role === 'instructor' && (
          <ButtonDelete
            type="button"
            onClick={async (): Promise<void> => {
              // eslint-disable-next-line no-alert
              const response = window.confirm(
                t('courseEditingContentsForm.deleteWarning'),
              );
              if (response) {
                try {
                  await courseDeleteAPI({ courseId });
                  toast.success(
                    t('courseEditingContentsForm.theCourseHasBeenDeleted'),
                  );
                  history.push('/');
                  dispatchFetchProfile();
                } catch (e) {
                  toast.warn(
                    t('courseEditingContentsForm.thisIsTheWrongRequest'),
                  );
                }
              }
            }}
          >
            {t('courseEditingContentsForm.deleteCourses')}
          </ButtonDelete>
        )}

        <div>
          <CustomButtonCancel
            type="button"
            onClick={(): void => history.goBack()}
          >
            {t('courseEditingContentsForm.cancellation')}
          </CustomButtonCancel>
          <ButtonSave type="submit">
            {t('courseEditingContentsForm.save')}
          </ButtonSave>
        </div>
      </BasicDivFlexBetween>
    </Form>
  );
};

export default React.memo(ContentsForm);
