import { Grid, Typography } from '@material-ui/core';
import { Section, SectionLabelNormal } from 'components/atoms';
import { AntSwitch } from 'components/organisms/courseEditing/ContentsForm';
import { AssignmentFormData } from 'components/organisms/newAssignmnets/NAForm/serializeAssignment';
import { t } from 'i18next';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const PublicOptionSetting = () => {
  const { control } = useFormContext<AssignmentFormData>();
  return (
    <Section>
      <SectionLabelNormal style={{ marginBottom: '10px' }}>
        {t('newAssignmentsScheduling.publicSetting')}
      </SectionLabelNormal>
      <Typography component="p" variant="caption" color="textSecondary">
        {t('newAssignmentsScheduling.publicSettingCaption')}
      </Typography>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{t('newAssignmentsScheduling.publicSettingOff')}</Grid>
          <Grid item>
            <Controller
              control={control}
              as={AntSwitch}
              name="isPublic"
              defaultChecked
            />
          </Grid>
          <Grid item>{t('newAssignmentsScheduling.publicSettingOn')}</Grid>
        </Grid>
      </Typography>
    </Section>
  );
};

export default PublicOptionSetting;
