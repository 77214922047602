import styled from 'styled-components';

const TitleMedium = styled.h4`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 300;
  color: #173c51;
`;

export default TitleMedium;
