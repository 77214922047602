import styled from 'styled-components';

const PeerReviewTh = styled.th`
  @media (min-width: 768px) {
    &:first-child {
      width: 70%;
    }
    padding: 2px 0;
    text-align: left;
    &:last-child {
      width: 30%;
    }
  }
  font-weight: 400;
`;

export default PeerReviewTh;
