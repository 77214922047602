import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BtnPurpleLgOutline, QuestionCard } from 'components/atoms';
import styled from 'styled-components';
import ReportContext from 'pages/Report/context';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

const Div = styled.div`
  padding: 10px 10px 15px 20px;
`;
const Btn = styled(BtnPurpleLgOutline)`
  margin-right: 10px;
  margin-top: 5px;
`;
const Heading = styled.div`
  font-size: 15px;
  color: rgba(23, 60, 81, 0.5);
`;

const OthersSection: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    url,
    params: { role },
  } = useRouteMatch();
  const {
    is_my_report,
    peer_review_performance: { targets },
  } = useContext(ReportContext);
  const otherTargets = targets.filter(
    (target) => target.reviewer !== target.target,
  );
  const urlParts = url.split('/');
  const reportListUrl = urlParts.slice(0, urlParts.length - 1).join('/');

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading>
            {t('componentsOrganismsReportsMobileOthersSection.others')}
          </Heading>
        </AccordionSummary>
        <AccordionDetails style={{ width: '100%', display: 'block' }}>
          {is_my_report && role === 'learner' && otherTargets.length > 0 && (
            <QuestionCard>
              <Div>
                {otherTargets.map((other) => {
                  return (
                    <Btn
                      key={other.id}
                      onClick={(): void => {
                        history.push(`${reportListUrl}/${other.target_report}`);
                      }}
                    >
                      {other.target_name}
                    </Btn>
                  );
                })}
              </Div>
            </QuestionCard>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OthersSection;
