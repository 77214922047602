import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const serverUri = process.env.REACT_APP_LOCALHOST || '';

export const requests = (): AxiosInstance => {
  const csrftoken = Cookies.get('csrftoken');
  const token = localStorage.getItem('token');
  const authorizationHeaders = token ? { Authorization: `token ${token}` } : {};
  const instance = axios.create({
    baseURL: serverUri,
    headers: {
      ...axios.defaults.headers,
      'X-CSRFToken': csrftoken,
      ...authorizationHeaders,
    },
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 413) {
        toast.warn(t('utilsRequests.itExceededTheMaximumUploadCapacityOf20MB'));
      }
      throw error;
    },
  );
  return instance;
};

export type ResponseType<T> = {
  data: T;
};
