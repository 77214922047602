import styled from 'styled-components';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const DateTimeField = styled(KeyboardDateTimePicker)`
  background: rgba(6, 88, 169, 0.2);
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInputBase-input {
    width: 160px;
    height: 25px;
    padding: 5px 10px;
    text-align: center;
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }
`;

export default DateTimeField;
