import PerfectScrollbar from 'react-perfect-scrollbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { useEffect } from 'react';
import useAuthenticationActions from 'hooks/authentication/useAuthenticationActions';
import useSettings from 'hooks/settings/useSettings';
import { useMediaQuery } from 'react-responsive';
import useSettingActions from 'hooks/settings/useSettingActions';
import { useTranslation } from 'react-i18next';
import MenuSideTop from '../../../molecules/mains/MenuSideTop';
import MenuUser from '../../../molecules/mains/MenuUser';
import { SideHr, SideMenu, SignOut } from '../../../atoms/mains';
import MainMenu from '../MainMenu';

const MainSide: React.FC = () => {
  const iconStyle = { marginRight: 5 };
  const { dispatchSignOut } = useAuthenticationActions();
  const { dispatchToggleSidebar } = useSettingActions();
  const { isWideSidebar, sideRole } = useSettings();

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  useEffect(() => {
    if (!isDesktop) {
      dispatchToggleSidebar();
    }
  }, [isDesktop, dispatchToggleSidebar]);

  const { t } = useTranslation();

  return (
    <SideMenu isWideSidebar={isWideSidebar} sideRole={sideRole}>
      <MenuSideTop />
      {isWideSidebar && (
        <>
          <MenuUser />
          <SideHr />
          <PerfectScrollbar>
            <MainMenu />
            <SignOut onClick={dispatchSignOut}>
              <ExitToAppIcon style={iconStyle} />
              {t('mainSide.logOut')}
            </SignOut>
          </PerfectScrollbar>
        </>
      )}
    </SideMenu>
  );
};

export default React.memo(MainSide);
