import React, { useContext, useEffect } from 'react';
import QuestionsWriting from 'templates/QuestionsWriting';
import ContainerForm from 'components/organisms/questionsWriting/ContainerForm';
import WhiteBackCardForm from 'components/organisms/questionsWriting/WhiteBackCardForm/';
import { useTranslation } from 'react-i18next';
import PerformanceContext from '../PerformanceMain/context';

const QuestionsWritingPage: React.FC = () => {
  const { setPageTitle } = useContext(PerformanceContext);
  const { t } = useTranslation();
  useEffect(() => {
    setPageTitle(t('app.질문작성'));
  }, [setPageTitle, t]);
  return (
    <QuestionsWriting
      Container={ContainerForm}
      WhiteBackCard={WhiteBackCardForm}
    />
  );
};

export default QuestionsWritingPage;
