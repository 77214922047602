import { createAction } from 'typesafe-actions';

export enum PROFILES {
  FETCH_PROFILE = 'profiles/FETCH_PROFILE',
  FETCH_PROFILE_SUCCESS = 'profiles/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE = 'profiles/FETCH_PROFILE_FAILURE',
  FETCH_STANDARD_SCORES = 'profiles/FETCH_STANDARD_SCORES',
  FETCH_STANDARD_SCORES_SUCCESS = 'profiles/FETCH_STANDARD_SCORES_SUCCESS',
  FETCH_STANDARD_SCORES_FAILURE = 'profiles/FETCH_STANDARD_SCORES_FAILURE',
}

export const fetchProfile = createAction(PROFILES.FETCH_PROFILE)();
export const fetchStandardScores = createAction(
  PROFILES.FETCH_STANDARD_SCORES,
)();
