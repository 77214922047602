import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  th: {
    color: '#173c51',
    height: '55px',
    paddingRight: '30px',
    fontSize: '15px',
    textAlign: 'left',
  },
  th2: {
    color: '#173c51',
    paddingRight: '30px',
    fontSize: '15px',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  thTitle: {
    color: '#173c51',
    fontSize: '15px',
    marginTop: '30px',
  },
  tdColor: {
    color: '#5646ff',
    fontSize: '15px',
  },
  tdBlack: {
    color: '#173c51',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  tdName: {
    fontWeight: 'bold',
    paddingRight: '5px',
  },
  codeBtn: {
    fontSize: '18px',
    padding: '5px 14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '16px',
    borderColor: '#5646ff',
    color: '#5646ff',
  },
  paddingR100: {
    paddingRight: '100px',
  },
  paddingT20: {
    paddingTop: '20px',
  },
  paddingBoth: {
    padding: '0 10px',
    fontWeight: 400,
  },
  editText: { textDecoration: 'underline', cursor: 'pointer' },
  outlineIcon: {
    verticalAlign: 'middle',
    paddingRight: '5px',
    fontSize: '24px',
  },
  peopleIcon: {
    color: '#5646ff',
    fontSize: '30px',
    paddingRight: '5px',
  },
  input: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '20px 0 10px 0',
  },
  chk: {
    verticalAlign: 'middle',
    width: '20px',
    height: '20px',
  },
}));

export const DownloadAnchor = styled.a`
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid rgba(23, 60, 81, 0.5);
  padding: 7px 10px 7px 10px;
  display: inline-flex;
  align-items: center;
  color: unset;
  text-decoration: none;
  font-size: 15px;
  margin-right: 10px;
  &:last-child {
    margin-right: inherit;
  }
`;

export const DownloadText = styled.span`
  margin-right: 14px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  font-weight: 300;
  margin-top: 10px;
  font-size: 15px;
  ::placeholder {
    color: #bebebf;
    font-weight: 300;
  }
  min-height: 120px;
`;
