import { requests, ResponseType } from 'utils';

export type LearnerStatusChangeType = {
  is_assistant?: boolean;
  is_active?: boolean;
};
export type LearnerStatusUpdateAPIProps = {
  enrollId: number;
} & LearnerStatusChangeType;

export type LearnerStatusChangeMutateType = {
  is_assistant: boolean;
  is_active: boolean;
};
export type CreateEnrollAPIProps = {
  course_code: string;
  code: string;
};
export type EnrollResponseType = {
  id: number;
  course: number;
  code: number;
};
export const learnerStatusUpdateAPI = async ({
  is_assistant,
  is_active,
  enrollId,
}: LearnerStatusUpdateAPIProps): Promise<
  ResponseType<LearnerStatusChangeMutateType>
> => {
  return requests().patch(`/api/v1/enrolls/${enrollId}/enroll-change/`, {
    is_assistant,
    is_active,
  });
};

export const createEnrollAPI = async ({
  code,
  course_code,
}: CreateEnrollAPIProps): Promise<ResponseType<EnrollResponseType>> => {
  return requests().post(`/api/v1/enrolls/`, {
    code,
    course_code,
  });
};

type ReportScoreType = {
  essay_writing_score: number;
  peer_review_score: number;
  metacognition_score: number;
  feedback_review_score: number;
  revisement_score: number;
  total: number;
};

type AssignmentScoreType = {
  id: number;
  name: string;
  individual_score: ReportScoreType;
  all_score: ReportScoreType;
  report: number;
};

export type PersonalScoreResponse = {
  id: number;
  name: string;
  assignments: AssignmentScoreType[];
};

export const personalScoreFetchAPI = async (
  key: string,
  courseId: number,
  enrollId: number,
): Promise<ResponseType<PersonalScoreResponse>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/enrolls/${enrollId}/personal-score`,
  );
};
