import styled from 'styled-components';

const BtnPurpleLg = styled.button`
  background: #5646ff;
  color: #fff;
  border: 1px solid #5646ff;
  display: inline-block;
  padding: 8px 24px 7px 24px;
  line-height: 1.4;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s;
  text-align: center;
  border-radius: 8px;
  outline: 0;
  &:disabled {
    background: rgba(86, 70, 255, 0.5);
    border: rgba(86, 70, 255, 0.5);
    cursor: not-allowed;
  }
`;

export default BtnPurpleLg;
