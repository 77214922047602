import { all } from 'redux-saga/effects';
import authenticationSaga from './authentication.saga';
import profileSaga from './profile.saga';
import coursesSaga from './courses.saga';
import loginCheckSaga from './loginCheck.saga';
import dashboardSaga from './dashboard.saga';

export default function* root(): Generator {
  yield all([
    authenticationSaga(),
    profileSaga(),
    coursesSaga(),
    loginCheckSaga(),
    dashboardSaga(),
  ]);
}
