import styled from 'styled-components';

const MiddleDash = styled.div`
  content: ' ';
  display: inline-block;
  width: 14px;
  height: 1px;
  margin-right: 20px;
  margin-left: 20px;
  background: rgba(0, 0, 0, 0.5);
`;

export default MiddleDash;
