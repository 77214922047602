import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import ScoreProgress from 'templates/ScoreProgress';
import ContainerForm from 'components/organisms/scoreProgress/ContainerForm';
import TopGraphForm from 'components/organisms/scoreProgress/TopGraphForm/';
import BottomTableForm from 'components/organisms/scoreProgress/BottomTableForm/';
import { personalScoreFetchAPI } from 'apis';
import { RoleContext } from 'routes/Private/context';
import PersonalScoreContext from './context';
import LoadingPage from '../LoadingPage';

const ScoreProgressPage: React.FC = () => {
  const { enroll: myEnrollId } = useContext(RoleContext);
  const { courseId, enrollId } = useParams();
  const history = useHistory();
  const { data, error, isLoading } = useQuery(
    ['personal-score', courseId, enrollId || myEnrollId],
    personalScoreFetchAPI,
  );
  if (error) {
    history.push('/404');
  }
  if (!data?.data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <PersonalScoreContext.Provider value={data.data}>
      <ScoreProgress
        Container={ContainerForm}
        TopGraph={TopGraphForm}
        BottomTable={BottomTableForm}
      />
    </PersonalScoreContext.Provider>
  );
};

export default ScoreProgressPage;
