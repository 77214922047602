import styled from 'styled-components';

const BtnInstructor = styled.a`
  padding: 8px 10px;
  border-radius: 6px;
  background-color: #5646ff;
  color: #fff;
  min-width: 266px;
  margin-top: 10px;
  font-size: 15px;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  :hover {
    background: rgba(86, 70, 255, 0.8);
  }
`;

export default BtnInstructor;
