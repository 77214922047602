import { takeLatest } from 'redux-saga/effects';
import { sagaHelper } from '../utils';
import { AUTHENTICATION } from '../reducers/authentication';
import { signInAPI, signUpAPI, updatePasswordAPI } from '../apis';

const signInSaga = sagaHelper(AUTHENTICATION.SIGN_IN, signInAPI);
const signUpSaga = sagaHelper(AUTHENTICATION.SIGN_UP, signUpAPI);
const changePasswordSaga = sagaHelper(
  AUTHENTICATION.CHANGE_PASSWORD,
  updatePasswordAPI,
);
export default function* authenticationSaga(): Generator {
  yield takeLatest(AUTHENTICATION.SIGN_IN, signInSaga);
  yield takeLatest(AUTHENTICATION.SIGN_UP, signUpSaga);
  yield takeLatest(AUTHENTICATION.CHANGE_PASSWORD, changePasswordSaga);
}
