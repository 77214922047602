import styled from 'styled-components';

const CoverContainer = styled.div`
  display: flex;
  @media (min-width: 768px) {
    margin: auto;
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    justify-content: center;
    align-items: center;
    padding-top: 31px;
    padding-bottom: 29px;
  }
`;

export default CoverContainer;
