export enum STAGES {
  PREPARATION = 'PR',
  ESSAY = 'ES',
  QUESTION = 'QU',
  PEER_REVIEW = 'PE',
  ADJUSTMENT = 'AD',
  FEEDBACK_REVIEW = 'FE',
  REVISEMENT = 'RE',
  COMPLETE = 'CO',
}

export const STAGE_ORDER_LIST = [
  STAGES.PREPARATION,
  STAGES.ESSAY,
  STAGES.QUESTION,
  STAGES.PEER_REVIEW,
  STAGES.ADJUSTMENT,
  STAGES.FEEDBACK_REVIEW,
  STAGES.REVISEMENT,
  STAGES.COMPLETE,
];

export enum SCORE_TYPE {
  WRITING = 'ES',
  PEER_REVIEW = 'PE',
  META = 'ME',
  FEEDBACK_REVIEW = 'FE',
  REVISEMENT = 'RE',
}
