import React from 'react';
import { BasicDivHeight100 } from 'components/atoms';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ContainerForm: React.FC = ({ children }) => {
  return (
    <BasicDivHeight100>
      <PerfectScrollbar>{children} </PerfectScrollbar>
    </BasicDivHeight100>
  );
};

export default React.memo(ContainerForm);
