import styled from 'styled-components';
import MidButton from '../MidButton';

const ButtomButton = styled(MidButton)`
  color: #0765e8;
  border-color: #0765e8;
  font-size: 14px;
  outline: 0;
`;

export default ButtomButton;
