import styled from 'styled-components';

const TitleInLeftBox = styled.h4`
  color: #4a6cbb;
  font-weight: 300;
  font-size: 18px;
  padding: 15px 0;
`;

export default TitleInLeftBox;
