import styled from 'styled-components';
import NumIconSmOutline from '../NumIconSmOutline';

const NumIconSm = styled(NumIconSmOutline)`
  color: #fff;
  background: #0658a9;
  ::before {
    color: #0658a9;
    background: #fff;
  }
`;

export default NumIconSm;
