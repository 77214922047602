import React from 'react';

interface CourseResultsProps {
  Container: React.FC;
  TopSection: React.FC;
  MidSection: React.FC;
  BottomSection: React.FC;
}

const CourseResults: React.FC<CourseResultsProps> = ({
  Container,
  TopSection,
  MidSection,
  BottomSection,
}) => {
  return (
    <Container>
      <TopSection />
      <MidSection />
      <BottomSection />
    </Container>
  );
};

export default CourseResults;
