import styled from 'styled-components';

const VoteInput = styled.input`
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.08);
  padding: 9px 14px;
  font-size: 15px;
  overflow: visible;
  ::placeholder {
    color: #bebebf;
  }
`;

export default VoteInput;
