import styled from 'styled-components';

const CommonTextarea = styled.textarea`
  box-sizing: border-box;
  font-weight: 300;
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
  overflow: auto;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  padding: 10px 8px;
  font-size: 16px;
  width: 100%;
`;

export default CommonTextarea;
