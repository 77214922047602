import { createContext } from 'react';
import { ConfigureType } from '../apis';

const AppContext = createContext<ConfigureType>({
  id: 0,
  name: '',
  login_desc: '',
  login_image: '',
  login_title: '',
  is_sign_up_available: true,
  rgb: [86, 70, 255],
  expire_date: '',
  subdomain: '',
  is_private_info: true,
  is_integrated_management: false,
});

export default AppContext;
