import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Tooltip, Typography } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  BtnPurpleLgOutline,
  ColoredSquar,
  FontSize15NavySpan,
  QuestionCard,
} from 'components/atoms';
import { useTranslation } from 'react-i18next';
import CourseResultContext from '../../../../pages/CourseResults/context';
import { makeCourseTableExcel } from '../../../../utils/parsers';
import useCourses from '../../../../hooks/courses/useCourses';

const useStyles = makeStyles(() => ({
  mgt15: { marginTop: '15px' },
  mgt8: { marginTop: '8px' },
  ft18: { fontSize: '18px' },
  mgtb20: { margin: '20px 0', overflow: 'auto' },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    maxWidth: 220,
    fontSize: '14px',
  },
}))(Tooltip);

const Table = styled.table`
  margin: 10px 0;
`;

const Th1 = styled.th`
  background-color: #2f64de;
  border: 1px solid #ececec;
  padding: 10px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 160px;
  max-width: 200px;
`;
const Th2 = styled(Th1)`
  background-color: #0d1b4c;
  min-width: 130px;
`;
const Th3 = styled(Th2)`
  background-color: #797979;
`;

const Td = styled.td`
  text-align: center;
  padding: 10px;
  border: 1px solid #ececec;
`;
const TdPointer = styled(Td)`
  cursor: pointer;
  color: #0d5aa7;
  text-decoration: underline;
`;

const BottomSectionForm: React.FC = () => {
  const { tableData } = useContext(CourseResultContext);
  const { currentCourse } = useCourses();
  const history = useHistory();
  const { url } = useRouteMatch();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <QuestionCard>
      <FontSize15NavySpan className={classes.ft18}>
        {t('courseResultsBottomSectionForm.taskScore')}
      </FontSize15NavySpan>
      <div className={classes.mgt15}>
        <ColoredSquar />
        {t('courseResultsBottomSectionForm.desc1')}
      </div>
      <div className={classes.mgt8}>
        <ColoredSquar />
        {t('courseResultsBottomSectionForm.desc2')}
      </div>
      <div className={classes.mgt8}>
        <ColoredSquar />
        {t('courseResultsBottomSectionForm.desc3')}
      </div>
      <div className={classes.mgtb20}>
        <Table style={{ width: '100%' }}>
          <thead>
            <tr>
              <Th1>{t('courseResultsBottomSectionForm.academicLife')}</Th1>
              {tableData.headers.map((header) => {
                return <Th1 key={header.pk}>{header.name}</Th1>;
              })}
              <Th3>{t('courseResultsBottomSectionForm.essayMisilCity')}</Th3>
              <Th3>
                {t('courseResultsBottomSectionForm.colleagueEvaluation')}
              </Th3>
              <Th3>
                {t('courseResultsBottomSectionForm.feedbackEvaluation')}
              </Th3>
              <Th3>
                {t('courseResultsBottomSectionForm.retirementMisilCity')}
              </Th3>
              <Th3>{t('courseResultsBottomSectionForm.totalScore')}</Th3>
              <Th3>{t('courseResultsBottomSectionForm.conversionScore')}</Th3>
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row) => {
              return (
                <tr key={row.pk}>
                  <TdPointer
                    onClick={(): void =>
                      history.push(`${url}/enrolls/${row.pk}/personal-score`)
                    }
                  >
                    {row.name}
                  </TdPointer>

                  {row.assignments.map((assignment, index) => {
                    const is_completed = tableData.headers[index];
                    return (
                      <CustomTooltip
                        key={assignment.pk}
                        placement="top"
                        title={
                          <Typography color="inherit" variant="caption">
                            {`${t(
                              'courseResultsBottomSectionForm.글쓰기',
                            )} ${assignment.essay_writing_score.toFixed(
                              1,
                            )} | ${t(
                              'courseResultsBottomSectionForm.평가능력',
                            )} ${assignment.peer_review_score.toFixed(1)} | ${t(
                              'courseResultsBottomSectionForm.메타인지',
                            )} ${assignment.metacognition_score.toFixed(
                              1,
                            )} | ${t(
                              'courseResultsBottomSectionForm.피드백평가',
                            )} ${assignment.feedback_review_score.toFixed(
                              1,
                            )} | ${t(
                              'courseResultsBottomSectionForm.퇴고',
                            )} ${assignment.revisement_score.toFixed(1)}`}
                          </Typography>
                        }
                      >
                        <Td>
                          {is_completed ? assignment.total.toFixed(1) : '-'}
                        </Td>
                      </CustomTooltip>
                    );
                  })}
                  {row.not_performed.map((item) => (
                    <CustomTooltip
                      placement="top"
                      key={item.unit}
                      title={
                        <Typography color="inherit" variant="caption">
                          {`${t('courseResultsBottomSectionForm.미실시')} ${
                            item.not_performed
                          } / ${t('courseResultsBottomSectionForm.전체')} ${
                            item.total
                          }`}
                        </Typography>
                      }
                    >
                      <Td>
                        <span
                          style={{
                            color:
                              item.not_performed === 0 ? 'inherit' : '#eb5b5b',
                          }}
                        >
                          {item.not_performed}
                        </span>
                        &nbsp;/ {item.total}
                      </Td>
                    </CustomTooltip>
                  ))}
                  <Td>{row.total.toFixed(1)}</Td>
                  <Td>{row.conversion_score.toFixed(1)}</Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <BtnPurpleLgOutline
        onClick={(): void => {
          makeCourseTableExcel(currentCourse, tableData);
        }}
      >
        {t('courseResultsBottomSectionForm.cumulative')}
      </BtnPurpleLgOutline>
    </QuestionCard>
  );
};

export default BottomSectionForm;
