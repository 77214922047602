import { requests, ResponseType } from 'utils';

type SupportResponse = {
  content: string;
  course: number;
};

const createSupportAPI = async ({
  content,
  course,
}: SupportResponse): Promise<ResponseType<SupportResponse>> => {
  return requests().post('/api/v1/supports/', { content, course });
};

export default createSupportAPI;
