import styled from 'styled-components';

const TopContainer = styled.div`
  media(min-width: 768px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 14px;
    padding-bottom: 20px;
    color: #173c51;
  }
`;

export default TopContainer;
