import { createContext } from 'react';
import { AssignmentInfoType } from '../../../apis';

type PerformanceContextType = {
  refetch: () => void;
  isFold: boolean;
  setFold: (fold: boolean) => void;
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
  pageTitle: string;
  setPageTitle: (pageTitle: string) => void;
};

const PerformanceContext = createContext<
  AssignmentInfoType & PerformanceContextType
>({
  id: 0,
  name: '',
  description: '',
  files: [],
  progresses: [],
  is_auto_schedule: false,
  current_stage: {
    stage: 'preparations',
    start_date: '',
    end_date: '',
  },
  request_user_report: undefined,
  is_waiting_next_stage: false,
  now: '',
  instructor: {
    name: '',
    email: '',
  },
  refetch: () => {},
  isFold: false,
  setFold: () => {},
  collapse: false,
  setCollapse: () => {},
  pageTitle: '',
  setPageTitle: () => {},
});

export default PerformanceContext;
