import { createContext } from 'react';
import { CourseResultType, FetchScoresByLearnerType } from '../../apis/courses';
import { FetchAssignmentWeightsResponseType } from '../../apis/assignments';

export type CourseResultContextType = {
  chartData: CourseResultType[];
  weights: FetchAssignmentWeightsResponseType[];
  tableData: FetchScoresByLearnerType;
  refetch: () => void;
};

const CourseResultContext = createContext<CourseResultContextType>({
  chartData: [],
  weights: [],
  tableData: {
    headers: [],
    rows: [],
  },
  refetch: () => {},
});

export default CourseResultContext;
