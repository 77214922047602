import AssignmentIcon from '@material-ui/icons/Assignment';
import Add from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CourseCreateDialog from 'components/organisms/modals/CourseCreate';
import CourseEnrollDialog from 'components/organisms/modals/CourseEnroll';
import { useTranslation } from 'react-i18next';
import GenerateCourseButton from '../../../atoms/mains/GenerateCourseButton';
import { MenuItem, MenuLabel } from '../../../atoms/mains';

export type CourseType = {
  id: number;
  name: string;
};

export type IMainCourseProps = {
  iconStyle: React.CSSProperties;
  addIconStyle: React.CSSProperties;
  isInstructor: boolean;
  courses: CourseType[];
};

type IsActiveProps = {
  pathname: string;
  url: string;
  isInstructor: boolean;
  assistantUrl: string;
};

const isActive = ({
  pathname,
  url,
  isInstructor,
  assistantUrl,
}: IsActiveProps): boolean => {
  if (isInstructor) {
    return pathname.includes(url);
  }

  return pathname.includes(url) || pathname.includes(assistantUrl);
};

const MainCourse: React.FC<IMainCourseProps> = ({
  iconStyle,
  addIconStyle,
  courses,
  isInstructor,
}) => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const displayCourse = expand ? courses : courses.slice(0, 3);

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };
  return (
    <div>
      <MenuLabel>
        {isInstructor ? t('mainCourse.openingCourse') : t('mainCourse.course')}
      </MenuLabel>
      {displayCourse.map((course) => {
        const prefix = isInstructor ? 'instructor' : 'learner';
        const url = `/courses/${course.id}/${prefix}`;
        const assistantUrl = `/courses/${course.id}/assistant`;
        return (
          <MenuItem
            isActive={isActive({ isInstructor, assistantUrl, url, pathname })}
            key={course.id}
            onClick={(): void => {
              history.push(url);
            }}
          >
            <AssignmentIcon style={iconStyle} />
            {course.name}
          </MenuItem>
        );
      })}
      <MenuItem onClick={(): void => setExpand((prevState) => !prevState)}>
        {courses.length > 3 &&
          (expand
            ? t('mainCourse.hide')
            : t('mainCourse.moreLecturesInSize', { size: courses.length - 3 }))}
      </MenuItem>
      <GenerateCourseButton
        onClick={handleOpenToggle(true)}
        style={{ outline: 0 }}
      >
        <Add style={addIconStyle} />
        {isInstructor ? t('mainCourse.lecturer') : t('mainCourse.enrolment')}
      </GenerateCourseButton>
      {open && isInstructor ? (
        <CourseCreateDialog handleClose={handleOpenToggle(false)} open={open} />
      ) : (
        <CourseEnrollDialog handleClose={handleOpenToggle(false)} open={open} />
      )}
    </div>
  );
};

export default MainCourse;
