import { createReducer } from 'typesafe-actions';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { DashboardState } from './types';
import { DASHBOARD } from './actions';

const initialState: DashboardState = {
  dashboard: [],
  errors: [],
};

const dashboard = createReducer<DashboardState>(initialState, {
  [DASHBOARD.FETCH_DASHBOARD_SUCCESS]: (state, action) => {
    return {
      ...state,
      dashboard: action.payload,
    };
  },
  [DASHBOARD.FETCH_DASHBOARD_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersCoursesReducer.youDoNotHavePermission'));
    errorNotify();
    return {
      ...state,
    };
  },
});

export default dashboard;
