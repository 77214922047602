import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ReportContext from '../../../../pages/Report/context';

const useStyles = makeStyles(() => ({
  divDesktop: {
    padding: '40px',
  },
  divMobile: {
    padding: '1.2rem',
  },
  divMg: {
    marginBottom: '19px',
    fontSize: '22px',
    color: '#173c51',
    fontWeight: 400,
  },
  span: {
    marginLeft: '5px',
    color: 'red',
  },
}));

const ContainerForm: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { name, is_my_report, late } = useContext(ReportContext);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <div className={isDesktop ? classes.divDesktop : classes.divMobile}>
      <div className={classes.divMg}>
        {is_my_report
          ? t('componentsOrganismsReportsContainer.my')
          : t('componentsOrganismsReportsContainer.notMy', { name })}
        {late && (
          <span className={classes.span}>
            ({t('componentsOrganismsReportsContainer.delayedSubmission')})
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

export default ContainerForm;
