import styled from 'styled-components';

const BackText = styled.div`
  color: #0658a9;
  letter-spacing: -1.1px;
  font-size: 15px;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
`;

export default BackText;
