import styled from 'styled-components';

const RoundedCardBox = styled.div`
  marign-bottom: 8px;
  width: 100%;
  padding: 6px 6px 0 6px;
  background: #fff;
`;

export default RoundedCardBox;
