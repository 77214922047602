import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import Container from './container';
import { peerReviewMatchingInstructorFetchAPI } from '../../../../apis/peerReviews';

export type ScoreEditProps = {
  handleClose: () => void;
  open: boolean;
  matchingId: number;
  name: string;
};

const ScoreEdit: React.FC<ScoreEditProps> = ({
  open,
  handleClose,
  matchingId,
  name,
}) => {
  const { data, refetch, isLoading } = useQuery(
    ['peerReviewMatchingInstructorFetchAPI', matchingId],
    peerReviewMatchingInstructorFetchAPI,
    { enabled: false },
  );
  useEffect(() => {
    if (open) {
      refetch().then();
    }
  }, [open, refetch]);

  if (!data || isLoading) {
    return <div>loading...</div>;
  }
  return (
    <Container
      open={open}
      handleClose={handleClose}
      name={name}
      data={data.data}
    />
  );
};

export default ScoreEdit;
