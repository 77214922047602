import React from 'react';

interface AssignmentInfoProps {
  Description: React.FC;
  Timeline: React.FC;
  FileAttatchment: React.FC;
}

const AssignmentInfo: React.FC<AssignmentInfoProps> = ({
  Description,
  Timeline,
  FileAttatchment,
}) => {
  return (
    <div>
      <Description />
      <Timeline />
      <FileAttatchment />
    </div>
  );
};

export default AssignmentInfo;
