import React from 'react';

interface PeerReviewMatchingProps {
  Container: React.FC;
  EssaySection: React.FC;
  ReviewSection: React.FC;
  TopBarSection: React.FC;
}

const PeerReviewMatching: React.FC<PeerReviewMatchingProps> = ({
  Container,
  EssaySection,
  ReviewSection,
  TopBarSection,
}) => {
  return (
    <Container>
      <TopBarSection />
      <EssaySection>
        <ReviewSection />
      </EssaySection>
    </Container>
  );
};

export default PeerReviewMatching;
