import React from 'react';
import {
  Wrapper,
  ManageText,
  Card,
  SupportTitleText,
  MidButton,
  PeerReviewOptionSelect,
  CommonDivFlexEnd,
  SupportTextarea,
} from 'components/atoms';
import useProfiles from 'hooks/profiles/useProfiles';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CardSectionForm: React.FC = () => {
  const { register } = useFormContext();
  const {
    user: { courses, enrolls },
  } = useProfiles();
  const { t } = useTranslation();
  const coursesSet = new Set();
  const allCourses = [
    ...courses,
    ...enrolls.map((enroll) => enroll.course),
  ].filter((course) => {
    if (coursesSet.has(course.id)) {
      return false;
    }
    coursesSet.add(course.id);
    return true;
  });

  return (
    <Wrapper>
      <ManageText>{t('supportsCardSectionForm.inquiryAndSupport')}</ManageText>
      <Card>
        <SupportTitleText>
          {t('supportsCardSectionForm.relatedCourseOptional')}
        </SupportTitleText>
        <PeerReviewOptionSelect ref={register} name="course">
          {allCourses.map((course) => {
            return (
              <option value={course.id} key={course.id}>
                {course.name}
              </option>
            );
          })}
        </PeerReviewOptionSelect>
        <Wrapper>
          <SupportTitleText>
            {t('supportsCardSectionForm.contents')}
          </SupportTitleText>
          <SupportTextarea
            name="content"
            placeholder={t('supportsCardSectionForm.pleaseReportErrors') ?? ''}
            ref={register}
          />
          <CommonDivFlexEnd>
            <MidButton>{t('supportsCardSectionForm.submission')}</MidButton>
          </CommonDivFlexEnd>
        </Wrapper>
      </Card>
    </Wrapper>
  );
};

export default React.memo(CardSectionForm);
