import { requests, ResponseType } from 'utils';

export type SignInAPIProps = {
  email: string;
  password: string;
};

export type SignUpAPIProps = {
  email: string;
  password: string;
  name: string;
  address?: string;
  attending_type?: string;
  date_of_birth?: string;
  grade?: number;
};

export type SignInResponse = {
  token: string;
  is_staff: boolean;
};

export type UpdatePasswordAPIProps = {
  oldPassword: string;
  newPassword: string;
};

export type ConfigureType = {
  id: number;
  name: string;
  login_image: string;
  login_title: string;
  is_sign_up_available: boolean;
  login_desc: string;
  rgb: [number, number, number];
  expire_date: string;
  subdomain: string;
  is_private_info: boolean;
  is_integrated_management: boolean;
};

export const signInAPI = ({
  email,
  password,
}: SignInAPIProps): Promise<ResponseType<SignInResponse>> => {
  return requests().post('/api/v1/users/sign-in/', { email, password });
};

export const signUpAPI = ({
  email,
  password,
  name,
  address,
  grade,
  attending_type,
  date_of_birth,
}: SignUpAPIProps): Promise<ResponseType<SignUpAPIProps>> => {
  return requests().post('/api/v1/users/sign-up/', {
    email,
    password,
    name,
    address,
    grade,
    attending_type,
    date_of_birth,
  });
};

export const updatePasswordAPI = ({
  oldPassword,
  newPassword,
}: UpdatePasswordAPIProps): Promise<ResponseType<void>> => {
  return requests().post('/api/v1/users/password-change/', {
    old_password: oldPassword,
    new_password: newPassword,
  });
};

export const fetchConfigure = async (): Promise<
  ResponseType<ConfigureType>
> => {
  return requests().get('/api/v1/users/configuration/');
};

export const fetchConfigures = async (): Promise<
  ResponseType<ConfigureType[]>
> => {
  return requests().get('/api/v1/users/configurations/');
};
