import React, { useContext, useState } from 'react';
import {
  ButtonEdit,
  ButtonPassword,
  Card,
  ContentMedium,
  InfoRow,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from 'components/atoms';
import PasswordChange from 'components/organisms/modals/PasswordChange';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useProfiles from 'hooks/profiles/useProfiles';
import AppContext from '../../../../App/context';

const TopTextForm: React.FC = () => {
  const { is_private_info } = useContext(AppContext);
  const history = useHistory();
  const {
    user: {
      email,
      name,
      address,
      grade,
      display_attending_type,
      date_of_birth,
      attending_type,
    },
    role,
  } = useProfiles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };
  return (
    <>
      <TitleLarge>{t('myPagesTopTextForm.myPage')}</TitleLarge>
      <Card>
        <TitleMedium>{t('myPagesTopTextForm.profile')}</TitleMedium>
        <InfoRow>
          <TitleSmall>{t('myPagesTopTextForm.emailAddress')}</TitleSmall>
          <ContentMedium>{email}</ContentMedium>
        </InfoRow>
        <InfoRow>
          <TitleSmall>{t('myPagesTopTextForm.name')}</TitleSmall>
          <ContentMedium>{name}</ContentMedium>
        </InfoRow>
        <InfoRow>
          <TitleSmall>{t('myPagesTopTextForm.accountType')}</TitleSmall>
          <ContentMedium>{t(`app.${role}`)}</ContentMedium>
        </InfoRow>
        {is_private_info && (
          <>
            <InfoRow>
              <TitleSmall>{t('myPagesTopTextForm.school')}</TitleSmall>
              <ContentMedium>
                {t(`app.attending_type.${display_attending_type}`)}
              </ContentMedium>
            </InfoRow>
            {attending_type !== 'N' && (
              <InfoRow>
                <TitleSmall>{t('myPagesTopTextForm.grade')}</TitleSmall>
                <ContentMedium>{grade}</ContentMedium>
              </InfoRow>
            )}
            <InfoRow>
              <TitleSmall>{t('myPagesTopTextForm.address')}</TitleSmall>
              <ContentMedium>{address}</ContentMedium>
            </InfoRow>
            <InfoRow>
              <TitleSmall>{t('myPagesTopTextForm.dateOfBirth')}</TitleSmall>
              <ContentMedium>{date_of_birth}</ContentMedium>
            </InfoRow>
          </>
        )}
        <InfoRow>
          <ButtonPassword onClick={handleOpenToggle(true)}>
            {t('myPagesTopTextForm.changePassword')}
          </ButtonPassword>
          <ButtonEdit onClick={(): void => history.push('/users/edit')}>
            {t('myPagesTopTextForm.correction')}
          </ButtonEdit>
          <PasswordChange handleClose={handleOpenToggle(false)} open={open} />
        </InfoRow>
      </Card>
    </>
  );
};

export default TopTextForm;
