import React from 'react';
import { GrayBackground } from 'components/atoms';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: 0,
      flexDirection: 'column',
    },
  }),
);

const ContainerForm: React.FC = ({ children }) => {
  const classes = useStyles();
  return <GrayBackground className={classes.root}>{children}</GrayBackground>;
};

export default ContainerForm;
