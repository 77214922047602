import React, { useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  AddButton,
  DeleteButtonIcon,
  QuestionContainer,
  QuestionInput,
  QuestionListItem,
  StageContainer,
  StageOptionCaption,
  StageOptionLabel,
  StageTitle,
} from '../../../atoms/newAssignmnets';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

export const NoneStyleButton = styled.button`
  background: none;
  outline: none;
  padding: 0;
  border: none;
  margin: 0;
  cursor: pointer;
`;

const QuestionOption: React.FC = () => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'quizs',
  });
  const { currentStage } = useContext(IsEditableContext);
  const { t } = useTranslation();
  const currentIndex = stageIndex.indexOf(currentStage);

  const handleClick = (): void => {
    append({ name: 'quizs' });
  };
  return (
    <StageContainer>
      <StageTitle>
        {t('newAssignmentsQuestionOption.writeAQuestion')}
      </StageTitle>
      <StageOptionLabel>
        {t('newAssignmentsQuestionOption.questionsTaskDirective')}
      </StageOptionLabel>
      <StageOptionCaption>
        {t(
          'newAssignmentsQuestionOption.youCanWriteAQuestionToDiscussWithYourEssay',
        )}
      </StageOptionCaption>
      <QuestionContainer>
        {fields.map((item, index) => {
          return (
            <QuestionListItem key={item.id}>
              <QuestionInput
                name={`quizs[${index}].quiz`}
                ref={register()}
                required
                maxLength={100}
              />
              <NoneStyleButton
                disabled={currentIndex > 0}
                onClick={(): void => {
                  remove(index);
                }}
              >
                <DeleteButtonIcon />
              </NoneStyleButton>
            </QuestionListItem>
          );
        })}
      </QuestionContainer>
      <AddButton onClick={handleClick}>
        <AddIcon style={{ fontSize: 25 }} />
        {t('newAssignmentsQuestionOption.addQuestions')}
      </AddButton>
    </StageContainer>
  );
};

export default QuestionOption;
