import { toast } from 'react-toastify';
import { ChangeEvent } from 'react';
import { t } from 'i18next';
import {
  patchLateEssay,
  updatePerformance,
} from '../../../../apis/performances';
import { serializeFormData } from '../../../../utils';

type OnFileCancelProps = {
  courseId: number;
  assignmentId: number;
  performanceId: number;
  fileName?: string;
  refetch: () => void;
  isLate?: boolean;
  fileId: number;
};

export const onFileCancelHelper = async ({
  courseId,
  assignmentId,
  performanceId,
  fileName,
  refetch,
  isLate,
  fileId,
}: OnFileCancelProps): Promise<void> => {
  // eslint-disable-next-line no-alert
  const res = window.confirm(
    t('essayWritingBottomBarHelpers.wouldYouLikeToDeleteTheFilenameFile', {
      fileName,
    }),
  );
  if (res) {
    try {
      const data = new FormData();
      data.append('stage_object', JSON.stringify({ delFile: true, fileId }));
      if (!isLate) {
        await updatePerformance({
          data,
          courseId,
          assignmentId,
          performanceId,
        });
      } else {
        await patchLateEssay({
          data,
          courseId,
          assignmentId,
          performanceId,
        });
      }
      toast.success(
        t('essayWritingBottomBarHelpers.theFilenameFileHasBeenDeleted', {
          fileName,
        }),
      );
      refetch();
    } catch (e) {
      toast.warn(t('essayWritingBottomBarHelpers.thisIsTheWrongRequest'));
    }
  }
};

export const fileGenerateHelper = ({
  courseId,
  assignmentId,
  performanceId,
  refetch,
  isLate,
}: Omit<OnFileCancelProps, 'fileId'>) => async (
  e: ChangeEvent<HTMLInputElement>,
): Promise<void> => {
  const { files: currentFiles } = e.target;
  try {
    const data = serializeFormData({
      currentFiles,
      stage_object: {},
    });
    if (!isLate) {
      await updatePerformance({
        data,
        courseId,
        assignmentId,
        performanceId,
      });
    } else {
      await patchLateEssay({
        data,
        courseId,
        assignmentId,
        performanceId,
      });
    }
    toast.success(t('essayWritingBottomBarHelpers.theFileHasBeenCreated'));
    refetch();
  } catch (error) {
    if (error.response.data.detail) {
      toast.warn(error.response.data.detail);
    } else {
      toast.warn(t('essayWritingBottomBarHelpers.thisIsTheWrongRequest'));
    }
  }
};
