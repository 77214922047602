import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  fetchMatchingsInfo,
  MatchingTargetType,
  resetMatchingsInfo,
} from 'apis';
import { BtnSkyblueLgOutline, FontSize15NavySpan } from 'components/atoms';
import ManageAssignmentContext from 'pages/ManageAssignment/context';
import Select, { components } from 'react-select';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CustomTooltip } from 'components/molecules/newAssignments/ScoreWeight';
import LoadingPage from '../LoadingPage';

const Title = styled.h2`
  color: #4a6cbb;
  margin-top: 16px;
  margin-bottom: 31px;
  font-size: 24px;
  font-weight: 300;
`;

const FormCard = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const HeaderRow = styled(Row)`
  height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-left: 1px solid black;
`;

const Cell = styled.span`
  min-width: 180px;
  width: 100%;
  text-align: center;
  background: inherit;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
`;

const NameCell = styled(Cell)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
`;

const Header = styled(Cell)`
  background-color: #f2f2f2;
  font-size: 1.125em;
  border-top: 1px solid black;
  align-self: center;
  padding: 8px;
`;

const OptionCell = styled.span`
  flex: 2;
  text-align: center;
  background: inherit;
  box-sizing: border-box;
  font-size: 0.825em;
  color: gray;
  align-self: center;
  padding: 2px;
`;

type Option = {
  value: number;
  label: string;
  count: number;
};

const MatchingPage: React.FC = () => {
  const { courseId, assignmentId } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const assignmentPageUrl = (() => {
    const pathParts = url.split('/');
    const targetPath = pathParts.slice(0, pathParts.length - 1).join('/');
    return targetPath;
  })();
  const { name: assignmentName, current_stage } = useContext(
    ManageAssignmentContext,
  );
  const goAssignmentPage = () => {
    history.push(assignmentPageUrl);
  };
  const peerCount = (current_stage as any)?.stage_object.peer_count;
  const { data, isLoading, isError } = useQuery(
    ['matchings', courseId, assignmentId],
    fetchMatchingsInfo,
  );

  const [options, setOptions] = useState<Option[]>([]);
  const performances = data?.data;
  const matchingsMap = new Map(
    performances?.map((performance) => [
      performance.stage_object.id,
      performance,
    ]),
  );

  const [select, setSelect] = useState<React.SetStateAction<{} | undefined>>();
  const resetOptions = useCallback(
    (newSelect: React.SetStateAction<{} | undefined>) => {
      const counts = _.countBy(Object.values(newSelect || {}).flat());
      const newOptions = Array.from(matchingsMap.values()).map(
        (performance) => ({
          label: performance.name,
          value: performance.stage_object.id,
          count: peerCount - (counts[performance.stage_object.id] || 0),
        }),
      );
      setOptions(newOptions);
      setSelect(newSelect);
    },
    [matchingsMap, peerCount],
  );

  useEffect(() => {
    if (performances) {
      if (!select) {
        const newSelect = performances?.reduce(
          (obj, performance) =>
            Object.assign(obj, {
              [performance.stage_object
                .id]: performance.stage_object.targets.map(
                (matching) => matching.target,
              ),
            }),
          {},
        );
        resetOptions(newSelect);
      }
    }
  }, [performances, select, resetOptions]);

  if (current_stage.stage !== '동료평가') {
    return <Redirect to={assignmentPageUrl} />;
  }

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }

  const handleChange = (name: string) => (newValue: any) => {
    const [reviewer, targetIdx] = name.split('-');
    const target = newValue === null ? newValue : newValue.value;
    const targets = (select as Record<string, number[]>)[reviewer];
    targets.splice(Number(targetIdx), 1, target);
    const newSelect = {
      ...select,
      [reviewer]: [...targets],
    };
    resetOptions(newSelect);
  };

  const Option = (props: any) => {
    const {
      data: { count },
    } = props;
    return (
      <Row>
        <span style={{ flex: '3' }}>
          <components.Option {...props} />
        </span>
        <OptionCell>
          ({t('pagesMatching.theRemainingNumber')} : {count})
        </OptionCell>
      </Row>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '40px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <div style={{ cursor: 'pointer' }}>
          <FontSize15NavySpan onClick={goAssignmentPage}>
            <ArrowBackIcon style={{ verticalAlign: 'middle' }} />
            {t('pagesMatching.goBack')}
          </FontSize15NavySpan>
        </div>

        <Title>
          {assignmentName} {t('pagesMatching.fellowEvaluationAssignment')}
        </Title>
        <pre>{t('pagesMatching.afterAssigning')}</pre>
        <pre>{t('pagesMatching.ifThereIsAlreadyAStudent')}</pre>
      </div>
      <div
        style={{
          width: '100%',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <CustomTooltip
            title={t('pagesMatching.appearsInTheBlank') ?? ''}
            placement="top"
          >
            <BtnSkyblueLgOutline
              type="button"
              onClick={() => {
                resetOptions(
                  Object.entries(select || {}).reduce(
                    (obj, [key, value]) =>
                      Object.assign(obj, {
                        [key]: (value as number[]).map((v) => null),
                      }),
                    {},
                  ),
                );
              }}
            >
              {t('pagesMatching.deleteEvaluatorInput')}
            </BtnSkyblueLgOutline>
          </CustomTooltip>
        </div>
        <FormCard>
          <HeaderRow>
            <Header>{t('pagesMatching.evaluator')}</Header>
            {Array.from(new Array(peerCount).keys()).map((idx) => (
              <Header key={idx}>
                {t('pagesMatching.rater')}
                {idx + 1}
              </Header>
            ))}
          </HeaderRow>
          {performances &&
            performances.map((performance) => {
              const { id } = performance.stage_object;
              const targets = ((select as Record<string, number[]>) || {})[id];
              return (
                <Row key={id}>
                  <NameCell>
                    <span>{performance.name}</span>
                  </NameCell>
                  {targets?.map((target, order) => {
                    const name = `${id}-${order}`;
                    return (
                      <Cell key={target || name}>
                        <Select
                          components={{ Option }}
                          options={options.filter(
                            (option) =>
                              option.value !== id &&
                              !targets?.includes(option.value),
                          )}
                          defaultValue={{
                            value: target,
                            label: matchingsMap.get(target)?.name || '',
                            count: 0,
                          }}
                          name={name}
                          onChange={handleChange(name)}
                          isClearable
                          isOptionDisabled={(option: Option) =>
                            option.count === 0
                          }
                        />
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
        </FormCard>
        <div
          style={{
            width: '100%',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <BtnSkyblueLgOutline
            type="button"
            onClick={goAssignmentPage}
            style={{
              marginRight: '10px',
            }}
          >
            {t('pagesMatching.cancellation')}
          </BtnSkyblueLgOutline>
          <BtnSkyblueLgOutline
            type="button"
            onClick={async () => {
              if (!window.confirm(t('pagesMatching.windowConfirm'))) {
                return;
              }
              const counts = _.countBy(Object.values(select || {}).flat());
              if ('null' in counts) {
                toast.warning(
                  t('pagesMatching.allThoseWhoAreEvaluatedMustBeAssigned'),
                );
                return;
              }
              const matchings = Object.entries(select || {})
                .map(([key, value]) =>
                  (value as number[]).map(
                    (target) =>
                      ({
                        reviewer: Number(key),
                        target,
                      } as MatchingTargetType),
                  ),
                )
                .flat();
              try {
                await resetMatchingsInfo(
                  'matchings-reset',
                  courseId,
                  assignmentId,
                  { matchings },
                );
                toast.success(t('pagesMatching.reviewersHasBeenAssigned'));
              } catch (e) {
                toast.warning(t('pagesMatching.thisIsTheWrongRequest'));
              }
            }}
          >
            {t('pagesMatching.assignment')}
          </BtnSkyblueLgOutline>
        </div>
      </div>
    </div>
  );
};

export default MatchingPage;
