import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { EssayWritingBottomBarDiv } from '../../../atoms/performAssignment';
import {
  BasicDivFlexBetween,
  BtnSkyblueLgOutline,
  FontSize18Span,
} from '../../../atoms/commons';
import SwitchPerformanceContext, {
  SwitchPerformanceContextType,
} from '../../../../pages/PerformAssignment/SwitchPage/context';
import {
  patchLateEssay,
  updatePerformance,
  WritingEssayPerformanceType,
} from '../../../../apis/performances';
import PerformanceContext from '../../../../pages/PerformAssignment/PerformanceMain/context';
import QuillEditorEssayWritingContext from '../../../../pages/PerformAssignment/QuillEditorEssayWriting/context';
import { STAGES } from '../../../../utils/constants';
import { switchStage } from '../../../../pages/EditAssignment/context';
import {
  ActionContainer,
  FileField,
  FileFieldContainer,
  LateButton,
  OCancelItem,
  OrangeSpan,
  OverrideField,
  OverrideFileLabel,
} from '../../essayWriting/BottomBar';
import {
  fileGenerateHelper,
  onFileCancelHelper,
} from '../../essayWriting/BottomBar/helpers';
import { ResponseType, serializeFormData } from '../../../../utils';
import { EssayWritingType } from '../../../../apis/assignments';

const QuillFooterSection: React.FC = () => {
  const { t } = useTranslation();
  const [change, setChange] = useState(true);
  const [fileChange, setFileChange] = useState(false);
  const { current_stage, is_waiting_next_stage, progresses } = useContext(
    PerformanceContext,
  );
  const {
    refetch,
    stage_object: { files, content },
    is_completed,
  } = useContext(SwitchPerformanceContext) as WritingEssayPerformanceType &
    SwitchPerformanceContextType;
  const essay_info_array = progresses.find(
    (progress) => progress.stage === '에세이작성',
  ) as EssayWritingType;
  const { max_length, limit_type } = essay_info_array.stage_object;

  const { courseId, assignmentId, performanceId, role } = useParams();

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const { editorRef, value, isRevisement } = useContext(
    QuillEditorEssayWritingContext,
  );
  const { url } = useRouteMatch();

  let currentLength = 0;
  if (limit_type === 'W') {
    currentLength =
      editorRef?.current?.unprivilegedEditor
        ?.getText()
        .split(/(\s+)/)
        .filter((str) => str.trim().length > 0).length || 0;
  } else {
    currentLength =
      (editorRef?.current?.unprivilegedEditor?.getLength() || 1) - 1;
  }
  const prefix = switchStage(current_stage.stage);
  const late = (): boolean => {
    if (isRevisement) {
      return false;
    }
    return (
      (prefix !== STAGES.ESSAY &&
        !is_completed &&
        prefix !== STAGES.COMPLETE) ||
      (prefix === STAGES.ESSAY && is_waiting_next_stage && !is_completed)
    );
  };
  const editable = (): boolean => {
    const stage = switchStage(current_stage.stage);
    if (isRevisement) {
      return stage !== STAGES.COMPLETE;
    }
    return stage === STAGES.ESSAY && !is_waiting_next_stage;
  };
  const isLate = late();
  const isEditable = editable();
  const style = max_length < currentLength ? { color: 'red' } : {};
  const history = useHistory();

  useEffect(() => {
    if (content === value && !fileChange) {
      setChange(false);
    } else {
      setChange(true);
    }
  }, [content, fileChange, value]);
  const onFileCancel = (fileName: string, fileId: number) => async (): Promise<
    void
  > => {
    await onFileCancelHelper({
      courseId,
      assignmentId,
      performanceId,
      fileName,
      refetch,
      isLate,
      fileId,
    });
    setFileChange(true);
  };
  const onClick = async (): Promise<void> => {
    if (currentLength > max_length) {
      toast.warn(
        t('componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.max'),
      );
    } else {
      const data = serializeFormData({
        stage_object: {
          content: value,
          plain_text: editorRef?.current?.unprivilegedEditor?.getText(),
          editor_type: 'Q',
        },
      });
      try {
        let response: ResponseType<WritingEssayPerformanceType>;
        if (!isLate) {
          response = (await updatePerformance({
            data,
            courseId,
            assignmentId,
            performanceId,
          })) as ResponseType<WritingEssayPerformanceType>;
        } else {
          response = (await patchLateEssay({
            data,
            courseId,
            assignmentId,
            performanceId,
          })) as ResponseType<WritingEssayPerformanceType>;
        }
        toast.success(
          t(
            'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.done',
          ),
        );
        setFileChange(false);
        if (!isRevisement) {
          setTimeout(() => {
            if (
              response.data.stage_object.extra.question &&
              !(url in localStorage)
            ) {
              localStorage.setItem(url, '');
              // eslint-disable-next-line no-alert
              const answer = window.confirm(
                t(
                  'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.remain',
                ),
              );
              if (answer) {
                history.push(
                  `/courses/${courseId}/${role}/assignments/${assignmentId}/performances/${response.data.stage_object.extra.question}`,
                );
              }
            }
          }, 1000);
        }

        refetch();
      } catch (e) {
        toast.warn(
          t(
            'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.wrong',
          ),
        );
      }
    }
  };
  const renderFileBtn = (): null | React.ReactElement => {
    if (!isEditable && !isLate) {
      return null;
    }
    return (
      <OverrideFileLabel>
        <input
          type="file"
          name="files"
          multiple
          accept="application/pdf,.mp4"
          style={{ display: 'none' }}
          onChange={async (e): Promise<void> => {
            await fileGenerateHelper({
              courseId,
              assignmentId,
              performanceId,
              refetch,
              isLate,
            })(e);
            await onClick();
          }}
        />
        <OverrideField>
          <AttachFileIcon />
          {t(
            'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.pdfMp4FileUpload',
          )}
        </OverrideField>
      </OverrideFileLabel>
    );
  };

  return (
    <fieldset disabled={!isEditable && !isLate}>
      <EssayWritingBottomBarDiv>
        <BasicDivFlexBetween>
          <div>
            <FontSize18Span style={style}>{currentLength}</FontSize18Span>
            <span> / </span>
            <span>{max_length}</span>
            {limit_type === 'L' &&
              t(
                'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.letters',
              )}
            {limit_type === 'W' &&
              t(
                'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.words',
              )}
          </div>
          {!isEditable && !isLate && (
            <div>
              <OrangeSpan style={{ fontSize: isDesktop ? 'inherit' : '13px' }}>
                {t(
                  'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.only',
                )}
              </OrangeSpan>
            </div>
          )}
          <ActionContainer>
            {files.length > 0 &&
              files.map((file) => {
                const fileArray = file.file.split('/');
                const name = decodeURI(fileArray[fileArray.length - 1]);
                return (
                  <FileFieldContainer key={file.file}>
                    <AttachFileIcon />
                    <FileField download href={file.file} target="_blank">
                      {name}
                    </FileField>
                    {(isEditable || isLate) && (
                      <OCancelItem
                        onClick={onFileCancel(name, file?.id || 0)}
                      />
                    )}
                  </FileFieldContainer>
                );
              })}
            {renderFileBtn()}
            {isLate ? (
              <LateButton
                style={{ padding: '10px 50px' }}
                onClick={onClick}
                disabled={!change}
                type="button"
              >
                {change
                  ? t(
                      'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.delayedSubmission',
                    )
                  : t(
                      'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.delayedSubmissionIsStored',
                    )}
              </LateButton>
            ) : (
              <BtnSkyblueLgOutline
                style={{ padding: '10px 50px' }}
                onClick={onClick}
                disabled={!change}
                type="button"
              >
                {change
                  ? t(
                      'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.saveAndSubmission',
                    )
                  : t(
                      'componentsOrganismsQuillEditorEssayWritingsQuillFooterSection.saved',
                    )}
              </BtnSkyblueLgOutline>
            )}
          </ActionContainer>
        </BasicDivFlexBetween>
      </EssayWritingBottomBarDiv>
    </fieldset>
  );
};

export default QuillFooterSection;
