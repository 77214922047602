import styled from 'styled-components';

const IconWithBorder = styled.a`
  display: flex;
  color: #5646ff;
  border: 1px solid #5646ff;
  font-size: 14px;
  border-radius: 8px;
  margin-left: 15px;
  background: none;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  padding: 5px;
`;

export default IconWithBorder;
