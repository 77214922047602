import { requests, ResponseType } from 'utils';
import {
  AssignmentCreateAPIProps,
  AssignmentInfoType,
  AssignmentManagementType,
  AssignmentResponseType,
  AssignmentUpdateAPIProps,
  FetchAssignmentWeightsResponseType,
  FetchMatchingsInfoResponseType,
  FetchMaxScoreResponseType,
  FetchStagesOfLearnersType,
  PatchAssignmentWeightsProps,
  ResetMatchingsInfoResponseType,
  AssignmentListType,
  AssignmentListItemType,
} from './types';

export const fetchAssignmetList = async (
  queryString: string,
): Promise<ResponseType<AssignmentListType>> => {
  return requests().get(`/api/v1/assignments/pages/?${queryString}`);
};

export const assignmentListAPI = async (): Promise<
  ResponseType<AssignmentListItemType[]>
> => {
  return requests().get(`/api/v1/assignments/`);
};

export const assignmentsFetchAPI = async (
  key: string,
  courseId: number,
): Promise<ResponseType<AssignmentResponseType[]>> => {
  return requests().get(`/api/v1/courses/${courseId}/assignments/`);
};

export const assignmentCreateAPI = async ({
  courseId,
  assignment,
}: AssignmentCreateAPIProps): Promise<ResponseType<void>> => {
  return requests().post(
    `/api/v1/courses/${courseId}/assignments/`,
    assignment,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const assignmentFetchAPI = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<AssignmentResponseType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/`,
  );
};

export const assignmentUpdateAPI = async ({
  courseId,
  assignmentId,
  assignment,
}: AssignmentUpdateAPIProps): Promise<ResponseType<AssignmentResponseType>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/`,
    assignment,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const assignmentLearnerInfo = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<AssignmentInfoType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/info/`,
  );
};

export const assignmentMoveToNextStage = async (
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<void>> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/move-to-next-stage/`,
  );
};

export const assignmentCreateLateParticipation = async (
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<void>> => {
  return requests().post(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/late-participation/`,
  );
};

export const assignmentFetchManagement = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<AssignmentManagementType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/management/`,
  );
};

export const fetchLastAssignment = async (
  key: string,
  courseId: number,
): Promise<ResponseType<AssignmentResponseType>> => {
  return requests().get(`/api/v1/courses/${courseId}/last-assignment/`);
};

export const fetchStagesOfLearners = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<FetchStagesOfLearnersType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/stages-of-learners/`,
  );
};

export const fetchMaxScore = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<FetchMaxScoreResponseType>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/scores/`,
  );
};

export const fetchMatchingsInfo = async (
  key: string,
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<FetchMatchingsInfoResponseType[]>> => {
  return requests().get(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/matchings/`,
  );
};

export const resetMatchingsInfo = async (
  key: string,
  courseId: number,
  assignmentId: number,
  payload: ResetMatchingsInfoResponseType,
): Promise<ResponseType<void>> => {
  return requests().post(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/matchings-reset/`,
    payload,
  );
};

export const fetchAttachments = async (
  courseId: number,
  assignmentId: number,
  assignmentName: string,
): Promise<void> => {
  return requests()
    .get(
      `/api/v1/courses/${courseId}/assignments/${assignmentId}/attachments/`,
      {
        responseType: 'blob',
      },
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${assignmentName}.zip`);
      document.body.append(link);
      link.click();
    });
};

export const fetchAssignmentWeights = async (
  key: string,
  courseId: number,
): Promise<ResponseType<FetchAssignmentWeightsResponseType[]>> => {
  return requests().get(`/api/v1/courses/${courseId}/assignments/weights`);
};

export const patchAssignmentWeights = async ({
  courseId,
  weights,
}: PatchAssignmentWeightsProps): Promise<
  ResponseType<FetchAssignmentWeightsResponseType[]>
> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/weights/`,
    weights,
  );
};

export const deleteAssignment = async (
  courseId: number,
  assignmentId: number,
): Promise<ResponseType<void>> => {
  return requests().delete(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/`,
  );
};

export const patchAttendance = async (
  courseId: number,
  assignmentId: number,
): Promise<void> => {
  return requests().patch(
    `/api/v1/courses/${courseId}/assignments/${assignmentId}/attendance/`,
  );
};
