import styled from 'styled-components';

const Li = styled.li`
  margin-bottom: 20px;
  list-style: none;
  display: flex;
  align-items: center;
`;

export default Li;
