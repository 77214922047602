import styled from 'styled-components';

const UserBoxImg = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: white;
  margin-right: 16px;
  background: none;
  background-color: transparent;
`;

export default UserBoxImg;
