import styled from 'styled-components';

const SupportTextarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  padding: 10px 8px;
  width: 100%;
  margin-bottom: 25px;
  overflow: auto;
  ::placeholder {
    color: #cccccc;
  }
`;

export default SupportTextarea;
