import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  BasicDivFlexBothCentered,
  CommonDivFlex,
  CommonDivider,
  FlexDiv,
  FontSize14Div,
} from 'components/atoms';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ProjectCard from '../../../molecules/ProjectCard';
import { assignmentsFetchAPI } from '../../../../apis/assignments';
import LoadingPage from '../../../../pages/LoadingPage';

const useStyles = makeStyles(() => ({
  createBtn: {
    fontSize: '14px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5646ff',
    borderRadius: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: '0.3s',
    padding: '8px 24px 7px 24px',
    border: '1px solid #5646ff;',
  },
}));

const SimpleMenu: React.FC = () => {
  const history = useHistory();
  const {
    url,
    params: { courseId, role },
  } = useRouteMatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data, isLoading, isError, refetch } = useQuery(
    ['assignments', courseId],
    assignmentsFetchAPI,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onPush = (load: boolean) => (): void => {
    history.push({
      pathname: `${url}/assignments/new`,
      state: { isLastAssignment: load },
    });
  };
  const classes = useStyles();
  const { t } = useTranslation();

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }
  const assignments = data.data;
  return (
    <>
      <CommonDivFlex>
        {role !== 'learner' && (
          <Button
            className={classes.createBtn}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {t('coursesMobileBottomSectionForm.createNewTasks')}
            <ArrowDropDownIcon />
          </Button>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(): void => {
            setAnchorEl(null);
          }}
        >
          <MenuItem onClick={onPush(false)}>
            {t('coursesMobileBottomSectionForm.createNewTasks')}
          </MenuItem>
          <MenuItem onClick={onPush(true)}>
            {t('coursesMobileBottomSectionForm.importThePreviousProject')}
          </MenuItem>
        </Menu>
      </CommonDivFlex>
      <FlexDiv>
        {assignments.length !== 0 ? (
          assignments.map((assignment) => {
            return (
              <ProjectCard
                refetch={refetch}
                key={assignment.id}
                assignment={assignment}
                courseRole={role}
                courseId={courseId}
              />
            );
          })
        ) : (
          <BasicDivFlexBothCentered style={{ width: '100%' }}>
            <FontSize14Div>
              {t('coursesMobileBottomSectionForm.thereIsNoCreatedTask')}
            </FontSize14Div>
          </BasicDivFlexBothCentered>
        )}
      </FlexDiv>
      <CommonDivider />
    </>
  );
};

export default React.memo(SimpleMenu);
