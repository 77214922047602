import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchLastAssignment } from 'apis';
import NAForm from '../../components/organisms/newAssignmnets/NAForm';
import { IsEditableContext } from '../EditAssignment/context';
import { Stage } from '../EditAssignment/types';
import LoadingPage from '../LoadingPage';
import { generateStages } from '../../utils/parsers';

type LocationType = {
  isLastAssignment: boolean;
};

const NewAssignment: React.FC = () => {
  const { courseId } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['last-assignment', courseId],
    fetchLastAssignment,
    { enabled: false },
  );
  const { state } = useLocation<LocationType>();

  useEffect(() => {
    if (state && state.isLastAssignment) {
      refetch().then();
    }
  }, [state, refetch]);
  let stages: Stage = {};
  let providingData;

  if (state && state.isLastAssignment) {
    if (!data || isLoading) {
      return <LoadingPage />;
    }
    const { progresses } = data.data;
    stages = generateStages(progresses);
    Object.keys(stages).forEach((key) => {
      const stageKey = key as keyof Stage;
      const progress = stages[stageKey];
      if (progress) {
        progress.start_date = null;
        progress.end_date = null;
      }
    });
    providingData = data.data;
  } else {
    providingData = undefined;
  }
  return (
    <IsEditableContext.Provider
      value={{ isEditable: true, isEdit: false, currentStage: 'PR', files: [] }}
    >
      <NAForm assignmentData={providingData} stages={stages} />
    </IsEditableContext.Provider>
  );
};

export default NewAssignment;
