import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Question = styled.div`
  font-size: 16px;
  font-weight: 400;
  @media (min-width: 768px) {
    display: flex;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const ScoreText = styled.span`
  margin-left: 7px;
  vertical-align: top;
`;

export const Answer = styled.div`
  font-size: 15px;
  font-weight: 300;
  padding-bottom: 20px;
  line-height: 1.4;
  word-break: break-all;
`;

export const Mgt25 = styled.div`
  margin-top: 25px;
  font-weight: 400;
  color: #eb5b5b;
`;
export const Mgt15 = styled.div`
  margin-top: 15px;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 300;
`;

export const CircleBorderBox = styled.div`
  padding: 23px;
  margin: 12px 0;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
`;

export const TopBorderBox = styled.div`
  padding: 23px;
  margin: 12px 0;
  border-top: 1px solid #bcbcbc;
`;

export const CustomDivider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #bcbcbc;
  margin-top: 40px;
`;

export const SpaceDiv = styled.div`
  @media (min-width: 768px) {
    margin-left: 20px;
  }
  @media (max-width: 767px) {
    margin-left: 0px;
  }
  border-left: 10px solid #ececec;
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const NothingDiv = styled.div`
  font-size: 14px;
  padding-bottom: 15px;
`;

export const useReportStyles = makeStyles(() => ({
  mgT: { marginTop: '30px', fontSize: '18px', fontWeight: 400 },
  mgTB: {
    margin: '14px 0',
    fontSize: '18px',
    fontWeight: 400,
    paddingTop: '16px',
  },
  inline: { display: 'inline', marginBottom: 0, marginLeft: '7px' },
  ft20: { fontSize: '20px', color: '#173c51', fontWeight: 400 },
  blue: { color: '#2f64de' },
  red: { marginBottom: '15px', color: '#eb5b5b' },
  reason: { marginTop: '20px' },
  objection: { marginBottom: '15px', color: '#eb5b5b' },
  ft16: { fontSize: '16px', color: '#173c51', fontWeight: 400 },
}));
