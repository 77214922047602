import styled from 'styled-components';

const StageOptionLabel = styled.h6`
  color: #4a6cbb;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 400;
  ::before {
    content: '■';
    margin-right: 5px;
  }
`;

export default StageOptionLabel;
