import React, { useContext } from 'react';
import {
  Bg,
  Content,
  CoverContainer,
  CoverDiv,
  CoverLine,
  DesktopOnlyParagraph,
  LogoImg,
} from 'components/atoms';
import diagonal from 'assets/images/diagonal.svg';
import logo from 'assets/images/logo-typo.svg';
import styled from 'styled-components';
import AppContext from '../../../../App/context';

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper: React.FC = ({ children }) => {
  const { login_image, login_title, login_desc, rgb } = useContext(AppContext);
  let applyRGB: [number, number, number] = [87, 70, 255];
  if (rgb.length === 3) {
    applyRGB = rgb;
  }
  return (
    <Bg login_image={login_image}>
      <CoverDiv rgb={applyRGB}>
        <CoverContainer>
          <CoverLine src={diagonal} />
          <div>
            <LogoImg src={logo} alt="classprep" />
            {login_title && <FlexDiv>with {login_title}</FlexDiv>}
            <DesktopOnlyParagraph>{login_desc}</DesktopOnlyParagraph>
          </div>
        </CoverContainer>
      </CoverDiv>
      <Content>{children}</Content>
    </Bg>
  );
};

export default Wrapper;
