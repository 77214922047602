import React from 'react';
import styled from 'styled-components';

interface EssayWritingProps {
  EditorSection: React.FC;
  BottomBar: React.FC;
}

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const EssayWriting: React.FC<EssayWritingProps> = ({
  EditorSection,
  BottomBar,
}) => {
  return (
    <FlexDiv>
      <EditorSection />
      <BottomBar />
    </FlexDiv>
  );
};

export default EssayWriting;
