import React from 'react';
import CourseEditing from 'templates/CourseEditing';
import ContainerForm from 'components/organisms/courseEditing/ContainerForm';
import ContentsForm from 'components/organisms/courseEditing/ContentsForm/';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { courseFetchAPI } from '../../apis';
import CourseContext from './context';
import LoadingPage from '../LoadingPage';

const CourseEditingPage: React.FC = () => {
  const { courseId } = useParams();
  const history = useHistory();
  const { data, error, isLoading } = useQuery(
    ['course', courseId],
    courseFetchAPI,
  );
  if (error) {
    history.push('/404');
  }
  if (!data?.data || isLoading) {
    return <LoadingPage />;
  }
  return (
    <CourseContext.Provider value={data.data}>
      <CourseEditing Container={ContainerForm} Contents={ContentsForm} />
    </CourseContext.Provider>
  );
};

export default CourseEditingPage;
