import styled from 'styled-components';
import ButtomButton from '../../courses/BottomButton';

const ButtonDelete = styled(ButtomButton)`
  color: #ff314b;
  border-color: #ff314b;
  margin-right: 10px;
  outline: 0;
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
`;

export default ButtonDelete;
