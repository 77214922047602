import React, { useContext } from 'react';
import {
  ActionWrapper,
  Button,
  Field,
  FieldWrapper,
  Form,
  FormTitle,
  Links,
  StyledLink,
} from 'components/atoms';
import { useForm } from 'react-hook-form';
import { signInAPI, SignInAPIProps } from 'apis';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AUTHENTICATION } from '../../../../reducers/authentication';
import AppContext from '../../../../App/context';

const SignInForm: React.FC = () => {
  const { register, handleSubmit } = useForm<SignInAPIProps>();
  const dispatch = useDispatch();
  const { is_sign_up_available } = useContext(AppContext);
  const { t } = useTranslation();

  const onSubmit = async (values: SignInAPIProps): Promise<void> => {
    try {
      const response = await signInAPI(values);
      dispatch({
        type: AUTHENTICATION.SIGN_IN_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      const {
        response: { status },
      } = e;
      toast.warn(t([`login.error.${status}`, 'login.error.unspecific']));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>{t('login.login')}</FormTitle>
      <FieldWrapper>
        <Field
          ref={register}
          placeholder="Email"
          autoFocus
          required
          type="email"
          name="email"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Field
          ref={register}
          placeholder="Password"
          required
          type="password"
          name="password"
        />
      </FieldWrapper>
      <ActionWrapper>
        <Button type="submit">{t('login.login')}</Button>
      </ActionWrapper>
      <Links>
        {is_sign_up_available && (
          <StyledLink to="/sign-up">{t('login.signUp')}</StyledLink>
        )}
        <StyledLink to="/password">{t('login.forgotYourPassword')}</StyledLink>
      </Links>
    </Form>
  );
};

export default SignInForm;
