import React, { useContext, useEffect } from 'react';
import {
  DateTimeContainer,
  DateTimeField,
  DateTimeLabel,
  MiddleDash,
} from 'components/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import moment, { Moment } from 'moment';
import {
  IsEditableContext,
  stageIndex,
} from '../../../../pages/EditAssignment/context';

type DateTimeRowProps = {
  label: string;
  name: string;
  disabled: boolean;
};

type ScheduleType = null | Moment;

const allSchedules = [
  'essayScheduleStart',
  'essayScheduleEnd',
  'peerScheduleStart',
  'peerScheduleEnd',
  'adjustScheduleStart',
  'adjustScheduleEnd',
  'feedbackScheduleStart',
  'feedbackScheduleEnd',
];

const calculateMaxDate = (
  target: string,
  dates: Record<string, ScheduleType>,
): undefined | Moment => {
  const startAfterTimes: Record<string, string[]> = {};
  allSchedules.forEach((schedule, index) => {
    startAfterTimes[schedule] = allSchedules.slice(
      index + 1,
      allSchedules.length,
    );
  });
  const targetSchedules = startAfterTimes[target]
    .filter((schedule) => !!dates[schedule])
    .map((schedule) => dates[schedule]?.unix() || Infinity);

  return targetSchedules.length > 0
    ? moment.unix(Math.min(...targetSchedules))
    : undefined;
};

const calculateMinDate = (
  target: string,
  dates: Record<string, ScheduleType>,
): undefined | Moment => {
  const endBeforeTimes: Record<string, string[]> = {};
  allSchedules.forEach((schedule, index) => {
    endBeforeTimes[schedule] = allSchedules.slice(0, index);
  });
  const targetSchedules = endBeforeTimes[target]
    .filter((schedule) => !!dates[schedule])
    .map((schedule) => dates[schedule]?.unix() || 0);

  return targetSchedules.length > 0
    ? moment.unix(Math.max(...targetSchedules))
    : undefined;
};

const DateTimeRow: React.FC<DateTimeRowProps> = ({ name, label, disabled }) => {
  const { getValues, control, watch, errors, setValue } = useFormContext();
  const startName = `${name}Start`;
  const endName = `${name}End`;
  const startValue = getValues()[startName];
  const endValue = getValues()[endName];
  const { currentStage } = useContext(IsEditableContext);
  watch(startName);
  watch(endName);
  useEffect(() => {
    if (disabled) {
      const index = allSchedules.indexOf(startName);
      if (allSchedules[index - 1] && allSchedules[index - 3]) {
        setValue(
          startName,
          getValues(allSchedules[index - 1]) ||
            getValues(allSchedules[index - 3]),
        );
      } else if (allSchedules[index - 1]) {
        setValue(startName, getValues(allSchedules[index - 1]));
      }
    }
  }, [disabled, setValue, startName, getValues]);
  const prefix = startName.slice(0, 2).toUpperCase();
  const currentIndex = stageIndex.indexOf(currentStage);
  const componentIndex = stageIndex.indexOf(prefix);
  return (
    <DateTimeContainer>
      <DateTimeLabel>{label}</DateTimeLabel>
      <Controller
        as={DateTimeField}
        control={control}
        disablePast
        required={!disabled}
        disabled={disabled || currentIndex >= componentIndex}
        ampm={false}
        value={startValue}
        format="YYYY-MM-DD HH:mm:ss"
        name={startName}
        minDate={calculateMinDate(startName, getValues())}
        maxDate={calculateMaxDate(startName, getValues())}
        error={!!errors[startName]}
        onAccept={() => {
          setValue(startName, getValues(startName).seconds(0));
        }}
      />
      <MiddleDash />
      <Controller
        as={DateTimeField}
        control={control}
        disablePast
        required
        disabled={currentIndex > componentIndex}
        ampm={false}
        rules={{ required: true }}
        value={endValue}
        error={!!errors[endName]}
        minDate={calculateMinDate(endName, getValues())}
        maxDate={calculateMaxDate(endName, getValues())}
        format="YYYY-MM-DD HH:mm:ss"
        name={endName}
        onAccept={() => {
          setValue(endName, getValues(endName).seconds(59));
        }}
      />
    </DateTimeContainer>
  );
};

export default React.memo(DateTimeRow);
