import { CourseRoleType } from 'apis';
import { createContext } from 'react';

export const RoleContext = createContext<CourseRoleType>({
  is_assistant: false,
  is_instructor: false,
  is_learner: false,
  course: '',
  enroll: null,
});

type PrivateContextType = {
  is_staff: boolean;
};

const PrivateContext = createContext<PrivateContextType>({
  is_staff: false,
});

export default PrivateContext;
