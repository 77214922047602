import { createContext, Dispatch, RefObject, SetStateAction } from 'react';
import ReactQuill from 'react-quill';

type QuillEditorEssayWritingContextType = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  editorRef: RefObject<ReactQuill> | null;
  isRevisement?: boolean;
};

const QuillEditorEssayWritingContext = createContext<
  QuillEditorEssayWritingContextType
>({
  value: '',
  setValue: () => {},
  editorRef: null,
});

export default QuillEditorEssayWritingContext;
