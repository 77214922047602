import React, { useContext } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { DesktopOnly, NAContainer } from 'components/atoms/newAssignmnets';
import DefaultInfo from 'components/molecules/newAssignments/DefaultInfo';
import StageSelection from 'components/molecules/newAssignments/StageSelection';
import Scheduling from 'components/molecules/newAssignments/Scheduling';
import ScoreWeight from 'components/molecules/newAssignments/ScoreWeight';
import { useHistory, useParams } from 'react-router-dom';
import {
  assignmentCreateAPI,
  AssignmentResponseType,
  assignmentUpdateAPI,
} from 'apis';
import { toast } from 'react-toastify';
import { Stage } from 'pages/EditAssignment/types';
import moment from 'moment';
import styled from 'styled-components';
import { serializeFormData } from 'utils';
import { useTranslation } from 'react-i18next';
import SettingByStage from '../SettingByStage';
import FormAction from '../../../molecules/newAssignments/FormAction';
import serializeAssignment, { AssignmentFormData } from './serializeAssignment';
import { IsEditableContext } from '../../../../pages/EditAssignment/context';
import { SCORE_TYPE } from '../../../../utils/constants';
import PublicOptionSetting from 'components/molecules/newAssignments/PublicOptionSetting';

export type NAFormProps = {
  assignmentData?: AssignmentResponseType;
  stages?: Stage;
};

const NoticeDiv = styled.div`
  margin-bottom: 15px;
  color: indianred;
  font-size: 14px;
  line-height: 1.3;
`;

const NAForm: React.FC<NAFormProps> = ({ assignmentData, stages }) => {
  const { t } = useTranslation();

  const methods = useForm<AssignmentFormData>({
    defaultValues: {
      name: assignmentData?.name || '',
      description: assignmentData?.description || '',
      isAuto: assignmentData?.is_auto_schedule ? 'true' : 'false',
      isDirectlyStarting: assignmentData?.is_directly_starting,
      isPrevious: assignmentData?.is_continue_from_previous_step,
      essay: !!stages?.ES || true,
      limitType: stages?.ES ? stages.ES.stage_object.limit_type : 'L',
      essayLimit: stages?.ES ? stages.ES.stage_object.max_length : 2500,
      [SCORE_TYPE.WRITING]: stages?.ES
        ? stages.ES.stage_object.max_writing_score
        : 70,
      essayScheduleStart: stages?.ES?.start_date
        ? moment(stages.ES.start_date)
        : null,
      essayScheduleEnd: stages?.ES?.end_date
        ? moment(stages.ES.end_date)
        : null,
      question: !!stages?.QU,
      quizs: stages?.QU ? stages.QU.stage_object.quizs : [{ quiz: '' }],
      [SCORE_TYPE.PEER_REVIEW]: stages?.PE
        ? stages.PE.stage_object.max_review_score
        : 15,
      [SCORE_TYPE.META]: stages?.PE
        ? stages.PE.stage_object.max_metacognition_score
        : 15,
      peerReviewCount: stages?.PE ? stages.PE.stage_object.peer_count : 3,
      standards: stages?.PE
        ? stages.PE.stage_object.standards
        : [
            {
              max_point: 6,
              question: `${t(
                'componentsOrganismsNewAssignmnetsNAForm.howCreativeIsIt',
              )}`,
            },
          ],
      peer: !!stages?.PE || true,
      peerScheduleStart: stages?.PE?.start_date
        ? moment(stages.PE.start_date)
        : null,
      peerScheduleEnd: stages?.PE?.end_date ? moment(stages.PE.end_date) : null,
      adjust: !!stages?.AD,
      adjustScheduleStart: stages?.AD?.start_date
        ? moment(stages.AD.start_date)
        : null,
      adjustScheduleEnd: stages?.AD?.end_date
        ? moment(stages.AD.end_date)
        : null,
      feedback: !!stages?.FE,
      [SCORE_TYPE.FEEDBACK_REVIEW]: stages?.FE
        ? stages.FE.stage_object.max_review_score
        : 10,
      feedbackMaxPoint: stages?.FE ? stages.FE.stage_object.max_point : 10,
      feedbackScheduleStart: stages?.FE?.start_date
        ? moment(stages.FE.start_date)
        : null,
      feedbackScheduleEnd: stages?.FE?.end_date
        ? moment(stages.FE.end_date)
        : null,
      revise: !!stages?.RE,
      [SCORE_TYPE.REVISEMENT]: stages?.RE
        ? stages.RE.stage_object.max_revisement_score
        : 0,
      isPublic: assignmentData?.is_public ?? true,
    },
  });
  const { isEditable, isEdit } = useContext(IsEditableContext);
  const history = useHistory();
  const { courseId, assignmentId, role } = useParams();
  const onSubmit = async (data: AssignmentFormData): Promise<void> => {
    const weights = [
      data[SCORE_TYPE.WRITING],
      data[SCORE_TYPE.PEER_REVIEW],
      data[SCORE_TYPE.META],
      data[SCORE_TYPE.FEEDBACK_REVIEW],
      data[SCORE_TYPE.REVISEMENT],
    ];
    const total = weights.reduce((a, b) => {
      return Number(a || 0) + Number(b || 0);
    }, 0);
    if (total !== 100) {
      const errMsg = t('componentsOrganismsNewAssignmnetsNAForm.total');
      toast.warn(errMsg);
      throw Error(errMsg);
    }
    const assignment = serializeFormData(serializeAssignment(data));
    try {
      if (!isEdit) {
        await assignmentCreateAPI({ courseId, assignment });
        toast.success(
          `${t('componentsOrganismsNewAssignmnetsNAForm.aTaskWasCreated')}`,
        );
      } else {
        await assignmentUpdateAPI({ courseId, assignmentId, assignment });
        toast.success(
          `${t(
            'componentsOrganismsNewAssignmnetsNAForm.theTaskHasBeenModified',
          )}`,
        );
      }
      history.push(`/courses/${courseId}/${role}`);
    } catch (e) {
      if (e.response.status === 406) {
        toast.warn(
          `${t('componentsOrganismsNewAssignmnetsNAForm.alreadyExist')}`,
        );
      } else {
        const msg = Object.values(e.response.data)
          .map((r) => {
            return r;
          })
          .join('\n');
        toast.warn(
          msg || `${t('componentsOrganismsNewAssignmnetsNAForm.wrongRequest')}`,
        );
      }
    }
  };
  const { setValue } = methods;
  const { feedback, revise } = methods.getValues();
  React.useEffect(() => {
    if (!isEdit) {
      if (feedback && !revise) {
        setValue(SCORE_TYPE.WRITING, 70);
        setValue(SCORE_TYPE.PEER_REVIEW, 10);
        setValue(SCORE_TYPE.META, 10);
        setValue(SCORE_TYPE.FEEDBACK_REVIEW, 10);
        setValue(SCORE_TYPE.REVISEMENT, 0);
      } else if (feedback && revise) {
        setValue(SCORE_TYPE.WRITING, 60);
        setValue(SCORE_TYPE.PEER_REVIEW, 10);
        setValue(SCORE_TYPE.META, 10);
        setValue(SCORE_TYPE.FEEDBACK_REVIEW, 10);
        setValue(SCORE_TYPE.REVISEMENT, 10);
      } else {
        setValue(SCORE_TYPE.WRITING, 70);
        setValue(SCORE_TYPE.PEER_REVIEW, 15);
        setValue(SCORE_TYPE.META, 15);
        setValue(SCORE_TYPE.FEEDBACK_REVIEW, 0);
        setValue(SCORE_TYPE.REVISEMENT, 0);
      }
    }
  }, [isEdit, feedback, setValue, revise]);

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <NAContainer>
          <DesktopOnly>
            {t('componentsOrganismsNewAssignmnetsNAForm.task')}{' '}
            {isEdit
              ? `${t('componentsOrganismsNewAssignmnetsNAForm.edit')}`
              : `${t('componentsOrganismsNewAssignmnetsNAForm.create')}`}
          </DesktopOnly>
          {!isEditable && (
            <NoticeDiv>
              {t('componentsOrganismsNewAssignmnetsNAForm.notice')}
            </NoticeDiv>
          )}
          <DefaultInfo />
          <StageSelection />
          <SettingByStage />
          <Scheduling />
          <ScoreWeight />
          <PublicOptionSetting />
          <FormAction />
        </NAContainer>
      </form>
    </FormContext>
  );
};

export default NAForm;
