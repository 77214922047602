import styled from 'styled-components';
import CommonDivFlex from '../CommonDivFlex';

const CommonDivFlexEnd = styled(CommonDivFlex)`
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 18px;
`;

export default CommonDivFlexEnd;
