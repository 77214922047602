import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackReviewTemplate from 'templates/FeedbackReview';
import ContainerForm from '../../../components/organisms/feedbackReview/ContainerForm';
import EssayViewForm from '../../../components/organisms/feedbackReview/EssayViewForm';
import ReviewCardForm from '../../../components/organisms/feedbackReview/ReviewCardForm';
import PerformanceContext from '../PerformanceMain/context';

const FeedbackReview: React.FC = () => {
  const { setPageTitle } = useContext(PerformanceContext);
  const { t } = useTranslation();
  useEffect(() => {
    setPageTitle(t('app.피드백평가'));
  }, [setPageTitle, t]);

  return (
    <FeedbackReviewTemplate
      Container={ContainerForm}
      EssayView={EssayViewForm}
      ReviewCard={ReviewCardForm}
    />
  );
};

export default React.memo(FeedbackReview);
