import { takeLatest } from 'redux-saga/effects';
import { loginCheckAPI } from 'apis';
import createRequestSaga from '../utils/sagaHelpers';
import { LOGIN_CHECK } from '../reducers/loginCheck';
import { signOut } from '../reducers/authentication';

const fetchLoginCheckSaga = createRequestSaga(
  LOGIN_CHECK.FETCH_LOGIN_CHECK,
  loginCheckAPI,
  [],
  [signOut()],
);

export default function* loginCheckSaga(): Generator {
  yield takeLatest(LOGIN_CHECK.FETCH_LOGIN_CHECK, fetchLoginCheckSaga);
}
