import React from 'react';
import { CommentSectionFormProps } from '../../components/organisms/posts/CommentSectionForm';

interface PostWritingProps {
  Container: React.FC<CommentSectionFormProps>;
  PostSection: React.FC;
  AddFilesSection: React.FC;
  ButtonsSection: React.FC;
  post?: CommentSectionFormProps;
}

const PostWriting: React.FC<PostWritingProps> = ({
  Container,
  PostSection,
  AddFilesSection,
  ButtonsSection,
  post,
}) => {
  return (
    <Container {...post}>
      <PostSection />
      <AddFilesSection />
      <ButtonsSection />
    </Container>
  );
};

export default PostWriting;
