import styled from 'styled-components';

const CommentViewBox = styled.div`
  margin-top: 8px;
  padding: 8px 12px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 1.4;
`;

export default CommentViewBox;
