import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ButtonCancel, ContentsBodyDiv } from 'components/atoms';
import styled from 'styled-components';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useTranslation } from 'react-i18next';
import { EditorType } from '../../../../apis/performances';

export type EssayViewProps = {
  handleClose: () => void;
  open: boolean;
  content: string;
  content2: string;
  content_editor_type: EditorType;
  content2_editor_type: EditorType;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  overflow: auto;
  flex: 1;
`;

type BlockType = {
  text: string;
  data: Record<string, string>;
  depth: number;
  entityRanges?: [];
  length: number;
  inlineStyleRanges?: [];
  key: string;
  type: string;
};

const EssayView: React.FC<EssayViewProps> = ({
  handleClose,
  open,
  content,
  content2,
}) => {
  const defaultStyles = {
    variables: {
      light: {
        diffViewerBackground: '#fff',
        diffViewerColor: '#173c51',
        addedBackground: '#e6ffed',
        addedColor: '#24292e',
        removedBackground: '#ffeef0',
        removedColor: '#24292e',
        wordAddedBackground: '#acf2bd',
        wordRemovedBackground: '#fdb8c0',
        addedGutterBackground: '#cdffd8',
        removedGutterBackground: '#ffdce0',
        gutterBackground: '#f7f7f7',
        gutterBackgroundDark: '#f3f1f1',
        highlightBackground: '#fffbdd',
        highlightGutterBackground: '#fff5b1',
        codeFoldGutterBackground: '#dbedff',
        codeFoldBackground: '#f1f8ff',
        emptyLineBackground: '#fafbfc',
        gutterColor: '#212529',
        addedGutterColor: '#212529',
        removedGutterColor: '#212529',
        codeFoldContentColor: '#212529',
        diffViewerTitleBackground: '#fff',
        diffViewerTitleColor: '#173c51',
        diffViewerTitleBorderColor: '#fff',
      },
    },
    titleBlock: { fontWeight: 400, fontSize: '18px' },
  };
  let oldValue;
  let newValue;
  try {
    oldValue = content
      ? JSON.parse(content)
          .blocks.map((block: BlockType) => {
            return block.text;
          })
          .join(' ')
      : '';
  } catch (e) {
    oldValue = content;
  }
  try {
    newValue = content2
      ? JSON.parse(content2)
          .blocks.map((block: BlockType) => {
            return block.text;
          })
          .join(' ')
      : '';
  } catch (e) {
    newValue = content2;
  }
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogContent>
        <ContentsBodyDiv style={{ display: 'flex' }}>
          <Container>
            <ReactDiffViewer
              oldValue={oldValue}
              newValue={newValue}
              compareMethod={DiffMethod.WORDS}
              splitView
              styles={defaultStyles}
              hideLineNumbers
              leftTitle={t('modalsEssayViewCompare.beforeModification') ?? ''}
              rightTitle={
                t('modalsEssayViewCompare.textAfterModification') ?? ''
              }
            />
          </Container>
        </ContentsBodyDiv>
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <ButtonCancel
          type="button"
          onClick={handleClose}
          style={{ marginRight: 0 }}
        >
          {t('modalsEssayViewCompare.close')}
        </ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};

export default EssayView;
