import React from 'react';
import EditProfileForm from '../../components/organisms/profiles/EditProfileForm';
import ContainerForm from '../../components/organisms/myPages/ContainerForm';

const UsersEdit: React.FC = () => {
  return (
    <ContainerForm>
      <EditProfileForm />
    </ContainerForm>
  );
};

export default React.memo(UsersEdit);
