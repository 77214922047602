import styled from 'styled-components';

const BtnFullWidthOutline = styled.button`
  border-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #5646ff;
  border: 1px solid #5646ff;
  border-radius: 8px;
  padding: 8px 24px 7px 24px;
  transition: 0.3s;
  background: none;
  text-align: center;
  outline: 0;
  line-height: 1.4;
  font-size: 14px;
`;

export default BtnFullWidthOutline;
