import React from 'react';
import { TopTitle } from 'components/atoms';
import { useTranslation } from 'react-i18next';

const TitleForm: React.FC = () => {
  const { t } = useTranslation();
  return <TopTitle>{t('helpsTitleForm.manual')}</TopTitle>;
};

export default TitleForm;
