export default {
  colors: {
    black: 'rgba(0, 0, 0, 0.8)',
    blue: 'rgba(0,101,193)',
    lightBlue: 'rgba(102,176,222, 0.2)',
    borderBlue: 'rgba(102,176,222)',
    darkBlue: 'rgba(0,101,193, 0.8)',
    white: '#ffffff',
    lightGray: '#929191',
  },
  transition: '0.3s',
};
