import { createReducer } from 'typesafe-actions';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AuthenticationState } from './types';
import { AUTHENTICATION } from './actions';

const initialState = {
  signIn: {
    token: null,
    is_staff: false,
    isLoggedIn: !!localStorage.getItem('token'),
    errorOccur: null,
    errors: [],
  },
};

const authentication = createReducer<AuthenticationState>(initialState, {
  [AUTHENTICATION.SIGN_IN_SUCCESS]: (state, action) => {
    localStorage.setItem('token', action.payload.token);
    return {
      ...state,
      signIn: {
        ...state.signIn,
        isLoggedIn: true,
        token: action.payload.token,
        is_staff: action.payload.is_staff,
      },
    };
  },
  [AUTHENTICATION.SIGN_IN_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersAuthenticationReducer.emailOrPasswordIsDifferent'));
    errorNotify();
    localStorage.removeItem('token');
    return {
      ...state,
      signIn: {
        ...state.signIn,
        errorOccur: new Date(),
      },
    };
  },
  [AUTHENTICATION.SIGN_UP_SUCCESS]: (state) => {
    const successNotify = (): ReactText =>
      toast.success(
        t(
          'reducersAuthenticationReducer.theAccountHasBeenCreatedYouCanLogInNow',
        ),
      );
    successNotify();
    return {
      ...state,
    };
  },
  [AUTHENTICATION.SIGN_UP_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersAuthenticationReducer.thisIsTheWrongRequest'));
    errorNotify();
    return {
      ...state,
    };
  },
  [AUTHENTICATION.SIGN_OUT]: (state) => {
    localStorage.removeItem('token');
    return {
      ...state,
      signIn: {
        ...state.signIn,
        isLoggedIn: false,
      },
    };
  },
  [AUTHENTICATION.CHANGE_PASSWORD_SUCCESS]: (state) => {
    const successNotify = (): ReactText =>
      toast.success(
        t('reducersAuthenticationReducer.thePasswordHasBeenChanged'),
      );
    successNotify();
    return {
      ...state,
    };
  },
  [AUTHENTICATION.CHANGE_PASSWORD_FAILURE]: (state) => {
    const errorNotify = (): ReactText =>
      toast.warn(t('reducersAuthenticationReducer.thisIsTheWrongRequest'));
    errorNotify();
    return {
      ...state,
    };
  },
});

export default authentication;
