import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Help from 'pages/Help';
import { Layout, MainContainer } from '../../components/atoms/mains';
import MainTopBar from '../../components/molecules/mains/MainTopbar';
import MainSide from '../../components/organisms/mains/MainSide';
import MyPage from '../MyPage';
import DashboardPage from '../Dashboard';
import UsersEdit from '../UsersEdit';
import SupportsPage from '../Supports';
import { MiddleCourseRouter } from '../../routes/Private/CourseRoute';

const Main: React.FC = () => {
  return (
    <Layout>
      <MainTopBar />
      <MainSide />
      <MainContainer>
        <Switch>
          <Route path="/" exact component={DashboardPage} />
          <Route path="/helps" exact component={Help} />
          <Route path="/my-page" exact component={MyPage} />
          <Route path="/users/edit" exact component={UsersEdit} />
          <Route path="/supports" exact component={SupportsPage} />
          <Route
            path="/courses/:courseId(\d+)/:role"
            component={MiddleCourseRouter}
          />
          <Redirect path="*" to="/" />
        </Switch>
      </MainContainer>
    </Layout>
  );
};

export default React.memo(Main);
