import React, { useContext, useState } from 'react';
import {
  BasicDivBlock,
  BtnSkyblueLgOutline,
  CardBorderedOnelineDiv,
} from 'components/atoms';
import styled from 'styled-components';
import EssayViewModal from 'components/organisms/modals/EssayView';
import { useTranslation } from 'react-i18next';
import SwitchPerformanceContext, {
  SwitchPerformanceContextType,
} from '../../../../pages/PerformAssignment/SwitchPage/context';
import { FeedbackReviewPerformanceType } from '../../../../apis/performances';

const NameDiv = styled.div`
  margin-right: 37px;
  white-space: nowrap;
`;

const EssayViewForm: React.FC = () => {
  const [open, setOpen] = useState(false);
  const {
    stage_object: { essay },
  } = useContext(SwitchPerformanceContext) as SwitchPerformanceContextType &
    FeedbackReviewPerformanceType;
  const { t } = useTranslation();

  const handleOpenToggle = (state: boolean) => (): void => {
    setOpen(state);
  };
  return (
    <BasicDivBlock>
      <CardBorderedOnelineDiv style={{ display: 'flex' }}>
        <NameDiv>{t('feedbackReviewEssayViewForm.myEssay')}</NameDiv>
        <BtnSkyblueLgOutline
          style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}
          onClick={handleOpenToggle(true)}
        >
          {t('feedbackReviewEssayViewForm.essayText')}
        </BtnSkyblueLgOutline>
        <EssayViewModal
          editor_type={essay.editor_type}
          content={essay.content}
          files={essay.files}
          handleClose={handleOpenToggle(false)}
          open={open}
        />
      </CardBorderedOnelineDiv>
    </BasicDivBlock>
  );
};

export default React.memo(EssayViewForm);
