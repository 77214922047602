import styled from 'styled-components';

const PeerReviewOptionSelect = styled.select`
  appearance: none;
  font-size: 18px;
  padding: 5px 45px 5px 40px;
  background: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-align-last: center;
`;

export default PeerReviewOptionSelect;
