import styled from 'styled-components';

const CommentButton = styled.button`
  color: white;
  background: #71bad8;
  border: 1px solid #71bad8;
  -webkit-appearance: button;
  display: inline-block;
  border-radius: 8px;
  padding: 8px 24px 7px 24px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  transition: 0.3s;
  letter-spacing: -1px;
`;

export default CommentButton;
