import styled, { css, FlattenInterpolation } from 'styled-components';
import MenuItem from '../MenuItem';

type MenuItemProps = {
  isActive?: boolean;
};

const MiniItem = styled(MenuItem)`
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: rgba(255, 255, 255, 0.9);
  }

  ${({ isActive }): FlattenInterpolation<MenuItemProps> => css`
    color: ${isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)'};
    background-color: ${isActive ? '#0d1b4c' : 'transparent'};
    font-weight: ${isActive ? 400 : 300};
  `}
`;

export default MiniItem;
