import { requests, ResponseType } from 'utils';
import {
  PatchUserDetailProps,
  UserDetailType,
  UserListType,
  UserCreationProps,
  UserListItemType,
} from './types';

export const fetchUserPageList = async (
  queryString: string,
): Promise<ResponseType<UserListType>> => {
  return requests().get(`/api/v1/users/pages/?${queryString}`);
};

export const fetchUserList = async (): Promise<
  ResponseType<UserListItemType[]>
> => {
  return requests().get(`/api/v1/users/`);
};

export const fetchUserDetail = async (
  userId: number,
): Promise<ResponseType<UserDetailType>> => {
  return requests().get(`/api/v1/users/${userId}/`);
};

export const patchUserDetail = async ({
  userId,
  params,
}: PatchUserDetailProps): Promise<ResponseType<UserDetailType>> => {
  return requests().patch(`/api/v1/users/${userId}/`, params);
};

export const createUser = async (
  data: UserCreationProps,
): Promise<ResponseType<UserDetailType>> => {
  return requests().post(`/api/v1/users/`, data);
};
