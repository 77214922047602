import React, { useContext, useEffect } from 'react';
import PeerReviewMatchingTemplate from 'templates/PeerReviewMatching';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { peerReviewMatchingFetchAPI } from 'apis/peerReviews';
import { useMediaQuery } from 'react-responsive';
import ContainerForm from '../../../components/organisms/peerReviewMatching/Container';
import EssaySectionForm from '../../../components/organisms/peerReviewMatching/EssaySection';
import ReviewSectionForm from '../../../components/organisms/peerReviewMatching/ReviewSection';
import TopBarSectionForm from '../../../components/organisms/peerReviewMatching/TopBarSection';
import PeerReviewMatchingContext from './context';
import PerformanceContext from '../PerformanceMain/context';
import ReviewSectionMobileForm from '../../../components/organisms/peerReviewMatching/ReviewSectionMobile';

const PeerReviewMatching: React.FC = () => {
  const { matchingId } = useParams();
  const { data, isLoading } = useQuery(
    ['peerReviewMatchingFetchAPI', matchingId],
    peerReviewMatchingFetchAPI,
  );
  const { setCollapse } = useContext(PerformanceContext);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  useEffect(() => {
    setCollapse(true);
    return (): void => {
      setCollapse(false);
    };
  }, [setCollapse]);

  if (!data || isLoading) {
    return <div>loading...</div>;
  }

  return (
    <PeerReviewMatchingContext.Provider value={data.data}>
      <PeerReviewMatchingTemplate
        Container={ContainerForm}
        TopBarSection={TopBarSectionForm}
        EssaySection={EssaySectionForm}
        ReviewSection={isDesktop ? ReviewSectionForm : ReviewSectionMobileForm}
      />
    </PeerReviewMatchingContext.Provider>
  );
};

export default React.memo(PeerReviewMatching);
