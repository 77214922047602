import styled from 'styled-components';

const MobileOnlyOptionLabel = styled.h6`
  color: #4a6cbb;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 400;
  @media (min-width: 768px) {
    display: none;
  }
`;

export default MobileOnlyOptionLabel;
