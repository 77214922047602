import { createContext } from 'react';
import { FetchReportResponseType } from '../../apis/reports';
import { EditorType } from '../../apis/performances';

const ReportContext = createContext<
  FetchReportResponseType & { refetch: () => void }
>({
  id: 0,
  name: '',
  essay_writing_score: 0,
  peer_review_score: 0,
  metacognition_score: 0,
  feedback_review_score: 0,
  is_average_essay_writing_score: false,
  is_average_feedback_review_score: false,
  late: false,
  completed: false,
  revisement_score: 0,
  origin_revisement_score: null,
  peer_review_performance: {
    reviewers: [],
    targets: [],
    objection: {
      id: 0,
      comment: '',
    },
  },
  comment: null,
  total_info: {
    rank: 0,
    percent: 0,
    member_cnt: 0,
  },
  questions: {
    id: 0,
    responses: [],
  },
  peer_review_points: [],
  feedback_review_point: {
    avg: 0,
    std: 0,
  },
  refetch: () => {},
  essay_writing_score_avg: 0,
  peer_review_score_avg: 0,
  metacognition_score_avg: 0,
  feedback_review_score_avg: 0,
  revisement_score_avg: 0,
  scores: [],
  is_my_report: false,
  pr_performance_id: 0,
  essay: {
    files: [],
    id: 0,
    editor_type: EditorType.DRAFT,
    plain_text: '',
    is_late: false,
    content: '',
  },
  revisement: {
    files: [],
    id: 0,
    editor_type: EditorType.DRAFT,
    plain_text: '',
    is_late: false,
    content: '',
  },
});

export default ReportContext;
