import AttachmentIcon from '@material-ui/icons/Attachment';
import React, { useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FileLabel,
  InputLabel,
  NAField,
  NAInput,
  NATextArea,
  Section,
  SectionLabelNormal,
  FileAddBtn,
} from '../../../atoms/newAssignmnets';
import { IsEditableContext } from '../../../../pages/EditAssignment/context';
import renderFiles from '../../../../utils/renderFiles';
import { AssignmentFileType } from '../../../../apis/assignments';

export const parseFiles = (targetFiles: FileList): string[] => {
  if (targetFiles) {
    return [...Array.from(targetFiles)].map((file) => file.name);
  }
  return [];
};

export const LocalFiles = ({
  parsedFiles,
  serverFiles,
}: {
  parsedFiles: string[];
  serverFiles: AssignmentFileType[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FileAddBtn>
        <AttachmentIcon />
        {`${t('newAssignmentsDefaultInfo.file')} ${
          serverFiles.length + parsedFiles.length > 0
            ? t('newAssignmentsDefaultInfo.change')
            : t('newAssignmentsDefaultInfo.add')
        }`}
      </FileAddBtn>
      {parsedFiles.map((f, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i} style={{ marginRight: 10 }}>
          {f}
        </span>
      ))}
    </>
  );
};

const DefaultInfo: React.FC = () => {
  const { register, getValues, watch } = useFormContext();
  const files = getValues('files');
  const filesRef = useRef({});
  filesRef.current = watch('files', undefined);
  const parsedFiles = parseFiles(files);
  const { files: serverFiles } = useContext(IsEditableContext);
  const { t } = useTranslation();

  return (
    <Section>
      <SectionLabelNormal>
        {t('newAssignmentsDefaultInfo.basicInformation')}
      </SectionLabelNormal>
      <NAField>
        <InputLabel>{t('newAssignmentsDefaultInfo.projectTitle')}</InputLabel>
        <NAInput
          required
          name="name"
          placeholder={t('newAssignmentsDefaultInfo.taskName') ?? '과제 이름'}
          ref={register}
          maxLength={50}
        />
      </NAField>
      <NAField>
        <InputLabel>{t('newAssignmentsDefaultInfo.assignment')}</InputLabel>
        <NATextArea
          required
          ref={register}
          name="description"
          placeholder={
            t('newAssignmentsDefaultInfo.descriptionPlaceholder') ?? ''
          }
        />
      </NAField>
      <NAField>
        {renderFiles(serverFiles, parsedFiles)}
        <InputLabel style={{ marginTop: '10px' }}>
          {t('newAssignmentsDefaultInfo.youCanAttachAPluralityOfFiles')}
        </InputLabel>
        <FileLabel style={{ alignItems: 'center' }}>
          <LocalFiles parsedFiles={parsedFiles} serverFiles={serverFiles} />
          <input
            type="file"
            multiple
            ref={register}
            name="files"
            style={{ display: 'none' }}
          />
        </FileLabel>
      </NAField>
    </Section>
  );
};

export default DefaultInfo;
