import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PeerReviewTemplate from 'templates/PeerReview';
import ContainerForm from '../../../components/organisms/peerReview/ContainerForm';
import WhiteBackCardForm from '../../../components/organisms/peerReview/WhiteBackCardForm';
import PerformanceContext from '../PerformanceMain/context';

const PeerReview: React.FC = () => {
  const { setPageTitle } = useContext(PerformanceContext);
  const { t } = useTranslation();
  useEffect(() => {
    setPageTitle(t('app.동료평가'));
  }, [setPageTitle, t]);
  return (
    <PeerReviewTemplate
      Container={ContainerForm}
      WhiteBackCard={WhiteBackCardForm}
    />
  );
};

export default React.memo(PeerReview);
