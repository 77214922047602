import styled from 'styled-components';

const Container = styled.div`
  counter-reset: section;
  @media (min-width: 768px) {
    // padding: 100px 40px 40px 40px;
    padding: 40px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export default Container;
