import styled from 'styled-components';

const Card = styled.div`
  margin-bottom: 24px;
  padding: 28px 24px 23px 24px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;

export default Card;
