import React from 'react';
import Authentication from 'templates/Authentication';
import Wrapper from '../../components/organisms/authentications/Wrapper';
import SignInForm from '../../components/organisms/authentications/SignInForm';

const SignIn: React.FC = () => {
  return <Authentication Wrapper={Wrapper} Form={SignInForm} />;
};

export default React.memo(SignIn);
