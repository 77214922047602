import styled from 'styled-components';

const NumIconLgWithoutNum = styled.div`
  padding: 10px 10px 10px 15px;
  font-size: 16px;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  background: #0658a9;
  color: #fff;
  margin: auto 0;
  z-index: 1;
  border: solid 1px #0658a9;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  ::before {
    font-size: 15px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    background: #fff;
    color: #0658a9;
    margin-right: 7px;
    border-radius: 50%;
    font-weight: 400;
    text-align: center;
  }
`;

export default NumIconLgWithoutNum;
