import styled from 'styled-components';

const LicenceLink = styled.a`
  margin-left: 10px;
  text-decoration: underline;
  font-size: 12px;
  color: #173c51;
`;

export default LicenceLink;
