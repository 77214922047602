import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {
  CommonDiv,
  CommonDivFlex,
  CommonDivFlexEnd,
  CommonDivider,
  TopContainer,
  TopExplain,
  TopLink,
  TopManageButton,
  TopTitleText,
  BtnGrayLgOutline,
} from 'components/atoms';
import {
  courseLearnersCountFetchAPI,
  learnerInfoFetchAPI,
  CourseResponse,
} from 'apis';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import StudentsManageDialog from '../../modals/StudentsManage';
import MyClassInfo from '../../../molecules/courses/MyClassInfo';
import CourseContext from '../../../../pages/Course/context';
import renderFiles from '../../../../utils/renderFiles';

const useStyles = makeStyles(() => ({
  th: {
    color: '#173c51',
    height: '41px',
    paddingRight: '10px',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  flex: { display: 'flex', alignItems: 'center' },
  tdColor: {
    color: '#5646ff',
    fontSize: '15px',
  },
  tdBlack: {
    color: '#173c51',
    fontSize: '15px',
  },
  tdName: {
    fontWeight: 'bold',
    paddingRight: '5px',
  },
  codeBtn: {
    fontSize: '18px',
    padding: '5px 14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '16px',
    borderColor: '#5646ff',
    color: '#5646ff',
  },
  paddingR100: { paddingRight: '100px' },
  paddingBoth: { padding: '0 10px', fontWeight: 400 },
  paddingB20: { paddingBottom: '20px', display: 'flex', alignItems: 'center' },
  editText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  outlineIcon: {
    verticalAlign: 'middle',
    paddingRight: '5px',
    fontSize: '18px',
  },
  peopleIcon: {
    color: '#5646ff',
    fontSize: '30px',
    paddingRight: '5px',
  },
  manage: {
    alignItems: 'center',
    marginBottom: 0,
  },
}));

const Btn = styled(BtnGrayLgOutline)`
  border: 1px solid rgba(23, 60, 81, 0.5);
  color: rgb(23, 60, 81);
  margin-left: 10px;
  margin-top: 10px;
  padding: 8px 24px 7px 24px;
  font-size: 15px;
`;

const TopSectionForm: React.FC<CourseResponse> = ({
  name,
  instructor,
  description,
  code,
}) => {
  const classes = useStyles();

  const [open2, setOpen2] = useState(false);
  const handleOpenToggle2 = (state: boolean) => (): void => {
    setOpen2(state);
  };
  const {
    url,
    params: { role, courseId },
  } = useRouteMatch();

  const { data, refetch } = useQuery(
    ['learner-count', courseId],
    courseLearnersCountFetchAPI,
  );

  const { data: learnerInfoData } = useQuery(
    ['learnerInfo', courseId],
    learnerInfoFetchAPI,
  );

  const { files } = useContext(CourseContext);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <TopContainer>
        <CommonDivFlex style={{ marginBottom: 0 }}>
          <TopTitleText>{name}</TopTitleText>
          <CommonDivFlexEnd className={classes.manage}>
            {(role === 'instructor' || role === 'assistant') && (
              <>
                <TopLink onClick={handleOpenToggle2(true)}>
                  {t('coursesTopSectionForm.management')}
                </TopLink>
                <StudentsManageDialog
                  countRefetch={refetch}
                  handleClose={handleOpenToggle2(false)}
                  open={open2}
                />
              </>
            )}
            <CommonDivFlex className={classes.manage}>
              <PeopleAltIcon className={classes.peopleIcon} />
              <TopExplain>{data?.data.count}</TopExplain>
            </CommonDivFlex>
          </CommonDivFlexEnd>
        </CommonDivFlex>
        <CommonDiv style={{ marginBottom: '35px' }}>
          <TopExplain>{description}</TopExplain>
        </CommonDiv>
        <div>
          <div className={classes.paddingB20}>
            <span className={classes.th}>
              {t('coursesTopSectionForm.teachingInformation')}
            </span>
            <span className={classes.tdColor}>
              <span className={classes.tdName}>{instructor.name}</span>
              <span>{`(${instructor.email})`}</span>
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.th}>
              {t('coursesTopSectionForm.lectureCode')}
            </div>
            <div className={classes.paddingR100}>
              <span className={classes.codeBtn}>{code}</span>
            </div>
            <div className={classes.flex}>
              <div className={classes.th}>
                {t('coursesTopSectionForm.lecturePlan')}
              </div>
              <div className={classes.tdBlack}>{renderFiles(files, [])}</div>
            </div>

            {/* 로그인 정보가 조교면 리다이렉트 */}
            {learnerInfoData?.data.is_assistant && (
              <Redirect to={`/courses/${courseId}/assistant`} />
            )}

            {/* 주소가 학생이면 학생으로 이동 */}
            {role === 'learner' && <MyClassInfo />}
          </div>
        </div>

        {role === 'learner' ? (
          <CommonDivFlexEnd>
            <TopManageButton
              onClick={(): void => history.push(`${url}/my-score`)}
            >
              {t('coursesTopSectionForm.taskScoreTrend')}
            </TopManageButton>
          </CommonDivFlexEnd>
        ) : (
          <CommonDivFlexEnd>
            <TopManageButton
              onClick={(): void => history.push(`${url}/results`)}
            >
              {t('coursesTopSectionForm.confirmTheResultsOfTheCourse')}
            </TopManageButton>
            <TopManageButton onClick={(): void => history.push(`${url}/edit`)}>
              <SettingsOutlinedIcon className={classes.outlineIcon} />
              {t('coursesTopSectionForm.courseManagement')}
            </TopManageButton>
            {role === 'assistant' && (
              <Btn
                onClick={(): void =>
                  history.push(`/courses/${courseId}/learner`)
                }
              >
                {t('coursesTopSectionForm.studentMode')}
              </Btn>
            )}
          </CommonDivFlexEnd>
        )}
      </TopContainer>
      <CommonDivider />
    </>
  );
};

export default React.memo(TopSectionForm);
