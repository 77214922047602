import React from 'react';

interface MypageProps {
  Container: React.FC;
  TopText: React.FC;
  BottomGraph: React.FC | null;
}

const Mypage: React.FC<MypageProps> = ({ Container, TopText, BottomGraph }) => {
  return (
    <Container>
      <TopText />
      {BottomGraph && <BottomGraph />}
    </Container>
  );
};

export default Mypage;
