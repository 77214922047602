import styled from 'styled-components';

const PeerReviewOptionTable = styled.table`
  @media (min-width: 768px) {
    table-layout: fixed;
    margin-top: 14px;
  }
  @media (max-width: 767px) {
    margin-top: 8px;
  }
  width: 100%;
`;

export default PeerReviewOptionTable;
