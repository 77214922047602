import React, { useContext, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { FlexDiv } from '../../../templates/EssayWriting';
import QuillEditorSection from '../../../components/organisms/quillEditorEssayWritings/QuillEditorSection';
import SwitchPerformanceContext from '../SwitchPage/context';
import QuillEditorEssayWritingContext from './context';
import { WritingEssayStageObjectType } from '../../../apis/performances';
import QuillFooterSection from '../../../components/organisms/quillEditorEssayWritings/QuillFooterSection';
import { switchStage } from '../../EditAssignment/context';
import { STAGES } from '../../../utils/constants';

const QuillEditorEssayWriting: React.FC = () => {
  const { stage_object, progress } = useContext(SwitchPerformanceContext);
  const editorRef = useRef<ReactQuill>(null);
  const assertedStageObject = stage_object as WritingEssayStageObjectType;
  const [value, setValue] = useState(assertedStageObject.content);
  const isRevisement = switchStage(progress.stage || '') === STAGES.REVISEMENT;
  return (
    <QuillEditorEssayWritingContext.Provider
      value={{
        editorRef,
        value,
        setValue,
        isRevisement,
      }}
    >
      <FlexDiv>
        <QuillEditorSection />
        <QuillFooterSection />
      </FlexDiv>
    </QuillEditorEssayWritingContext.Provider>
  );
};

export default QuillEditorEssayWriting;
