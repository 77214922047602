import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReportPage from 'pages/Report';
import TopSection from '../../../components/organisms/performAssignment/TopSection';
import AssignmentInfo from '../AssignmentInfo';
import PeerReviewMatching from '../PeerReviewMatching';
import {
  MainContainer,
  PerformAssignmentWholeDiv,
} from '../../../components/atoms';
import { assignmentLearnerInfo } from '../../../apis';
import PerformanceContext from './context';
import SwitchPage from '../SwitchPage';
import LoadingPage from '../../LoadingPage';

const PerformanceMain: React.FC = () => {
  const {
    path,
    params: { courseId, assignmentId },
  } = useRouteMatch();
  const [isFold, setFold] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('');
  const { data, isLoading, isError, refetch } = useQuery(
    ['assignmentInfo', courseId, assignmentId],
    assignmentLearnerInfo,
  );

  if (isError) {
    return <Redirect to="/" />;
  }

  if (!data || isLoading) {
    return <LoadingPage />;
  }

  const pathParts = path.split('/');
  const performanceListPath = pathParts
    .slice(0, pathParts.length - 1)
    .join('/');
  return (
    <PerformanceContext.Provider
      value={{
        ...data.data,
        refetch,
        isFold,
        setFold,
        collapse,
        setCollapse,
        pageTitle,
        setPageTitle,
      }}
    >
      <PerformAssignmentWholeDiv>
        {!collapse && <TopSection />}
        <MainContainer>
          <Switch>
            <Route
              path={`${performanceListPath}/info`}
              component={AssignmentInfo}
              exact
            />
            <Route
              path={`${performanceListPath}/reports/:reportId`}
              component={ReportPage}
              exact
            />
            <Route path={`${path}`} component={SwitchPage} exact />
            <Route
              path={`${path}/peer-review/:matchingId`}
              component={PeerReviewMatching}
              exact
            />
          </Switch>
        </MainContainer>
      </PerformAssignmentWholeDiv>
    </PerformanceContext.Provider>
  );
};

export default React.memo(PerformanceMain);
