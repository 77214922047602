import styled from 'styled-components';

const Section = styled.section`
  @media (min-width: 768px) {
    padding: 24px 32px 32px 32px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
  margin-bottom: 24px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #a8b7be;
`;

export default Section;
