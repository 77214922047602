import React from 'react';
import { Card, TitleMedium, LineGraph } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import useProfiles from '../../../../hooks/profiles/useProfiles';

const BottomGraphForm: React.FC = () => {
  const {
    user: { name },
  } = useProfiles();
  const { t } = useTranslation();
  return (
    <Card>
      <TitleMedium>{t('myPagesBottomGraphForm.score', { name })}</TitleMedium>
      <LineGraph />
    </Card>
  );
};

export default BottomGraphForm;
