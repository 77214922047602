import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  BottomButton,
  CommonTextarea,
  Field,
  FieldWrapper,
  FileLabel,
  FlexDiv,
  Form,
  TitleLarge,
  TitleSmall,
} from 'components/atoms';
import { courseCreateAPI, CourseCreateAPIProps } from 'apis';

import { serializeFormData } from 'utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useProfileActions from '../../../../hooks/profiles/useProfileActions';

export type CourseCreateProps = {
  handleClose: () => void;
  open: boolean;
};

type CourseCreateFormType = CourseCreateAPIProps;

const CourseCreateDialog: React.FC<CourseCreateProps> = ({
  handleClose,
  open,
}) => {
  const { dispatchFetchProfile } = useProfileActions();
  const [files, setFiles] = useState<string[]>([]);
  const { register, handleSubmit } = useForm<CourseCreateFormType>({});
  const history = useHistory();
  const { t } = useTranslation();
  const onSubmit = async (data: CourseCreateFormType): Promise<void> => {
    const formData = serializeFormData(data);
    try {
      const response = await courseCreateAPI({ formData });
      toast.success(t('modalsCourseCreate.lecturesHaveBeenCreated'));
      history.push(`/courses/${response.data.id}/instructor`);
      dispatchFetchProfile();
      handleClose();
    } catch (e) {
      toast.warn(t('modalsCourseCreate.thisIsTheWrongRequest'));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <TitleLarge>{t('modalsCourseCreate.lecturer')}</TitleLarge>
        </DialogTitle>
        <DialogContent>
          <FieldWrapper>
            <TitleSmall>{t('modalsCourseCreate.lectureName')}</TitleSmall>
            <Field
              required
              type="text"
              name="name"
              ref={register}
              maxLength={50}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TitleSmall>{t('modalsCourseCreate.lectures')}</TitleSmall>
            <CommonTextarea name="description" ref={register} />
          </FieldWrapper>
          <FieldWrapper>
            <TitleSmall>{t('modalsCourseCreate.lecturePlan')}</TitleSmall>
            <FileLabel>
              <AttachmentIcon />
              <span>
                {files.length < 1
                  ? t('modalsCourseCreate.addFiles')
                  : files.join(', ')}
              </span>
              <input
                type="file"
                name="files"
                ref={register}
                style={{ display: 'none' }}
                onChange={(e): void => {
                  const uploadFiles = e.target?.files;
                  if (uploadFiles) {
                    setFiles(
                      [...Array.from(uploadFiles)].map((file) => file.name),
                    );
                  }
                }}
              />
            </FileLabel>
          </FieldWrapper>
          <FlexDiv>
            <input
              type="checkbox"
              name="is_instructor_learner"
              ref={register}
              style={{ marginRight: '7px' }}
            />
            {t('modalsCourseCreate.theInstructorAlsoPerformsTheAssignment')}
          </FlexDiv>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 16px 24px' }}>
          <BottomButton type="submit">
            {t('modalsCourseCreate.opening')}
          </BottomButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default CourseCreateDialog;
