import styled from 'styled-components';

const NumIconRightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  margin-left: 12px;
  padding-left: 12px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
`;

export default NumIconRightSide;
