import styled from 'styled-components';

const AssignmentDescription = styled.p`
  white-space: pre-wrap;
  margin: 0;
  font-size: 15px;
  padding-bottom: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export default AssignmentDescription;
